import React, {useCallback} from 'react';
import useChampionFunc from "../../../../../common/hooks/useChampionFunc";
import CRTChampion
    from "../../../../Champions/ChampionsContent/ChampionsRank/ChampionsRankingTable/ChampionsRankingTableContent/CRTChampion";
import PatchBlock from "../../../../common/PatchBlock";
import AramBalanceTablePivot from "../../AramBalanceMain/AramBalanceTable/AramBalanceTablePivot";
import BalanceText from "../../../../common/BalanceText";
import styled from "styled-components";
import {useHistory} from "react-router-dom";


const Content = styled.td`
  vertical-align: middle;
`;

const ContentRightBorder = styled(Content)`
  border-right: 1px solid ${props => props.theme.color.champion.border};
`;

const TempWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const WinPickBanText = styled.span`
  text-align: center;
  font-size: 12px;
  color: ${props => props.theme.color.default_gray_font_color};
`;

const PercentText = styled.span`
  font-size: 12px;
  color: ${props => props.theme.color.default_gray_font_color};
  opacity: 0.6;
`;

const ChampWrapper = styled.div`
  width: 160px;
`;

function AramBalanceTableContentMemo({
                                         atkSpeedScale,
                                         haste,
                                         healing,
                                         pickRate,
                                         champion_id,
                                         energyReg,
                                         shieldMod,
                                         winRate,
                                         tenacity,
                                         status,
                                         damageDealt,
                                         damageTaken,
                                         version,
                                         maxDamageDealt,

                                         maxDamageTaken,

                                     }) {

    const {getChampion, getUrlNameByChampion, getChampionName} = useChampionFunc();
    const currentChampion = getChampion(champion_id);
    const renderName = getChampionName(currentChampion);
    const history = useHistory();


    const navigateToBuild = useCallback(() => {
        const champName = getUrlNameByChampion(currentChampion);
        history.push(`/champions/${champName}/build/aram`);
    }, [currentChampion]);
    return <>
        <Content>
            <ChampWrapper onClick={navigateToBuild}>
                <CRTChampion
                    fontSize={"12px"}
                    width={30}
                    disableOnClick={true}
                    height={30}
                    lane={"aram"}
                    imageName={currentChampion?.image_name}
                    paddingLeft={"16px"}
                    championName={renderName}
                    enChampName={"Annie"}
                    version={version}
                />
            </ChampWrapper>
        </Content>
        <Content>
            <PatchBlock
                status={status}
            />
        </Content>
        <Content>
            <TempWrapper>
                <WinPickBanText>{(winRate * 100).toFixed(1)}<PercentText> %</PercentText></WinPickBanText>
            </TempWrapper>
        </Content>
        <ContentRightBorder>
            <TempWrapper>
                <WinPickBanText>{(pickRate * 100).toFixed(1)}<PercentText> %</PercentText></WinPickBanText>
            </TempWrapper>
        </ContentRightBorder>
        <ContentRightBorder>
            <AramBalanceTablePivot
                max={maxDamageDealt}
                value={damageDealt}
            />
        </ContentRightBorder>
        <ContentRightBorder>
            <AramBalanceTablePivot
                max={maxDamageTaken}
                colorReverse={true}
                value={damageTaken}
            />
        </ContentRightBorder>
        <Content>
            <BalanceText>{healing}</BalanceText>
        </Content>
        <Content>
            <BalanceText>{shieldMod}</BalanceText>
        </Content>
        <Content>
            <BalanceText>{tenacity}</BalanceText>
        </Content>
        <Content>
            <BalanceText>{energyReg}</BalanceText>
        </Content>
        <Content>
            <BalanceText noPercent={true}>{haste}</BalanceText>
        </Content>
        <Content>
            <BalanceText>{atkSpeedScale}</BalanceText>
        </Content>
    </>;
}

export default React.memo(AramBalanceTableContentMemo)

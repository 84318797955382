import React from 'react';
import styled from "styled-components";
import {BuildImageItem} from "../../../../common/ImageSrc";

const ExtraItemWrapper = styled.div`
  display: flex;
  flex-direction: row;


  .imgItem {
    width: 32px;
    height: 32px;
    border-radius: 5px;
  }
`;

const EmptyWrapper = styled.div`
  width: 32px;
  height: 32px;
`;

const ArrowBox = styled.div`
  display: flex;
  align-items: center;
    margin: 0 10px;
`;

const AccessoriesBuild = ({data}) => {

    if(!Array.isArray(data)) {
        return <EmptyWrapper/>
    }

    return (
        <ExtraItemWrapper>
            {data.map((item, index) => {
                if (index / 2 === 0) {
                    return (
                        <React.Fragment key={item + "accessores"}>
                            <BuildImageItem itemId={item}/>
                            <ArrowBox>
                                <img src={'/images/icon_collapse_arrow.svg'} width={12} height={12}/>
                            </ArrowBox>
                            <BuildImageItem itemId={data[index + 1]}/>
                        </React.Fragment>
                    )
                }
                return null;
            })}
        </ExtraItemWrapper>
    );
};

export default AccessoriesBuild;

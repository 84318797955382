import React, {useCallback} from 'react';
import styled from "styled-components";

const NavigatorBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${props =>props.theme.color.border_A4};
  border-radius: 6px;
  width: 24px;
  height: 24px;
  background-color: ${props => props.theme.color.default_input_background};

  svg {
    fill: #A4A4B1;
  }

  &:hover {
    cursor: pointer;
    border: 1px solid ${props => props.theme.color.border_53};
    background-color: ${props => props.theme.color.background_A4};

    .down-arrow {
      border-top-color: ${props => props.theme.color.default_gray_font_color_white};
    }

    svg {
      fill: ${props => props.theme.color.default_gray_font_color_white};
    }
  }
`;

const LeftArrowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8px;
  height: 12px;
  transform: matrix(${props => props.matrix});

`;



const RankingTableNavigatorArrow = ({disabled,matrix,Arrow,onClick}) => {
    const onClickNavigator = useCallback(() => {
        if(!disabled && typeof onClick === 'function') {
            onClick();
        }
    },[disabled,onClick])

    return (
        <NavigatorBox disabled={disabled} onClick={onClickNavigator}>
            <LeftArrowWrapper matrix={matrix}>
                <Arrow  color={'#A4A4B1'}/>
            </LeftArrowWrapper>
        </NavigatorBox>
    );
};

export default RankingTableNavigatorArrow;

import React from "react";
import SummarySummonerInfo from "../GameAnalytics/GameSummary/Table/TableContent/SummarySummonerInfo";
import KdaTable from "../GameAnalytics/GameSummary/Table/TableContent/KdaTable";
import DamageGraph from "../GameAnalytics/GameSummary/Table/TableContent/DamageGraph";
import ItemInfo from "../GameAnalytics/GameSummary/Table/TableContent/ItemInfo";
import styled, {useTheme} from "styled-components";
import ArgumentImage from "../../../common/ArgumentImage";
import {FlexBox} from "../../../ui/styled";


const Wrapper = styled.tr`
  height: 42px;
  background-color: ${props => {
    let color = 'transparent';
    if (props?.isme) {
      if (props.win) color = props.theme.color.background_B3D;
      if (!props.win) color = props.theme.color.background_EBC;
    } else {
      if (props.win) color = props.theme.color.background_win;
      if (!props.win) color = props.theme.color.background_lose;
    }

    if (props.hide) {
      if (props.win) color = props.theme.color.background_win;
      if (!props.win) color = props.theme.color.background_lose;
    }
    return color;
  }};

  border-top: 1px solid ${props => props.borderColor};
`;


const TableChild = styled.td`
  vertical-align: middle;
`;




const DamageWrapper = styled.div`
    width: 68px;
`;
const DamageBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
const ArenaGameAnalyticsTableContent = ({
                                            data,
                                            allDamage,
                                            isme,
                                            region,
                                            hide,
                                        }) => {
    const theme = useTheme();

    return (
        <Wrapper isme={isme} win={data.is_win} hide={hide} className={'arena_item'} borderColor={theme.color.champion.border}>
            <TableChild >
                <SummarySummonerInfo
                    disableBar={true}
                    hide={hide}
                    smite={data.smite}
                    data={data}
                    region={region}
                    isme={hide ? false : isme}
                    tier={data.tier}
                    division={data.division}
                    level={data.final_stat_dict.champion_level}
                    champId={data.champion_id}
                    win={data.is_win}
                    summonerName={data.riot_id_name}
                    tag={data.riot_id_tag_line}
                    puuId={data.puu_id}
                    spellFId={data.spell_id_dict.spell_1}
                    spellDId={data.spell_id_dict.spell_2}
                />
            </TableChild>
            <TableChild >
                <FlexBox justifyContent={'center'} width={'100%'}>
                    <ArgumentImage height={24} width={24} imgId={data.arena_augments.playerAugment1}
                                   margin={'0 2px 0 0'}/>
                    <ArgumentImage height={24} width={24} imgId={data.arena_augments.playerAugment2}
                                   margin={'0 2px 0 0'}/>
                    <ArgumentImage height={24} width={24} imgId={data.arena_augments.playerAugment3}
                                   margin={'0 2px 0 0'}/>
                    <ArgumentImage height={24} width={24} imgId={data.arena_augments.playerAugment4}/>
                </FlexBox>
            </TableChild>
            <TableChild >
                <FlexBox justifyContent={'center'} width={'100%'}>
                <KdaTable
                    runaway={data.is_runaway}
                    kill={data.final_stat_dict.kills}
                    death={data.final_stat_dict.deaths}
                    assist={data.final_stat_dict.assists}
                />
                </FlexBox>
            </TableChild>

                <TableChild >
                    <DamageBox>
                        <DamageWrapper>
                            <DamageGraph
                                dmg={data.total_damage_dealt}
                                total={allDamage?.totaldmg}
                                color={theme.color.font_74_EA}
                                barColor={'#EF4D8A'}
                            />
                        </DamageWrapper>
                    </DamageBox>
                </TableChild>

            <TableChild >
                <ItemInfo
                    disable={true}
                    myMatch={data}
                    item={data.final_item_dict}
                />
            </TableChild>
        </Wrapper>
    );
};

const isEquals = (prev, next) => {
    return prev.champion_id === next.champion_id && prev.graphIndex === next.graphIndex && prev.hide === next.hide;
}
export default React.memo(ArenaGameAnalyticsTableContent, isEquals);
export const GET_CHAMPIONS_RANK_REQUEST = 'champions/GET_CHAMPIONS_RANK_REQUEST';
export const GET_CHAMPIONS_RANK_SUCCESS = 'champions/GET_CHAMPIONS_RANK_SUCCESS';
export const GET_CHAMPIONS_RANK_FAILURE = 'champions/GET_CHAMPIONS_RANK_FAILURE';

export const GET_CHAMPIONS_BUILD_REQUEST = 'champions/GET_CHAMPIONS_BUILD_REQUEST';
export const GET_CHAMPIONS_BUILD_SUCCESS = 'champions/GET_CHAMPIONS_BUILD_SUCCESS';
export const GET_CHAMPIONS_BUILD_FAILURE = 'champions/GET_CHAMPIONS_BUILD_FAILURE';

export const GET_CHAMPIONS_BUILD_VERSION_REQUEST = 'champions/GET_CHAMPIONS_BUILD_VERSION_REQUEST';
export const GET_CHAMPIONS_BUILD_VERSION_SUCCESS = 'champions/GET_CHAMPIONS_BUILD_VERSION_SUCCESS';
export const GET_CHAMPIONS_BUILD_VERSION_FAILURE = 'champions/GET_CHAMPIONS_BUILD_VERSION_FAILURE';

export const GET_CHAMPIONS_VERSIONS_REQUEST = 'champions/GET_CHAMPIONS_VERSIONS_REQUEST';
export const GET_CHAMPIONS_VERSIONS_SUCCESS = 'champions/GET_CHAMPIONS_VERSIONS_SUCCESS';
export const GET_CHAMPIONS_VERSIONS_FAILURE = 'champions/GET_CHAMPIONS_VERSIONS_FAILURE';


export const GET_CHAMPIONS_PATCH_HISTORY_REQUEST = 'champions/GET_CHAMPIONS_PATCH_HISTORY_REQUEST';
export const GET_CHAMPIONS_PATCH_HISTORY_SUCCESS = 'champions/GET_CHAMPIONS_PATCH_HISTORY_SUCCESS';
export const GET_CHAMPIONS_PATCH_HISTORY_FAILURE = 'champions/GET_CHAMPIONS_PATCH_HISTORY_FAILURE';


export const GET_MASTERY_RANKING_REQUEST = 'champions/champions/GET_MASTERY_RANKING_REQUEST';
export const GET_MASTERY_RANKING_SUCCESS = 'champions/champions/GET_MASTERY_RANKING_SUCCESS';
export const GET_MASTERY_RANKING_FAILURE = 'champions/champions/GET_MASTERY_RANKING_FAILURE';


export const GET_MASTERY_BUILD_REQUEST = 'champions/champions/GET_MASTERY_BUILD_REQUEST';
export const GET_MASTERY_BUILD_SUCCESS = 'champions/champions/GET_MASTERY_BUILD_SUCCESS';
export const GET_MASTERY_BUILD_FAILURE = 'champions/champions/GET_MASTERY_BUILD_FAILURE';

export const GET_CHAMPIONS_MASTERY_REQUEST = 'champions/GET_CHAMPIONS_MASTERY_REQUEST';
export const GET_CHAMPIONS_MASTERY_SUCCESS = 'champions/GET_CHAMPIONS_MASTERY_SUCCESS';
export const GET_CHAMPIONS_MASTERY_FAILURE = 'champions/GET_CHAMPIONS_MASTERY_FAILURE';
export const PREV_RANKING_LANE = 'champions/PREV_RANKING_LANE';

import React, {useCallback, useMemo} from 'react';
import styled, {useTheme} from "styled-components";
import {useSelector} from "react-redux";
import PositionChart from "./PositionChart";
import OverviewHeader from "../OverviewHeader";
import AiScoreBox from "./AiScoreBox";
import TierPrediction from "./TierPrediction";
import {useTranslation} from "react-i18next";
import OverviewKDA from "./OverviewKDA";
import {Loading} from "../../../common";
import NoGameBox from "../../../common/NoGameBox";
import OverviewGames from "./Games";
import {Cell, Label, Pie, PieChart} from "recharts";


const FlexBox = styled.div`
  display: flex;
  flex: ${props => props.flex || 1};
`;

const CenterBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: ${props => props.flex || 1};
  justify-content: center;
`;


const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 76px;
`;

const LoadingBox = styled.div`
  height: 123px;
`;


const queueArray = [{
    queue: 100,
    value: 'ranked_solo_5x5',
}, {
    queue: 430,
    value: 'ranked_flex_sr',
}, {
    queue: 420,
    value: 'ranked_solo_5x5'
}, {
    queue: 490,
    value: 'ranked_flex_sr'
}]


const OverviewContent = ({queue, matches}) => {
    const {t} = useTranslation();
    const {
        match,
        summoner,
        getMatchByQueueLoading,
        getMatchByQueueError
    } = useSelector((state) => state.summoner);
    const queueMemo = useMemo(() => queueArray.find((data) => data.queue === queue), [queue]);
    const theme = useTheme();


    const positionArray = useMemo(() => {
        try {
            if (Array.isArray(matches) && matches.length > 0 && summoner?.summoner_basic_info_dict?.puu_id) {
                const result = matches.reduce((sum, data) => {
                    if (data?.participants_list?.length > 0) {
                        const findMymatch = data.participants_list.find((item) => summoner.summoner_basic_info_dict?.puu_id === item.puu_id)
                        sum.push({
                            champion_id: findMymatch.champion_id,
                            position: findMymatch.position,
                            ai_score: findMymatch.ai_score,
                            is_win: findMymatch.is_win,
                            kills: findMymatch.final_stat_dict.kills,
                            deaths: findMymatch.final_stat_dict.deaths,
                            assists: findMymatch.final_stat_dict.assists,
                            queue_id: data.match_basic_dict.queue_id,
                        });
                    }
                    return sum;
                }, [])
                return result.slice(0, 100);
            }
            return [];
        } catch (e) {
            console.log(e);
            return []
        }
    }, [summoner, matches]);


    const aiScoreArray = useCallback(() => {
        let filterRank = [];
        if (queue === 'ALL') {
            filterRank = positionArray.filter((data) => data.queue_id === 420);
        } else {
            filterRank = positionArray.filter((data) => data.queue_id === 420 || data.queue_id === 440);
        }
        return filterRank.map((data) => data.ai_score);
    }, [positionArray, queue]);

    const KdaArray = useCallback(() => {
        const result = positionArray.reduce((sum, cur) => {
            if (cur.queue_id === 3000 || cur.queue_id === 1810 || cur.queue_id === 1820 || cur.queue_id === 1830 || cur.queue_id === 1840) {
                return sum;
            }
            sum.kills += cur.kills;
            sum.deaths += cur.deaths
            sum.assists += cur.assists
            return sum;

        }, {
            kills: 0,
            deaths: 0,
            assists: 0,
        });

        return {
            kills: result.kills / positionArray.length,
            deaths: result.deaths / positionArray.length,
            assists: result.assists / positionArray.length,
        }

    }, [positionArray]);

    const winArray = useMemo(() => {
        return positionArray.filter((data) => data.is_win);
    }, [positionArray]);

    const loseArray = useMemo(() => {
        return positionArray.filter((data) => data.is_win === false);
    }, [positionArray])


    const chartData = [{
        name: 'lose',
        value: loseArray.length,
    }, {
        name: 'win',
        value: winArray.length,
    }]

    const color = [theme.color.background_E84, theme.color.background_53]

    if (getMatchByQueueError) return <NoGameBox>{"Error"}</NoGameBox>
    if (getMatchByQueueLoading) return <LoadingBox><Loading/></LoadingBox>
    if (typeof summoner?.season_tier_info_dict?.ranked_solo_5x5?.tier !== 'string') return null;
    if (match.length === 0) return <NoGameBox>{t('notice.noGame')}</NoGameBox>

    return (
        <Wrapper>
            <FlexBox flex={0.75}>
                <CenterBox alignItems={'center'} height={'100%'}>
                    <PieChart width={55} height={55}>
                        <Pie dataKey={"value"} data={chartData} fill="#8884d8" innerRadius={18}
                             outerRadius={27} startAngle={-270} isAnimationActive={false}>
                            {chartData.map((data, index) => {
                                return (
                                    <Cell key={index} fill={color[index]} stroke={""}/>
                                )
                            })}
                            <Label
                                value={Math.trunc(winArray.length / matches.length * 100) + "%"} position="center"
                                className='label'
                                fill={color[1]}
                            />
                        </Pie>
                    </PieChart>
                </CenterBox>
                <FlexBox>
                    <OverviewHeader title={t('championDetailBuild.games')}>
                        <OverviewGames
                            games={matches.length}
                            wins={winArray.length}
                            lose={loseArray.length}
                        />
                    </OverviewHeader>
                </FlexBox>
                <FlexBox>
                    <OverviewHeader title={"KDA"}>
                        <OverviewKDA
                            data={KdaArray()}
                        />
                    </OverviewHeader>
                </FlexBox>
                <FlexBox>
                    <OverviewHeader title={t('ingame.avgAiScore')}>
                        <AiScoreBox
                            positionArray={positionArray}
                        />
                    </OverviewHeader>
                </FlexBox>
                <FlexBox>

                    <OverviewHeader title={t('summoner.aiTierPreciction')}
                                    explain={t('summoner.aiTierPredictionExplain')}
                                    explainCondition={aiScoreArray().length < 8}>
                        <TierPrediction
                            tier={queueMemo?.value && summoner.season_tier_info_dict[queueMemo.value].tier}
                            division={queueMemo?.value && summoner.season_tier_info_dict[queueMemo.value].division}
                            ai_score={aiScoreArray()}
                        />
                    </OverviewHeader>

                </FlexBox>
            </FlexBox>
            <FlexBox flex={0.25}>
                <OverviewHeader>
                    <PositionChart
                        positionArray={positionArray}
                    />
                </OverviewHeader>
            </FlexBox>
        </Wrapper>
    );
};

export default OverviewContent;

import React from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent; 
  border-radius: 5px;
  margin: 23px 0;
`;
const AdBox = styled.div`
  width: 970px;
  height: 90px;
  margin: 9px 0;
`;
const PlaywireHeaderAd = ({id = 'summoner_content_top_ad_box'}) => {
    return (
        <Wrapper>
            <AdBox id={id}>

            </AdBox>
        </Wrapper>
    );
};

export default PlaywireHeaderAd;
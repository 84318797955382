import React, {useMemo} from 'react';
import {Loading} from "../../common";
import {ChartLp} from "../../charts";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {ColumnBox, FlexBox} from "../../ui/styled";
import UpArrow from "../../common/TagWithArrow/UpArrow";
import DownArrow from "../../common/TagWithArrow/DownArrow";
import {getDivisionValue, getTierValue} from "../../../function";

const Title = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.color.font_7F};;
`;

const LPText = styled.span`
  font-size: 12px;
  color: ${props => props.theme.color.font_65};;
`;

const ArrowWrapper = styled.div`

  margin-right: 2px;
`;

const LpChartBox = ({loading, chart}) => {
    const {t} = useTranslation();
    const calcDiffValue = (chart) => {
        const league_points = chart.league_points;
        const tierValue = getTierValue(chart.tier);
        const divisionValue = getDivisionValue(chart.division);
        return league_points + tierValue + divisionValue;
    }
    const diff = useMemo(() => {
        try {
            const firstChart = chart.originalArr[0];
            const lastChart = chart.originalArr.at(-1);
            return calcDiffValue(lastChart) - calcDiffValue(firstChart)

        } catch (e) {
            return 0;
        }
    }, [chart]);

    if (!loading) return (
        <Loading/>
    )
    return (
        <ColumnBox>
            <FlexBox justifyContent={'space-between'} width={'100%'} alignItems={'center'} margin={'0 0 12px 0'} height={'auto'}>
                <Title>{t('summoner.recent')} 30{t('summoner.day')}</Title>
                <FlexBox alignItems={'center'} height={'auto'}>
                    <ArrowWrapper>
                        {diff > 0 ? <UpArrow size={4.5}/> : <DownArrow size={4.5}/>}
                    </ArrowWrapper>
                    <LPText>{diff} LP</LPText>
                </FlexBox>
            </FlexBox>
            {!loading ? <Loading/> :
                <ChartLp tierChart={chart} reverse={true}/>
            }
        </ColumnBox>
    );
};

export default React.memo(LpChartBox);

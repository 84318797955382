import {
    GET_CHAMPIONS_BUILD_FAILURE,
    GET_CHAMPIONS_BUILD_REQUEST,
    GET_CHAMPIONS_BUILD_SUCCESS,
    GET_CHAMPIONS_BUILD_VERSION_FAILURE,
    GET_CHAMPIONS_BUILD_VERSION_REQUEST,
    GET_CHAMPIONS_BUILD_VERSION_SUCCESS,
    GET_CHAMPIONS_MASTERY_FAILURE,
    GET_CHAMPIONS_MASTERY_REQUEST,
    GET_CHAMPIONS_MASTERY_SUCCESS,
    GET_CHAMPIONS_PATCH_HISTORY_FAILURE,
    GET_CHAMPIONS_PATCH_HISTORY_REQUEST,
    GET_CHAMPIONS_PATCH_HISTORY_SUCCESS,
    GET_CHAMPIONS_RANK_FAILURE,
    GET_CHAMPIONS_RANK_REQUEST,
    GET_CHAMPIONS_RANK_SUCCESS,
    GET_CHAMPIONS_VERSIONS_FAILURE,
    GET_CHAMPIONS_VERSIONS_REQUEST,
    GET_CHAMPIONS_VERSIONS_SUCCESS,
    GET_MASTERY_RANKING_FAILURE,
    GET_MASTERY_RANKING_REQUEST,
    GET_MASTERY_RANKING_SUCCESS,
    PREV_RANKING_LANE
} from "../reducer/champions";


const championsInit = {
    ranks: {
        all: [],
        top: [],
        jungle: [],
        middle: [],
        bot: [],
        supporter: [],
    },
    masteryGlobal: [],
    masteryChampion: [],
    build: {},
    buildRune: [],
    buildItem: {},
    buildVersion: "",
    patchHistory: {},
    rotations: [],
    championVersions: [],
    prevRankingLane: 'jungle',


    getChampionsRankLoading: false,
    getChampionsRankDone: false,
    getChampionsRankError: null,

    getChampionsBuildLoading: false,
    getChampionsBuildDone: false,
    getChampionsBuildError: null,

    getChampionVersionsLoading: false,
    getChampionVersionsDone: false,
    getChampionVersionsError: null,

    getChampionsBuildVersionLoading: false,
    getChampionsBuildVersionDone: false,
    getChampionsBuildVersionError: null,

    getChampionsPatchHistoryLoading: false,
    getChampionsPatchHistoryDone: false,
    getChampionsPatchHistoryError: null,

    getMasteryRankingLoading: false,
    getMasteryRankingDone: false,
    getMasteryRankingError: null,

    getChampionsMasteryLoading: false,
    getChampionsMasteryDone: false,
    getChampionsMasteryError: null,
}


const reducer = (state = championsInit, action) => {
    switch (action.type) {
        case GET_CHAMPIONS_RANK_REQUEST: {
            return {
                ...state,
                getChampionsRankLoading: true,
                getChampionsRankDone: false,
                getChampionsRankError: null,
            }
        }
        case GET_CHAMPIONS_RANK_SUCCESS : {
            const ranks = [...action?.data?.champion_data_list];

            const top = ranks.filter((data) => data.position_list.includes('Top'));
            const jungle = ranks.filter((data) => data.position_list.includes('Jungle'));
            const mid = ranks.filter((data) => data.position_list.includes('Middle'));
            const bot = ranks.filter((data) => data.position_list.includes('Bot'));
            const sup = ranks.filter((data) => data.position_list.includes('Supporter'));
            const rotations = ranks.reduce((sum, cur) => {
                if (cur.rotation) {
                    sum.push(cur.champion_id)
                }
                return sum;
            }, []);
            return {
                ...state,
                getChampionsRankLoading: false,
                getChampionsRankDone: true,
                ranks: {
                    all: [...ranks],
                    top: [...top],
                    jungle: [...jungle],
                    middle: [...mid],
                    bot: [...bot],
                    supporter: [...sup]
                },
                rotations: [...rotations],
            }
        }
        case GET_CHAMPIONS_RANK_FAILURE: {
            return {
                ...state,
                getChampionsRankLoading: false,
                getChampionsRankError: action.error,
            }
        }
        case GET_CHAMPIONS_BUILD_REQUEST: {
            return {
                ...state,
                build: {
                    build: [],
                },
                buildRune: [],
                buildItem: {},
                getChampionsBuildLoading: true,
                getChampionsBuildDone: false,
                getChampionsBuildError: null,
                getChampionsBuildVersionError: null,
            }
        }
        case GET_CHAMPIONS_BUILD_SUCCESS: {
            return {
                ...state,
                build: {...action.data.build},
                buildRune: [...action.data.rune],
                buildItem: {...action.data.item},
                buildVersion: action.data.currentVersion,
                getChampionsBuildLoading: false,
                getChampionsBuildDone: true,
            }
        }
        case GET_CHAMPIONS_BUILD_FAILURE: {
            return {
                ...state,
                getChampionsBuildLoading: false,
                getChampionsBuildError: action.error,
            }
        }
        case GET_CHAMPIONS_VERSIONS_REQUEST: {
            return {
                ...state,
                getChampionVersionsLoading: true,
                getChampionVersionsDone: false,
                getChampionVersionsError: false,
            }
        }
        case GET_CHAMPIONS_VERSIONS_SUCCESS: {
            return {
                ...state,
                championVersions: [...action.data.versions],
                getChampionVersionsLoading: false,
                getChampionVersionsDone: true,
            }
        }
        case GET_CHAMPIONS_VERSIONS_FAILURE: {
            return {
                ...state,
                getChampionVersionsLoading: false,
                getChampionVersionsError: action.error,
            }
        }
        case GET_CHAMPIONS_BUILD_VERSION_REQUEST: {
            return {
                ...state,
                build: {...state.build},
                getChampionsBuildVersionLoading: true,
                getChampionsBuildVersionDone: false,
                getChampionsBuildVersionError: null,
            }
        }
        case GET_CHAMPIONS_BUILD_VERSION_SUCCESS: {
            return {
                ...state,
                build: {...action.data},
                getChampionsBuildVersionLoading: false,
                getChampionsBuildVersionDone: true,
            }
        }
        case GET_CHAMPIONS_BUILD_VERSION_FAILURE: {
            return {
                ...state,
                getChampionsBuildVersionLoading: false,
                getChampionsBuildVersionError: action?.error,
            }
        }
        case GET_CHAMPIONS_PATCH_HISTORY_REQUEST: {
            return {
                ...state,
                getChampionsPatchHistoryLoading: true,
                getChampionsPatchHistoryDone: false,
                getChampionsPatchHistoryError: null,
            }
        }
        case GET_CHAMPIONS_PATCH_HISTORY_SUCCESS: {
            return {
                ...state,
                patchHistory: {...action.data.patchHistory},
                getChampionsPatchHistoryLoading: false,
                getChampionsPatchHistoryDone: true,
            }
        }
        case GET_CHAMPIONS_PATCH_HISTORY_FAILURE: {
            return {
                ...state,
                getChampionsPatchHistoryLoading: false,
                getChampionsPatchHistoryError: action.error,
            }

        }
        case GET_MASTERY_RANKING_REQUEST : {
            return {
                ...state,
                getMasteryRankingLoading: true,
                getMasteryRankingDone: false,
                getMasteryRankingError: null,
                masteryGlobal: [],
            }
        }
        case GET_MASTERY_RANKING_SUCCESS: {
            return {
                ...state,
                getMasteryRankingLoading: false,
                getMasteryRankingDone: true,
                masteryGlobal: [...action.data.ranking],
            }
        }
        case GET_MASTERY_RANKING_FAILURE: {
            return {
                ...state,
                getMasteryRankingLoading: false,
                getMasteryRankingError: action.error,
            }
        }
        case GET_CHAMPIONS_MASTERY_REQUEST: {
            return {
                ...state,
                getChampionsMasteryLoading: true,
                getChampionsMasteryDone: false,
                getChampionsMasteryError: null,
                masteryChampion: [],
            }
        }
        case GET_CHAMPIONS_MASTERY_SUCCESS: {
            return {
                ...state,
                getChampionsMasteryLoading: false,
                getChampionsMasteryDone: true,
                masteryChampion: [...action.data.ranking],
            }
        }
        case GET_CHAMPIONS_MASTERY_FAILURE: {
            return {
                ...state,
                getChampionsMasteryLoading: false,
                getChampionsMasteryError: action.error,
            }
        }
        case PREV_RANKING_LANE: {
            return {
                ...state,
                prevRankingLane: action.data,
            }
        }
        default:
            return state;
    }
};
export default reducer;

import {Header, Loading, Notice} from "../common";
import CenterContentBox from "../Champions/ContentWrapper";
import {ErrorBoundary} from "react-error-boundary";
import React, {Suspense} from "react";
import ProContent from "../pro/proContent";
import styled from "styled-components";

const Wrapper = styled.div`
`;
function Fallback({ error, resetErrorBoundary }) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.

    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre >{error.message}</pre>
        </div>
    );
}
const Strm = () => {
    return (
        <Wrapper>
            <Header/>
            <Notice fixed={false}/>
            <CenterContentBox disableSponsor={true}>
                <ErrorBoundary FallbackComponent={Fallback}>
                    <Suspense fallback={<Loading/>}>
                        <ProContent status={'streamer'}/>
                    </Suspense>
                </ErrorBoundary>
            </CenterContentBox>
        </Wrapper>
    );
};

export default Strm;
import React, {useCallback, useMemo} from 'react';
import JustRuneImage from "../../ChampionsDetail/ChampionsDetailContent/ChampionsDetailRunes/JustRuneImage";
import DetailMapRune from "../../ChampionsDetail/ChampionsDetailContent/ChampionsDetailRunes/DetailPrimaryRunes";
import styled from "styled-components";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import runeShards from "../../../store/static_json/runeShards";
import runeShards_en from "../../../store/static_json/runeShards_en";
import EmptyWithText from "../EmptyWithText";

const MainRuneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  padding: 0 24px;
`;


const RuneInfoWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: 16px 0px 32px;

  ${MainRuneWrapper}:nth-child(2) {
    border-style: solid;
    border-color: rgba(202, 215, 226, 0.85);
    border-left-width: 1px;
    border-right-width: 1px;
  }
`;

const SubRune = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${props => props.marginTop || 16}px;
`;

const MainRuneInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
`;

const MainRuneInfoBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: ${props => props.marginBottom || 0}px;
`;

const MainRuneText = styled.span`
  font-size: 14px;
  font-weight: 500;
  margin-left: 9px;
`;

const DiamondWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  margin-top: 14px;
`;

const DiamondBar = styled.div`
  width: 95%;
  height: 1px;
  background-color: #CAD7E2;
`;


const AbsoluteDiamon = styled.div`
  position: absolute;
  left: ${props => props.left}px;
  right: ${props => props.right}px;
`;

const Diamond = styled.div`
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-bottom-color: #CAD7E2;
  position: relative;
  top: -4px;

  &:after {
    content: '';
    position: absolute;
    left: -4px;
    top: 4px;
    width: 0;
    height: 0;
    border: 4px solid transparent;
    border-top-color: #CAD7E2;
  }
`;

const FlexWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
`;

const FullRuneView = ({runeList}) => {
    const runes = useSelector((state) => state.imageStore.runes);
    const {t, i18n} = useTranslation();
    const currentRuneShards = useMemo(() => ['ko', 'ko-KR'].includes(i18n.language) ? runeShards : runeShards_en, [i18n.language]);
    const findCategoryRune = useCallback((runeData, findValue) => {
        return runeData.find((data) => data.id === findValue);
    }, []);



    const findChildRune = useCallback((categoryRune = [], findValue) => {
        return categoryRune.find((data) => {
            if (data.id === findValue) {
                return true;
            } else if (Array.isArray(data.runes)) {
                return findCategoryRune(data.runes, findValue);
            }
        });
    }, [findCategoryRune]);


    const findParentRune = useCallback((categoryRune = [], findValue) => {
        try {
            if (typeof findValue === 'number') {
                return categoryRune.find((data) => {
                    if (data.id === findValue) {
                        return true;
                    } else {
                        return findChildRune(data.slots, findValue);
                    }
                });
            }
            return null;
        } catch (e) {
            return [];
        }

    }, [findChildRune]);


    if (runeList?.length === 0 || !runeList) return (
        <div>
            <EmptyWithText
                text={t('autoComplete.noData')}
            />
        </div>
    )


    return (
        <FlexWrapper>
            <RuneInfoWrapper>
                <MainRuneWrapper>
                    <MainRuneInfo>
                        <MainRuneInfoBox marginBottom={8}>
                            <JustRuneImage
                                width={24}
                                height={24}
                                image={findParentRune(runes, runeList?.perk_0).icon}
                            />
                            <MainRuneText>{findParentRune(runes, runeList?.perk_0).name}</MainRuneText>
                        </MainRuneInfoBox>
                    </MainRuneInfo>
                    {findParentRune(runes, runeList?.perk_0).slots.map((data, index) => {
                        return (
                            <React.Fragment key={index + "RuneSelectedPerk1"}>
                                <SubRune
                                    marginTop={index === 1 ? 14 : 16}
                                >
                                    <DetailMapRune
                                        selectedRune={[runeList.perk_0, runeList.perk_1, runeList.perk_2, runeList.perk_3]}
                                        imageBorder={index === 0 ? 0 : 2}
                                        borderColor={"#46B9AA"}
                                        runes={data.runes}
                                    />
                                </SubRune>
                                {index === 0 &&
                                    <DiamondWrapper>
                                        <AbsoluteDiamon left={0}>
                                            <Diamond/>
                                        </AbsoluteDiamon>
                                        <DiamondBar/>
                                        <AbsoluteDiamon right={0}>
                                            <Diamond/>
                                        </AbsoluteDiamon>
                                    </DiamondWrapper>}
                            </React.Fragment>
                        )
                    })}
                </MainRuneWrapper>
                <MainRuneWrapper>
                    <MainRuneInfo>
                        <MainRuneInfoBox marginBottom={20}>
                            <JustRuneImage
                                width={24}
                                height={24}
                                image={findParentRune(runes, runeList.perk_4).icon}
                            />
                            <MainRuneText>{findParentRune(runes, runeList.perk_4).name}</MainRuneText>
                        </MainRuneInfoBox>
                    </MainRuneInfo>
                    {findParentRune(runes, runeList.perk_4).slots.map((data, index) => {
                        if (index === 0) return null;
                        return (
                            <React.Fragment key={index + "RuneSelectedPerk1"}>
                                <SubRune>
                                    <DetailMapRune
                                        selectedRune={[runeList.perk_4, runeList.perk_5]}
                                        borderColor={"#46B9AA"}
                                        runes={data.runes}
                                    />
                                </SubRune>
                            </React.Fragment>
                        )
                    })}
                </MainRuneWrapper>
                <MainRuneWrapper>
                    <MainRuneInfoBox marginBottom={24}>
                        <MainRuneText>{currentRuneShards?.name}</MainRuneText>
                    </MainRuneInfoBox>
                    {Array.isArray(currentRuneShards?.slots) && currentRuneShards.slots.map((data, index) => {
                        const selectedRuneData = () => {
                            if (index === 0) {
                                return [runeList.stat_perk_0];
                            } else if (index === 1) {
                                return [runeList.stat_perk_1]
                            } else if (index === 2) {
                                return [runeList.stat_perk_2]
                            }
                        }
                        return (
                            <SubRune key={index + "RuneShards"} marginTop={22}>
                                <DetailMapRune
                                    height={28}
                                    width={28}
                                    borderColor={"#46B9AA"}
                                    selectedRune={selectedRuneData()}
                                    runes={data.runes}
                                />
                            </SubRune>
                        )

                    })}
                </MainRuneWrapper>
            </RuneInfoWrapper>
        </FlexWrapper>
    );
};

export default FullRuneView;

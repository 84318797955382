import React, {useCallback} from 'react';
import {useTranslation} from "react-i18next";

const UseQueueId = () => {
    const {t} = useTranslation();

    const getMatchName = useCallback((queueId) => {
        switch (queueId) {
            case 0 :
                return t('summoner.custom');
            case 100:
                return t('tabs.total');
            case 400:
                return t('summoner.blind');
            case 420 :
                return t('summoner.matchRankedSolo');
            case 430 :
                return t('summoner.blind');
            case 440 :
                return t('summoner.matchRankedFlex');
            case 450 :
                return t('summoner.aram');
            case 490:
                return t('summoner.fast');
            case 700 :
                return t('summoner.clash');
            case 900 :
                return 'U.R.F';
            case 720 :
                return t('summoner.clashARAM');
            case 1020:
                return t("summoner.oneForAll");
            case 1300:
                return t('summoner.nexusBlitz')
            case 1400:
                return t('summoner.ultbook');
            case 1700:
                return t('summoner.arena.name')
            case 1710:
                return t('summoner.arena.name')
            case 1900 :
                return 'U.R.F';
            case 1810:
                return t('summoner.swarm')
            case 1820:
                return t('summoner.swarm')
            case 1830:
                return t('summoner.swarm')
            case 1840:
                return t('summoner.swarm')
            case 3000:
                return t('summoner.swarm')
            default:
                return queueId;
        }
    }, [t]);
    return {
        getMatchName,
    }
        ;
};

export default UseQueueId;
import React, {useMemo} from 'react';
import styled, {useTheme} from "styled-components";
import {ColumnBox, FlexBox} from "../../../../ui/styled";
import SummonerTags from "../../../../summoner/SummonerTags";
import FullRuneView from "../../../../common/FullRuneView";
import {useTranslation} from "react-i18next";
import {IngameMatchUpItem} from "./ingameMatchUpItem";

const CopyText = styled.span`
  position: absolute;
  top: 40%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 318px;
  background-color: #7181AC;
  height: 36px;
  border-radius: 9px;
  color: ${props => props.theme.color.white_font_color};
  transform: translateX(-50%);
  opacity: 0.8;
  z-index: 23;
`;
const ContentBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const Wrapper = styled.td`
  position: relative;
  height: 419px;
  background-color: ${props => props.theme.color.background_DFE};
  padding: 12px;
`;


const DivBox = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.theme.color.border_83};
  background-color: ${props => props.theme.color.default_input_background};
  margin-bottom: ${props => props.marginBottom};
  flex: 1;
`;

const HegihtWrapper = styled(DivBox)`
  flex: 1;
  height: 394px;
`;


const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  margin-right: 8px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${props => props.theme.color.background_F5_2E};
  height: 28px;
  border-bottom: 1px solid ${props => props.theme.color.border_83};
  font-weight: 500;
  font-size: 12px;
  padding-left: 22px;
`;

const Content = styled.div`
  padding: 8px 16px;
  min-height: 31px;
  background-color: ${props => props.theme.color.background_fff_22};
`;

const MatchUpContent = styled(Content)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 188px;
  height: 312px;
  border-radius: 9px;
  background-color: ${props => props.theme.color.background_F5};
  padding: 20px 0;
  margin: ${props => {
    let margin = 0;
    if (props.margin) margin = props.margin;
    return margin;
  }};
`;

const CardHeader = styled.span`
  font-weight: 500;
  font-size: 16px;
  color: ${props => props.theme.color.default_gray_font_color};
`;

const GameLengthSpan = styled.span`
  font-size: 12px;
  color: #F45256;
`;




const OpenPlayerInfo = ({data, enemyChampionId}) => {
    const theme = useTheme();
    const WINNING_COLOR = theme.color.background_20;
    const LOSING_COLOR = theme.color.font_E5;
    const EMPTY_COLOR = '#DFE3EF';
    const {t} = useTranslation();
    const counterStat = useMemo(() => data.champion_counter_stats, [data]);
    const mySide = useMemo(() => {
        if (data.side === 'BLUE') {
            return {
                win_rate: counterStat.blue_win_rate,
                win_rate_lane: counterStat.blue_win_rate_lane,
                games: counterStat.games,
            }
        } else {
            return {
                win_rate: counterStat.red_win_rate,
                win_rate_lane: counterStat.red_win_rate_lane,
                games: counterStat.games,
            }
        }

    }, [counterStat, data.side]);


    const getSideColo = (value, compareValue) => {
        if (value === compareValue) {
            return WINNING_COLOR;
        } else if (value > compareValue) {
            return WINNING_COLOR;
        } else {
            return LOSING_COLOR;
        }
    }
    const enemySide = useMemo(() => {
        if (data.side === 'BLUE') {
            return {
                win_rate: counterStat.red_win_rate,
                win_rate_lane: counterStat.red_win_rate_lane,
                games: counterStat.games,
            }
        } else {
            return {
                win_rate: counterStat.blue_win_rate,
                win_rate_lane: counterStat.blue_win_rate_lane,
                games: counterStat.games,
            }
        }
    }, [counterStat, data.side]);
    const runeList = data.rune_detail_dict;

    const mySideColor = {
        lane: getSideColo(mySide.win_rate_lane, enemySide.win_rate_lane),
        game: getSideColo(mySide.win_rate, enemySide.win_rate),
    }
    const enemySideColor = {
        lane: getSideColo(enemySide.win_rate_lane, mySide.win_rate_lane),
        game: getSideColo(enemySide.win_rate, mySide.win_rate),
    }



    return (
        <Wrapper colSpan={9}>
            <ContentBox>
                <Column>
                    <DivBox marginBottom={"8px"}>
                        <Header>
                            {t("ingame.tags")}
                        </Header>
                        <Content>
                            <SummonerTags data={data} ranks={1}/>
                        </Content>
                    </DivBox>
                    <DivBox>
                        <Header>
                            {t("ingame.rune")}
                        </Header>
                        <FullRuneView
                            runeList={runeList}
                        />
                    </DivBox>
                </Column>
                <HegihtWrapper>
                    <Header>
                        {t("ingame.matchUp")}
                    </Header>
                    <MatchUpContent>
                        {mySide.games === 0 && <CopyText>{t("ingame.matchUpdataEmpty")}</CopyText>}
                        {(mySide.games > 0 && mySide.games < 20) &&
                            <FlexBox justifyContent={'flex-start'} width={'406px'} height={'20px'}>
                                <GameLengthSpan>* {t("ingame.matchUpdataLow")}</GameLengthSpan>
                            </FlexBox>}
                        <FlexBox>
                            <CardWrapper margin={'0 30px 0 0'}>
                                <CardHeader>{t("ingame.lineWinRate")}</CardHeader>
                                <ColumnBox>
                                    <FlexBox alignItems={'flex-end'}>
                                        {mySide.games === 0 ?
                                            <>
                                                <IngameMatchUpItem win_rate={3} sideColor={EMPTY_COLOR}
                                                                   champion_id={data.champion_id}
                                                                   disableText={true}
                                                                   championBorderColor={WINNING_COLOR}/>
                                                <IngameMatchUpItem win_rate={3} sideColor={EMPTY_COLOR}
                                                                   disableText={true}
                                                                   champion_id={enemyChampionId}
                                                                   championBorderColor={LOSING_COLOR}/>
                                            </> :
                                            <>
                                                <IngameMatchUpItem win_rate={mySide.win_rate_lane}
                                                                   championBorderColor={mySideColor.lane}
                                                                   champion_id={data.champion_id}
                                                                   sideColor={mySideColor.lane}/>
                                                <IngameMatchUpItem win_rate={enemySide.win_rate_lane}
                                                                   championBorderColor={enemySideColor.lane}
                                                                   champion_id={enemyChampionId}
                                                                   sideColor={enemySideColor.lane}/>
                                            </>
                                        }
                                    </FlexBox>
                                </ColumnBox>
                            </CardWrapper>
                            <CardWrapper>
                                <CardHeader>{t("ingame.gameWinRate")}</CardHeader>
                                <ColumnBox>
                                    <FlexBox alignItems={'flex-end'}>
                                        {mySide.games === 0 ?
                                            <>
                                                <IngameMatchUpItem win_rate={3} sideColor={EMPTY_COLOR}
                                                                   disableText={true}
                                                                   champion_id={data.champion_id}
                                                                   championBorderColor={WINNING_COLOR}/>
                                                <IngameMatchUpItem win_rate={3} sideColor={EMPTY_COLOR}
                                                                   disableText={true}
                                                                   champion_id={enemyChampionId}
                                                                   championBorderColor={LOSING_COLOR}/>
                                            </> :
                                            <>
                                                <IngameMatchUpItem win_rate={mySide.win_rate}
                                                                   champion_id={data.champion_id}
                                                                   championBorderColor={mySideColor.game}
                                                                   sideColor={mySideColor.game}/>
                                                <IngameMatchUpItem win_rate={enemySide.win_rate}
                                                                   champion_id={enemyChampionId}
                                                                   championBorderColor={enemySideColor.game}
                                                                   sideColor={enemySideColor.game}/>
                                            </>
                                        }

                                    </FlexBox>
                                </ColumnBox>
                            </CardWrapper>
                        </FlexBox>
                    </MatchUpContent>
                </HegihtWrapper>
            </ContentBox>
        </Wrapper>
    );
};

export default OpenPlayerInfo;

import React, {useCallback} from 'react';
import {threedigits} from "../../../../../../../function";
import styled, {useTheme} from "styled-components";
import WinRateGraph from "../../../../../../common/WinRateGraph";
import ChampionImageWithLane from "../../../../../../common/ChampionImageWithLane";

const ContentTD = styled.td`
  padding: 8px;
`;

const TableContentWrapper = styled.div`
  display: flex;
  align-items: center;

  .imgChamp {
    width: 34px;
    aspect-ratio: 1;
  }
  .imgDiv {
    border-radius: 5px;
  }

`;


const GamesCountText = styled.span`
  font-size: 12px;
  flex: 1;
  text-align: center;
  color: ${props => props.theme.color.font_74_EA};
`;


const ChampSynergyTableItem = ({games, win_rate, champion_id}) => {
    const theme = useTheme();
    const renderWinRatePercent = useCallback((value) => {
        if (value === 100) {
            return Number(value);
        } else {
            return value.toFixed(1)
        }
    }, []);

    return (
        <tr>
            <ContentTD>
                <TableContentWrapper>
                    <ChampionImageWithLane
                        champion_id={champion_id}
                    />
                    <GamesCountText>{threedigits(games)}</GamesCountText>
                </TableContentWrapper>
            </ContentTD>
            <ContentTD>
                <WinRateGraph
                    addPercent={true}
                    dmg={renderWinRatePercent(Number(win_rate))}
                    total={100}
                    color={theme.color.font_74_EA}
                    barColor={'#EF4D8A'}
                    bgColor={theme.color.background_D9}
                />
            </ContentTD>
        </tr>
    );
};

export default React.memo(ChampSynergyTableItem);
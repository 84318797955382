import React, {useCallback} from 'react';
import styled from "styled-components";
import RankingDropBox from "../../../../common/RankingDropBox";
import ChampSearchWrapper from "./ChampSearchWrapper";
import {GET_CHAMPIONS_BY_SEASON_REQUEST} from "../../../../../store/reducer/summoner";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";


const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
`;

const SeasonWrapper = styled.div`
  height: 28px;
`;

const ResetButton = styled.button`
  margin-left: 10px;
  width: 32px;
  height: 32px;
  background: ${props => props.theme.color.background_5C6_606} url('/images/ico_reset.svg') no-repeat center;
  border: 1px solid #474c72;
  border-radius: 3px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
  font-size: 0;
  text-indent: -9999px;

`;

const TableARAMFilter = ({season, seasonList, onChangeSelectedSeason, selectedSeason,onChangeInput,handleReset}) => {
    const dispatch = useDispatch();
    const summoner = useSelector((state) => state.summoner.summoner);
    const {region} = useParams();
    const onChangeSeason = useCallback((e) => {
        const value = e.target.value;
        if (value && summoner) {
            onChangeSelectedSeason(value);
            dispatch({
                type: GET_CHAMPIONS_BY_SEASON_REQUEST,
                data: {
                    platform_id: region,
                    puu_id: summoner.summoner_basic_info_dict.puu_id,
                    season: value,
                }
            });
        }

    }, [summoner]);


    return (
        <Wrapper>
            <SeasonWrapper>
                <RankingDropBox
                    onChange={onChangeSeason}
                    selected={(data) => Number(data) === Number(selectedSeason)}
                    value={(data) => seasonList.find((item) => data === item.value).value}
                    title={(data) => seasonList.find((item) => data === item.value).title}
                    list={season}
                />
            </SeasonWrapper>
            <ChampSearchWrapper
                onChangeInput={onChangeInput}
            >
                <ResetButton onClick={handleReset}/>
            </ChampSearchWrapper>
        </Wrapper>
    );
};

export default TableARAMFilter;





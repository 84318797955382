import React from 'react';
import styled from "styled-components";
import {FlexBox} from "../../ui/styled";
import {useTranslation} from "react-i18next";
import {capitalizeLetter} from "../../../function";


const RoundColor = (props) => {
    let color = props.theme.color.background_99;
    if (props.win === 1) color = '#5C9CEB';
    if (props.win === -1) color = props.theme.color.font_E3;
    return color;
}

const ArenaWrapper = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;


`;

const FlatBox = styled.div`
  flex: 1;
  height: 4px;
  background-color: ${props => RoundColor(props)};

  & + & {
    margin-left: ${props => {
      let margin = '1px';
      if (props.largeText) margin = '2px';
      return margin
    }};
  }
`;

const RoundBox = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 2px;
  background-color: ${props => RoundColor(props)};

  & + & {
    margin-left: 3px;
  }
`;


const ArenaWinLoseSpan = styled.span`
  line-height: 16px;
  font-size: 11px;
  color: ${props => props.theme.color.font_7F_CE};;
  margin-right: 2px;
`;
const ArenaSmallWinLoseSpan = styled(ArenaWinLoseSpan)`
  color: ${props => props.theme.color.font_6B};
  margin-left: 5px;
  font-size: 12px;

`;
const Percent = styled.span`
  font-size: 11px;
  color: ${props => props.theme.color.font_7F_CE};;
  line-height: 16px;
`;
const RoundWinSpan = styled.span`
  font-size: 11px;
  color: ${props => props.theme.color.font_7F_CE};;
`;
const SpanMarginRight = styled.span`
  margin-right: 1px;
`;


const LargeBox = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background-color: ${props => RoundColor(props)};
`;


const ArenaInfo = ({team, height, width, isWin}) => {
    const {t} = useTranslation();


    return (
        <>
            <FlexBox>
                <ArenaSmallWinLoseSpan>(Team {capitalizeLetter(team.id.toLowerCase())})</ArenaSmallWinLoseSpan>
            </FlexBox>
        </>
    )

};

export default ArenaInfo;
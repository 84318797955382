import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const Wrapper = styled.button`
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  font-weight: 500;
  opacity: ${props => {
    let opacity = '0.6';
    if (props.selected) opacity = '1';
    return opacity;
  }};
  color: ${props => {
    let color = props => props.theme.color.default_gray_font_color;
    if (props.selected) color = props => props.theme.color.font_fff;;
    return color;
  }};
  border: 1px solid ${props => {
    let color = props.theme.color.default_bright_border_color;
    if (props.selected) color = 'transparent';
    return color;
  }};
  background-color: ${props => {
    let color =  props.theme.color.background_fff_1A;
    if (props.selected) color = props.theme.color.border_6A;
    return color;
  }};
  padding: 6px 12px;
  font-size: 12px;
  margin-right: 6px;
`;
const SummonerMasteryPatch = ({selected, patch, onClick}) => {
    const {t} = useTranslation()
    return (
        <Wrapper selected={selected} onClick={onClick}>
            {patch === 'All' ? t('summoner.mastery.allPatches') : `Patch ${patch}`}
        </Wrapper>
    );
};

export default SummonerMasteryPatch;
import React, {useMemo} from 'react';
import styled, {useTheme} from "styled-components";

const LaneTag = styled.div`
  cursor: pointer;
  min-width: 54px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: ${props => {
    let background = 'transparent';
    if (props.selected) background = props.theme.color.border_6A;
    return background;
  }};
  color: ${props => {
    let color = props.theme.color.font_6A_EA;
    if (props.selected) color = props => props.theme.color.font_fff;
    return color;
  }};
`;


const LaneBox = styled(LaneTag)`
  opacity: ${props => {
    let opacity = '0.6';
    if (props.selected) opacity = '1';
    return opacity;
  }};
  img {
    margin-right: 4px;
  }
`;

const ValueWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  min-width: ${props => props.width || 'auto'};
  
`;

const ValueText = styled.span`
  font-size: 12px;
`;
const PercentText = styled.span`
  font-size: 12px;
  transform: scale(0.83);
  margin-left: 1px;
`;

const AllLangSpan = styled.span`
  font-size: 12px;
  transform: scale(0.9);
  font-weight: bold;
`;

const ChampLane = ({selectedLane,customList, onChangePosition, mapValue,valueWidth,noPercent}) => {
    const theme = useTheme();
    const basicList = useMemo(() => ['All', 'Top', 'Jungle', 'Middle', 'Bot', 'Supporter'], []);
    const laneName = useMemo(() => {
        if(customList) {
            return customList;
        }
        return basicList;
    },[customList]);

    const getIconName = (lane) => {
        const currentTheme = theme.color.theme;
        if(currentTheme === 'light') {
            return selectedLane.toLowerCase() === lane.toLowerCase() ? "_w" : ""
        } else {
            return "_w"
        }
    }
    return <>
        {laneName.map((lane, index) => {

            if (typeof selectedLane === 'string' && typeof onChangePosition === 'function') {
                if (lane.toLowerCase() === 'all') {
                    return (
                        <LaneTag selected={selectedLane.toLowerCase() === 'all'} onClick={() => onChangePosition("All")} key={index} className={'laneTag'}>
                            <AllLangSpan>
                                ALL
                            </AllLangSpan>
                        </LaneTag>)
                } else {
                    return (
                        <LaneBox key={lane} selected={selectedLane.toLowerCase() === lane.toLowerCase()} onClick={() => onChangePosition(lane)} className={'laneTag'}>
                            <img
                                alt={""}
                                width={16} height={16}
                                src={`${theme.icon.path}/icon_s_position_${lane.toLowerCase()}${getIconName(lane.toLowerCase())}.svg`}
                                onError={(e) => e.target.style.display = 'none'}
                            />
                            {(mapValue && mapValue(lane)) &&
                                <ValueWrapper width={valueWidth}>
                                    <ValueText>
                                        {mapValue(lane)}
                                    </ValueText>
                                    {!noPercent &&
                                    <PercentText>
                                        %
                                    </PercentText>}
                                </ValueWrapper>}
                        </LaneBox>
                    )
                }
            } else {
                return null;
            }
        })}
    </>;
};

export default ChampLane;

import React, {useMemo} from 'react';
import {ImageItem} from "../../common";
import styled from "styled-components";

const LensWrapper = styled.div`
  .imgItem {
    border-radius: 50%;
  }

  .imgItem + .imgItem {
    margin-left: 4px;
  }
`;
const ItemWithMythic = ({item, array, lens, margin}) => {

    const itemMemo = useMemo(() => {
        try {
            if (Array.isArray(array)) {
                return array;
            }
            return [];
        } catch (e) {
            return [];
        }
    }, [array]);

    return (
        <>
            {itemMemo.map((data, index) => {
                return (
                    <div className={'img_wrapper'} key={index}>
                        <ImageItem itemId={data} />
                    </div>
                )
            })}
            {lens &&
                <LensWrapper>
                    <ImageItem itemId={lens}/>
                </LensWrapper>
            }
        </>
    );
};

export default ItemWithMythic;
import React, {useCallback, useEffect, useState} from 'react';
import styled from "styled-components";
import ChampFilter from "../ChampFilter";
import {getNestedObject} from "../../../../function";
import {useSelector} from "react-redux";
import useInput from "../../../../common/hooks/useInput";
import ChampCarousel from "./ChampCarousel";
import ChampionImageWithLane from "../../../common/ChampionImageWithLane";
import ChampSynergyTable from "./ChampSynergyTable";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  width: 1080px;
`;

const FilterWrapper = styled.div`
  background: ${props => props.theme.color.default_input_background};
  padding: 10px 10px 0 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;

const ChampCarouselWrapper = styled.div`
  padding-top: 10px;
  background: ${props => props.theme.color.default_input_background};
`;

const SelectedChampInfo = styled.div`
  border-radius: 6px;
  background: ${props => props.theme.color.background_DD_2E};
  padding: 8px 12px;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  width: fit-content;
`;

const ReletiveDiv = styled.div`
  position: relative;
  width: 36px;
  margin-right: 7px;
`;

const AbsoluteDiv = styled.div`
  position: absolute;
  bottom: -3px;

  .non_image {
    width: 36px;
    height: 36px;
  }

  .imgChamp {
    width: 36px;
    height: 36px;
    margin: 0;
  }

  .imgDiv {
    border-radius: 6px;
  }

`;

const SynergyText = styled.span`
  font-size: 14px;
  color: ${props => props.theme.color.default_gray_font_color};
`;

const TableWrapper = styled.div`

  margin-top: 8px;
`;

const StaticDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-right: 10px;
`;

const EmptyDiv = styled.div`
  width: 100%;
  height: 492px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.color.default_input_background};
`;

const ChampSynergy = ({champCategory, currentCategory, selectedSeason, onChangeSelectedSeason, seasonList}) => {
    const {t} = useTranslation();
    const [position, setPosition] = useState('All');
    const [currentRank, setCurrentRank] = useInput('total');
    const {summonerStatus} = useSelector((state) => state.summoner);
    const [champName, setChampName] = useState('');
    const [currentList, setCurrentList] = useState(getNestedObject(`${champCategory[currentCategory].value}.${currentRank}.pair_champion_stats.${position}`, summonerStatus));
    const [selectedChamp, setSelectedChamp] = useState(0);
    const season = useSelector((state) => state.imageStore.season);
    const champions = useSelector((state) => state.imageStore.champions);


    useEffect(() => {
        setCurrentList(getNestedObject(`${champCategory[currentCategory].value}.${currentRank}.pair_champion_stats.${position}`, summonerStatus));
    }, [currentCategory, currentRank, position])


    const handeLane = useCallback((lane) => {
        if (typeof lane === 'string') {
            setPosition(lane);
            setSelectedChamp(0)
        }
    }, []);

    const handleGameType = useCallback((type) => {
        if (typeof type === 'string') {
            setCurrentRank(type);
            setSelectedChamp(0)
        }
    }, []);

    const handleReset = useCallback(() => {
        setPosition('All');
        setCurrentRank('total');
        setSelectedChamp(0);
    }, []);

    const handleChangeChamp = useCallback((value) => {
        if (typeof value === 'number') {
            setSelectedChamp(value)
        }

    }, []);


    const handleInput = useCallback((e) => {
        const value = e?.target?.value;
        if (typeof value === 'string') {
            setChampName(value);

            const result = getNestedObject(`${champCategory[currentCategory].value}.${currentRank}.pair_champion_stats.${position}`, summonerStatus);
            const regexp = new RegExp(`${value}`);

            if (value.length > 0) {

                setCurrentList(result.filter((data) => {
                    const findChamp = champions.find((champ) => Number(champ.champion_id) === Number(data.champion_id));
                    if (findChamp) {
                        return (regexp.test(findChamp.champion_name_kr) || regexp.test(findChamp.champion_name_en))
                    } else {
                        return false;
                    }

                }));

            } else {
                setCurrentList(result);
            }

        }
    }, [currentCategory, currentRank, position, summonerStatus, champCategory])

    if (currentList.length === 0) {
        return (
            <Wrapper>
                <FilterWrapper>
                    <ChampFilter
                        currentCategory={currentCategory}
                        seasonList={seasonList}
                        season={season}
                        selectedSeason={selectedSeason}
                        onChangeSelectedSeason={onChangeSelectedSeason}
                        summonerStautsLength={Object.values(summonerStatus).length}
                        champName={champName}
                        handleInput={handleInput}
                        laneInfo={getNestedObject(`${champCategory[currentCategory].value}.${currentRank}.position_rate`, summonerStatus)}
                        onChangePosition={handeLane}
                        selectedLane={position}
                        selectedRank={currentRank}
                        handleGameType={handleGameType}
                        handleReset={handleReset}

                    />
                </FilterWrapper>
                <EmptyDiv>{t('notice.noData')}</EmptyDiv>
            </Wrapper>
        )
    }

    return (
        <Wrapper>
            <FilterWrapper>
                <ChampFilter
                    currentCategory={currentCategory}
                    seasonList={seasonList}
                    season={season}
                    selectedSeason={selectedSeason}
                    onChangeSelectedSeason={onChangeSelectedSeason}
                    summonerStautsLength={Object.values(summonerStatus).length}
                    champName={champName}
                    handleInput={handleInput}
                    laneInfo={getNestedObject(`${champCategory[currentCategory].value}.${currentRank}.position_rate`, summonerStatus)}
                    onChangePosition={handeLane}
                    selectedLane={position}
                    selectedRank={currentRank}
                    handleGameType={handleGameType}
                    handleReset={handleReset}

                />
            </FilterWrapper>
            {Object.values(summonerStatus).length === 1 ?
                <EmptyDiv>{t('notice.noData')}</EmptyDiv>
                :
                <>
                    <ChampCarouselWrapper>
                        {currentList.length > 0 &&
                            <ChampCarousel
                                position={position}
                                list={currentList}
                                selectedChamp={selectedChamp}
                                handleChamp={handleChangeChamp}
                            />}
                    </ChampCarouselWrapper>
                    <ContentWrapper>
                        <StaticDiv>
                            <SelectedChampInfo>
                                <ReletiveDiv>
                                    <AbsoluteDiv>
                                        <ChampionImageWithLane
                                            position={position}
                                            champion_id={selectedChamp}
                                        />
                                    </AbsoluteDiv>
                                </ReletiveDiv>
                                <SynergyText>{t('champions.info.synergyInfo')}</SynergyText>
                            </SelectedChampInfo>
                        </StaticDiv>

                        <TableWrapper>
                            <ChampSynergyTable
                                selectedChamp={selectedChamp}
                                position={position}
                                list={currentList}
                            />
                        </TableWrapper>
                    </ContentWrapper>
                </>}
        </Wrapper>
    );
};

export default ChampSynergy;

import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {ErrorBoundary} from "react-error-boundary";
import SummonerContent from "../common/SummonerContent";
import {GET_SUMMONER_REQUEST, INIT_SUMMONER} from "../../store/reducer/summoner";
import {searchList} from "../../store/modules/searchList";
import {useTitle} from "../../function";
import {useTranslation} from "react-i18next";
import {krAdStart, playwire_destory, playwire_start, summoner_ad_content} from "../../ad/bidrich";
import {Header, Modal} from "../common";


const Summoner = () => {
    const modalOpen = useSelector((state) => state?.modal.open);
    const modalData = useSelector((state) => state?.modal.modalData);
    const match = useSelector((state) => state?.modal.match);
    const country = useSelector((state) => state?.imageStore.country);
    const {
        summoner,
        getSummonerDone,
        getSummonerError,
    } = useSelector((state) => state?.summoner);    // useEffect(() => {
    const {t} = useTranslation();
    const titleUpdate = useTitle('Loading...');
    const currentSeason = useSelector((state) => state.imageStore.currentSeason);
    const dispatch = useDispatch();
    const {userName, region, matchId} = useParams();
    const [tempPuuId, setTempPuuId] = useState(JSON.parse(localStorage.getItem("tempPuuId")));


    const currentUserName = useMemo(() => {
        if (userName && summoner) {
            return summoner?.summoner_basic_info_dict?.riot_id_name;
        } else {
            return userName;
        }
    }, [summoner, userName]);

    const currentTagName = useMemo(() => {
        if (userName && summoner) {
            return `#${summoner?.summoner_basic_info_dict?.riot_id_tag_line}`
        } else {
            return '#';
        }
    }, [summoner, userName])

    const currentProName = useMemo(() => {
        if (userName && summoner) {
            return summoner?.summoner_basic_info_dict?.pro_streamer_info_dict?.name
        } else {
            return '';
        }
    }, [summoner, userName])


    const getData = useCallback(() => {
        if (currentSeason) {
            dispatch({
                type: GET_SUMMONER_REQUEST,
                data: {
                    username: userName,
                    region: region.toUpperCase(),
                    matchId: matchId,
                    puu_id: tempPuuId,
                    currentSeason: currentSeason,
                }
            })
        }
    }, [userName, region, matchId, tempPuuId, currentSeason]);
    useEffect(() => {
        getData();
    }, [userName, matchId, region, currentSeason]);

    useEffect(() => {
        return () => {
            dispatch({
                type: INIT_SUMMONER
            })
            if (country !== 'KR') {
                playwire_destory();
            }
        }
    }, []);

    // decodeURI(currentUserName)
    useEffect(() => {
        if (getSummonerDone) {
            dispatch(searchList({...summoner, region: region}, 'add'));

            // window.history.replaceState(null, null, `/summoner/${region}/${currentUserName}`);
            setTempPuuId('');
            if (country === 'KR') {
                krAdStart();
            } else {
                playwire_start(summoner_ad_content);
            }
        }
    }, [getSummonerDone])


    useEffect(() => {
        if (getSummonerError === 404) {
            setTimeout(() => titleUpdate(`${t('header.title')}`), 100);
        }
    }, [getSummonerError]);
    return (
        <>
            <Header
                noticeOff={true}
            />
            <ErrorBoundary fallback={<>Something went wrong...</>}>
                <SummonerContent currentUserName={currentUserName}/>
                {modalOpen && <Modal modalData={modalData} open={modalOpen} match={match}/>}
            </ErrorBoundary>
        </>
    );
};

export default Summoner;

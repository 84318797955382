import React from 'react';
import {getKda, isSwarm} from "../../function";
import {ColumnBox} from "../ui/styled";
import MultiKill from "../common/MultiKill";
import styled from "styled-components";


const KDAText = styled.p`
  line-height: 15px; /* 100% */
  margin-bottom: 3px;
  z-index: 2;

  span {
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
  }

  .slash {
    color: ${props => props.theme.color.font_7F};
    margin: 0 3px;
  }

  .death {
    color: ${props => props.theme.color.font_C2};
  }
`;

const KDAResultBox = styled.p`
  z-index: 2;
  min-width: 84px;
  margin-bottom: 4px;
`;

const KDASpan = styled.span`
  font-size: 12px;
  line-height: 12px;
  color: ${props => props.theme.color.font_7F_CE};
`;


const SwarmFlexBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 139px;
  height: 54px;
`;

const SwarmText = styled.span`
  color: ${props => props.theme.color.default_gray_font_color};
  font-size: 16px;
  font-weight: bold;
`;

const MatchKda = ({match, myMatch}) => {

    if (isSwarm(match.match_basic_dict.queue_id)) {
        return null;
    }


    return (
        <ColumnBox height={'54px'} justifyContent={'center'} alignItems={'center'}
                   margin={'0 0 0 10px'}>
            <KDAText className="type2">
                <span>{myMatch.final_stat_dict.kills}</span>
                <span className={'slash'}>
                                       /
                                   </span>
                <span
                    className="death">{myMatch.final_stat_dict.deaths}</span>
                <span className={'slash'}>
                                       /
                                   </span>
                <span>{myMatch.final_stat_dict.assists}</span>
            </KDAText>
            <KDAResultBox className="type3">
                {myMatch.final_stat_dict.deaths === 0 ? (
                    <KDASpan>Perfect</KDASpan>) : (<>
                    <KDASpan>{getKda(myMatch.final_stat_dict.kills, myMatch.final_stat_dict.deaths, myMatch.final_stat_dict.assists).toFixed(2)} KDA</KDASpan>

                </>)}
            </KDAResultBox>
            <MultiKill
                triple={myMatch?.triple_kills}
                penta={myMatch?.penta_kills}
                quadra={myMatch?.quadra_kills}
            />
        </ColumnBox>);
};

export default MatchKda;
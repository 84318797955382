import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const TypeWrapper = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  flex: 1 0 100%;
  border-color: ${props => props.theme.color.champion.border};
  margin-right: 20px;
  min-width: 110px;
  white-space: nowrap;
`;

const RenderDiv = styled.button`
  width: 100%;
  display: flex;
  flex:1;
  padding: 3px 4px;
  flex-direction: row;
  justify-content: center;
  border-style: solid;
  border-width: 1px;
  border-color: ${props => {
      let color = props => props.theme.color.champion.border;
      if(props.selected) color = '#353063';
      return color;
  }};
  background-color: ${props => {
      let color = props.theme.color.background_FOE;
      if(props.selected) color = props.theme.color.background_5F;
      return color;
  }};
`;

const RenderButton = styled.div`
  font-size: 10px;
  font-weight: 400;
    color: ${props => {
        let color = props => props.theme.color.default_gray_font_color;
        if(props.selected) color = '#FFFFFF';
        return color;
    }};
  opacity: ${props => {
      let opacity = '0.5';
    if(props.selected) opacity = '1';
    return opacity;
  }};
`;

const MultiListType = ({onClickButton, renderType}) => {
    const {t} = useTranslation();
    return (
        <>
            <TypeWrapper>
                <RenderDiv
                    onClick={() => onClickButton(0)}
                    selected={renderType === 0}>
                    <RenderButton
                        selected={renderType === 0}
                        >{t('multi.type.aiScore')}</RenderButton>
                </RenderDiv>
                <RenderDiv
                    onClick={() => onClickButton(1)}
                    selected={renderType === 1}>
                    <RenderButton
                        selected={renderType === 1}>{t('multi.type.KDA')}</RenderButton>
                </RenderDiv>
            </TypeWrapper>
        </>
    );
};

export default MultiListType;
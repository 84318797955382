import React from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.theme.color.champion.border};
  background-color: ${props => props.theme.color.default_input_background};
`;

const ChampionsWhiteWrapper = ({children}) => {
    return (
        <Wrapper className={'champions-wrapper'}>
            {children}
        </Wrapper>
    );
};

export default ChampionsWhiteWrapper;

import React from 'react';

import styled from "styled-components";
import {useTranslation} from "react-i18next";
import RankingTableHeader
    from "../../../../../Champions/ChampionsContent/ChampionsRank/ChampionsRankingTable/RankingTableHeader";
import BigTooltip from "../../../../../Tooltip/BigTooltipBox";

const TableHeaderWrapper = styled.tr`
  border-color: ${props => props.theme.color.champion.border};
  background-color: ${props => props.theme.color.background_F1F};
  border-style: solid;
  border-top-width: 1px;
  border-bottom-width: 1px;
`;

const AramRankTableHeader = ({onClickSort, sortData, currentSort}) => {
    const {t} = useTranslation()

    return (
        <TableHeaderWrapper>
            <RankingTableHeader
                currentSort={currentSort}
                onClickSort={onClickSort}
                sort={sortData.rank}
                title={t('championsRanking.ranking')}
            />
            <RankingTableHeader
                onClickSort={onClickSort}
                title={t('championsRanking.champion')}/>
            <RankingTableHeader
                currentSort={currentSort}
                onClickSort={onClickSort}
                sort={sortData.tier}
                title={t('championsRanking.tier')}/>
            <RankingTableHeader
                currentSort={currentSort}
                onClickSort={onClickSort}
                sort={sortData.win_rate}
                title={t('championsRanking.winRate')}/>
            <RankingTableHeader
                currentSort={currentSort}
                onClickSort={onClickSort}
                title={t('championsRanking.pickRate')}
                sort={sortData.pick_rate}
            />
            <div>
                <BigTooltip tooltipText={t('championsRanking.dpmTooltip')}>
                    <RankingTableHeader
                        currentSort={currentSort}
                        onClickSort={onClickSort}
                        sort={sortData.dpm}
                        title={"DPM"}/>
                </BigTooltip>
            </div>
            <RankingTableHeader
                currentSort={currentSort}
                onClickSort={onClickSort}
                sort={sortData.pentakills}
                title={t('championsRanking.pentaKill')}/>
        </TableHeaderWrapper>
    );
};

export default AramRankTableHeader;

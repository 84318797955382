import React from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ImageBox = styled.div`
  margin: 0 2px;
`;

const NumberBox = styled.div`
  text-align: center;
  width: 14px;
  height: 14px;
  margin: 3px 2px 0;
`;


const TableMultiKill = ({doubleKill,tripleKill,pentaKill,quadraKill}) => {
    return (
        <Wrapper>
            <ImageWrapper>
                <ImageBox>
                    <img src={`/images/ico_damage_p_${pentaKill ?'nor': 'dim'}.svg`} alt={''} width={14} height={14}/>
                </ImageBox>
                <ImageBox>
                    <img src={`/images/ico_damage_q_${quadraKill ?'nor': 'dim'}.svg`} alt={''} width={14} height={14}/>
                </ImageBox>
                <ImageBox>
                    <img src={`/images/ico_damage_t_${tripleKill ?'nor': 'dim'}.svg`} alt={''} width={14} height={14}/>
                </ImageBox>
                <ImageBox>
                    <img src={`/images/ico_damage_d_${doubleKill ?'nor': 'dim'}.svg`} alt={''} width={14} height={14}/>
                </ImageBox>
            </ImageWrapper>
            <ImageWrapper>
                <NumberBox>{pentaKill ? pentaKill : ''}</NumberBox>
                <NumberBox>{quadraKill ? quadraKill : ''}</NumberBox>
                <NumberBox>{tripleKill ? tripleKill : ''}</NumberBox>
                <NumberBox>{doubleKill ? doubleKill : ''}</NumberBox>
            </ImageWrapper>
        </Wrapper>
    );
};

export default TableMultiKill;

import React, {useCallback, useMemo} from 'react';
import {useSelector} from "react-redux";
import styled from "styled-components";
import {isArena, isSwarm, sortChampData} from "../../../../function";
import ChampionImageWithLane from "../../../common/ChampionImageWithLane";
import useQueueId from "../../../../common/hooks/useQueueId";


const Box = styled.button`
  display: flex;
  flex-direction: row;
  padding: 7px 10px;
  margin-right: 6px;
  border: 1px solid ${props => {
    let color = props.theme.color.default_bright_border_color;
    if (props.selected) color = 'transparent';
    return color;
  }};
  border-radius: 5px;
  font-size: 12px;
  background-color: ${props => {
    let color = props.theme.color.background_fff_trans;
    if (props.selected) color = props.theme.color.background_4A;
    return color;
  }};
  color: ${props => {
    let color = props.theme.color.default_gray_font_color;
    if (props.selected) color = props.theme.color.font_fff;
    return color;
  }};
  opacity: ${props => {
    let opacity = '0.6';
    if (props.selected) opacity = '1';
    return opacity
  }};

  &:hover {
    color: ${props => {
      let color = props.theme.color.font_5D_A7;
      if (props.selected) color = props => props.theme.color.font_fff;
      return color;
    }};
    border: 1px solid ${props => {
      let color = props.theme.color.border_5D;
      if (props.selected) color = 'transparent';
      return color;
    }};
    background-color: ${props => {
      let color = props.theme.color.background_fff_1A;
      if (props.selected) color = props.theme.color.background_4A;
      return color;
    }};
    opacity: 1;
  }
`;
const CountSpan = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 11px;
  margin-left: 12px;
`;
const Xspan = styled.span`
  font-size: 11px;
  position: absolute;
  left: -6px;
  bottom: 0.5px;
`;
const ChampBox = styled.button`
  margin-right: 7px;
  margin-top: 3px;
  margin-bottom: 3px;
  opacity: ${props => {
    let opacity = '0.6';
    if (props.selected) opacity = '1';
    return opacity
  }};


  .imgChamp {
    width: 42px;
    height: 42px;
  }

  .imgDiv {
    border-radius: 7px;
  }
`;
const Wrapper = styled.div`
  background-color: ${props => props.theme.color.default_input_background};
  margin-bottom: 9px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  border: 1px solid ${props => props.theme.color.default_border_color};
  border-radius: 6px;
  padding: 0 7px;
`;


const OverviewSortFilter = ({queue, selectedChampionId, onChangeQueue, onChangeChampionId,match}) => {
    const matchInfo = useSelector((state) => state.summoner.match);
    const summoner = useSelector((state) => state.summoner.summoner);

    const {getMatchName} = useQueueId();
    const matchSortValue = useCallback((queueId) => {
        switch (queueId) {
            case 100:
                return 1;
            case 0 :
                return 9;
            case 400:
                return 11;
            case 420 :
                return 2;
            case 430 :
                return 4;
            case 490:
                return 4;
            case 440 :
                return 3;
            case 450 :
                return 5;
            case 900 :
                return 6;
            case 1400:
                return 10;
            case 1900 :
                return 7;
            case 700 :
                return 8;
            case 720 :
                return 9
            default:
                return queueId;
        }
    }, []);

    const matchTypeList = useMemo(() => {
        try {
            const obj = {100: matchInfo.length}
            matchInfo.forEach((data) => {
                let queueId = data.match_basic_dict.queue_id;
                if (isSwarm(queueId)) {
                    queueId = 3000;
                }
                if(isArena(queueId)) {
                    queueId = 1700
                }
                if (obj[queueId]) {
                    obj[queueId]++;
                } else {
                    obj[queueId] = 1;
                }
            });
            const mapped = Object.entries(obj).map((data) => {
                return {
                    sortValue: matchSortValue(Number(data[0])),
                    queue: data[0],
                    count: data[1],
                }
            });
            return sortChampData(mapped, [{value: 'count', sortType: false}, {value: 'sortValue', sortType: true}])
        } catch (e) {
            console.log(e);
            return [];
        }
    }, [matchInfo]);

    const ChampionList = useMemo(() => {
        try {
            const myPuuId = summoner.summoner_basic_info_dict?.puu_id;
            const reduced = match.reduce((sum, cur) => {
                const me = cur.participants_list.find((item) => item.puu_id === myPuuId);
                if (sum?.[me.champion_id]?.value) {
                    sum[me.champion_id].value += 1;
                } else {
                    sum[me.champion_id] = {
                        champion_id: me.champion_id,
                        value: 1,
                        createdAt: cur.match_basic_dict.creation_timestamp,
                    };
                }
                return sum;
            }, {});
            const array = sortChampData(Object.values(reduced), [{value: 'value', sortType: false}, {
                value: 'createdAt', sortType: true,
            }]);
            return array;
        } catch (e) {
            console.log(e);
            return [];
        }
    }, [matchInfo, queue]);

    return (<Wrapper>
        {matchTypeList.map((data, index) => {

            return (
                <Box key={index}
                     selected={Number(data.queue) === queue}
                     onClick={() => onChangeQueue(Number(data.queue))}>{getMatchName(Number(data.queue))}<CountSpan><Xspan>x</Xspan>{data.count}
                </CountSpan></Box>
            )
        })}
        {ChampionList.map((data, index) => {
            return (
                <ChampBox key={index}
                          selected={data.champion_id === selectedChampionId || selectedChampionId === 0}
                          onClick={() => onChangeChampionId(data.champion_id)}
                          style={
                              index === 0 ? {marginLeft: 4} : {}
                          }
                >
                    <ChampionImageWithLane champion_id={data.champion_id} disabled={true} count={data.value}/>
                </ChampBox>
            )
        })}
    </Wrapper>);
};

export default OverviewSortFilter;
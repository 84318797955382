import React, {useCallback} from 'react';

import styled from "styled-components";
import {useTranslation} from "react-i18next";
import RankingTableHeader
    from "../../../../../Champions/ChampionsContent/ChampionsRank/ChampionsRankingTable/RankingTableHeader";

const TableHeaderWrapper = styled.tr`
  border-color: ${props => props.theme.color.champion.border};
  background-color: ${props => props.theme.color.background_F1F};
  border-style: solid;
  border-top-width: 1px;
  border-bottom-width: 1px;
`;

const MasteryRankTableHeader = ({onClickSort, sortData, currentSort}) => {
    const {t} = useTranslation()
    const onClickTierSort = useCallback((sort) => {
        onClickSort(sort, [{value: "lp", sortType: !sort.sortType}]);
    }, []);
    return (
        <TableHeaderWrapper>
            <RankingTableHeader
                title={t('#')}
                currentSort={currentSort}
                onClickSort={onClickTierSort}
                sort={sortData.rank}
            />
            <RankingTableHeader
                onClickSort={onClickSort}
                title={t('mastery.region')}/>
            <RankingTableHeader
                currentSort={currentSort}
                onClickSort={onClickSort}
                title={t('mastery.summonerName')}/>
            <RankingTableHeader
                currentSort={currentSort}
                onClickSort={onClickTierSort}
                sort={sortData.tier}
                title={t('mastery.tier')}/>
            <RankingTableHeader
                currentSort={currentSort}
                onClickSort={onClickSort}
                title={t('mastery.playRate')}
                sort={sortData.play_rate}
            />
            <RankingTableHeader
                currentSort={currentSort}
                onClickSort={onClickSort}
                sort={sortData.games}
                title={t('mastery.games')}/>
            <RankingTableHeader
                currentSort={currentSort}
                onClickSort={onClickSort}
                sort={sortData.win_rate}
                title={t('mastery.winRate')}/>
        </TableHeaderWrapper>
    );
};

export default MasteryRankTableHeader;

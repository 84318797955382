import React from 'react';
import ChampSynergy from "../ChampSynergy";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import ChampRenderTableARAM from "../ChampRenderTableARAM";
import RenderTable from "../ChampRenderTable";


const ChampRenderBy = ({
                           currentCategory,
                           champCategory,
                           onChangeSelectedSeason,
                           selectedSeason,
                           setBannerClicked,
                           bannerClicked
                       }) => {
    const {summonerARAM, summonerURF} = useSelector((state) => state.summoner);
    const {t} = useTranslation();


    const seasonList = [
        {
            title: t('season.season2024-2'),
            value: 21,
        },
        {
            title: t('season.season2024'),
            value: 19,
        },
        {
            title: t('season.season2023split'),
            value: 17,
        },
        {
            title: t('season.season2023'),
            value: 15,
        },
        {
            title: t('season.preseason2023'),
            value: 14,
        },
        {
            title: t('season.season2022'),
            value: 13,
        },
        {
            title: t('season.preseason2022'),
            value: 12,
        },
    ]

    if (currentCategory === 0 || currentCategory === 1) {
        return (
            <RenderTable
                setBannerClicked={setBannerClicked}
                bannerClicked={bannerClicked}
                seasonList={seasonList}
                onChangeSelectedSeason={onChangeSelectedSeason}
                selectedSeason={selectedSeason}
                currentCategory={currentCategory}
                champCategory={champCategory}
            />
        );
    } else if (currentCategory === 2) {
        return <ChampSynergy
            seasonList={seasonList}
            onChangeSelectedSeason={onChangeSelectedSeason}
            selectedSeason={selectedSeason}
            currentCategory={currentCategory}
            champCategory={champCategory}
        />;
    } else if (currentCategory === 3) {
        return <ChampRenderTableARAM
            summonerARAM={summonerARAM}
            currentCategory={currentCategory}
            champCategory={champCategory}
            onChangeSelectedSeason={onChangeSelectedSeason}
            selectedSeason={selectedSeason}
            seasonList={seasonList}
        />
    } else if (currentCategory === 4) {
        return <ChampRenderTableARAM
            summonerARAM={summonerURF}
            currentCategory={currentCategory}
            champCategory={champCategory}
            onChangeSelectedSeason={onChangeSelectedSeason}
            selectedSeason={selectedSeason}
            seasonList={seasonList}
        />
    }

    return null;

};

export default ChampRenderBy;

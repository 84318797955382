import {ColumnBox} from "../../../../ui/styled";
import HeightGraph from "../../../../common/HeightGraph";
import ChampionImageWithLane from "../../../../common/ChampionImageWithLane";
import React from "react";
import styled from "styled-components";

const ChampWrapper = styled.div`

  .imgDiv {
    border: 3px solid ${props => props.border};
  }

  .imgChamp {
    width: 38px;
    height: 38px;
  }
  .imgDiv {
    border-radius: 6px;
  }

`;

const GraphWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  margin-bottom: 18px;
`;

const MatchUpText = styled.span`
  color: ${props => props.color};
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 3px;
`;

const Percent = styled.span`
  color: ${props => props.theme.color.default_font_color};
  font-size: 10px;
  opacity: 0.6;
  margin-left: 2px;
`;

const AbsoluteBar = styled.div`
  position: absolute;
  width: 60px;
  height: 1px;
  background-color: ${props => props.theme.color.champion.border};
  bottom: 0;
`;

export function IngameMatchUpItem({win_rate, sideColor, champion_id, championBorderColor, disableText}) {

    return <ColumnBox justifyContent={"flex-end"} alignItems={"center"}
                      margin={"0 22px 0 0"}>
        <GraphWrapper>
            <AbsoluteBar/>
            {!disableText && <MatchUpText
                color={sideColor}>{win_rate.toFixed(1)}<Percent>%</Percent>
            </MatchUpText>}
            <HeightGraph percent={win_rate} barColor={sideColor}/>
        </GraphWrapper>
        <ChampWrapper border={championBorderColor}>
            <ChampionImageWithLane champion_id={champion_id}
                                   borderRadius={"50%"}/>
        </ChampWrapper>
    </ColumnBox>;
}
import React, {useMemo} from 'react';
import styled from "styled-components";
import {NavLink, useLocation} from "react-router-dom";


const NavBlock = styled.div`
  display: flex;
  flex-direction: row;
`;

const TextBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
  padding-left: 20px;
  margin: 10px 0;
  font-size: 18px;
  color: rgba(255, 255, 255, ${props => {
    let opacity = '0.6';
    if (props.selected) opacity = '1';
    return opacity
  }});
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 45px;
  border-radius: 6px;
  padding-right: 20px;
  background-color: ${props => props.backgroundColor};

  ${TextBox}:not(:last-of-type) {
    border-right: 1px solid rgba(255, 255, 255, 0.3);
  }
`;


const ColorHeader = ({list, children}) => {
        const pathName = useLocation().pathname;
        const selected = useMemo(() => {
            return list.find(d => {
                return pathName.indexOf(d.to) > -1;
            });
        }, [list, pathName]);


        if (!selected) {
            return null
        }

        return (
            <Wrapper backgroundColor={selected.backgroundColor}>
                <NavBlock>
                    {list.map((data, index) => {
                        return (
                            <TextBox selected={selected.to === data.to} key={index + "colorHead"}>
                                <NavLink
                                    to={data.to}>{data.title}
                                </NavLink>
                            </TextBox>
                        )
                    })}
                </NavBlock>
                {children}
            </Wrapper>
        );
    }
;

export default ColorHeader;

import React from 'react';
import styled, {useTheme} from "styled-components";
import DownArrow from "../TagWithArrow/DownArrow";

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  background-color: ${props => props.theme.color.background_fff_1A};
  border-radius: 4px;
  margin-right: 18px;
  height: 100%;

  select {
    color: ${props => props.theme.color.default_gray_font_color};
    border: 1px solid ${props => props.theme.color.default_border_color};
    border-radius: 4px;
    padding: 0 20px 0 8px;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 100%;
    //background: ${props => `url("${props.theme.icon.path}/icon_select_arrow.svg")`} no-repeat;
    //background-position: calc(100% - 0.75rem) center !important;
  }
  option {
    background: ${props => props.theme.color.background_fff_1A};
    color:${props => props.theme.color.default_gray_font_color};
  }
`;

const AbsoluteBox = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: calc(100% - 0.75rem);
`;



const RankingDropBox = ({onChange, list, selected, value, title}) => {
    const theme = useTheme();
    return (
        <SelectWrapper>
            <AbsoluteBox>
                <DownArrow size={3} color={theme.color.font_black_60}/>
            </AbsoluteBox>
            <select onChange={onChange}>
                {Array.isArray(list) && list.map((data, index) => {
                    return <option value={value(data)}
                                   selected={selected(data)}
                                   key={index}>{title(data)}</option>
                })}
            </select>
        </SelectWrapper>
    );
};

export default RankingDropBox;

import React, {useCallback} from 'react';
import SummonerMasteryProvider from "../../compound/summonerMasteryProvider";
import {ColumnBox, FlexBox} from "../../ui/styled";
import styled, {useTheme} from "styled-components";
import useMasteryFunc from "../../../common/hooks/useMasteryFunc";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import UseSetCanonical from "../../../common/hooks/useSetCanonical";
import useChampionFunc from "../../../common/hooks/useChampionFunc";
import useTitleAndDescription from "../../../common/hooks/useTitleAndDescription";
import useSummonerTitleData from "../../../common/hooks/useSummonerTitleData";

const HeaderText = styled.span`
  font-size: 14px;
  font-weight: 500;
`;

const ServerText = styled.span`
  font-size: 12px;
  font-weight: normal;
  opacity: 0.6;
`;
const ShareInfoText = styled.span`
  font-weight: 500;
  font-size: 11px;
`;
const TierSelector = styled.select`
  padding: 6px 18px 6px 14px;
  border-radius: 3px;
  height: 30px;
  font-weight: 500;
  line-height: 1.3;
  color: ${props => props.theme.color.default_gray_font_color};
  border-color: ${props => {
    let color = '#313131';
    if (props.borderColor) color = props.borderColor;
    return color;
  }};
  -webkit-appearance: none;
  background: ${props => `url("${props.theme.icon.path}/icon_select_arrow.svg")`} no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  background-color: ${props => props.theme.color.background_fff_1A};
`;


const SummonerMastery = () => {
    const {getChampionName} = useChampionFunc();
    const {
        mastery,
        versionList,
        versionArray,
        onChangeVersion,
        selectedLane,
        onChangeLane,
        overview,
        coreList,
        lanePick,
        onChangeChampion,
        championIndex,
        onChangeCoreSelected,
        coreSelected
    } = useMasteryFunc();
    UseSetCanonical();
    const {t} = useTranslation();
    const {region} = useParams();
    const {currentProName,currentTagName, currentUserName} = useSummonerTitleData();
    const championName = getChampionName(mastery[championIndex]?.champion_id)



    useTitleAndDescription({
        title: t('header.titleSummonerOtp',{
            champion: championName,
            pro:currentProName,
            summoner:currentUserName,
            tag:currentTagName,
        }),
        description: t('header.titleSummonerOtpDescription',{
            champion: championName,
            version: versionList[versionArray],
            pro:currentProName,
            summoner:currentUserName,
            tag:currentTagName,
        })
    })

    const onChangeSelect = useCallback((e) => {
        onChangeVersion(e.target.value)
    }, [versionArray]);
    const theme = useTheme();
    return (
        <SummonerMasteryProvider>
            <ColumnBox padding={'19px 15px 0 15px'} background={'transparent'}>
                <FlexBox alignItems={'center'} margin={'0 0 15px 0'}>
                    <HeaderText>{t('summoner.mastery.champSelect')}<ServerText> ({region.toUpperCase()} {t('summoner.mastery.server')})</ServerText></HeaderText>
                </FlexBox>
                <FlexBox>
                    {mastery.map((data, index) => {
                        return (
                            <SummonerMasteryProvider.Champion
                                key={data.champion_id}
                                selected={index === championIndex}
                                champion_id={data.champion_id}
                                games={data.totalGames}
                                onClick={() => onChangeChampion(data.champion_id)}/>
                        )
                    })}
                </FlexBox>
            </ColumnBox>
            <FlexBox justifyContent={'flex-end'} margin={'16px 0 6px 0'}>
                <ShareInfoText>
                    {t('summoner.mastery.shareInfo')}
                </ShareInfoText>
            </FlexBox>
            <ColumnBox alignItems={'flex-start'} border={`1px solid ${theme.color.default_bright_border_color}`} padding={'13px 15px'}
                       margin={'0 0 8px 0'} background={theme.color.default_input_background}>
                <FlexBox justifyContent={'space-between'} width={'100%'} margin={'0 0 16px 0'}>
                    <SummonerMasteryProvider.LaneFilter selectedLane={selectedLane}
                                                        setSelectedLane={onChangeLane} pickList={lanePick}/>
                    <FlexBox>

                        <FlexBox margin={'0 8px 0 0'}>
                            <TierSelector value={versionArray} borderColor={theme.color.default_border_color} onChange={onChangeSelect}>
                                {versionList.map((data, index) => (<option value={index} key={index}>{data}</option>))}
                            </TierSelector>
                            {/*{versionList.map((data, index) => {*/}
                            {/*    return <SummonerMasteryProvider.PatchFilter*/}
                            {/*        key={data}*/}
                            {/*        patch={data}*/}
                            {/*        selected={versionArray === index}*/}
                            {/*        onClick={() => onChangeVersion(index)}/>*/}
                            {/*})}*/}
                        </FlexBox>

                        <SummonerMasteryProvider.Share/>
                    </FlexBox>
                </FlexBox>

                <SummonerMasteryProvider.Overview
                    overview={overview}
                    iconId={3}
                    patchId={versionList[versionArray]}
                    lane={selectedLane}
                />
            </ColumnBox>
            <SummonerMasteryProvider.Runes
                coreList={coreList}
                coreSelected={coreSelected}
                onChangeCoreSelected={onChangeCoreSelected}
                champId={mastery[championIndex]?.champion_id}
            />
        </SummonerMasteryProvider>
    );
};

export default SummonerMastery;
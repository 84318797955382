import React, {useState} from 'react';
import styled from "styled-components";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import SharedMessage from "./SharedMessage";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  position: relative;
  margin-right: 4px;
`;


const ButtonWrapper = styled.button`
  position: relative;
  border-radius: 3px;
  border: 1px solid ${props => props.theme.color.default_bright_border_color};
  background: ${props => props.theme.color.background_fff_1A1};
  color: ${props => props.theme.color.default_gray_font_color};
  height: 28px;
  min-width: 89px;
  padding: 0 15px;
`;

const ButtonText = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
`;



const ShareText = styled.div`
  color: ${props => props.theme.color.font_6B};
  display: flex;
  border-radius: 3px;
  border: 1px solid ${props => props.theme.color.default_bright_border_color};
  background: ${props => props.theme.color.background_fff_1A1};
  height: 28px;
  flex: 1;
  padding: 0 9px;
  align-items: center;
  margin-right: 4px;
  input {
    color: ${props => props.theme.color.default_gray_font_color};
    flex: 1 1 0;
    border: 0;
    font-size: 12px;
    font-weight: 400;
    user-select: auto;
    background-color: transparent;
  }
`;

const DetailShareTag = ({match, summoner, region}) => {
    const {t} = useTranslation();

    const url = `${window.location.origin}/summoner/${region}/${encodeURIComponent(summoner.summoner_basic_info_dict.riot_id_name)}-${encodeURIComponent(summoner.summoner_basic_info_dict.riot_id_tag_line)}/matches/${match.match_basic_dict.match_id}`


    const [copy, setCopy] = useState({
        copied: false,
    });

    const handleCopy = () => {
        setCopy({copied: true});
        closeCopied();
    };

    // 공유하기 툴팁
    const closeCopied = () => {
        setTimeout(() => {
            setCopy({copied: false});
        }, 2000);
    };

    return (
        <Wrapper>
            <ShareText><input value={url} readOnly={true}/></ShareText>
            <CopyToClipboard onCopy={handleCopy} text={url}>
                <ButtonWrapper>
                    {copy.copied && <SharedMessage/>}
                    <ButtonText>
                        {t('detailTab.share')}
                    </ButtonText>
                </ButtonWrapper>
            </CopyToClipboard>
        </Wrapper>
    );
};

export default DetailShareTag;

import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useTitle} from '../../function';
import {HomeContainer} from '../../styled/HomeStyle';
import {Footer, Header, Notice} from '../common';
import {useDispatch, useSelector} from "react-redux";
import useQuery from "../../common/hooks/useQuery";
import styled, {useTheme} from "styled-components";
import ServerStatusErrorbox from "../common/Error/StatusError";
import NewSearch from "../common/NewSearch";
import {home_ad_conetne} from "../../ad/bidrich";
import useAdHooks from "../../common/hooks/useAdHooks";
import UseSetCanonical from "../../common/hooks/useSetCanonical";


const AdSection = styled.div`
  width: 970px;
  height: 250px;
`;

const BannerWrapper = styled.div`
  margin: 0 0 100px;
  width: 100%;
  min-height: 192px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;

const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled(ColumnBox)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  width: 100%;
`;

// const testTierList = ['challenger','grandmaster','master','diamond','emerald','platinum','gold','silver','bronze','iron','unranked'];

const Home = () => {
    UseSetCanonical();

    const {t, i18n} = useTranslation();
    const titleUpdate = useTitle('Loading...');
    const query = useQuery();
    const dispatch = useDispatch();
    const country = useSelector((state) => state?.imageStore.country);
    // const argumentsInfo = useSelector((state) => state?.imageStore.argumentsInfo);
    useAdHooks(home_ad_conetne);
    const theme = useTheme();

    const [openFeedback, setOpenFeedback] = useState(false);
    setTimeout(() => titleUpdate(t('header.title')), 500);


    // if(true) {
    //     return (
    //         <Wrapper>
    //             {argumentsInfo.map((data) => {
    //                 return (
    //                     <FlexBox>
    //                         <ArgumentImage imgId={data.id} key={data.id} width={48} height={48}/>
    //                     </FlexBox>
    //
    //                 )
    //             })}
    //         </Wrapper>
    //     )
    // }


    return (
        <Wrapper country={country}>
            {/*Rich*/}
            <ColumnBox>
                <Header
                    isHome={true}
                    setOpenView={setOpenFeedback}
                    openView={openFeedback}
                />
                <Notice/>
                <ServerStatusErrorbox/>
            </ColumnBox>
            <HomeContainer>
                <div className="home" id={'home_content_wrapper'}>
                    <h2>
                        <img src={`${theme.icon.path}/ico_logo_w.svg`} alt="DEEPLOL"/>
                    </h2>
                    <BannerWrapper>
                        {/*<HomeNotice/>*/}
                        <NewSearch home/>
                    </BannerWrapper>
                    {/*<HomeVideoBannerWrapper/>*/}
                    <AdSection class={''} id={'home_content_bottom_ad_box'} data-ad={'billboard'}>

                    </AdSection>
                </div>
            </HomeContainer>
            <Footer
                setOpenFeedback={setOpenFeedback}
            />

        </Wrapper>
    );

};

export default Home;

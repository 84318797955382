import React from 'react';
import {useSelector} from "react-redux";
import styled, {useTheme} from "styled-components";

const CampImg = styled.img`
  margin: -3px;
`;


const MatchChampImg = ({champId, fullImage, imgObj, emptyHelmet}) => {
    const version = useSelector((state) => state.gameVersion.version);
    const theme = useTheme();


    if (champId > 3000 && Object.keys(imgObj).length > 0) {
        return (<div style={{overflow: 'hidden'}} className={"imgDiv"}>
            <CampImg
                className="imgChamp"
                src={`./images/swarm/${champId}.png`}
                alt={imgObj.image_name}
                onError={(e) => e.target.src = "/images/icon_non_item.svg"}
            />
        </div>)
    }

    if (Object.keys(imgObj).length > 0) {
        return (
            <div style={{overflow: 'hidden'}} className={"imgDiv"}>
                <CampImg
                    className="imgChamp"
                    src={`${process.env.REACT_APP_IMG}${version}/img/champion/${imgObj.image_name}?${fullImage ? "" : "w=64&h=64"}&f=webp`}
                    alt={imgObj.image_name}
                    onError={(e) => e.target.src = "/images/icon_non_item.svg"}
                />
            </div>
        )
    }

    if (champId === 0) {
        <div style={{overflow: 'hidden'}} className={"imgDiv"}>
            <div className="non_image">
                <CampImg src={`${theme.icon.path}/icon_cell_all.svg`} className="imgChamp" alt={imgObj}/>
            </div>
        </div>
    }

    return (
        <div style={{overflow: 'hidden'}} className={"imgDiv"}>
            <div className="non_image">
                <CampImg
                    src={emptyHelmet ? `${theme.icon.path}/icon_empty_champion.svg` : "/images/icon_non_item.svg"}
                    className="imgChamp" alt={theme.icon.path}/>
            </div>
        </div>
    );
}

export default MatchChampImg;

import React, {useCallback} from 'react';
import styled, {useTheme} from "styled-components";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {ColumnBox, FlexBox} from "../../../ui/styled";
import {hundredCheck, sortChampData, threedigits} from "../../../../function";
import CombinedRuneImages from "../../../common/CombinedRuneImages";
import {ImageSpell} from "../../../common";
import CRTTier
    from "../../../Champions/ChampionsContent/ChampionsRank/ChampionsRankingTable/ChampionsRankingTableContent/CRTTier";
import TagWithArrow from "../../../common/TagWithArrow";
import {BuildImageItem, ChampionRune} from "../../../common/ImageSrc";


const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: ${props => props.width}px;
  border-right: 1px solid rgba(187, 207, 233, 0.3);
  padding: 12px 13px 14px 13px;

  .imgSpell {
    width: 28px;
    border-radius: 5px;
  }

  .imgItem {
    width: 28px;
    border-radius: 5px;
  }
`;


const Text = styled.span`
  font-size: ${props => {
    let size = '12';
    if (props.fontSize) size = props.fontSize;
    return size;
  }}px;
  color: ${props => {
    let color = props.theme.color.default_gray_font_color;
    if (props.color === 'purple') {
      color = props.theme.color.font_74_EA;
    } else if (props.color) {
      color = props.color;
    }
    return color;
  }};
  font-weight: ${props => {
    let weight = '400';
    if (props.fontWeight) weight = props.fontWeight;
    return weight;
  }};
  opacity: ${props => {
    let opacity = '1';
    if (props.opacity) opacity = props.opacity;
    return opacity
  }};
  margin: ${props => props.margin || 0};
`;


const ExtraWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  bottom: 0;
`;

const Wrapper = styled.div`
  height: 100px;
  width: 100%;
  border: 1px solid ${props => props.theme.color.default_bright_border_color};
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  background-color: ${props => props.theme.color.background_fff_1A};

  ${ContentWrapper}:last-of-type {
    border: 0;
  }
`;


// const YellowWrapper = styled.div`
//   border: 2px solid ${props => {
//     let color = 'transparent';
//     if (props.isMythic) color = '#FCB000';
//     return color
//   }};
//   border-radius: 8px;
// `;


styled(Text)`
  width: 17ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 3px;
  padding-bottom: 2px;
`;

const PickText = styled.span`
  font-size: 11px;
  color: ${props => props.theme.color.font_7F};;
  margin-bottom: 4px;
`;

const ReletiveColumn = styled(ColumnBox)`
  position: relative;
`;

const RankPoolText = styled(Text)`
  margin-left: 3px;
`;

function BasicContent({title, children, row, width}) {
    const theme = useTheme();
    return <ContentWrapper width={width}>
        <Text color={theme.color.font_67} fontSize={12}>{title}</Text>
        {row ? <FlexBox height={'100%'} alignItems={'center'}> {children} </FlexBox> :
            <ReletiveColumn width={'100%'} height={"100%"} alignItems={'center'} justifyContent={'center'}>
                {children}
            </ReletiveColumn>}
    </ContentWrapper>;
}

const BuildOverview = ({overview, champion}) => {
    const champions = useSelector((state) => state.imageStore.champions);
    const {t} = useTranslation();
    const theme = useTheme();


    const pickRateSort = useCallback((list, sliceCount) => {
        return sortChampData(list, [{value: 'pick_rate', sortType: false}]).slice(0, sliceCount)
    }, []);


    if (overview.win_rate === 'NaN') return null;
    return (
        <Wrapper>
            <BasicContent title={t('championDetailBuild.tier')} width={66}>
                <FlexBox>
                    <CRTTier
                        height={35}
                        width={35}
                        tier={overview.tier}
                    />
                </FlexBox>
            </BasicContent>
            <BasicContent title={t('championDetailBuild.ranks')} width={83}>
                <Text fontSize={18} color={theme.color.font_58}>{hundredCheck(overview?.rank)}
                    <RankPoolText fontSize={9} color={theme.color.default_gray_font_color} opacity={'0.6'}>
                        / {overview.boundary === 0 ? champions.length : overview.boundary}
                    </RankPoolText>
                </Text>
                <ExtraWrapper>
                    <TagWithArrow
                        multiply={1}
                        fixed={0}
                        percent={""}
                        baseValue={0}
                        value={overview.rank_delta}
                    />
                </ExtraWrapper>
            </BasicContent>
            <BasicContent title={t('championDetailBuild.pickRate')} width={83}>
                <Text fontSize={18} color={theme.color.font_58}>{hundredCheck(overview?.pick_rate) || 0}<Text
                    opacity={'0.6'}
                    fontSize={12}>%</Text></Text>
                <ExtraWrapper justifyContent={'center'}>
                    <PickText>{threedigits(overview?.games)}</PickText>
                </ExtraWrapper>
            </BasicContent>
            <BasicContent title={t('summoner.mastery.winRate')} width={83}>
                <Text fontSize={18} color={theme.color.font_58}>{hundredCheck(overview?.win_rate)}<Text
                    opacity={'0.6'}
                    fontSize={12}>%</Text></Text>
            </BasicContent>
            <BasicContent title={t('championDetailBuild.banRate')} width={83}>
                <Text fontSize={18} color={theme.color.font_58}>{hundredCheck(overview?.ban_rate)}<Text
                    opacity={'0.6'}
                    fontSize={12}>%</Text></Text>
            </BasicContent>
            <BasicContent title={t('summoner.mastery.rune')} row={true} width={91}>
                {pickRateSort(overview.rune, 2).map((data, index) => {
                    const margin = index === 1 ? 0 : '0 12px 0 0'
                    return (
                        <ReletiveColumn height={'100%'} margin={margin} key={index} justifyContent={'center'}>
                            <CombinedRuneImages
                                RuneComponent={ChampionRune}
                                primary={data.rune_main}
                                sub={data.rune_sub}
                                primaryAttr={{width: 32, height: 32}}
                                subAttr={{width: 14, height: 14}}
                            />
                            <ExtraWrapper justifyContent={'center'}>
                                <Text fontSize={11}>{hundredCheck((data.pick_rate * 100).toFixed(0))}<Text fontSize={11}
                                                                                                           margin={'0 0 0 2px'}
                                                                                                           opacity={'0.6'}>%</Text></Text>
                            </ExtraWrapper>
                        </ReletiveColumn>
                    )
                })}
            </BasicContent>
            <BasicContent title={t('summoner.mastery.spell')} row={true} width={110}>
                {pickRateSort(overview.spell, 3).map((data, index) => {
                    const margin = index === 2 ? '0' : '0 5px 0 0';
                    return (<ReletiveColumn height={'100%'} margin={margin} key={index}>
                        <ImageSpell
                            smite={true}
                            spellId={data.spell}
                        />
                        <ExtraWrapper justifyContent={'center'}>
                            <Text fontSize={11}>{hundredCheck((data.pick_rate * 100).toFixed(0))}<Text fontSize={11}
                                                                                                       margin={'0 0 0 2px'}
                                                                                                       opacity={'0.6'}>%</Text></Text>
                        </ExtraWrapper>
                    </ReletiveColumn>)
                })}
            </BasicContent>
            <BasicContent title={t('summoner.mastery.firstCore')} row={true} width={82}>
                {pickRateSort(overview.mythic, 2).map((data, index) => {
                    const margin = index === 1 ? 0 : '0 5px 0 0';
                    return (
                        <ReletiveColumn height={'100%'} margin={margin} key={index}>
                            <BuildImageItem
                                itemId={data.item}
                                smite={true}
                            />
                            <ExtraWrapper justifyContent={'center'}>
                                <Text fontSize={11}>{hundredCheck((data.pick_rate * 100).toFixed(0))}
                                    <Text fontSize={11} margin={'0 0 0 2px'} opacity={'0.6'}>%</Text>
                                </Text>
                            </ExtraWrapper>
                        </ReletiveColumn>
                    )
                })}
            </BasicContent>
            <BasicContent title={t('summoner.mastery.hot')} row={true} width={209}>
                {pickRateSort(overview.core, 6).map((data, index) => {
                    const margin = index === 5 ? '0' : '0 1px 0 0';
                    return (
                        <ReletiveColumn height={'100%'} margin={margin} key={index} justifyContent={'center'}>
                            <BuildImageItem
                                itemId={data.item}
                            />
                            <ExtraWrapper justifyContent={'center'}>
                                <Text fontSize={11}>{hundredCheck((data.pick_rate * 100).toFixed(0))}<Text fontSize={11}
                                                                                                           margin={'0 0 0 2px'}
                                                                                                           opacity={'0.6'}>%</Text></Text>
                            </ExtraWrapper>
                        </ReletiveColumn>
                    )
                })}
            </BasicContent>
            <BasicContent title={t('summoner.mastery.popularBoots')} row={true} width={108}>
                {pickRateSort(overview.shoes, 3).map((data, index) => {
                    const margin = index === 2 ? '0' : '0 5px 0 0';
                    return (
                        <ReletiveColumn height={'100%'} margin={margin} key={index}>
                            <BuildImageItem
                                itemId={data.boots}
                            />
                            <ExtraWrapper justifyContent={'center'}>
                                <Text fontSize={11}>{hundredCheck((data.pick_rate * 100).toFixed(0))}<Text fontSize={11}
                                                                                                           margin={'0 0 0 2px'}
                                                                                                           opacity={'0.6'}>%</Text></Text>
                            </ExtraWrapper>
                        </ReletiveColumn>
                    )
                })}

            </BasicContent>
        </Wrapper>
    );
};

export default BuildOverview;
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import ChampSynergyTableContent from "./ChampSynergyTableContent";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`

`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 22px;

  .wrapper:not(:last-of-type) {
    margin-right: 6px;
  }
  
  ${props => {
      if(!props.more) {
        return `tr:nth-child(n+20) {display:none}`      
      }
  }}
`;

const MoreDiv = styled.div`
  cursor: pointer;
  background: ${props => props.theme.color.background_E3E};
  border: 1px solid ${props => props.theme.color.border_81};
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 41px;
  width: 100%;
`;

const ChampSynergyTable = ({position,list, selectedChamp}) => {
    const {t} = useTranslation();
    // const positionList = ['TOP', 'JUNGLE', 'MIDDLE', 'BOT', 'SUPPORTER'];
    const [more, setMore] = useState(false);
    const [currentList, setCurrentList] = useState(() => {
        if (Array.isArray(list) && list.length > 0) {
            return list.find((data) => data.champion_id === selectedChamp);
        }
        return {};
    });



    const highestMoreMemo = useMemo(() => {
        if (currentList?.stats_by_pair) {
            const topLength = currentList.stats_by_pair.Top.length;
            const jungleLength = currentList.stats_by_pair.Jungle.length;
            const middleLength = currentList.stats_by_pair.Middle.length;
            const botLength = currentList.stats_by_pair.Bot.length;
            const supporterLength = currentList.stats_by_pair.Supporter.length;
            return Math.max(topLength, jungleLength, middleLength, botLength, supporterLength);
        }
        return 0;
    }, [currentList]);

    const handleMore = useCallback(() => {
            setMore(true);
    },[])

    useEffect(() => {
        if (Array.isArray(list) && list.length > 0) {
            const result = list.find((data) => data.champion_id === selectedChamp);
            if (result) {
                setCurrentList(result);
                setMore(0);
            }
        }
    }, [selectedChamp, list]);


    if (!Array.isArray(list) || list.length === 0) return null;

    return (
        <Wrapper >
            <ContentWrapper more={more}>
                <ChampSynergyTableContent
                    positionText={t('championAnalyze.top')}
                    position={'TOP'}
                    selectedPosition={position}
                    list={currentList.stats_by_pair.Top}
                    champion_id={currentList.champion_id}
                    games={currentList.games}
                    winRate={currentList.win_rate}
                />
                <ChampSynergyTableContent
                    positionText={t('championAnalyze.jungle')}
                    position={'JUNGLE'}
                    selectedPosition={position}
                    list={currentList.stats_by_pair.Jungle}
                    champion_id={currentList.champion_id}
                    games={currentList.games}
                    winRate={currentList.win_rate}
                />
                <ChampSynergyTableContent
                    positionText={t('championAnalyze.mid')}
                    position={'MIDDLE'}
                    selectedPosition={position}
                    list={currentList.stats_by_pair.Middle}
                    champion_id={currentList.champion_id}
                    games={currentList.games}
                    winRate={currentList.win_rate}
                />
                <ChampSynergyTableContent
                    positionText={t('championAnalyze.bot')}
                    position={'BOT'}
                    selectedPosition={position}
                    list={currentList.stats_by_pair.Bot}
                    champion_id={currentList.champion_id}
                    games={currentList.games}
                    winRate={currentList.win_rate}
                />
                <ChampSynergyTableContent
                    positionText={t('championAnalyze.support')}
                    position={'SUPPORTER'}
                    selectedPosition={position}
                    list={currentList.stats_by_pair.Supporter}
                    champion_id={currentList.champion_id}
                    games={currentList.games}
                    winRate={currentList.win_rate}
                />
            </ContentWrapper>
            {(!more && highestMoreMemo > 19) &&
                <MoreDiv onClick={handleMore}>
                    {t('summoner.more')}
                </MoreDiv>
            }
        </Wrapper>
    );
};


export default ChampSynergyTable;
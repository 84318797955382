import React from 'react';
import styled from "styled-components";

const Text = styled.span`
  color: ${props => {
    let color = props.theme.color.font_69;
    const NumberedValue = Number(props.children);
    if (NumberedValue >= 4) color = props.theme.color.font_F19;
    else if (NumberedValue >= 3) color = props.theme.color.font_35;
    else if (NumberedValue >= 2) color = "#259A7E";
    return color;
  }};

  
  font-weight: ${props => {
    let weight = 'normal';
    if (props.fontWeight) weight = props.fontWeight;
    return weight;
  }};
  font-size: ${props => {
    let size = '14px';
    if (props.fontSize) size = props.fontSize;
    return size;
  }};
  line-height: ${props => {
    let size = '14px';
    if (props.lineHeight) size = props.lineHeight;
    return size;
  }};
`;

const KDAText = ({children, fontWeight, fontSize,lineHeight}) => {
    return (
        <Text fontWeight={fontWeight} fontSize={fontSize} lineHeight={lineHeight}>{children}</Text>
    );
};

export default KDAText;
// 액션 생성
import {
  GET_IMAGE_DATA_FAILURE,
  GET_IMAGE_DATA_REQUEST,
  GET_IMAGE_DATA_SUCCESS,
  SET_AI_DATA,
  SET_ARGUMENT_INFO,
  SET_ITEM,
  SET_ITEM_INFO,
  SET_OLD_SEASON,
  SET_RUNE,
  SET_SEASON,
  SET_SPELL
} from "../reducer/imageStore";
import runeShards from "../static_json/runeShards";

const IMAGE_STORE = 'imageStore/IMAGE_STORE';

// 액션 함수 내보내기
export const imageStore = (champions,tooltip, country) => ({
  type: IMAGE_STORE,
  champions,
  tooltip,
  country,
});

// 초기 상태 값
const initialState = {
  champions: [],
  spells: {},
  gameVersion : [],
  runes: [],
  runeShards: runeShards,
  items: {},
  aiData: {
    tier_boundary_solo :[],
  },
  oldSeason:[],
  country: "",
  season:[],
  streamerTag:[],
  challenges:{},
  currentSeason: 17,
  tooltip: false,
  itemInfo: {
    mythic_item_lst: [],
    legendary_item_lst:[],
    ornn_item_lst:[],
  },
  argumentsInfo: [],
  getImageDataLoading : false,
  getImageDataDone :false,
  getImageDataError : null,
};

// 리듀서 (순수함수)
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_IMAGE_DATA_REQUEST: {
      return {
        ...state,
        getImageDataLoading : false,
        getImageDataDone :false,
        getImageDataError : null,
      }
    }
    case GET_IMAGE_DATA_SUCCESS: {
      return {
        ...state,
        getImageDataLoading : false,
        getImageDataDone :true,
      }
    }
    case GET_IMAGE_DATA_FAILURE: {
      return {
        ...state,
        getImageDataLoading : false,
        getImageDataDone :false,
        getImageDataError : action.error,
      }
    }
    case SET_OLD_SEASON: {
      return {
        ...state,
        oldSeason: action.data
      }
    }
    case SET_ITEM_INFO: {
      return {
        ...state,
        itemInfo: action.data
      }
    }
    case SET_ARGUMENT_INFO: {
      return  {
        ...state,
         argumentsInfo: action.data,
      }
    }
    case SET_RUNE: {
      return  {
        ...state,
        runes: action.data,
      }
    }
    case SET_ITEM: {
      return  {
        ...state,
        items: action.data,
      }
    }
    case SET_SPELL: {
      return  {
        ...state,
        spells: action.data,
      }
    }
    case SET_SEASON: {
      let currentSeason = 0;
      if(action.data) {
        currentSeason = Number(Math.max.apply(null, action.data.season_list));
      }
      return {
        ...state,
        season: [17,...action.data.season_list.reverse()],
        aramSeason: action.data.aram_season_list.reverse(),
        currentSeason: currentSeason,
      }
    }
    case SET_AI_DATA: {
      return {
        ...state,
        aiData: action.data
      }
    }
    case IMAGE_STORE:
      return {
        ...state,
        champions: action.champions,
        tooltip: action.tooltip,
        country: action.country,
      };
    default:
      return state;
  }
};
export default reducer;

import React from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";

const CategorySpan = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${props => {
    let color = props.theme.color.font_7F;
    if (props.selected) color = props => props.theme.color.font_fff;
    return color;
  }};
  text-align: center;
  font-size: 18px;
  min-width: 12ch;
  padding-inline: 28px;
  padding-block: 6px;
  background: ${props => {
    let background = 'transparent';
    if (props.selected) background = props.theme.color.background_36;
    return background
  }};
  border-radius: 6px;

  &:hover {
    color: ${props => {
      let color = props.theme.color.font_5D;
      if (props.selected) color = props => props.theme.color.font_fff;
      return color;
    }};
    opacity: 1;
  }
`;
const TabItem = ({to, selected, children, onClick}) => {
    return (
        <CategorySpan to={to} selected={selected} onClick={onClick}>{children}</CategorySpan>
    );
};

export default TabItem;
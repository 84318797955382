import React, {useCallback, useState} from 'react';
import ChampionsDetailAgainstTable from "../ChampionsDetailAgainstTable";
import {useTranslation} from "react-i18next";
import useInput from "../../../../common/hooks/useInput";
import {useTheme} from "styled-components";

const ChampionDetailAgainstHard = ({currentBuild, version}) => {
    const {t} = useTranslation();
    const [currentSort, setCurrentSort] = useInput([{value: "win_rate", sortType: true}]);
    const theme = useTheme();
    const [sortList, setSortList] = useState({
        cnt: {
            value: "cnt",
            sortType: true,
        },
        win_rate: {
            value: "win_rate",
            sortType: true,
        },
    });


    const onClickHeader = useCallback((sort) => {
        if (typeof sort === 'object') {
            const copyData = {...sort};
            const copySortData = {...sortList};
            if (copyData) {
                if(copySortData[copyData.value]) {
                    const allOff = allOffObject(copySortData);
                    copyData.sortType = !copyData.sortType;
                    allOff[copyData.value].sortType = copyData.sortType;
                    setCurrentSort([{...copyData}]);
                    setSortList(copySortData);
                }
            }
        }
    }, []);


    const allOffObject = useCallback((copyData) => {
        if (typeof copyData === 'object') {
            const copy = {...copyData};
            Object.keys(copy).forEach((key) => {
                copy[key].sortType = true;
            })
            return copy;
        }
        return copyData;
    }, [])


    return (
        <ChampionsDetailAgainstTable
            sortList={sortList}
            currentSort={currentSort}
            onClickHeader={onClickHeader}
            champions={currentBuild?.difficult_champion_list}
            title={t('championDetailBuild.hardAgainst')}
            barColor={theme.color.font_E3}
            wrapperFlex={0.292134}
            width={'318.09px'}
            version={version}

        />
    );
};

export default ChampionDetailAgainstHard;
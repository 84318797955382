import React from 'react';
import styled, {useTheme} from "styled-components";
import {useTranslation} from "react-i18next";
import {ColumnBox} from "../../../ui/styled";
import useChampMasteryHooks from "../../../../common/hooks/useChampMasteryHooks";
import ChampionsDetailBuildProvider from "../../../compound/ChampionsDetailBuildProvider";
import BuildCategory from "../ChampionsDetailBuild/BuildCategory";
import {Route, Switch} from "react-router-dom";
import ChampionDetailAgainstHard from "../ChampionDetailAginstHard";
import ChampionsDetailAgainstEasy from "../ChampionsDetailAginstEasy";
import ChampionsDetailSynergy from "../ChampionsDetailSynergy";
import {useSelector} from "react-redux";
import useSetCanonical from "../../../../common/hooks/useSetCanonical";

const HeaderText = styled.span`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 7px;
`;



const AgainstWrapper = styled.div`
  margin-top: 10px;
  border: 1px solid ${props => props.theme.color.default_bright_border_color};
  background-color: ${props => props.theme.color.background_CF};
  padding: 16px 14px;
  display: flex;

  .againstTable:not(:last-of-type) {
    margin-right: 6px;
  }
`;

const ChampionsDetailMasteryLayout = ({
                                          skillList,
                                          championName,
                                          currentBuild,
                                          championId,
                                          champion,
                                          currentTier,
                                          onChangeTier,
                                          onChangeCurrentVersion,
                                          currentVersion,
                                          urls,
                                          currentLane
                                      }) => {
    const {
        selectedLane,
        overview,
        coreList,
        lanePick,
        onChangeCoreSelected,
        coreSelected,
        synergyWeakStrongList,
    } = useChampMasteryHooks(currentBuild.build, urls[currentLane]);
    const version = useSelector((state) => state?.gameVersion.version);
    const {t} = useTranslation();
    const theme = useTheme();
    useSetCanonical();
    return (
        <ChampionsDetailBuildProvider>
            <ColumnBox alignItems={'flex-start'} border={`1px solid ${theme.color.default_bright_border_color}`} padding={'13px 15px'}
                       margin={'0 0 8px 0'} background={theme.color.default_input_background}>
                {/*<FlexBox justifyContent={'space-between'} width={'100%'} margin={'0 0 16px 0'}>*/}
                {/*    <FlexBox>*/}
                {/*        <ChampionsDetailBuildProvider.LaneFilter selectedLane={selectedLane}*/}
                {/*                                            setSelectedLane={onChangeLane} pickList={lanePick}/>*/}
                {/*    </FlexBox>*/}
                {/*    <FlexBox margin={'0 0 0 28px'}>*/}
                {/*        /!*{versionList.map((data, index) => {*!/*/}
                {/*        /!*    return <SummonerMasteryProvider.PatchFilter*!/*/}
                {/*        /!*        key={data}*!/*/}
                {/*        /!*        patch={data}*!/*/}
                {/*        /!*        selected={versionArray === index}*!/*/}
                {/*        /!*        onClick={() => onChangeVersion(index)}/>*!/*/}
                {/*        /!*})}*!/*/}
                {/*    </FlexBox>*/}
                {/*</FlexBox>*/}
                <BuildCategory
                    championName={championName}
                    selectedLane={selectedLane}
                    lanePick={lanePick}
                    currentTier={currentTier}
                    onChangeTier={onChangeTier}
                    changeVersion={onChangeCurrentVersion}
                    filterCurrentVersion={currentVersion}
                    champion_id={Number(champion.champion_id)}
                    buildInfo={currentBuild}
                    urls={urls ? urls : []}
                />
                <ColumnBox margin={'15px 0 0 0'} width={'100%'}>
                    <HeaderText>{selectedLane === 'aram' ? t('championDetailBuild.summaryAramHeader') : t('championDetailBuild.summaryHeader')}</HeaderText>
                    <ChampionsDetailBuildProvider.Overview
                        version={currentVersion}
                        champion={champion}
                        overview={overview}
                        iconId={3}
                        patchId={0}
                        lane={selectedLane}
                    />

                </ColumnBox>
            </ColumnBox>
            <ChampionsDetailBuildProvider.Runes
                skillList={skillList}
                coreList={coreList}
                coreSelected={coreSelected}
                onChangeCoreSelected={onChangeCoreSelected}
                champId={championId}
            />
            <Switch>
                <Route exact path={`/champions/:champName/build/aram`}>{null}</Route>
                <Route exact path={`/champions/:champName/build/urf`}>{null}</Route>
                <Route>
                    <AgainstWrapper>
                        <ChampionDetailAgainstHard
                            currentBuild={synergyWeakStrongList}
                            version={version}
                        />
                        <ChampionsDetailAgainstEasy
                            currentBuild={synergyWeakStrongList}
                            version={version}
                        />
                        <ChampionsDetailSynergy
                            currentBuild={synergyWeakStrongList}
                            version={version}
                        />
                    </AgainstWrapper>
                </Route>
            </Switch>
        </ChampionsDetailBuildProvider>
    );
};

export default ChampionsDetailMasteryLayout;
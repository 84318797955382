import React, {useMemo} from 'react';
import {FlexBox} from "../../../ui/styled";
import MasteryFilterLane from "./MasterFilterLane";
import MasteryFilterRegion from "./MasteryFilterRegion";
import useQuery from "../../../../common/hooks/useQuery";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";

const InfoText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-size: 11px;
  height: 100%;
  opacity: 0.8;
`;
const ChampionDetailMasteryFilter = () => {
    const laneList = ['all', 'top', 'bot', 'supporter', 'jungle', 'middle'];
    const regionList = ['all', 'kr', 'na', 'euw','br','vn'];
    const {t} = useTranslation();
    const query = useQuery();
    const params = useParams();
    const region = query.get('region');
    const position = params?.position
    const selectedRegion = useMemo(() => {
        if (region) {
            const hadRegion = regionList.find((data) => data === region.toLowerCase());
            if (hadRegion) {
                return hadRegion
            }
            return "All";
        }
        return 'All';
    }, [region]);
    const selectedPosition = useMemo(() => {
        if (position) {
            const hadPosition = laneList.find((data) => data === position.toLowerCase());
            if (hadPosition) {
                return hadPosition
            }
            return 'All';
        }
        return 'All';
    }, [position]);
    return (
        <FlexBox justifyContent={'space-between'} alignItems={'flex-start'} height={'auto'}>
            <FlexBox justifyContent={'flex-start'}>
                <MasteryFilterRegion selected={selectedRegion}/>
                <MasteryFilterLane selected={selectedPosition}/>
            </FlexBox>
            <InfoText>
                {t('mastery.info')}
            </InfoText>
        </FlexBox>
    );
};

export default ChampionDetailMasteryFilter;
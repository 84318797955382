import BigTooltip from "../../Tooltip/BigTooltipBox";
import React from "react";

const RuneShardLayout = ({selectedRune, data}) => {
    return (
        <BigTooltip
            textAlign={'left'}
            tooltipText={`<span style="color : rgb(23, 199, 145); font-weight: 500">${data.name}</span>\n${data.longDesc ? data.longDesc : data.shortDesc}`}
        >
            <span
                className={`${selectedRune === data.id && 'selected'}`}>
                <img
                    src={`${process.env.REACT_APP_IMG}img/${data.icon}?w=64&h=64&f=webp`}
                    alt="id"
                />
            </span>
        </BigTooltip>
    )
}


export default RuneShardLayout
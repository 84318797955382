import React, {useMemo} from 'react';
import {ColumnBox, FlexBox} from "../../ui/styled";
import SummonerMasteryRuneCore from "./SummonerMasteryRuneCore";
import SummonerMasteryRuneBuild from "./SummonerMasteryRuneBuild";
import styled from "styled-components";
import {ImageItem, ImageSpell} from "../../common";
import SummonerMasteryItem from "./SummonerMasteryItem";
import useGetChampionSpells from "../../../common/hooks/useGetChampionSpells";
import useChampionFunc from "../../../common/hooks/useChampionFunc";
import ChampionSkillImage from "../../common/ChampionSkillImage";
import {useSelector} from "react-redux";
import SummonerMasteryCoreTable from "./SummonerMasteryCoreTable";
import SummonerMasteryStartItem from "./SummonerMasteryStartItem";
import {useTranslation} from "react-i18next";
import SummonerMasterySkillSeq from "./SummonerMasterySkillSeq";
import _ from "lodash";
import {sortChampData} from "../../../function";
import SummonerMasteryRuneList from "./SummonerMasteryRuneList";

const HeaderBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 34px;
  width: 100%;
  border-left: 1px solid ${props => props.theme.color.default_bright_border_color};
  border-top: 1px solid ${props => props.theme.color.default_bright_border_color};
  border-right: 1px solid ${props => props.theme.color.default_bright_border_color};
  color: ${props => props.theme.color.font_5C};
  font-weight: bold;
  font-size: 12px;
  background-color: ${props => props.theme.color.default_input_background};
`;
const EmptyBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 160px;
  border: 1px solid ${props => props.theme.color.default_bright_border_color};
  background-color: ${props => props.theme.color.background_F6};
  font-size: 14px;
`;
const ItemBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: ${props => {
    let margin = 0;
    if (props.margin) margin = props.margin;
    return margin;
  }};

  .imgSpell {
    width: 32px;
    border-radius: 5px;
    margin-right: 8px;
  }


  .imgSkillDiv {
    border: 0;
  }

  .imgSkill {
    border-radius: 5px;
  }
`;
const BackgroundWrapper = styled.div`
  background-color: ${props => props.theme.color.background_CF};
  padding: 10px;
  border: 1px solid ${props => props.theme.color.default_bright_border_color};
`;
const MarginBox = styled.div`
  margin-bottom: 7px;

  .imgItem {
    width: 32px;
    border-radius: 5px;
  }
`;

const ArrowBox = styled.div`
  margin: 0 10px;
`;

const ItemMarginBox = styled.div`
  margin-right: 8px;
`


const RuneListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${props => props.theme.color.default_bright_border_color};
  height: 481px;
  background-color: ${props => props.theme.color.default_input_background};
`;
const SummonerMasteryRunes = ({coreList, champId, coreSelected, onChangeCoreSelected}) => {
    const {t} = useTranslation();
    const {getUrlNameById} = useChampionFunc();
    const version = useSelector((state) => state.gameVersion.version);
    const champName = getUrlNameById(champId);
    const skillList = useGetChampionSpells(champName);
    const allRuneList = useMemo(() => {
        try {
            const copyCoreList = [...coreList];
            copyCoreList.shift();
            const result = copyCoreList.reduce((sum, cur, index) => {
                const sameRune = sum.findIndex((data) => {

                    if (!_.isEqual(data?.rune?.rune_main, cur?.rune?.rune_main)) {
                        return false;
                    }
                    return _.isEqual(data?.rune?.rune_sub, cur?.rune?.rune_sub);
                })



                if (sum.length > 0 && sameRune > -1) {

                    sum[sameRune] = {
                        ...sum[sameRune],
                        win_rate: sum[sameRune].win_rate + cur.win_rate,
                        games: cur.games + sum[sameRune].games,
                        pick_rate: sum[sameRune].pick_rate + cur.pick_rate,
                        count: sum[sameRune].count + 1,
                    };
                } else {
                    sum.push({...cur, count: 1});
                }
                return sum;
            }, []);

            const runeList = result.map((data) => {
                return {
                    ...data.rune,
                    games: data.games,
                    pick_rate: data.pick_rate,
                    win_rate: data.win_rate / data.count,
                }
            }).splice(0, 10);
            return sortChampData(runeList, [{
                sortType: false,
                value: 'games'
            }])
        } catch (e) {
            console.log(e);
            return [];
        }
    }, [coreList]);
    const selectedItem = useMemo(() => {
        const obj = {
            spell: [],
            shoes: [],
            skillMaster: [],
            startItem: [],
            skillBuild: [],
            coreBuild: [],
            rune: {},
        }
        try {
            const item = coreList[coreSelected];
            return {
                spell: item.spell,
                shoes: item.boots,
                skillMaster: item.skill_master_seq,
                startItem: item.start_item,
                skillBuild: item.skill_build,
                coreBuild: [item.item_build_2, item.item_build_3, item.item_build_4, item.item_build_5],
                rune: item.rune,
            }

        } catch (e) {
            return {...obj}
        }
    }, [coreSelected, coreList, skillList]);

    const getSkillName = (index) => {
        switch (index) {
            case 1:
                return 'Q';
            case 2:
                return 'W';
            case 3:
                return 'E';
            case 4:
                return 'R';
            default:
                return ""
        }
    }

    if (coreList.length === 0 || skillList.spells.length === 0) return <EmptyBox>
        {t('summoner.mastery.runCoreEmpty')}
    </EmptyBox>;
    return (
        <FlexBox width={'100%'} alignItems={'flex-start'}>


            <ColumnBox width={'210px'} margin={'0 11px 0 0'}>
                <HeaderBox>{t('summoner.mastery.runeCore')}</HeaderBox>
                <SummonerMasteryRuneCore coreList={coreList} coreSelected={coreSelected}
                                         onChangeCoreSelected={onChangeCoreSelected}/>
            </ColumnBox>
            <ItemBox>
                <HeaderBox>
                    {t('summoner.mastery.runeBuild')}
                </HeaderBox>
                <BackgroundWrapper>
                    <FlexBox alignItems={'flex-start'} height={'100%'} margin={'0 0 10px 0'}>
                        <ItemBox margin={'0 7px 0 0'}>
                            <MarginBox>
                                {coreSelected === 0 ?
                                    <RuneListWrapper>
                                        <SummonerMasteryRuneList list={allRuneList}/>
                                    </RuneListWrapper>
                                    :
                                    <SummonerMasteryRuneBuild runeList={selectedItem.rune}/>}
                            </MarginBox>
                            <SummonerMasteryItem title={t('summoner.mastery.skillMaster')}
                                                 list={selectedItem.skillMaster} minHeight={'143px'}>
                                {(data) => data?.skill?.map((item, index) => {
                                    return <ChampionSkillImage
                                        width={34}
                                        height={34}
                                        key={index}
                                        spell={skillList?.spells[item - 1]}
                                        version={version}
                                        img={skillList?.spells[item - 1]?.image?.full}
                                        absoluteText={getSkillName(item)}
                                    >
                                        {index + 1 !== data?.skill?.length && <ArrowBox>
                                            <img src={'/images/icon_collapse_arrow.svg'} width={12} height={12}/>
                                        </ArrowBox>}
                                    </ChampionSkillImage>
                                })}
                            </SummonerMasteryItem>
                        </ItemBox>
                        <ItemBox>
                            <MarginBox>
                                <SummonerMasteryItem title={t('summoner.mastery.spell')} list={selectedItem.spell}
                                                     minHeight={'123px'}>
                                    {(data) => data?.spell?.map((item, index) => {
                                        return <ImageSpell spellId={item} smite={true} key={index} width={32}
                                                           height={32}/>
                                    })}
                                </SummonerMasteryItem>
                            </MarginBox>
                            <MarginBox>
                                <SummonerMasteryStartItem list={selectedItem.startItem}/>
                            </MarginBox>
                            <MarginBox>
                                <SummonerMasteryItem title={t('summoner.mastery.boots')} list={selectedItem.shoes}
                                                     minHeight={'171px'}>
                                    {(data) => (
                                        <ItemMarginBox>
                                            <ImageItem itemId={data?.boots} width={32} height={32}/>
                                        </ItemMarginBox>
                                    )}
                                </SummonerMasteryItem>
                            </MarginBox>
                            <SummonerMasterySkillSeq list={selectedItem.skillBuild} champion_id={champId} skillList={skillList}/>

                            {/*<SummonerMasteryItem title={t('summoner.mastery.skillBuild')} list={selectedItem.skillBuild} minHeight={'143px'}>*/}
                            {/*    {(data) => data?.skill?.map((item, index) => {*/}
                            {/*        return (*/}
                            {/*            <PaddingBox key={index}>*/}
                            {/*                <ListTagValue>{getSkillName(item)}</ListTagValue>*/}
                            {/*            </PaddingBox>*/}
                            {/*        )*/}
                            {/*    })}*/}
                            {/*</SummonerMasteryItem>*/}
                        </ItemBox>
                    </FlexBox>
                    <SummonerMasteryCoreTable list={selectedItem.coreBuild}/>
                </BackgroundWrapper>
            </ItemBox>
        </FlexBox>
    );
};

export default SummonerMasteryRunes;
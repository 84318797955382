import React, {useCallback, useMemo, useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import CNameLI from "./NameLI";
import {useSelector} from "react-redux";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
`;

const NameUL = styled.ul`
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;


const FullNameUL = styled(NameUL)`
  li {
    width: auto;
    flex: 1;
  }
  li:first-of-type {
    border-radius: 5px 0 0 5px;
  }
  li:last-of-type {
    border-radius: 0 5px 5px 0;
  }
`;

const EngAlphaWrapper = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  border-bottom: 1px solid ${props => props.theme.color.default_bright_border_color};
  border-right: 1px solid ${props => props.theme.color.default_bright_border_color};
  border-left: 1px solid ${props => props.theme.color.default_bright_border_color};
  height: 40px;
  margin-left: 31px;
  border-radius: 0 0 5px 5px;
`;

const AlphabetWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;

`;
const FirstAlphabetBorderBox = styled.div`
  li:first-of-type {
    border-radius: 5px 0 0 5px;
  }
`;
const KoreanWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;

  li:last-of-type {
    border-radius: 0 5px 5px 0;
    width: 31.2px;
  }

  li:first-of-type {
    border-radius: 5px 0 0 5px;
  }
`;

const FullWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
`;




const SecondLayWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 0 0 5px 5px;
  flex-wrap: wrap;

  li:last-of-type {
    width: 31.2px;
    border-radius: 0 0 5px 0;
  }

  li:nth-of-type(14) {
    width: 31.2px;
    border-radius: 0 5px 5px 0;
  }

  li:nth-of-type(15) {
    border-radius: 0 0 0 5px;
  }

`;

const ColumnListBox = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  flex: 1;
`;

const ChampionsCname = ({onClick, width, full}) => {
    const [selected, setSelected] = useState(0);
    const {i18n, t} = useTranslation();
    const allName = useMemo(() => ['전체', 'ALL'], [])
    const korName = useMemo(() => ['ㄱ', 'ㄴ', 'ㄷ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅅ', 'ㅇ', 'ㅈ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ'], []);
    const fullEngName = useMemo(() => ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'], []);
    const endName = useMemo(() => ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N',], []);
    const engName2 = useMemo(() => ['O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'], []);
    const currentLang = useSelector((state) => state.translation.lang);
    const fullRenderName = useMemo(() => currentLang === 'KOR' ? korName : fullEngName, [i18n.language]);

    const onClickLi = useCallback((value, data) => {
        if (value !== undefined && data !== undefined) {
            setSelected(value);

            if (data === allName[0] || data === allName[1]) {
                onClick(false);
            } else {
                onClick(data);
            }

        }
    }, []);

    if (full) {
        return (
            <Wrapper className={'champion-cname'}>
                <FullNameUL>
                    <FullWrapper>
                        <CNameLI
                            onClick={onClickLi}
                            selected={selected === 0}
                            index={0}
                            data={currentLang === 'KOR' ? allName[0] : allName[1]}
                        />
                        {Array.isArray(fullRenderName) && fullRenderName.map((data, index) => {
                            return (
                                <CNameLI
                                    key={data + "choData"}
                                    onClick={onClickLi}
                                    selected={selected === index + 1}
                                    index={index + 1}
                                    data={data}
                                />
                            )
                        })}
                    </FullWrapper>
                </FullNameUL>
            </Wrapper>
        )
    }

    if (currentLang !== 'KOR') {
        return (
            <Wrapper className={'champion-cname'}>
                <NameUL>
                    <AlphabetWrapper>
                        <FirstAlphabetBorderBox>
                            <CNameLI
                                onClick={onClickLi}
                                selected={selected === 0}
                                index={0}
                                data={currentLang === 'KOR' ? allName[0] : allName[1]}
                            />
                        </FirstAlphabetBorderBox>
                        <ColumnListBox>
                            <SecondLayWrapper>
                                {endName.map((data, index) => {
                                    return (
                                        <CNameLI
                                            key={data + "choData"}
                                            onClick={onClickLi}
                                            selected={selected === index + 1}
                                            index={index + 1}
                                            data={data}
                                        />
                                    )
                                })}

                                {engName2.map((data, index) => {
                                    return (
                                        <CNameLI
                                            key={data + "choData"}
                                            onClick={onClickLi}
                                            selected={selected === index + endName.length + 1}
                                            index={index + endName.length + 1}
                                            data={data}
                                        />
                                    )
                                })}
                            </SecondLayWrapper>
                        </ColumnListBox>
                    </AlphabetWrapper>
                </NameUL>
            </Wrapper>
        )
    }

    return (
        <Wrapper className={'champion-cname'}>
            <NameUL>
                <KoreanWrapper>
                    <CNameLI
                        onClick={onClickLi}
                        selected={selected === 0}
                        index={0}
                        data={currentLang === 'KOR' ? allName[0] : allName[1]}
                    />
                    {korName.map((data, index) => {
                        return (
                            <CNameLI
                                key={data + "choData"}
                                onClick={onClickLi}
                                selected={selected === index + 1}
                                index={index + 1}
                                data={data}
                            />
                        )
                    })}
                </KoreanWrapper>
            </NameUL>
        </Wrapper>
    );
};


export default React.memo(ChampionsCname);

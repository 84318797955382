import React, {useCallback, useMemo} from 'react';
import styled from "styled-components";
import {FlexBox} from "../../ui/styled";
import DailyFilterButtonList from "./DailyFilterButtonList";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";


const Wrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${props => props.theme.color.border_C31};
  background-color: ${props => props.theme.color.default_input_background};
  padding: 10px;
  border-radius: 6px;
  row-gap: 10px;
`;

const FilterName = styled.span`
  font-size: 12px;
  color: ${props => props.theme.color.font_7F};
  min-width: 88px;
`;


const DailyFilter = () => {
    const {t} = useTranslation();
    const history = useHistory();
    const {platform, queue, tier} = useParams();
    const {search} = useLocation();


    const date = useMemo(() => {
        const queryString = new URLSearchParams(search);
        if (queryString.get('date') === 'yesterday') {
            return 'yesterday'
        }
        return 'today'
    }, [search]);


    const translateQueue = (value) => {
        if (value === 'Ranked Solo') {
            return 'rank'
        }
        return value.toLowerCase()
    }

    const onClickTier = (value) => {
        if (typeof value === 'string') {
            history.push(`/daily/ai/${platform}/${queue}/${value.toLowerCase()}${search}`)
        }
    }
    const onClickQueue = (value) => {
        if (typeof value === 'string') {
            const translateValue = translateQueue(value);
            if (translateValue === 'aram') {
                history.push(`/daily/ai/${platform}/aram${search}`)
            } else {
                history.push(`/daily/ai/${platform}/${translateValue}/${tier}${search}`)
            }
        }
    }
    const onClickPlatform = (value) => {
        if (typeof value === 'string') {
            history.push(`/daily/ai/${value.toLowerCase()}/${queue}/${tier}${search}`)
        }
    }


    const onClickDate = (value) => {
        if (typeof value === 'string') {
            history.push(`/daily/ai/${platform}/${queue}/${tier}?date=${value}`)
        }
    }

    const tierTrans = useCallback((data) => {
        switch (data.trim()) {
            case "Emerald": {
                return t('gameAnalytics.emerald')
            }
            case "Platinum": {
                return t('gameAnalytics.platinum')
            }
            case "Diamond" : {
                return t('gameAnalytics.diamond')
            }
            case "Master" : {
                return t('gameAnalytics.master')
            }
            case "Grandmi18naster" : {
                return t('gameAnalytics.grandmaster')
            }
            case "Challenger": {
                return t('gameAnalytics.challenger')
            }
            default:
                return ""
        }
    }, []);
    return (
        <Wrapper>
            <FlexBox>
                <FilterName>{t('daily.date')}</FilterName>
                <DailyFilterButtonList
                    minWidth={100}
                    selected={date}
                    list={[
                        {title: t('daily.today'), value: 'today'},
                        {title: t('daily.yesterday'), value: 'yesterday'},

                    ]}
                    onClick={onClickDate}
                />
            </FlexBox>
            <FlexBox>
                <FilterName>{t('proStreamer.region')}</FilterName>
                <DailyFilterButtonList
                    minWidth={70}
                    selected={platform}
                    list={[
                        {title: 'NA', value: 'NA'},
                        {title: 'EUW', value: 'EUW'},
                        {title: 'EUN', value: 'EUN'},
                        {title: 'KR', value: 'KR'},
                        {title: 'JP', value: 'JP'},
                        {title: 'OCE', value: 'OCE'},
                        {title: 'LAN', value: 'LAN'},
                        {title: 'LAS', value: 'LAS'},
                        {title: 'BR', value: 'BR'},
                        {title: 'TR', value: 'TR'},
                        {title: 'TW', value: 'TW'},
                        {title: 'VN', value: 'VN'},
                        {title: 'ME', value: 'ME'}
                    ]}
                    onClick={onClickPlatform}
                />
            </FlexBox>
            <FlexBox>
                <FilterName>{t('daily.queue')}</FilterName>
                <DailyFilterButtonList
                    minWidth={100}
                    selected={queue}
                    list={[{title: t('tabs.rankedSolo'), value: 'rank'}, {
                        title: t('summoner.aram'),
                        value: "aram"
                    }]}
                    onClick={onClickQueue}
                />
            </FlexBox>
            <FlexBox>
                <FilterName>{t('ranking.tier')}</FilterName>
                <DailyFilterButtonList
                    minWidth={88}
                    selected={tier}
                    list={[{title: t('championAnalyze.all'), value: 'all'},
                        {title: t('gameAnalytics.challenger'), value: 'challenger'},
                        {title: t('gameAnalytics.grandmaster'), value: 'grandmaster'},
                        {title: t('gameAnalytics.master'), value: 'master'},
                        {title: t('gameAnalytics.diamond'), value: 'diamond'},
                        {title: t('gameAnalytics.emerald'), value: 'emerald'},
                        {title: t('gameAnalytics.platinum'), value: 'platinum'},
                        {title: t('gameAnalytics.gold'), value: 'gold'},
                        {title: t('gameAnalytics.silver'), value: 'silver'},
                        {title: t('gameAnalytics.bronze'), value: 'bronze'},
                        {title: t('gameAnalytics.iron'), value: 'iron'}
                    ]}
                    onClick={onClickTier}
                />
            </FlexBox>
        </Wrapper>
    );
};

export default DailyFilter;
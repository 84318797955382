import React, {useCallback} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import BuildPickWinTable from "../BuildPickWinTable";
import useChampionFunc from "../../../../common/hooks/useChampionFunc";

const PaddingBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 42px;
`;

const ListTagValue = styled.div`
  margin-right: 2px;
  background-color: ${props => {
    let color = props.theme.color.background_CF;
    if (props.isFirstMastered) color = props.theme.color.background_B2
    if (props.last) color = props.theme.color.background_46;
    return color;
  }};
  font-size: 12px;
  font-weight: 500;
  width: 24px;
  aspect-ratio: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${props => {
    let color = props => props.theme.color.default_gray_font_color;
    if (props.last) color = props => props.theme.color.font_fff;
    return color;
  }};
`;
const BuildSkillSq = ({list, champion_id, skillList}) => {
    const {getChampion} = useChampionFunc()
    const {t} = useTranslation();
    const champion = getChampion(champion_id);

    const getLastSkills = useCallback((array) => {
        try {
            if (Array.isArray(array)) {
                const sliceArray = array.slice(0, 14);
                const qIndex = sliceArray.lastIndexOf(1);
                const qCount = sliceArray.filter((data) => data === 1).length;
                const qMastered = qCount >= skillList?.spells[0].cost.length ? 1 : 0;
                const wIndex = sliceArray.lastIndexOf(2);
                const wCount = sliceArray.filter((data) => data === 2).length
                const wMastered = wCount >= skillList?.spells[1].cost.length ? 1 : 0;
                const eIndex = sliceArray.lastIndexOf(3);
                const eCount = sliceArray.filter((data) => data === 3).length
                const eMastered = eCount >= skillList?.spells[2].cost.length ? 1 : 0;
                const rIndex = sliceArray.lastIndexOf(4);
                const rCount = sliceArray.filter((data) => data === 4).length
                const rMastered = rCount >= skillList?.spells[3]?.cost.length ? 1 : 0;
                let indexArray = [{
                    index: qIndex,
                    count: qCount,
                    mastered: qMastered,
                    value: 1,
                    text: 'Q'
                }, {
                    index: wIndex,
                    count: wCount,
                    mastered: wMastered,
                    value: 2,
                    text: 'W'
                }, {
                    index: eIndex,
                    count: eCount,
                    mastered: eMastered,
                    value: 3,
                    text: 'E'
                }];

                //77 == 우디르
                if (Number(champion?.champion_id) === 77) {
                    indexArray = [
                        ...indexArray,
                        {
                            index: rIndex,
                            count: rCount,
                            mastered: rMastered,
                            value: 4,
                            text: 'R'
                        }];
                }


                indexArray.sort((a, b) => {
                    if (a.mastered < b.mastered) {
                        return 1;
                    } else if (a.mastered > b.mastered) {
                        return -1;
                    } else if (a.count < b.count) {
                        return 1;
                    } else if (a.count > b.count) {
                        return -1;
                    } else if (a.index > b.index) {
                        return 1;
                    } else if (a.index < b.index) {
                        return -1;
                    }
                    return 0;
                });
                return indexArray || [];
            }
            return [];
        } catch (e) {
            console.log(e);
            return [];
        }

    }, [skillList])
    const getSkillName = (index) => {
        switch (index) {
            case 1:
                return 'Q';
            case 2:
                return 'W';
            case 3:
                return 'E';
            case 4:
                return 'R';
            default:
                return ""
        }
    }

    const renderItem = (data) => {
        const masterSkill = getLastSkills(data.skill)[0];
        return data?.skill?.map((item, index) => {
            return (
                <PaddingBox key={index}>
                    <ListTagValue isFirstMastered={masterSkill.value === item}
                                  last={masterSkill.index === index}>{getSkillName(item)}</ListTagValue>
                </PaddingBox>
            )
        })
    }

    return (
        <BuildPickWinTable title={t('summoner.mastery.skillBuild')} list={list}
                           minHeight={'143px'} >
            {(data) => renderItem(data)}
        </BuildPickWinTable>
    );
};

export default BuildSkillSq;
import React from 'react';
import styled, {useTheme} from "styled-components";
import SummonerMasteryTable from "./SummonerMasteryTable";
import {FlexBox} from "../../ui/styled";
import {useTranslation} from "react-i18next";
import DamageGraph from "../../summoner/DetailTab/GameAnalytics/GameSummary/Table/TableContent/DamageGraph";
import {hundredCheck} from "../../../function";

const Wrapper = styled.div`
  width: 100%;
  border: 1px solid ${props => props.theme.color.default_bright_border_color};
  background-color: ${props => props.theme.color.default_input_background};
  min-height: ${props => props.minHeight || 'auto'};
`;

const ContentTD = styled.td`
  vertical-align: middle;
  padding: 8px;
`;

const DPMWrapper = styled.div`
  width: 200px;
`;


const ValueWrapper = styled.div`
  display: inline;
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const BlackSpan = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: ${props => props.theme.color.font_58};

`;
const ValueText = styled.span`
  font-size: 12px;
  color: ${props => {
    let color = props.theme.color.font_E3;
    if (props.value > 50) {
      color = props.theme.color.font_61;
    }
    return color;
  }};
  font-weight: 500;
`;


const PercentText = styled.span`
  font-size: 10px;
  color: ${props => props.theme.color.default_gray_font_color};
  opacity: 0.6;
  margin-left: 2px;
`;

const BarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 72px;
`;
const SummonerMasteryItem = ({title, children, list, minHeight, hasDpm, highestDPM}) => {
    const {t} = useTranslation();
    const theme = useTheme();
    return (
        <Wrapper minHeight={minHeight} className={'masteryItem'}>
            <SummonerMasteryTable title={title} headers={
                hasDpm ?
                    [{title: t('summoner.mastery.dpm'), col: '300px'},
                        {
                            title: t('summoner.mastery.games'),
                            col: '50px'
                        }, {title: t('summoner.mastery.winRate'), col: "100px"}]
                    :
                    [{
                        title: t('summoner.mastery.games'),
                        col: '50px'
                    }, {title: t('summoner.mastery.winRate'), col: "100px"}]
            }
                                  list={list}>
                {(data) => {
                    return (
                        <>
                            <ContentTD>
                                <FlexBox>
                                    {children(data)}
                                </FlexBox>
                            </ContentTD>
                            {hasDpm && <ContentTD>
                                <CenterDiv>
                                    <DPMWrapper>
                                        <DamageGraph
                                            bgColor={theme.color.background_E6}
                                            color={theme.color.font_58}
                                            barColor={'#4BBEAA'}
                                            dmg={data.dpm}
                                            total={highestDPM}
                                        />
                                    </DPMWrapper>
                                </CenterDiv>
                            </ContentTD>}
                            <ContentTD>
                                <CenterDiv>
                                    <BlackSpan>{data.games}</BlackSpan>
                                </CenterDiv>
                            </ContentTD>
                            <ContentTD>
                                <CenterDiv>
                                    <ValueWrapper>
                                        <ValueText value={(data.win_rate * 100).toFixed(1)}>
                                            {hundredCheck((data.win_rate * 100).toFixed(1))}
                                        </ValueText>
                                        <PercentText>
                                            %
                                        </PercentText>
                                    </ValueWrapper>
                                </CenterDiv>
                            </ContentTD>
                        </>
                    )

                }}
            </SummonerMasteryTable>
        </Wrapper>
    );
};

export default SummonerMasteryItem;
import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {NoticeContainer} from '../../styled/CommonStyle';
import {useSelector} from "react-redux";
import styled from "styled-components";

const ErrorText = styled.p`
  color: white;
`;

const VisibleP = styled.p`
  color: white;
  display: ${props => {
    let display = 'none';
    if (props.visible) display = 'flex';
    return display;
  }};
  flex-direction: row;
  align-items: center;

  animation: fadeIn 1300ms;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;

    }
    to {
      opacity: 0;
    }
  }
  img {
    margin-right: 3px;
  }
`;


const Notice = ({fixed}) => {
    const currentLang = useSelector((state) => state.translation.lang);
    const [noticeNumber, setNoticeNumber] = useState(0);
    const {t} = useTranslation();

    const contentList = [{
        title: `🔔 ${t('notice.text2')}`,
        linkKor: 'https://deeplol-notice.notion.site/2024-06-21-f6bc42b67fbe48fab8a446c0ee39f22f',
        linkEng: 'https://deeplol-notice.notion.site/2024-06-21-UPDATE-1be4808e82974b999a4822486f7120f4',
    }]

    useEffect(() => {
        const interval = setInterval(() => {
            setNoticeNumber(prev => {
                if (prev >= contentList.length - 1) {
                    return 0;
                } else {
                    return prev + 1;
                }
            });
        }, 4000);

        return () => clearInterval(interval);
    }, []);


    return (
        <NoticeContainer fixed={fixed}>
            {contentList.map((data, index) => {
                if(!data.linkKor) {
                    return (
                        <VisibleP visible={index === noticeNumber} key={index}>
                            {data.icon && <img src={data.icon} alt={""} width={28} height={24}/>}
                            <ErrorText>{data.title}</ErrorText>
                        </VisibleP>
                    )
                }
                return (
                    <VisibleP className="link" visible={index === noticeNumber} key={index}>
                            <Link to={{pathname: currentLang === 'KOR' ? data.linkKor : data.linkEng}} target="_blank">
                                {data.title}
                            </Link>
                    </VisibleP>
                )
            })}
        </NoticeContainer>
    )
};

export default Notice;

import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled, {useTheme} from "styled-components";
import OverviewTableTr from "./overviewTableTr";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {getTierScore} from "../../../../function";
import LiveBlock from "./LiveBlock";
import {Loading} from "../../../common";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const FlexBox = styled.div`
  background-color: ${props => props.theme.color.default_input_background};
  display: flex;
  flex-direction: row;
  flex: 1;
  border: 1px solid ${props => props.theme.color.champion.border};
  border-radius: 10px;
`;


const BasicInfoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-right: 50px;
  margin-left: 16px;
`;

const RankText = styled.span`
  font-size: 16px;
  margin-bottom: 6px;
`;

const MapText = styled.div`
  font-size: 12px;
  opacity: 0.8;
`;


const Table = styled.table`
  flex: 1;
  border-left: 1px solid ${props => props.theme.color.champion.border};
`;

const HeaderTr = styled.tr`
  height: 32px;
  border-bottom: 1px solid ${props => props.theme.color.champion.border};
`;


const HeaderTh = styled.th`
  font-size: 12px;
  position: relative;
  border-right: ${props => {
    let bottom = 0;
    if (props.borderRight) bottom = `1px solid ${props.theme.color.border_A5}`;
    return bottom;
  }};
  vertical-align: middle;
  opacity: 0.8;
`;

const LoadingBox = styled.div`
  position: absolute;
  right: 0;
  left: 100px;
  top: 28px;
  bottom: 0;
`;

const RightBar = styled.div`
  position: absolute;
  right: 0;
  top: 11px;
  height: 9px;
  background-color: ${props => props.theme.color.champion.border};
  width: 1px;
`;

const ShareWrapper = styled.div`
  width: 92px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${props => {
    let border = `1px solid ${props.theme.color.font_5F}`
    if (props.theme.color.theme === 'dark') {
      border = 0;
    }
    return border;
  }};
  background-color: ${props => props.theme.color.default_input_background};
  margin-left: 9px;
  border-radius: 6px;
  cursor: pointer;
`;

const ShareImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

`;

const ShareText = styled.span`
  font-size: 16px;
  color: ${props => props.theme.color.font_5F_EA};
  margin-top: 4.5px;
`;

const CopyText = styled.span`
  position: absolute;
  top: -15px;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.85);
  border-radius: 8px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  color: #65cbff;
  transform: translateX(-50%);
  z-index: 23;
`;


const IngameOverview = () => {
    const theme = useTheme();
    const [copy, setCopy] = useState(false);
    const {ingame, getIngameInfoLoading} = useSelector((state) => state.summoner);
    const {t} = useTranslation();
    const onClickShare = useCallback(() => {
        setCopy(true);
        const copyText = `${window.location.href}`;
        navigator.clipboard.writeText(copyText);
    }, []);

    useEffect(() => {
        if (copy) {
            let timer = setTimeout(() => {
                setCopy(false)
            }, 2000);
            return () => {
                clearTimeout(timer)
            }
        }
    }, [copy])


    const avgWinRate = useMemo(() => {
        try {
            const blueWinRate = ingame.model.blue_win_rate;
            return {
                blue: blueWinRate,
                red: ingame.model.red_win_rate,
            }
        } catch (e) {
            return {
                blue: 0,
                red: 0,
            }
        }

    }, [ingame]);

    const avgAiScore = useMemo(() => {
        try {
            const part = ingame.part.reduce((sum, cur) => {

                sum.blue += Number(cur.blue.summoner_info_dict.ai_score_avg);
                sum.red += Number(cur.red.summoner_info_dict.ai_score_avg);
                return sum;
            }, {blue: 0, red: 0});
            return {
                blue: part.blue / ingame.part.length,
                red: part.red / ingame.part.length,
            }
        } catch (e) {
            console.log(e);
            return {
                blue: 0,
                red: 0,
            }
        }
    }, [ingame]);


    const getAvgTier = useCallback((playerList) => {
        const filterUnrank = playerList.filter((data) => data.tier !== "unranked");
        const combinedPlayerTier = filterUnrank.reduce((sum, cur) => {
            sum += getTierScore(`${cur.tier} ${cur.division}`).num
            return sum;
        }, 0);
        let length = filterUnrank.length;
        if (filterUnrank.length === 0) {
            length = 1;
        }
        return getTierScore(Math.trunc(combinedPlayerTier / length)).obj;
    }, []);

    const avgTier = useMemo(() => {
        try {

            const blueTierMap = ingame.blueTeam.map((data) => data.season_tier_info_dict.ranked_solo_5x5);
            const redTierMap = ingame.redTeam.map((data) => data.season_tier_info_dict.ranked_solo_5x5);

            const blueAvgTier = getAvgTier(blueTierMap);
            const redAvgTier = getAvgTier(redTierMap);

            return {
                blue: blueAvgTier,
                red: redAvgTier
            }
        } catch (e) {
            console.log("avgTier", e);
            return {
                blue: {tier: "Unknown", divisiqon: 1},
                red: {tier: "Unknown", divisiqon: 1}
            }
        }
    }, [ingame]);


    const queueText = useMemo(() => {
        try {
            const queueId = ingame.basic.queue_id;
            switch (queueId) {
                case 420:
                    return t('summoner.rankedSolo');
                case 440:
                    return t('summoner.rankedFlex');
                default:
                    return t('summoner.blind');
            }
        } catch (e) {
            console.log(e);
            return t('summoner.blind');
        }
    })


    return (<Wrapper>
        <FlexBox>
            <BasicInfoBox>
                <LiveBlock
                    initTimer={ingame.basic.start_timestamp}
                />
                <RankText>{queueText}</RankText>
                <MapText>{t('ingame.mapName')}</MapText>
            </BasicInfoBox>
            <Table>
                <colgroup>
                    <col/>
                    <col width={'8%'}/>
                    <col width={'10%'}/>
                    <col width={'20%'}/>
                </colgroup>
                <thead>
                <HeaderTr>
                    <HeaderTh>{t("ingame.winPrediction")}{getIngameInfoLoading &&
                        <LoadingBox><Loading size={'4px'}/></LoadingBox>}<RightBar/></HeaderTh>
                    <HeaderTh>{t("ingame.avgTier")}<RightBar/></HeaderTh>
                    <HeaderTh>{t("ingame.avgAiScore")}<RightBar/></HeaderTh>
                    <HeaderTh>{t("ingame.ban")}</HeaderTh>
                </HeaderTr>
                </thead>
                <tbody>
                <OverviewTableTr
                    teamName={t('ingame.blueTeam')}
                    borderBottom={true}
                    teamColor={theme.color.font_56}
                    winRate={avgWinRate.blue}
                    aiScore={avgAiScore.blue}
                    banList={ingame.basic.ban_id_dict.blue}
                    tier={avgTier.blue}
                />
                <OverviewTableTr
                    teamName={t('ingame.redTeam')}
                    teamColor={theme.color.font_DE4}
                    winRate={avgWinRate.red}
                    aiScore={avgAiScore.red}
                    banList={ingame.basic.ban_id_dict.red}
                    tier={avgTier.red}
                />
                </tbody>
            </Table>
        </FlexBox>
        <ShareWrapper onClick={onClickShare}>
            <ShareImageWrapper>
                <img src={`${theme.icon.path}/ico_copy_link.svg`} width={21} height={21} alt={""}/>
                <ShareText>{t('buttons.share')}</ShareText>
                {copy && <CopyText className="share_tooltip">{t('multi.clipboard')}</CopyText>}
            </ShareImageWrapper>

        </ShareWrapper>
    </Wrapper>);
};

export default React.memo(IngameOverview);

import React, {useMemo} from 'react';
import styled, {useTheme} from "styled-components";
import {useTranslation} from "react-i18next";

const UnRegText = styled.span`
  color: #808DB2;
  font-size: 24px;
  line-height: 26px;
  font-weight: bold;
  margin-top: 14px;
`;
const Extra = styled.span`
  margin-top: 34px;
  margin-bottom: 25px;
  font-size: 14px;
  text-align: center;
  white-space: pre;
  color: ${props => props.theme.color.font_7F};;
  line-height: 30px;
`;



const ContentWrapper = styled.div`
  
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60vh;
`;

const Box = styled.div`
  background-color: ${props => props.theme.color.default_input_background};
  width: 1080px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 40px 8px 25px;
  border: 1px solid ${props => props.theme.color.summoner.not_found.border};
  border-radius: 6px;
`;


const SummonerName = styled.span`
  color: ${props => props.theme.color.default_gray_font_color};
  line-height: 40px;
  font-weight: bold;
  font-size: 30px;
  letter-spacing: 0.6px;
`;
const TagName = styled.span`
  color: ${props => props.theme.color.font_AC};
  margin-left: 6px;
`;
const SummonerNotFound = ({summonerName = ''}) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const getName = useMemo(() => {
        try {
            if (summonerName.indexOf('-') > 0) {
                const splitName = summonerName.split('-');
                return splitName[0];
            }
            return summonerName;
        } catch (e) {
            return summonerName;
        }
    }, [summonerName]);

    const getTag = useMemo(() => {
        try {
            if (summonerName.indexOf('-') > 0) {
                const splitName = summonerName.split('-');
                return splitName[1];
            }
        } catch (e) {
            return "";
        }
    }, [summonerName]);
    return (
        <ContentWrapper>
            <Box>
                <SummonerName>"{getName}
                    {getTag && <TagName>#{getTag}</TagName>}
                    "
                </SummonerName>
                <UnRegText>{t('summoner.notRegister')}</UnRegText>
                <Extra>{t('summoner.notFound')}</Extra>
                <img src={`${theme?.icon?.path}/ico_logo_w.svg`} width={160} height={30}/>
            </Box>
        </ContentWrapper>
    );
};

export default SummonerNotFound;

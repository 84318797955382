export const light = {
    theme: 'light',
    transparent_back_ground_color: "transparent",
    empty_back_ground_color: "#fff",
    default_background_color: "#fff",
    white_font_color: '#fff',
    default_font_color: "#313131",
    default_input_background: "#fff",
    default_input_font_color: "#313131",
    default_gray_font_color: "#313131",
    default_gray_font_color_white: "#fff",
    darkest_color: "#fff",
    default_border_color: "#C3D1DC",
    default_bright_border_color: "#BBCFE9",
    tier_cut_iron: "#514330",
    tier_cut_bronze: "#815639",
    tier_cut_silver: "#626B8A",
    tier_cut_gold: "#A17716",
    tier_cut_platinum: "#2A8492",
    tier_cut_emerald: "#21855A",
    tier_cut_diamond: "#0063BB",
    tier_cut_master: "#8D41A9",
    tier_cut_grandmaster: "#C23449",
    tier_cut_challenger: "#FD8A12",
    tier_cut_1st: "linear-gradient(41deg, #687AF3 0%, #1D8DB3 53%, #21B7CF 100%)",
    tier_cut_text_iron: "#514330",
    tier_cut_text_bronze: "#815639",
    tier_cut_text_silver: "#626B8A",
    tier_cut_text_gold: "#A17716",
    tier_cut_text_platinum: "#2A8492",
    tier_cut_text_emerald: "#21855A",
    tier_cut_text_diamond: "#0063BB",
    tier_cut_text_master: "#8D41A9",
    tier_cut_text_grandmaster: "#C23449",
    tier_cut_text_challenger: "#FD8A12",
    tier_cut_text_1st: "#155C78",
    tier_cut_bar_master: "#8D41A9",
    tier_cut_bar_grandmaster: "#C23449",
    tier_cut_bar_challenger: "#FD8A12",
    shadow_001: "3px 3px 10px #0000001A",
    dash_01: "rgba(0,0,0,0.1)",
    border_C31: "#C3D1DC",
    border_C31_22: "#C3D1DC",
    border_0: '1px',
    border_4C: "#4C506D",
    border_4C_60: "#4C506D",
    border_5F_EA : "#5F6695",
    border_BB_60: "#BBCFE9",
    border_AC: "#ACACAC",
    border_D8: "#D8DBE3",
    border_1B: "#1B9CA5",
    border_53: "#535358",
    border_A4: "#A4A4B1",
    border_31_trans: "#313131",
    border_E7: "#E7EEF3",
    border_81: "#818ac4",
    border_9B: "#9B9BC5",
    border_6A: "#6A5AAC",
    border_106: "rgba(106, 90, 172, 1)",
    border_5D: "#5D9CEB",
    border_5D_60: "#5D9CEB",
    border_A5_5B: "#A5BED2",
    border_A5_EA: "#A5BED2",
    border_A5: "#a5bed2",
    border_6B: "#6B78B5",
    border_6B_60: "#6068B8",
    border_A5_35: "#A5BED2",
    border_A5_trans: "#a5bed2",
    border_A5_60: "#A5BED2",
    border_C1: "#C1D1E1",
    border_83: "#839DB2",
    font_0DB: "#0DBBAE",
    font_fff_A7: "#fff",
    font_66: "#666",
    font_f6: "#f6f6f6",
    font_DE4: "#DE4469",
    font_56: "#5682DB",
    font_6B7: "#6B78B5",
    font_5D_61: "#5D9CEB",
    font_fff_f1: "#fff",
    font_fff_33: "#fff",
    font_31_A7: "rgba(31,31,31,0.4)",
    font_AA: "#AAAAAA",
    font_6B: "#6B6B6B",
    font_69:"#69788D",
    font_25:"#259A7E",
    font_35:"#3571E9",
    font_F19: "#F19000",
    font_45: "#45496f",
    font_fff_EA: "#ffffff",
    font_fff_EAop5: "rgba(49, 49, 49, 0.6)",
    font_3C: "#3C4360",
    font_4908: "rgba(49, 49, 49, 0.8)",
    font_22: "#222236",
    font_E3: "#E37E74",
    font_61: "#619EEC",
    font_67: "#676B7D",
    font_black_60: "#313131",
    font_21: "#212121",
    font_21_60: "#212121",
    font_6A: "#6A5AAC",
    font_6A_EA: "#6A5AAC",
    font_5C: "#5C68A7",
    font_5F: "#5F6695",
    font_5F_trans: "#5F6695",
    font_5F_EA : "#5F6695",
    font_rgba49: "rgba(49, 49, 49, 0.6)",
    font_74_EA: "#7473AB",
    font_74: "#7473AB",
    font_DE: "#DE5353",
    font_C2: "#C24A4A",
    font_E5: "#E57474",
    font_5D: "#5D9CEB",
    font_5D_7A: "#5D9CEB",
    font_5D_A7: "#5D9CEB",
    font_58_CE: "#585858",
    font_58_9D: "#585858",
    font_F1: "#F13353",
    font_fff: "#fff",
    font_58: "#585858",
    font_58_A7: "#585858",
    font_65: "#656565",
    font_65_A7: "#656565",
    font_89_89: "#88889C",
    font_000: "#000",
    font_7F: "#7F7F7F",
    font_7F_EA: "#7F7F7F",
    font_7F_89: "#7F7F7F",
    font_7F_A7: "#7F7F7F",
    font_7F_CE: "#7F7F7F",
    font_49: "#494949",
    font_AC: "#ACACAC",
    gradient_hard: "linear-gradient(264deg, rgba(255, 174, 238,0.5), rgba(218, 110, 255, 0.5), rgba(107, 186, 255, 0.5), rgba(73, 210, 253,1), rgba(116, 255, 205, 1), rgba(223, 255, 89, 0.5))",
    gradient_green4: "transparent linear-gradient(74deg, #1EB8A0 0%, #3C68B2 100%) no-repeat padding-box",
    gradient_green3: "transparent linear-gradient(92deg, #34b8a5 0%, #4174c8 100%)",
    gradient_green2 : "transparent linear-gradient(180deg, #58cbaa 0%, #37aaaa 100%)",
    gradient_green: "transparent linear-gradient(93deg, #1eb8a0 0%, #3c68b2 100%)",
    background_F9_1A: "#F9F9FA",
    background_F9_35: "#F9F9FA",
    background_fff_000: "#fff",
    background_255234: "rgba(225, 234, 246, 0.8)",
    background_1C: "#1c202a",
    background_41: "rgba(41, 56, 91, 0.6)",
    background_209: "rgba(209, 84, 101, 0.6)",
    background_88: "rgba(88, 144, 222, 0.6)",
    background_CA_AF: "#cad7e2",
    background_DBD: "#dbdbee",
    background_01: "rgba(0, 0, 0, 0.1)",
    background_68: "#68749c",
    background_CA: "#cad7e2",
    background_F4: "#F4F4F5",
    background_255_09: "rgba(255, 255, 255, 0.9)",
    background_183: "rgba(183, 212, 255, 0.5)",
    background_255: "rgba(255, 187, 195, 0.5)",
    background_52: "rgba(52, 49, 62, 0.08)",
    background_58: "#5890DE",
    background_D1: "#d15465",
    background_DFE: "#DFE3EF",
    background_20: "#20B19D",
    background_6B: "#6B78B5",
    background_ED_1A: "#EDECF5",
    background_B7_35: "#B7BEDE",
    background_4E_1A: "#5D9CEB",
    background_DB: "#dbe8ff",
    background_fff_22: "#fff",
    background_D9: "#D9DDE1",
    background_69: "#699ce3",
    background_F57: "#f57593",
    background_F2F2F2: "#f2f2f2",
    background_99: "#D5D5D5",
    background_BF: "#BFD1DF",
    background_D5: "#D56B63",
    background_D5_3f: "#D56B63",
    background_4E: "#4E7CC2",
    background_F3:"#F3A634",
    background_53: "#5383E8",
    background_E84: "#E84057",
    background_36: "#36496F",
    background_E7: "#E7ECFB",
    background_D2: "#D2E6F5",
    background_F8: "#F8FAFF",
    background_fff_60: "#fff",
    background_164_EA: "rgba(164, 164, 177, 1)",
    background_164164: "rgba(164, 164, 177, 0.1)",
    background_1641: "rgba(164, 164, 177, 1)",
    background_164: "rgba(164, 164, 177, 0.3)",
    background_A4: "#A4A4B1",
    background_49: "rgba(49,49,49,0.6)",
    background_E1: "#E1E6F2",
    background_E1_35:"#E1E6F2",
    background_EF: "#EFEBF9",
    background_EE: "#EEF0F5",
    background_EE_1A: "#EEF0F5",
    background_F2F4: "#F2F4F9",
    background_fff_35: "#fff",
    background_F1F: "#F1F3F8",
    background_F1F_2E: "#F1F3F8",
    background_F1: "#F1F3F9",
    background_F1_E2: "#F1F3F9",
    background_4AA: "#4AAFA2",
    background_trans_60: "transparent",
    background_E6: "#E6E6F6",
    background_E3E: "#e3e4ee",
    background_5C6: "#5c628d",
    background_5C6_606: "#5c628d",
    background_F5F7FB: "#F5F7FB",
    background_F5F7FB_22: "#F5F7FB",
    background_5F: "#5F57A5",
    background_5F_2E: "#5F57A5",
    background_5F_EA: "#5F57A5",
    background_E3: "#E3E6EF",
    background_E0: "#E0E6F5",
    background_DD_35: "#DDEBE2",
    background_DD_22: "#DDEBE2",
    background_DD: "#ddebe2",
    background_DD_2E: "#ddebe2",
    background_F6: '#F6F9FE',
    background_46: "#46B9AA",
    background_B2: "#B2D9D4",
    background_CF: "#CFD5E6",
    background_F5: "#F5F7FC",
    background_F5_2E: "#F5F7FC",
    background_FOE: "#F0EFF5",
    background_F0: "#F0F1FF",
    background_E8E5FE: "#E8E5FE",
    background_E8: "#E8E5FF",
    background_F9: "#F9FAFC",
    background_fff_trans: "#fff",
    background_DF: "#dfe9f1",
    background_CD: "#cde7f5",
    background_FB: "#fbfbfb",
    background_fff_1A1: "#ffffff",
    background_E4: "#e4e7f2",
    background_EB: "#EBF6FF",
    background_FF: "#FFEEEE",
    background_FC: "#FCF4EC",
    background_4A: "#4A618A",
    background_EA: "#EAEAEA",
    background_fff_1A: "#fff",
    background_F2: "#F2F5FB",
    background_EBC: "#EBCECC",
    background_B3D: "#B3D2E9",
    background_win: "#E7F0FC",
    background_win_hover: "#D2E6F5",
    background_lose: "#FBECEA",
    background_lose_hover: "#FFDEDB",
    opacity_1_04: "0.4",
    search: {
        searchArrow_bg: "#313131"
    },
    tag: {
        background_color: "#E6E6E6"
    },
    header: {
        font_color: 'rgba(255,255,255,0.7)',
    },
    summoner: {
        not_found: {
            extra: "#808db2",
            riot_id_name: "#313131",
            border: '#AAAAAA',
        },
        tab: {
            background_color: '#F9F9FA',
            border_color: "#C3D1DC",
            font_color: 'rgba(0, 0, 0, 0.6)',
            selected_gradient: "transparent linear-gradient(74deg, #1eb8a0 0%, #3c68b2 100%)",
            selected_font_color: "#fff",
            hover_font_color: "#5D9CEB",
        },
        profile: {
            aka_font_color: "#7F7F7F",
            background_color: '#F9F9FA',
            border_color: "#C3D1DC",
            summoner_level: {
                background_color: "transparent linear-gradient(180deg, #2f4d3c 0%, #233048 100%)",
                font_color: "#f2f2f2",
            },
            update: {
                fill_update_background: "transparent linear-gradient(90deg, rgba(63, 179, 180, 0.8) 0%, rgba(65, 89, 168, 0.8) 100%)",
                font_color: "#fff",
                border_error: "#CFD5E6",
                border_color: "#BBCFE9",
            }
        }
    },
    footer: {
        copy_font_color: "#000",
        body_font_color: "#aaa",
        content_font_color: "#313131",
        owner_font_color: "#797979",
    },
    autoComplete: {
        header_border_bottom_color: "#6B78B5",
        content_border_color: "#6B78B5",
        content_header_bg_color: "#F1F3F8",
        content_hover_bg_color: "rgba(80, 108, 184, 0.1)"
    },
    searchList: {
        tab_border_color: "#9CA1B2",
        tab_idle_bg: "#d7dae5",
        tab_font_color: "rgba(49, 49, 49, 0.5)",
        tab_selected_font_color: "#3362e5",
    },
    favorite: {
        background_color: "#fff",
        border_color: "#7e9ae6",
    },
    champion: {
        border: "#A5BED2"
    }
}


export const dark = {
    theme: 'dark',
    transparent_back_ground_color: "transparent",
    empty_back_ground_color: "#fff",
    default_background_color: "#181820",
    white_font_color: '#AAAAAA',
    default_font_color: "#313131",
    default_input_background: '#2E2E43',
    default_input_font_color: "#fff",
    default_gray_font_color: "#EAF0EC",
    default_gray_font_color_white: "#EAF0EC",
    default_border_color: "transparent",
    default_bright_border_color: "transparent",
    tier_cut_iron: "#514330",
    tier_cut_bronze: "#815639",
    tier_cut_silver: "#626B8A",
    tier_cut_gold: "#A17716",
    tier_cut_platinum: "#2A8492",
    tier_cut_emerald: "#21855A",
    tier_cut_diamond: "#0063BB",
    tier_cut_master: "#8D41A9",
    tier_cut_grandmaster: "#C23449",
    tier_cut_challenger: "#EE7A00",
    tier_cut_1st: "linear-gradient(41deg, #687AF3 0%, #1D8DB3 53%, #21B7CF 100%)",
    tier_cut_text_iron: "#A7A097",
    tier_cut_text_bronze: "#C0AA9B",
    tier_cut_text_silver: "#B7BBCB",
    tier_cut_text_gold: "#DBC798",
    tier_cut_text_platinum: "#97CBC4",
    tier_cut_text_emerald: "#7FD6A7",
    tier_cut_text_diamond: "#A7CDF2",
    tier_cut_text_master: "#D3B3EA",
    tier_cut_text_grandmaster: "#EBB4C4",
    tier_cut_text_challenger: "#EE7A00",
    tier_cut_text_1st: "#21B7CF",
    tier_cut_bar_master: "#D3B3EA",
    tier_cut_bar_grandmaster: "#EBB4C4",
    tier_cut_bar_challenger: "#EE7A00",
    shadow_001: "3px 3px 10px #004CFF1A",
    dash_01: "rgba(234, 240, 236, 0.12)",
    border_C31: "#6068B8",
    border_C31_22: "#222236",
    border_0: '0px',
    border_A5_5B: "#5B5B6F",
    border_6B_60: "#6068B8",
    border_4C: "transparent",
    border_4C_60: "#6068B8",
    border_BB_60: "#6068B8",
    border_AC: "transparent",
    border_6B: "transparent",
    border_5F_EA : "transparent",
    border_D8: "transparent",
    border_1B: "#6068B8",
    border_53: "transparent",
    border_A4: "transparent",
    border_31_trans: "transparent",
    border_E7: "#1A1A29",
    border_81: "transparent",
    border_9B: "transparent",
    border_6A: "#6068B8",
    border_106: "#6068B8",
    border_5D: "transparent",
    border_5D_60: "#6068B8",
    border_A5_EA: "#EAF0EC",
    border_A5_35: "#353A5B",
    border_A5: "#1A1A29",
    border_A5_trans: "transparent",
    border_C1: "transparent",
    border_83: "transparent",
    border_A5_60: "#6068B8",
    border_5F_trans: "transparent",
    font_0DB: "#0DBBAE",
    font_fff_A7: "#A7A7AF",
    font_66: "rgba(234, 240, 236, 77)",
    font_f6: "#EAF0EC",
    font_DE4: "#F13353",
    font_56: "#3377FF",
    font_5D_7A: "#7A99FF",
    font_6B7: "#6068B8",
    font_7F_CE: "#CECED8",
    font_6A_EA: "#EAF0EC",
    font_fff_f1: "#F13353",
    font_fff_33: "#3377FF",
    font_AA: "#88889C",
    font_fff_EA: "#EAF0EC",
    font_31_A7: "#A7A7AF",
    font_6B: "#EAF0EC",
    font_69:"#ADB5BF",
    font_25:"#2AAE8E",
    font_F19: "#FF944D",
    font_35:"#0080FF",
    font_89_89: "#88889C",
    font_45: "#EAF0EC",
    font_fff_EAop5: "rgba(234, 240, 236, 0.5)",
    font_3C: "#EAF0EC",
    font_4908: "#EAF0EC",
    font_22: "#EAF0EC",
    font_E3: "#F13353",
    font_61: "#3377FF",
    font_67: "#EAF0EC",
    font_black_60: "#6068B8",
    font_6A: "#6068B8",
    font_5C: "#EAF0EC",
    font_5F: "#6068B8",
    font_5F_EA : "#EAF0EC",
    font_rgba49: "rgba(234, 240, 236, 0.6)",
    font_74_EA: "#EAF0EC",
    font_7F_A7: "#A7A7AF",
    font_7F_89: "#88889C",
    font_21: "#EAF0EC",
    font_21_60: "#6068B8",
    font_74: "#9D9CD9",
    font_C2: "#F13353",
    font_DE: "#F13353",
    font_5D_A7: "#A7A7AF",
    font_5D: "#3377FF",
    font_5D_61: "#6186FF",
    font_E5: "#F13353",
    font_fff: "#EAF0EC",
    font_58: "#EAF0EC",
    font_58_A7: "#A7A7AF",
    font_58_CE: "#CECED8",
    font_58_9D: "#9D9CD9",
    font_65: "#EAF0EC",
    font_65_A7: "#A7A7AF",
    font_7F: "#A7A7AF",
    font_7F_EA: "#EAF0EC",
    font_000: "#EAF0EC",
    font_49: "#A7A7AF",
    font_AC: "#88889C",
    gradient_hard: "linear-gradient(264deg, rgba(255, 172, 229, 0.6), rgba(255, 204, 228, 0.6), rgba(229, 217, 225, 0.6), rgba(207, 220, 228, 0.6), rgba(129, 191, 212, 0.6), rgba(146, 133, 255, 0.6),rgba(126, 90, 255, 0.6))",
    gradient_green4: "#1A1A29",
    gradient_green3: "#6068B8",
    gradient_green2 : "#6068B8",
    gradient_green: "#6068B8",
    background_E1_35:"#353A5B",
    background_F9_35: "#353A5B",
    background_F9_1A: "#1A1A29",
    background_fff_000: "#000",
    background_255234: "rgba(26, 26, 41, 0.8)",
    background_1C: "#EAF0EC",
    background_41: "#798298",
    background_209: "#442124",
    background_88: "#1F2D55",
    background_CA_AF: "#A7A7AF",
    background_DBD: "#353A5B",
    background_01: "rgba(234, 240, 236, 0.1)",
    background_68: "#353A5B",
    background_CA: "#353A5B",
    background_F4: "#2E2E43",
    background_255_09: "#2E2E43",
    background_183: "#1F2D55",
    background_255: "#442124",
    background_52: "#1A1A29",
    background_58: "#3377FF",
    background_D1: "#F13353",
    background_F5_2E: "#2E2E43",
    background_DFE: "#1A1A29",
    background_20: "#3377FF",
    background_DD_35: "#353A5B",
    background_ED_1A: "#1A1A29",
    background_6B: "#6068B8",
    background_B7_35: "#353A5B",
    background_DD_22: "#222236",
    background_4E_1A: "#1A2850",
    background_D5_3f: "#3F1C1F",
    background_DB: "#353A5B",
    background_fff_22: "#222236",
    background_D9: "#1A1A29",
    background_F57: "#F13353",
    background_69: "#3377FF",
    background_F2F2F2: "#2E2E43",
    background_99: "#999CC1",
    background_BF: "#999CC1",
    background_D5: "#F13353",
    background_4E: "#3377FF",
    background_F3:"#FF944D",
    background_53: "#3377FF",
    background_E84: "#F13353",
    background_36: "#1A1A29",
    background_E7: "#353A5B",
    background_D2: "#353A5B",
    background_F8: "#1A1A29",
    background_fff_60: "#6068B8",
    background_164_EA: "rgba(96, 104, 184, 1)",
    background_164164: "rgba(34, 34, 54, 1)",
    background_1641: "rgba(53, 58, 91, 1)",
    background_49: "#A7A7AF",
    background_164: "rgba(53, 58, 91, 1)",
    background_A4: "#353A5B",
    background_E1: "#1A1A29",
    background_EF: "#2E2E43",
    background_F2F4: "#262639",
    background_EE: "#2E2E43",
    background_EE_1A: "#1A1A29",
    background_fff_35: "#353A5B",
    background_F1F_2E: "#2E2E43",
    background_F1F: "#1A1A29",
    background_F1: "#1A1A29",
    background_F1_E2: "#2E2E43",
    background_4AA: "#2E2E43",
    background_E6: "#1A1A29",
    background_E3E: "#222236",
    background_5C6_606: "#6068B8",
    background_5C6: "#EAF0EC",
    background_F5F7FB: "#2E2E43",
    background_F5F7FB_22: "#222236",
    background_FOE: "#1A1A29",
    background_trans_60: "#6068B8",
    background_5F: "#6068B8",
    background_5F_2E: "#2E2E43",
    background_5F_EA: "#EAF0EC",
    background_E3: "transparent",
    background_E0: "#353A5B",
    background_DD_2E: "#2E2E43",
    background_DD: "#1A1A29",
    background_F6: '#2E2E43',
    background_46: "#6068B8",
    background_B2: "#353A5B",
    background_CF: "#222236",
    background_F5: "#1A1A29",
    background_F0: "#353A5B",
    background_E8E5FE: "#353A5B",
    background_E8: "#353A5B",
    background_F9: "#222236",
    background_fff_trans: "transparent",
    background_DF: "#222236",
    background_CD: "#1F2D55",
    background_FB: "#2E2E43",
    background_fff_1A1: "#1A1A29",
    background_E4: "#2E2E43",
    background_EB: "transparent",
    background_FF: "transparent",
    background_FC: "transparent",
    background_EA: "transparent",
    background_4A: "#1A1A29",
    background_fff_1A: "#1A1A29",
    background_F2: "#1A1A29",
    background_EBC: "#682B2E",
    background_B3D: "#2A4184",
    background_win: "#1F2D55",
    background_win_hover: "#2A4184",
    background_lose: "#442124",
    background_lose_hover: "#682B2E",
    opacity_1_04: "1",
    search: {
        searchArrow_bg: "#6B78B5"
    },
    tag: {
        background_color: "#1A1A29"
    },
    header: {
        font_color: 'rgba(255,255,255,0.7)',
    },
    summoner: {
        not_found: {
            extra: "#808db2",
            riot_id_name: "#313131",
            border: 'transparent',
        },
        tab: {
            background_color: '#2E2E43',
            border_color: "transparent",
            font_color: 'rgba(0, 0, 0, 0.6)',
            selected_gradient: "transparent linear-gradient(74deg, #1eb8a0 0%, #3c68b2 100%)",
            selected_font_color: "#fff",
            hover_font_color: "#5D9CEB",
        },
        profile: {
            aka_font_color: "rgba(234, 240, 236, 0.5)",
            background_color: '#F9F9FA',
            border_color: "transparent",
            summoner_level: {
                background_color: "transparent linear-gradient(180deg, #2f4d3c 0%, #233048 100%)",
                font_color: "#f2f2f2",
            },
            update: {
                fill_update_background: "transparent linear-gradient(90deg, rgba(63, 179, 180, 0.8) 0%, rgba(65, 89, 168, 0.8) 100%)",
                font_color: "#fff",
                border_error: "transparent",
                border_color: "transparent",
            }
        }
    },
    footer: {
        copy_font_color: "#A0A4A5",
        body_font_color: "#6C6E71",
        content_font_color: "#EAF0EC",
        owner_font_color: "#6C6E71",
    },
    autoComplete: {
        header_border_bottom_color: "#181820",
        content_border_color: "transparent",
        content_header_bg_color: "#1A1A29",
        content_hover_bg_color: "rgba(80, 108, 184, 0.2)"
    },
    searchList: {
        tab_idle_bg: "#1A1A29",
        tab_border_color: "#1A1A29",
        tab_font_color: "rgba(234, 240, 236, 0.5)",
        tab_selected_font_color: "#EAF0EC",
    },
    favorite: {
        background_color: "#262639",
        border_color: "transparent"
    },
    champion: {
        border: "#1A1A29"
    }
}


export const lightIcon = {
    championPath: "/images/champion",
    path: "/images",
    top: '/images/icon_s_position_top.svg',
    bottom: '/images/icon_s_position_bot.svg',
    jungle: '/images/icon_s_position_jungle.svg',
    supporter: '/images/icon_s_position_supporter.svg',
    mid: '/images/icon_s_position_middle.svg',
}

export const darkIcon = {
    path: "/images/dark",
    championPath: "/images/champion/dark",
    top: '/images/dark/icon_s_position_top.svg',
    bottom: '/images/dark/icon_s_position_bot.svg',
    jungle: '/images/dark/icon_s_position_jungle.svg',
    supporter: '/images/dark/icon_s_position_supporter.svg',
    mid: '/images/dark/icon_s_position_middle.svg',
}

import React, {useMemo} from 'react';
import styled, {useTheme} from "styled-components";
import useInput from "../../../../../common/hooks/useInput";
import {useTranslation} from "react-i18next";
import {noPositionCheck} from "../../../../../function";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;



`;

const Chart = styled.div`
  width: 184px;
  height: 45px;
`;

const ChartContent = styled.div`
  height: 45px;
  background-color: ${props => props.theme.color.background_F2};;
  display: flex;
  flex-direction: row;
`;

const GraphWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  flex: 1;
`;
const BlueGraph = styled.div`
  width: 5px;
  height: ${props => props.height || 0};
  background-color: #4481D6;
  margin-right: 2px;
`;

const RedGraph = styled.div`
  width: 5px;
  height: ${props => props.height || 0};
  background-color: #DF6A52;
`;


const LaneWrapper = styled.div`
  margin-block: 3px;
  display: flex;
  flex-direction: row;
`;

const LaneBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;
`;


const reduceInit = {
    count: 0, win: 0, lose: 0, ai_score: 0,
}

const initReduceObject = {
    Top: {...reduceInit, position: 'Top'},
    Jungle: {...reduceInit, position: 'Jungle'},
    Middle: {...reduceInit, position: 'Middle'},
    Bot: {...reduceInit, position: 'Bot'},
    Supporter: {...reduceInit, position: 'Supporter'},
}
const PositionChart = ({positionArray}) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const [isHover, onChangeIsHover] = useInput(false);

    const reduceArray = useMemo(() => {
        if (Array.isArray(positionArray) && positionArray.length > 0) {
            return positionArray.reduce((sum, cur) => {
                if (noPositionCheck(cur.queue_id)) {
                    sum[cur.position] = {
                        count: sum[cur.position].count + 1,
                        win: cur.is_win ? sum[cur.position].win + 1 : sum[cur.position].win,
                        lose: !(cur.is_win) ? sum[cur.position].lose + 1 : sum[cur.position].lose,
                        ai_score: Number(sum[cur.position].ai_score) + Number(cur.ai_score),
                    }
                }

                return sum;
            }, {...initReduceObject})
        }
        return {...initReduceObject};
    }, [positionArray]);

    const getMaxValue = useMemo(() => {
        if (Object.values(reduceArray).length > 0) {
            return Object.values(reduceArray).reduce((sum, cur) => {

                const winMax = Math.max(sum.maxValue, cur.win);
                sum.maxValue = Math.max(winMax, cur.lose);
                return sum;

            }, {maxValue: 1})
        }
        return {maxValue: 1};
    }, [reduceArray])


    return (
        <Wrapper>
            <Chart data-testid={'hover'}>
                <ChartContent>
                    {Object.values(reduceArray).map((data, index) => {
                        return (
                            <React.Fragment key={"positionChart" + index}>
                                <GraphWrapper data-testid={'graph'}>
                                    <BlueGraph
                                        height={(data.count > 0 ? Math.trunc(data.win / getMaxValue.maxValue * 100) : 0) + "%"}/>
                                    <RedGraph
                                        height={(data.count > 0 ? Math.trunc(data.lose / getMaxValue.maxValue * 100) : 0) + "%"}/>
                                </GraphWrapper>
                            </React.Fragment>
                        )
                    })
                    }
                </ChartContent>
                <LaneWrapper>
                    <LaneBox>
                        <img width={16} height={16}
                             src={theme.icon.top}
                             alt={""}/>
                    </LaneBox>
                    <LaneBox>
                        <img width={16} height={16}
                             src={theme.icon.jungle}
                             alt={""}/>
                    </LaneBox>
                    <LaneBox>
                        <img width={16} height={16}
                             src={theme.icon.mid}
                             alt={""}/>
                    </LaneBox>
                    <LaneBox>
                        <img width={16} height={16}
                             src={theme.icon.bottom}
                             alt={""}/>
                    </LaneBox>
                    <LaneBox>
                        <img width={16} height={16}
                             src={theme.icon.supporter}
                             alt={""}/>
                    </LaneBox>
                </LaneWrapper>
            </Chart>
        </Wrapper>
    );
};

export default PositionChart;

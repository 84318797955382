import React, {useCallback} from 'react';
import styled from "styled-components";
import ChampLane from "../../../../summoner/Champ/ChampLane";
import {useHistory, useLocation} from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid ${props => props.theme.color.border_BB_60};
  border-radius: 4px;
  height: 28px;

  .laneTag {
    min-width: auto;
    padding: 0 4px;
    margin: 2px;
  }

  margin-left: 16px;
`;
const MasteryFilterLane = ({selected}) => {
    const history = useHistory();
    const location = useLocation();

    const onClickPosition = useCallback((position) => {
        const sliceURL =location.pathname.match(/.+mastery/i)?.[0];
        history.replace(`${sliceURL}/${position.toLowerCase()}${location.search}`)
    }, [location]);
    return (
        <Wrapper>
            <ChampLane selectedLane={selected} onChangePosition={onClickPosition}/>
        </Wrapper>
    );
};

export default MasteryFilterLane;
import {useCallback, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {uniq} from "lodash";
import {sortChampData} from "../../function";
import {useLocation} from "react-router-dom";
//TODO 룬코어 리셋
const useMasteryFunc = () => {
    const location = useLocation();
    const [versionArray, setVersionArray] = useState(0);
    const [selectedLane, setSelectedLane] = useState('All');
    const [coreSelected, onChangeCoreSelected] = useState(0);
    const {
        mastery
    } = useSelector((state) => state.summoner);
    const searchParams = new URLSearchParams(location.search);
    const initChampionId = searchParams.get("champion");
    const [champSelectedId, setChampSelectedId] = useState(initChampionId);


    const onChangeChampion = useCallback((championId) => {
        setChampSelectedId(championId)
        // setChampionIndex(index);
        setVersionArray(0)
        setSelectedLane('All')
        onChangeCoreSelected(0);
    }, []);

    const onChangeVersion = useCallback((index) => {
        setSelectedLane('All');
        onChangeCoreSelected(0);
        setVersionArray(index);
    }, [versionArray]);

    const onChangeLane = useCallback((lane) => {
        setSelectedLane(lane);
        onChangeCoreSelected(0);
    }, []);

    const masteryList = useMemo(() => {
        try {
            if (Array.isArray(mastery) && mastery.length > 0) {
                const mapped = mastery.map((data) => {

                    const totalGames = data.data_list.find((item) => item.version === 'All' && item.lane === 'All');
                    return {
                        ...data,
                        totalGames: totalGames?.games || 0,
                    }
                });
                return sortChampData(mapped, [{value: 'totalGames', sortType: false}]);
            }
            return [];
        } catch (e) {
            return [];
        }
    }, [mastery]);

    const championIndex = useMemo(() => {
        try {
            if(champSelectedId) {
                return masteryList.findIndex((list) => Number(list.champion_id) === Number(champSelectedId))
            }
            return 0;
        } catch (e) {
           return 0;
        }
    },[champSelectedId,masteryList]);

    const versionList = useMemo(() => {
        try {

            const list = masteryList[championIndex].data_list.map((data) => {
                return data.version;
            });
            return uniq(list);
        } catch (e) {
            return [];
        }
    }, [masteryList, championIndex]);
    const versionFilter = useMemo(() => {
        try {
            const versions = versionList[versionArray];
            const list = masteryList[championIndex].data_list.filter((data) => data.version === versions);
            return list;
        } catch (e) {
            return [];
        }
    }, [masteryList, versionList, versionArray]);

    const laneList = useMemo(() => {
        try {
            const list = uniq(versionFilter.map((data) => data.lane));
            return ['ALL', ...list]

        } catch (e) {
            return [];
        }
    }, [masteryList, versionFilter, versionArray]);

    const laneFilter = useMemo(() => {
        try {
            const list = versionFilter.filter((data) => data.lane.toLowerCase() === selectedLane.toLowerCase());
            return list;
        } catch (e) {
            return [];
        }
    }, [versionFilter, selectedLane]);


    const coreList = useMemo(() => {
        try {
            const list = laneFilter.reduce((sum, data) => {
                return [...sum, ...data.build_detail];
            }, []);
            return sortChampData(list, [{value: "games", sortType: false}, {value: "win_rate", sortType: false},{value: 'core_item', sortType: true}]);
        } catch (e) {
            return [];
        }
    }, [laneFilter]);

    const combined = useCallback((list, findList, equals) => {
        list.forEach((data) => {
            const findIndex = findList.findIndex((item) => equals(item, data));
            if (findIndex > -1) {
                const item = findList[findIndex];
                findList[findIndex] = {...item, pick_rate: item.pick_rate + data.pick_rate, cnt: item.cnt + 1}
            } else {
                findList.push({...data, cnt: 1});
            }
        });
        return findList.map((boot) => {
            return {
                ...boot,
                pick_rate: boot.pick_rate / boot.cnt
            }
        });
    }, []);

    const combinedItem = useCallback((list, findList, itemName) => {
        list.forEach((data, index) => {
            const findIndex = findList.findIndex((item) => item[itemName] === data[itemName]);
            if (findIndex > -1) {
                const item = findList[findIndex];
                findList[findIndex] = {...item, pick_rate: item.pick_rate + data.pick_rate, cnt: item.cnt + 1}
            } else {
                findList.push({...data, cnt: 1});
            }
        });
        return findList.map((boot) => {
            return {
                ...boot,
                pick_rate: boot.pick_rate / boot.cnt
            }
        });
    }, []);

    const overview = useMemo(() => {
        const obj = {
            champion_id: 0,
            games: 0,
            win_rate: 0,
            wins: 0,
            lose: 0,
            rune: [],
            spell: [],
            mythic: [],
            core: [],
            shoes: [],
        }
        try {
            const reduce = laneFilter.reduce((sum, cur,) => {
                const combinedShoes = combinedItem(cur.boots, sum.shoes, 'boots');
                const combinedCore = combinedItem(cur.core_items, sum.core, 'item');
                const combinedMythic = combinedItem(cur.mythic_items, sum.mythic, 'item');
                const combinedSpell = combinedItem(cur.spells, sum.spell, 'spell')
                const combinedRunes = combined(cur.rune, sum.rune, (a, b) => a.rune_main === b.rune_main && a.rune_sub === b.rune_sub);
                const champion_id = masteryList?.[championIndex]?.champion_id;
                return {
                    ...sum,
                    champion_id: champion_id,
                    games: sum.games + cur.games,
                    wins: sum.wins + cur.win_cnt,
                    lose: sum.lose + cur.loss_cnt,
                    rune: [...combinedRunes],
                    spell: [...combinedSpell],
                    mythic: [...combinedMythic],
                    core: [...combinedCore],
                    shoes: [...combinedShoes]
                }
            }, {
                ...obj
            });
            reduce.win_rate = (reduce.wins / reduce.games * 100).toFixed(1);
            return reduce
        } catch (e) {
            console.log(e);
            return obj;
        }

    }, [laneFilter]);

    const lanePick = useMemo(() => {
        try {
            const totalGames = versionFilter.reduce((sum, cur) => {
                if (cur.lane === 'All') {
                    return sum;
                }
                return sum + cur.games
            }, 0);
            const pickList = versionFilter.reduce((sum, cur) => {
                sum[cur.lane] = {
                    lane: cur.lane,
                    games: cur.games + (sum[cur.lane]?.games || 0),
                };
                return sum;
            }, {});

            return Object.values(pickList).map((data) => {
                return {
                    ...data,
                    pick_rate: ((data.games / totalGames) * 100).toFixed(0),
                }
            })

        } catch (e) {
            return [];
        }
    }, [overview]);

    return {
        mastery: masteryList,
        versionList,
        laneList,
        onChangeVersion,
        versionArray,
        selectedLane,
        coreList,
        overview,
        lanePick,
        championIndex,
        onChangeChampion,
        coreSelected,
        onChangeCoreSelected,
        onChangeLane,
    };
}


export default useMasteryFunc;
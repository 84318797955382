import React from 'react';
import styled from "styled-components";

const Span = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: ${props => props.theme.color.font_58_A7};
`;
const DailyRankingCrown = ({rank}) => {
    if (rank === 1) {
        return (<img src={'images/ico_best_player01.svg'} width={16} height={16} alt={""}/>)
    }
    if (rank === 2) {
        return (<img src={'images/ico_best_player04.svg'} width={16} height={16} alt={""}/>)
    }
    if (rank === 3) {
        return (<img src={'images/icn_3rd.svg'} width={16} height={16} alt={""}/>)
    }
    return (
        <Span>
            {rank}
        </Span>
    );
};

export default DailyRankingCrown;
import React, {useMemo} from 'react';
import styled from "styled-components";
import {useSelector} from "react-redux";
import regionList from "../../../store/static_json/regionList";

const Wrapper = styled.div`
  border-radius: 5px;
  padding: 0 4px;
  background-color: ${props => {
    let color = props.theme.color.tag.background_color;
    if (props.backgroundColor) {
      color = props.backgroundColor;
    }
    return color;
  }};
  margin-left: 4px;
`;
const Span = styled.span`
  color: ${props => {
    let color = props.theme.color.font_AC;
    if(props.fontColor) {
        color = props.fontColor
    }
    return color;
  }};
`;
const PlaceHolderTag = ({backgroundColor, fontColor}) => {
    const region = useSelector((state) => state.region.result);
    const serverRegion = useMemo(() => {
        try {
            const result = regionList.find((data) => data.title === region);
            return result.tagName;
        } catch (e) {
            return 'KR1'
        }
    }, [region])

    return (
        <Wrapper backgroundColor={backgroundColor}>
            <Span className={'tag_span'} fontColor={fontColor}>#{serverRegion}</Span>
        </Wrapper>
    );
};

export default PlaceHolderTag;
import React, {useMemo} from 'react';
import {useTranslation} from "react-i18next";
import TableHeader from "../GameAnalytics/GameSummary/Table/TableHeader";
import styled from "styled-components";
import TableWinLose from "../TableWinLose";
import SwarmAnalyticsTableContent from "../SwarmAnalyticsTableContent";

const SwarmGameSummary = ({match, hide, region, myMatch}) => {


    const MatchMemo = useMemo(() => match.participants_list, [match]);


    const Alldamage = useMemo(() => MatchMemo.reduce((sum, data, index) => {
        if (sum.totaldmg < data.swarm_total_damage_dealt) {
            sum.totaldmg = data.swarm_total_damage_dealt;
        }

        if (sum.totalTaken < data.total_damage_taken) {
            sum.totalTaken = data.total_damage_taken;
        }

        return sum;
    }, {totaldmg: 0, totalTaken: 0}), [MatchMemo]);


    return (
        <Table list={MatchMemo} hide={hide} region={region} Alldamage={Alldamage}
               myMatch={myMatch}/>
    )
};


const Wrapper = styled.div`
  background-color: ${props => props.theme.color.default_input_background};
`;
const TableWrapper = styled.table`
  position: relative;
  width: 100%;
`;

const TableHeaderWrapper = styled.tr`
  height: 28px;
  border-bottom: 1px solid ${props => props.theme.color.champion.border};
  border-top: 1px solid ${props => props.theme.color.champion.border};
`;

const HeaderTextWrapper = styled.div`
  margin-left: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Th = styled.th`
  background-color: ${props => props.theme.color.background_fff_1A};
  vertical-align: middle;
`;

const Table = ({list, hide, Alldamage, region, myMatch}) => {
    const {t} = useTranslation();
    console.log(list)
    return (
        <Wrapper>
            <TableWrapper>
                <colgroup>
                    <col width={'290px'}/>
                    <col width={'120px'}/>
                    <col/>
                    <col/>
                    <col width={'168px'}/>
                </colgroup>
                <thead>
                <TableHeaderWrapper>
                    <Th>
                        <HeaderTextWrapper>
                            <TableWinLose isWin={myMatch.is_win}/>
                        </HeaderTextWrapper>
                    </Th>
                    {/*<WinTableHeader*/}
                    {/*    filterSelected={sort[0].value === sortData.sideIndex.value}*/}
                    {/*    leftTitle={t('gameAnalytics.winLose')}*/}
                    {/*    onClickSort={onClickSort}*/}
                    {/*    sort={sortData.sideIndex}*/}
                    {/*    percent={25} title={t('gameAnalytics.summoner')}*/}
                    <TableHeader
                        percent={16}
                        title={t('swam.passive')}/>
                    <TableHeader
                        percent={16}
                        title={t('gameAnalytics.damage')}/>
                    <TableHeader
                        percent={16}
                        title={t('gameAnalytics.kill')}/>
                    <TableHeader
                        percent={16}
                        title={t('gameAnalytics.gold')}/>
                    <TableHeader
                        percent={27} title={t('summoner.arena.itemNone')}/>
                </TableHeaderWrapper>
                </thead>

                {list.map((player) => {
                    const isme = player?.riot_id_name === myMatch.riot_id_name;
                    if (!player) return null;
                    return (
                        <SwarmAnalyticsTableContent
                            hide={hide}
                            region={region}
                            isme={isme}
                            key={player.riot_id_name}
                            allDamage={Alldamage}
                            data={player}
                        />
                    )
                })}
            </TableWrapper>
        </Wrapper>
    )
}

export default SwarmGameSummary;
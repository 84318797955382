import React, {useCallback, useMemo} from 'react';
import styled from "styled-components";
import {Link, useHistory, useLocation} from "react-router-dom";


const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: ${props => {
    let left = 0;
    if (props.paddingLeft) left = props.paddingLeft;
    return left;
  }};
  margin-block: 7px;
  margin-inline: 1px;
  flex: 1;
`;

const ImageText = styled.span`
  cursor: pointer;
  margin-left: 12px;
  font-size: ${props => {
    let fontSize = '14px';
    if (props.fontSize) fontSize = props.fontSize;
    return fontSize;
  }};
  text-align: left;
  min-height: 22px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  &:hover {
    scale: 1.2;
    transition: scale .2s ease-in-out;
`;

const ChampionImage = styled.img`
  margin: -3px;
  ${(props) => props.rounded ?
          "border-radius: 50%;\n  border: 1px solid black;": ""};
`;
const CRTChampion = ({
                         version,
                         imageName,
                         championName,
                         fontSize,
                         enChampName,
                         width = 42,
                         height = 42,
                         paddingLeft,
                         lane,
                         rounded,
                         disableOnClick = false,
                     }) => {
    const champName = useMemo(() => enChampName?.replace(/\s|&|willump|!|/gi, "")?.toLowerCase(), [enChampName]);
    const history = useHistory();
    const location = useLocation();
    const onClickText = useCallback(() => {
        if (!disableOnClick) {
            history.push(`/champions/${champName}/build/${lane.toLowerCase()}${location.search}`);
        }
    }, [championName, lane, disableOnClick])



    return (
        <Wrapper paddingLeft={paddingLeft}>
            <ImageWrapper>
                {disableOnClick ?
                    <ChampionImage
                        rounded={rounded}
                        loading={'lazy'}
                        width={width}
                        height={height}
                        src={`${process.env.REACT_APP_IMG}${version}/img/champion/${imageName}?w=64&h=64&f=webp`}
                        onError={(e) => e.target.src = "/images/icon_non_item.svg"}
                    />
                    :
                    <Link to={`/champions/${champName}/build/${lane.toLowerCase()}`}>
                        <ChampionImage
                            rounded={rounded}
                            width={width}
                            height={height}
                            src={`${process.env.REACT_APP_IMG}${version}/img/champion/${imageName}?w=64&h=64&f=webp`}
                            onError={(e) => e.target.src = "/images/icon_non_item.svg"}
                        />
                    </Link>
                }
            </ImageWrapper>
            {championName && <ImageText fontSize={fontSize} onClick={onClickText}>{championName}</ImageText>}
        </Wrapper>
    );
};

export default CRTChampion;

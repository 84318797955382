import React, {useCallback} from 'react';
import {threedigits} from "../../../../../function";
import styled, {useTheme} from "styled-components";
import ChampionImageWithLane from "../../../../common/ChampionImageWithLane";

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
  padding-left: 12px;
  margin-right: 7px;
`;

const ChampImgWrapper = styled.div`
  flex: ${props => props.flex || 1};
  display: flex;
  align-items: center;
`;


const GamesWrapper = styled.div`
  font-size: 12px;
  display: flex;
  justify-content: center;
  flex: ${props => props.flex || 1};
`;

const SynergySpan = styled.span`
  color: ${props => {
    let color = props.theme.color.font_E3;
    if (props.value > 0) color = props.theme.color.font_5D;
    return color;
  }};
`;

const WinrateColor = styled.span`
  font-size: 12px;
  color: ${props => {
    let color = props.theme.color.font_E3;
    if (props.children >= 50) color = props.theme.color.font_5D;
    return color;
  }};
`;

const Percent = styled.span`
  opacity: .6;
  color: ${props => props.theme.color.default_gray_font_color};
  font-size: 10px;
  line-height: 14px;
  margin-left: 1px;
`;


const ChampionImgBox = styled.div`
  position: relative;
  border-radius: 4px;

  .champName {
    font-weight: 500;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 12px;
    margin-right: 3px;
    height: 16px;
  }

  .imgChamp {
    width: 38px;
    height: 38px;
  }

  .imgDiv {
    border-radius: 5px;
  }

`;

const LaneBox = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1;
`;

const AgainstTableContent = ({
                                 games,
                                 champion_id,
                                 lane,
                                 winRate,
                                 synergy,
                                 CHAMPION_TABLE_FLEX,
                                 GAMES_TABLE_FLEX,
                                 WINRATE_TABLE_FLEX,
                                 SYNERGY_TABLE_FLEX
                             }) => {
    const theme = useTheme();
    const synergyCalc = useCallback((value) => {
        if (value > 0) {
            return `+${(value * 100).toFixed(1)}`
        } else {
            return `-${(Math.abs(value) * 100).toFixed(1)}`
        }
    }, [])


    return (
        <ItemWrapper>
            <ChampImgWrapper flex={CHAMPION_TABLE_FLEX}>
                <ChampionImgBox>
                    <ChampionImageWithLane
                        enableChampName={true}
                        champion_id={champion_id}
                    />
                </ChampionImgBox>
                {lane &&
                    <LaneBox>
                        <img
                            alt={""}
                            width={16} height={16}
                            src={`${theme.icon.path}/icon_s_position_${lane.toLowerCase()}.svg`}
                        />
                    </LaneBox>
                }
            </ChampImgWrapper>
            <GamesWrapper flex={GAMES_TABLE_FLEX}>
                <span>{threedigits(games)}</span>
            </GamesWrapper>
            <GamesWrapper flex={WINRATE_TABLE_FLEX}>
                <WinrateColor>{winRate === 100 ? 100 : winRate}</WinrateColor>
                <Percent>%</Percent>
            </GamesWrapper>
            {typeof SYNERGY_TABLE_FLEX === 'number' &&
                <GamesWrapper flex={SYNERGY_TABLE_FLEX}>
                    <SynergySpan value={Number(synergy)}>{synergyCalc(synergy)}</SynergySpan>
                    <Percent>%</Percent>
                </GamesWrapper>
            }
        </ItemWrapper>
    );
};

export default AgainstTableContent;

import React, {useMemo} from 'react';
import styled from "styled-components";
import {Link, useLocation, useRouteMatch} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  background-color: ${props => props.theme.color.summoner.tab.background_color};
  border: 1px solid ${props => props.theme.color.default_border_color};
  padding: 7px;
  border-radius: 6px;

  span:nth-child(n+1) {
    margin-left: 5px;
  }
`;

const CategorySpan = styled(Link)`
  color: ${props => {
      let color = props.theme.color.default_gray_font_color;
      if(props.selected) color = props => props.theme.color.font_fff;
      return color;
  }};
  text-align: center;
  font-size: 18px;
  min-width: 12ch;
  padding-inline: 12px;
  padding-block: 6px;
  background: ${props => {
      let background = 'transparent';
      if(props.selected) background = props.theme.color.gradient_green4;
      return background
  }};
  border-radius: 6px;
  opacity: ${props => {
      let opacity = '0.6';
      if(props.selected) opacity = '1';
      return opacity;
  }};
`;

const ChampionsDetailCategory = () => {
    const {t} = useTranslation();
    const {url} = useRouteMatch();
    const location = useLocation();
    const currentSelected = useMemo(() => location.pathname.split("/")[3],[location]);



    return (
        <Wrapper>
            <CategorySpan
            to={`${url}/build`}
                selected={currentSelected === 'build'}>
                {t("championDetail.build")}
            </CategorySpan>
            {/*<CategorySpan*/}
            {/*    to={`${url}/counter`}*/}
            {/*    selected={currentSelected === 'counter'}>*/}
            {/*    {t("championDetail.counter")}*/}
            {/*</CategorySpan>*/}
            <CategorySpan
                to={`${url}/mastery/all`}
                selected={currentSelected === 'mastery'}
            >
                {t("championDetail.mastery")}
            </CategorySpan>
            <CategorySpan
                to={`${url}/history`}
                selected={currentSelected === 'history'}
                >
                {t("championDetail.history")}
            </CategorySpan>
        </Wrapper>
    );
};

export default React.memo(ChampionsDetailCategory);

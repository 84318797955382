import React, {useMemo} from 'react';
import styled from "styled-components";

const backgroundColorList = {
    challenger: '#FFD940',
    grandmaster: '#ebb4c4',
    master: '#d3b3ea',
    diamond: '#a7cdf2',
    emerald: '#7fd6a7',
    platinum: '#97cbc4',
    gold: '#dbc798',
    silver: '#b7bbcb',
    bronze: '#c0aa9b',
    iron: '#a7a097',
    default: '#d1d4db',
}

const fontColorList = {
    challenger: '#009cff',
    grandmaster: '#b9354a',
    master: '#8d41a9',
    diamond: '#0063bb',
    emerald: '#21855a',
    platinum: '#2a8492',
    gold: '#a17716',
    silver: '#626b8a',
    bronze: '#815639',
    iron: '#514330',
    default: '#7f7f7f',
}


const setTierColor = ({tier, colorList}) => {
    try {
        if (Object.keys(colorList).find((key) => key === tier)) {
            return colorList[tier] ?? colorList.default;
        }
        return colorList.default;
    } catch (e) {
        return colorList.default;
    }
}


const Wrapper = styled.div`
  padding: 4px;
  border-radius: 5px;
  width: 24px;
  height: 14px;
  font-size: 11px;
  display: flex;
  line-height: 10px;
  align-items: center;
  justify-content: center;
  background: ${props => {
    return setTierColor({tier: props.tier, colorList: backgroundColorList});
  }}`;

const TagSpan = styled.span`
  font: normal normal medium 11px/17px 'Apple SD Gothic Neo';
  text-align: center;
  line-height: 10px;
  font-weight: 500;
  color: ${props => {
    return setTierColor({tier: props.tier, colorList: fontColorList})
  }};
`;


const TierTag = ({tier, division, fontSize, padding, testId}) => {


    const getTierAndDivision = useMemo(() => {
        if (typeof tier === 'string' && tier) {

            if (tier.toLowerCase() === 'challenger') {
                return 'C';
            } else if (tier.toLowerCase() === 'grandmaster') {
                return 'GM';
            } else if (tier.toLowerCase() === 'master') {
                return "M";
            } else if (tier.toLowerCase() === 'unranked') {
                return 'U';
            } else {
                if (tier.length > 0) {
                    return tier.toUpperCase()[0] + division;
                }

            }
        }

        return "U"
    }, [tier, division])

    if (typeof tier !== 'string' && typeof division !== 'number') return null;

    return (
        <Wrapper tier={tier.toLowerCase()} data-testid={testId} padding={padding}>
            <TagSpan fontSize={fontSize} tier={tier.toLowerCase()}>{getTierAndDivision}</TagSpan>
        </Wrapper>
    );
};

export default TierTag;

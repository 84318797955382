import React, {useMemo} from 'react';

import styled from "styled-components";

import {useSelector} from "react-redux";

import {useTranslation} from "react-i18next";
import {ColumnBox, FlexBox} from "../../../ui/styled";
import ChampionSkillImage from "../../../common/ChampionSkillImage";
import {ImageSpell} from "../../../common";
import BuildRuneList from "../../../ChampionsDetail/ChampionDetailMastery/BuildRuneList";
import BuildPickWinTable from "../../../ChampionsDetail/ChampionDetailMastery/BuildPickWinTable";
import BuildStartItem from "../../../ChampionsDetail/ChampionDetailMastery/BuildStartItem";
import BuildCoreTable from "../../../ChampionsDetail/ChampionDetailMastery/BuildCoreTable";
import BuildSkillSq from "../../../ChampionsDetail/ChampionDetailMastery/BuildSkillSq";
import BuildRuneCore from "../../../ChampionsDetail/ChampionDetailMastery/BuildRuneCore";
import _ from "lodash";
import {sortChampData} from "../../../../function";
import AccessoriesBuild from "../../../ChampionsDetail/ChampionsDetailContent/ChampionsDetailBuild/AccessoriesBuild";
import {BuildImageItem} from "../../../common/ImageSrc";
import ChampionsDetailBuildRuneBuild
    from "../../../ChampionsDetail/ChampionsDetailContent/ChampionsDetailBuildRuneBuild";

const HeaderBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 34px;
  width: 100%;
  border-left: 1px solid ${props => props.theme.color.default_bright_border_color};
  border-top: 1px solid ${props => props.theme.color.default_bright_border_color};
  border-right: 1px solid ${props => props.theme.color.default_bright_border_color};
  color: ${props => props.theme.color.font_5C};
  font-weight: bold;
  font-size: 12px;
  background-color: ${props => props.theme.color.default_input_background};
`;
const EmptyBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 160px;
  border: 1px solid ${props => props.theme.color.default_bright_border_color};
  background-color: ${props => props.theme.color.background_F6};
  font-size: 14px;
`;
const ItemBox = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: ${props => {
    let margin = 0;
    if (props.margin) margin = props.margin;
    return margin;
  }};

  .summoner_mastery_wrapper {
    height: 481px;
  }

  .imgSpell {
    width: 32px;
    border-radius: 5px;
    margin-right: 8px;
  }


  .imgSkillDiv {
    border: 0;
  }

  .imgSkill {
    border-radius: 5px;
  }
`;
const BackgroundWrapper = styled.div`
  background-color: ${props => props.theme.color.background_CF};
  padding: 10px;
  border: 1px solid ${props => props.theme.color.default_bright_border_color};
`;
const MarginBox = styled.div`
  margin-bottom: 10px;

  .imgItem {
    width: 32px;
    border-radius: 5px;
  }
`;

const ArrowBox = styled.div`
  margin: 0 10px;
`;

const ItemMarginBox = styled.div`
  margin-right: 8px;
`


const RuneListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${props => props.theme.color.default_bright_border_color};
  height: 481px;
  background-color: ${props => props.theme.color.default_input_background};
`;
const BuildRunes = ({coreList, champId, coreSelected, onChangeCoreSelected, skillList}) => {
    const {t} = useTranslation();
    const version = useSelector((state) => state.gameVersion.version);
    const selectedItem = useMemo(() => {
        const obj = {
            spell: [],
            shoes: [],
            skillMaster: [],
            startItem: [],
            skillBuild: [],
            coreBuild: [],
            rune: {},
            wards: [],
        }
        try {
            const item = coreList[coreSelected];
            return {
                wards: item.wards,
                spell: item.spell,
                shoes: item.boots,
                skillMaster: item.skill_master_seq,
                startItem: item.start_item,
                skillBuild: item.skill_build,
                coreBuild: [item.item_build_2, item.item_build_3, item.item_build_4, item.item_build_5],
                rune: item.rune,
            }

        } catch (e) {
            return {...obj}
        }
    }, [coreSelected, coreList, skillList]);

    const allRuneList = useMemo(() => {
        try {
            const copyCoreList = [...coreList];
            copyCoreList.shift();
            const result = copyCoreList.reduce((sum, cur, index) => {
                const sameRune = sum.findIndex((data) => {
                    if (!_.isEqual(data?.rune?.rune_main, cur?.rune?.rune_main)) {
                        return false;
                    }
                    return _.isEqual(data?.rune?.rune_sub, cur?.rune?.rune_sub);
                })

                if (sum.length > 0 && sameRune > -1) {

                    sum[sameRune] = {
                        ...sum[sameRune],
                        win_rate: sum[sameRune].win_rate + cur.win_rate,
                        games: Number(cur.games) + Number(sum[sameRune].games),
                        pick_rate: sum[sameRune].pick_rate + cur.pick_rate,
                        count: sum[sameRune].count + 1,
                    };
                } else {
                    sum.push({...cur, count: 1});
                }
                return sum;
            }, []);

            const runeList = result.map((data) => {
                return {
                    ...data.rune,
                    games: data.games,
                    pick_rate: data.pick_rate,
                    win_rate: data.win_rate / data.count,
                }
            }).splice(0, 10);
            return sortChampData(runeList, [{
                sortType: false,
                value: 'pick_rate'
            }])
        } catch (e) {
            console.log(e);
            return [];
        }
    }, [coreList]);

    const getSkillName = (index) => {
        switch (index) {
            case 1:
                return 'Q';
            case 2:
                return 'W';
            case 3:
                return 'E';
            case 4:
                return 'R';
            default:
                return ""
        }
    }
    if (coreList.length < 1 || skillList.spells.length < 1) return null;

    return (
        <FlexBox width={'100%'} alignItems={'flex-start'}>

            <ColumnBox width={'210px'} margin={'0 11px 0 0'}>
                <HeaderBox>{t('summoner.mastery.runeCore')}</HeaderBox>
                <BuildRuneCore coreList={coreList} coreSelected={coreSelected}
                               onChangeCoreSelected={onChangeCoreSelected}/>
            </ColumnBox>
            <ItemBox>
                <HeaderBox>
                    {t('summoner.mastery.runeBuild')}
                </HeaderBox>
                <BackgroundWrapper>
                    <FlexBox alignItems={'flex-start'} height={'100%'} margin={'0 0 10px 0'}>
                        <ItemBox margin={'0 7px 0 0'}>
                            <MarginBox>
                                {coreSelected === 0 ?
                                    <RuneListWrapper>
                                        <BuildRuneList list={allRuneList}/>
                                    </RuneListWrapper>
                                    :
                                    <ChampionsDetailBuildRuneBuild runeList={selectedItem.rune}/>}
                            </MarginBox>
                            <BuildPickWinTable title={t('summoner.mastery.skillMaster')}
                                               list={selectedItem.skillMaster} minHeight={'143px'} >
                                {(data) => data?.skill?.map((item, index) => {
                                    return (
                                        <FlexBox margin={'3px 0'} key={index} height={'41px'}>
                                            <ChampionSkillImage
                                                width={34}
                                                height={34}
                                                key={index}
                                                spell={skillList?.spells[item - 1]}
                                                version={version}
                                                img={skillList?.spells[item - 1]?.image?.full}
                                                absoluteText={getSkillName(item)}
                                            >
                                                {index + 1 !== data?.skill?.length && <ArrowBox>
                                                    <img src={'/images/icon_collapse_arrow.svg'} width={12}
                                                         height={12}/>
                                                </ArrowBox>}
                                            </ChampionSkillImage>
                                        </FlexBox>
                                    )
                                })}
                            </BuildPickWinTable>
                        </ItemBox>
                        <ItemBox>
                            <MarginBox>
                                <BuildPickWinTable title={t('summoner.mastery.spell')} list={selectedItem.spell}
                                                   minHeight={'112px'}>
                                    {(data) => data?.spell?.map((item, index) => {
                                        return <ImageSpell spellId={item} smite={true} key={index} width={32}
                                                           height={32}/>
                                    })}
                                </BuildPickWinTable>
                            </MarginBox>
                            <MarginBox>
                                <BuildStartItem list={selectedItem.startItem}/>
                            </MarginBox>
                            <MarginBox>
                                <BuildPickWinTable title={t('championDetailBuild.accessoryBuild')}
                                                   list={selectedItem.wards}
                                                   minHeight={'115px'}>
                                    {(data) => {
                                        return <AccessoriesBuild data={data?.wards}/>
                                    }}
                                </BuildPickWinTable>
                            </MarginBox>
                            <MarginBox>
                                <BuildPickWinTable title={t('summoner.mastery.boots')} list={selectedItem.shoes}
                                                   minHeight={'111px'}>
                                    {(data) => (
                                        <ItemMarginBox>
                                            <BuildImageItem itemId={data?.boots} width={32} height={32}/>
                                        </ItemMarginBox>
                                    )}
                                </BuildPickWinTable>
                            </MarginBox>
                            <BuildSkillSq list={selectedItem.skillBuild} champion_id={champId}
                                          skillList={skillList}/>

                            {/*<SummonerMasteryItem title={t('summoner.mastery.skillBuild')} list={selectedItem.skillBuild} minHeight={'143px'}>*/}
                            {/*    {(data) => data?.skill?.map((item, index) => {*/}
                            {/*        return (*/}
                            {/*            <PaddingBox key={index}>*/}
                            {/*                <ListTagValue>{getSkillName(item)}</ListTagValue>*/}
                            {/*            </PaddingBox>*/}
                            {/*        )*/}
                            {/*    })}*/}
                            {/*</SummonerMasteryItem>*/}
                        </ItemBox>
                    </FlexBox>
                    <BuildCoreTable list={selectedItem.coreBuild} defaultIndex={1}/>
                </BackgroundWrapper>
            </ItemBox>
        </FlexBox>
    );
};

export default BuildRunes;
import React, {useCallback} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const ButtonWrapper = styled.button`
  background: ${props => props.theme.color.background_fff_1A1};
  color: ${props => props.theme.color.default_gray_font_color};
  border-radius: 3px;
  border: 1px solid ${props => props.theme.color.default_bright_border_color};
  height: 28px;
  min-width: 89px;
  padding:0 15px;
`;

const ButtonText = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
`;

const DetailHideSummonerName = ({onChangeHide,hide}) => {
    const {t} = useTranslation();

    const onClick = useCallback(() => {
        onChangeHide();
    },[]);

    return (
        <ButtonWrapper onClick={onClick}>
            <ButtonText>{hide ? t('detailTab.show') :t('detailTab.hide')  }</ButtonText>
        </ButtonWrapper>
    );
};

export default DetailHideSummonerName;

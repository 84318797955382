import React, {createContext, useContext, useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const ExplainBox = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 15px;
  border: 1px solid ${props => props.theme.color.default_border_color};
  background-color: ${props => props.theme.color.background_fff_1A};
  width: 167px;
  padding: 4px 5px 4px 0;
  cursor: pointer;
`;

const ExplainText = styled.span`
  flex: 1;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  line-height: 18px;
  color: ${props => props.theme.color.default_gray_font_color};
`;

const DropDownContext = createContext();


const Dropdown = ({children}) => {
    const [open, toggle] = useState(false);

    const ref = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref?.current?.contains(event.target)) {
                toggle(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);


    const providerValue = {open, toggle};
    return (
        <DropDownContext.Provider value={providerValue}>
            <div ref={ref}>
                {children}
            </div>
        </DropDownContext.Provider>
    );
};


const Button = ({children}) => {
    const {toggle} = useContext(DropDownContext);


    const onClick = () => {
        toggle(prev => !prev)
    }

    return (
        <div onClick={onClick}>
            {children}
        </div>
    );
};


const List = ({children}) => {
    const {open} = useContext(DropDownContext);

    return open ? <div>{children}</div> : null
}

const Item = ({children}) => {
    const {toggle} = useContext(DropDownContext);

    const onClick = () => {
        toggle(false)
    }

    return <div onClick={onClick} className={'dropdown-item'}>{children}</div>
}

const ExplainButton = ({children}) => {
    const {t} = useTranslation();
    const {open} = useContext(DropDownContext);
    return (
        <ExplainBox>
            <ExplainText>
                {t("explain.aiScoreHeader")}
            </ExplainText>
            <img src={`./images/icon_arrow_down_${open ? 'open' : 'close'}.svg`} width={20} height={20}/>
        </ExplainBox>
    )
}

Dropdown.Button = Button;
Dropdown.List = List;
Dropdown.Item = Item;
Dropdown.ExplainButton = ExplainButton


export default Dropdown;

import React from 'react';
import {Footer, Header, Notice} from "../common";
import {ErrorBoundary} from "react-error-boundary";
import styled from "styled-components";
import DailyContent from "./content/DailyContent";
import CenterContentBox from "../Champions/ContentWrapper";
import {useTranslation} from "react-i18next";
import UseSetCanonical from "../../common/hooks/useSetCanonical";
import {useParams} from "react-router-dom";
import useTitleAndDescription from "../../common/hooks/useTitleAndDescription";

const Wrapper = styled.div`
`;

function Fallback({error, resetErrorBoundary}) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.

    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
        </div>
    );
}

const Daily = () => {
    UseSetCanonical();
    const {t} = useTranslation();
    const {platform, queue, tier} = useParams();


    useTitleAndDescription({
        title: t('header.titleAiRanking',{
            server: platform === 'all' ? "" : platform?.toUpperCase()
        }),
        description: t('header.titleAiRankingDescription',{
            server: platform === 'all' ? "" : platform?.toUpperCase()
        })
    })

    return (
        <Wrapper>
            <Header/>
            <Notice fixed={false}/>
            <CenterContentBox disableSponsor={true} disableAramHeader={true}>
                <ErrorBoundary FallbackComponent={Fallback}>
                    <DailyContent/>
                </ErrorBoundary>
            </CenterContentBox>
            <Footer/>
        </Wrapper>
    );
};

export default Daily;
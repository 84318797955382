import React, {useMemo} from 'react';
import styled from "styled-components";
import AginstHeader from "./AginstHeader";
import ChampionsDetailTitle from "../ChampionsDetailTitle";
import {sortChampData} from "../../../../function";
import AgainstTableContent from "./AgainstTableContent";
import AgainstTableHeader from "./AgainstTableHeader";


const Wrapper = styled.div`
  border: 1px solid ${props => props.theme.color.default_bright_border_color};
  width: ${props => props.width || 1};
  ${props => {
    if (!props.width) return 'flex:1'
  }};

`;

const ContentWrapper = styled.div`
  border-top: 1px solid ${props => props.theme.color.default_bright_border_color};
  background: ${props => props.theme.color.default_input_background};
`;


const OverflowWrapper = styled.div`
  height: 480px;
  overflow-y: auto;
  margin-right: 5px;

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: ${props => props.theme.color.background_B7_35};
  }

  ::-webkit-scrollbar-track {
    margin: 10px;
    background-color: ${props => props.theme.color.background_ED_1A};
    border-radius: 6px;
  }
`;


const ChampionsDetailAgainstTable = ({
    synergy,
                                         sortList,
                                         onClickHeader,
                                         currentSort,
                                         champions,
                                         version,
                                         width,
                                         CHAMPION_TABLE_FLEX = 0.6,
                                         GAMES_TABLE_FLEX = 0.4,
                                         WINRATE_TABLE_FLEX = 0.2,
                                         SYNERGY_TABLE_FLEX,
                                         title,
                                         barColor
                                     }) => {


    const sortChamp = useMemo(() => {
        if (Array.isArray(champions)) {
            return sortChampData(champions, currentSort)
        } else {
            return [];
        }
    }, [champions, currentSort]);


    return (
        <Wrapper className={"againstTable"} width={width}>
            <AginstHeader barColor={barColor} synergy={synergy}>
                {title}
            </AginstHeader>
            <ContentWrapper>
                <ChampionsDetailTitle>
                    <AgainstTableHeader
                        currentSort={currentSort}
                        sort={sortList}
                        onClickHeader={onClickHeader}
                        length={Array.isArray(champions) ? champions.length : 0}
                        CHAMPION_TABLE_FLEX={CHAMPION_TABLE_FLEX}
                        WINRATE_TABLE_FLEX={WINRATE_TABLE_FLEX}
                        GAMES_TABLE_FLEX={GAMES_TABLE_FLEX}
                        SYNERGY_TABLE_FLEX={SYNERGY_TABLE_FLEX}
                    />
                </ChampionsDetailTitle>
                <OverflowWrapper>
                    {Array.isArray(champions) && sortChamp.map((champion, index) => {
                        return <AgainstTableContent
                            key={index}
                            pickRate={(champion.pick_rate * 100).toFixed(1)}
                            winRate={(champion.win_rate * 100).toFixed(1)}
                            lane={champion.lane}
                            games={champion?.cnt}
                            synergy={champion?.synergy}
                            champion_id={champion.enemy_champion_id}
                            version={version}
                            CHAMPION_TABLE_FLEX={CHAMPION_TABLE_FLEX}
                            WINRATE_TABLE_FLEX={WINRATE_TABLE_FLEX}
                            GAMES_TABLE_FLEX={GAMES_TABLE_FLEX}
                            SYNERGY_TABLE_FLEX={SYNERGY_TABLE_FLEX}
                        />
                    })}

                </OverflowWrapper>
            </ContentWrapper>
        </Wrapper>
    );
};

export default ChampionsDetailAgainstTable;
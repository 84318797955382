import React, {useCallback} from 'react';
import styled from "styled-components";
import {useHistory, useLocation} from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid ${props => props.theme.color.border_BB_60};
`;

const Item = styled.div`
  cursor: pointer;
  text-align: center;
  min-width: 77px;
  height: 26px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:not(:last-of-type) {
    border-right: 1px solid ${props => props.theme.color.border_BB_60};
  }

  background-color: ${props => {
    let color = props.theme.color.background_F2;
    if (props.selected) color = props.theme.color.border_6A;
    return color;
  }};
  opacity: ${props => {
    let opacity = '0.5';
    if (props.selected) opacity = '1';
    return opacity;
  }};
  color: ${props => {
    let color = props.theme.color.default_gray_font_color;
    if (props.selected) color = '#ffffff';
    return color;
  }};

  font-size: 12px;
  font-weight: 500;
`;

const MasteryFilterRegion = ({selected}) => {
    const history = useHistory()
    const location = useLocation();
    const regionList = ['All', 'KR', 'NA', 'EUW','BR','VN'];
    const onClickRegion = useCallback((region) => {
        history.replace(`${location.pathname}?region=${region.toLowerCase()}`)
    }, [location]);
    return (
        <Wrapper>
            {regionList.map((data) => {
                return (
                    <Item selected={selected.toLowerCase() === data.toLowerCase()} key={data}
                          onClick={() => onClickRegion(data)}>{data}</Item>
                )
            })}
        </Wrapper>
    );
};

export default MasteryFilterRegion;
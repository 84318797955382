import styled from "styled-components";
import React from "react";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid ${props => props.theme.color.border_C31};
  border-radius: 4px;
  overflow: hidden;
`;

const Item = styled.div`
  cursor: pointer;
  text-align: center;
  min-width: ${props => props.minWidth || '77'}px;
  height: 26px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${props => {
      let color = props.theme.color.background_E1_35;
      if (props.selected) color = props => props.theme.color.border_6A;
      return color;
    }};
  }

  &:not(:last-of-type) {
    border-right: 1px solid ${props => props.theme.color.border_C31};
  }

  background-color: ${props => {
    let color = props.theme.color.background_F2;
    if (props.selected) color = props.theme.color.border_6A;
    return color;
  }};
  color: ${props => {
    let color = props.theme.color.font_7F;
    if (props.selected) color = '#ffffff';
    return color;
  }};

  font-size: 12px;
`;

const DailyFilterButtonList = ({selected, onClick, list, minWidth}) => {
    const onClickButton =(value) => {
        if (onClick) {
            onClick(value)
        }
    } ;

    if (!Array.isArray(list) || list?.length === 0) return null;
    return (
        <Wrapper>
            {list.map((data) => {
                return (
                    <Item
                        minWidth={minWidth}
                        selected={selected.toLowerCase() === data.value.toLowerCase()}
                        key={data.value}
                        onClick={() => onClickButton(data.value)}
                    >{data.title}</Item>
                )
            })}
        </Wrapper>
    );
};

export default DailyFilterButtonList;
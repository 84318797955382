import React, {useCallback} from 'react';
import styled from "styled-components";
import UpArrow from "../../../../../common/TagWithArrow/UpArrow";
import DownArrow from "../../../../../common/TagWithArrow/DownArrow";
import {ColumnBox, FlexBox} from "../../../../../ui/styled";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 30px;
  border: 1px solid ${props => props.theme.color.default_bright_border_color};
  background-color: ${props => props.theme.color.summoner.tab.background_color};
  border-radius: 6px;
  width: 250px;
  height: 204px;

  & + & {
    margin-left: 8px;
  }

`;

const Title = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme.color.default_gray_font_color};
  margin-bottom: 14px;
`;

const LPText = styled.span`
  font-weight: bold;
  font-size: 31px;
  color: ${props => props.theme.color.default_gray_font_color};
`;

const ArrowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const LPTitle = styled.span`
  font-size: 20px;
  color: ${props => props.theme.color.font_7F_89};
  font-weight: 500;
  margin-left: 8px;
`;

const InfoTitle = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.color.font_7F_A7};
`;

const GreenRedText = styled.span`
  margin-left: 5px;
  font-size: 14px;
  color: ${props => {
    let color = '#DE5353';
    if (props.isGreen) color = '#1CA484'
    return color;
  }};
`;

const InfoText = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.color.default_gray_font_color};
`;

const Percent = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: ${props => props.theme.color.font_65_A7};
  margin-left: 2px;
`;
const Bar = styled.div`
  width: 10px;
  height: 2px;
  background-color: ${props => props.theme.color.default_gray_font_color};
  opacity: 0.6;
`;

const RankingHeaderItem = ({img, lp, title, diff, ratio, summonerCount}) => {
    const {t} = useTranslation();
    const renderDict = useCallback(() => {
        if (diff === 0) {
            return <Bar/>
        } else if (diff > 0) {
            return <UpArrow/>
        } else if (diff < 0) {
            return <DownArrow/>
        }
    }, [diff]);
    return (
        <Wrapper>
            <Title>
                {title} {t('ranking.cutOff')}
            </Title>
            <FlexBox height={'auto'} justifyContent={'space-between'} width={'100%'}>
                <img
                    src={img}
                    width={60}
                    height={60}
                    alt={""}
                />
                <LPText>{lp}<LPTitle>LP</LPTitle></LPText>
            </FlexBox>
            <ColumnBox width={'100%'} justifyContent={'flex-start'} margin={'9px 0 0 0'}>
                <FlexBox justifyContent={'space-between'} width={'100%'} height={'20px'}>
                    <InfoTitle>
                        {t('ranking.daily')}
                    </InfoTitle>
                    <ArrowWrapper>
                        {renderDict()}
                        {diff !== 0 && <GreenRedText isGreen={diff >= 0}>{Math.abs(diff)}</GreenRedText>}
                    </ArrowWrapper>
                </FlexBox>
                <FlexBox height={'20px'} justifyContent={'space-between'} width={'100%'} margin={'6px 0 0 0'}>
                    <InfoTitle>
                        {t('ranking.ratio')}
                    </InfoTitle>
                    <InfoText>
                        {(ratio * 100).toFixed(2)}<Percent>%</Percent>
                    </InfoText>
                </FlexBox>
                <FlexBox height={'20px'} justifyContent={'space-between'} width={'100%'} margin={'6px 0 0 0'}>
                    <InfoTitle>
                        {t('ranking.topPlayers')}
                    </InfoTitle>
                    <InfoText>
                        {summonerCount}
                    </InfoText>
                </FlexBox>
            </ColumnBox>
        </Wrapper>
    );
};

export default RankingHeaderItem;

import React from 'react';
import styled from "styled-components";


const ChampInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
`;

const ChampVersusInfo = styled.span`
  border-radius: 3px;
  padding: 3px 9px;
  background-color: ${props => props.theme.color.background_E3};
  border: 1px solid ${props => props.theme.color.background_trans_60};
  font-size: 12px;
  color: ${props => props.theme.color.font_21_60};
  font-weight: 400;
`;

const ExtraText = styled.span`
  margin-left: 12px;
  font-size: 12px;
  color: ${props => props.theme.color.font_21};
`;

const ChampInfo = ({infoText, extra}) => {
    return (
        <>
            <ChampInfoWrapper>
                {infoText &&<ChampVersusInfo>
                    {infoText}
                </ChampVersusInfo>}
                {extra &&
                <ExtraText>
                    {extra}
                </ExtraText>}
            </ChampInfoWrapper>
        </>
    );
};

export default ChampInfo;

import React from 'react';
import styled, {useTheme} from "styled-components";
import {useTranslation} from "react-i18next";
import SummonerMasteryTable from "../../../compound/summonerMasteryProvider/SummonerMasteryTable";
import {ColumnBox, FlexBox} from "../../../ui/styled";
import DamageGraph from "../../../summoner/DetailTab/GameAnalytics/GameSummary/Table/TableContent/DamageGraph";
import {hundredCheck, threedigits} from "../../../../function";

const Wrapper = styled.div`
  width: 100%;
  border: 1px solid ${props => props.theme.color.default_bright_border_color};
  background-color: ${props => props.theme.color.default_input_background};
  min-height: ${props => props.minHeight || 'auto'};

  tr:last-child {
    td {
      padding-bottom: 7px;
    }
  }
`;

const ContentTD = styled.td`
  padding-top: ${props => props.contentPaddingTop || '7px'};
  vertical-align: middle;
`;

const DPMWrapper = styled.div`
  width: 200px;
`;






const CenterDiv = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;


const ExtraText = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 5px;
  font-size: 10px;
  color: #AAAAAA;
`;



const AbsoluteWrapper = styled.div`
  position: absolute;
  bottom: -15px;
  font-size: 10px;
  color: #AAAAAA;
`;


const ValueText = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: ${props => {
    let color = props.theme.color.font_E3;
    if (props.value >= 50) {
      color = props.theme.color.font_61;
    }
    return color;
  }};
`;

const PurpleText = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: ${props => props.theme.color.font_58};
`;

const PercentText = styled.span`
  font-size: 10px;
  color: ${props => props.theme.color.default_gray_font_color};
  opacity: 0.6;
  margin-left: 2px;
`;



const BuildPickWinTable = ({title, children, list, minHeight, hasDpm, highestDPM, absoluteGames}) => {
    const {t} = useTranslation();
    const theme = useTheme();
    return (
        <Wrapper minHeight={minHeight} className={'masteryItem'}>
            <SummonerMasteryTable title={title} headers={
                hasDpm ?
                    [{title: t('summoner.mastery.dpm'), col: '300px'},
                        {
                            title: t('championDetailBuild.pickRate'),
                            col: '55px'
                        }, {title: t('summoner.mastery.winRate'), col: "100px"}]
                    :
                    [{
                        title: t('championDetailBuild.pickRate'),
                        col: '55px'
                    }, {title: t('summoner.mastery.winRate'), col: "100px"}]
            } list={list}>
                {(data, index) => {
                    const pickValue = (data.pick_rate * 100).toFixed(1);
                    const winValue = (data.win_rate * 100).toFixed(1)
                    return (
                        <React.Fragment key={index}>
                            <ContentTD>
                                <FlexBox>
                                    {children(data)}
                                </FlexBox>
                            </ContentTD>
                            {hasDpm && <ContentTD>
                                <CenterDiv>
                                    <DPMWrapper>
                                        <DamageGraph
                                            bgColor={theme.color.background_E6}
                                            color={theme.color.font_58_9D}
                                            barColor={'#EF538E'}
                                            dmg={data.dpm}
                                            total={highestDPM}
                                        />
                                    </DPMWrapper>
                                </CenterDiv>
                            </ContentTD>}
                            <ContentTD>
                                {absoluteGames ?
                                    <CenterDiv>
                                        <PurpleText
                                        >{pickValue}</PurpleText>
                                        <PercentText>
                                            %
                                        </PercentText>
                                        <AbsoluteWrapper>
                                            {threedigits(data.games)}
                                        </AbsoluteWrapper>
                                    </CenterDiv>
                                    :
                                    <ColumnBox>
                                        <CenterDiv>
                                            <PurpleText
                                            >{hundredCheck(pickValue)}</PurpleText>
                                            <PercentText>
                                                %
                                            </PercentText>
                                        </CenterDiv>
                                        <ExtraText>
                                            {threedigits(data.games)}
                                        </ExtraText>
                                    </ColumnBox>
                                }
                            </ContentTD>
                            <ContentTD>
                                {absoluteGames ?
                                    <CenterDiv>
                                        <ValueText value={winValue}>
                                            {hundredCheck(winValue)}
                                        </ValueText>
                                        <PercentText>
                                            %
                                        </PercentText>
                                    </CenterDiv>
                                    :
                                    <ColumnBox>

                                        <CenterDiv>
                                            <ValueText value={winValue}>
                                                {winValue}
                                            </ValueText>
                                            <PercentText>
                                                %
                                            </PercentText>
                                        </CenterDiv>
                                    </ColumnBox>
                                }
                            </ContentTD>
                        </React.Fragment>
                    )

                }}
            </SummonerMasteryTable>
        </Wrapper>
    );
};

export default BuildPickWinTable;
import React from 'react';
import styled from "styled-components";

const SectionTitleWrapper = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
`;

const SectionTitleText = styled.span`
  color: ${props => props.theme.color.background_5F_EA};
  font-size: 14px;
  font-weight: bold;
`;

const ChampionsListHeader = ({data}) => {
    return (
        <SectionTitleWrapper>
            <SectionTitleText>
                {data}
            </SectionTitleText>
        </SectionTitleWrapper>
    );
};

export default React.memo(ChampionsListHeader);
import React, {useCallback, useMemo} from 'react';
import {ImageRune, ImageSpell} from "../../../../../../../common";
import styled from "styled-components";
import {onStorePuuId} from "../../../../../../../../function";
import {useTranslation} from "react-i18next";
import {openModal} from "../../../../../../../../store/modules/modal";
import {useDispatch} from "react-redux";
import ChampionImageWithLane from "../../../../../../../common/ChampionImageWithLane";
import InfluInfo from "../../../../../../Profile/InfluInfo";
import TierTag from "../../../../../../../common/TierTag";
import useStreamerTag from "../../../../../../../../common/hooks/useStreamerTag";

const WinLoseBar = styled.div`
  display: flex;
  align-items: center;
  background-color: ${props => {
    let color = props.theme.color.font_E3;
    if (props.win) color = props.theme.color.font_5D;
    return color;
  }};
  padding: 0 4px;
`;
const WinLoseText = styled.span`
  color: ${props => props.theme.color.white_font_color};
  font-size: 12px;
  font-weight: 0;
  width: 2ch;
  text-align: center;
`;

const ChampionImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 0 0 7px;

  .imgDiv {
    border-radius: ${props => {
      let border = '7px';
      if (props.isme) border = '6px';
      return border;
    }};
    border: ${props => {
      let border = 0;
      if (props.isme) border = '3px solid #FFB727';
      return border;
    }}
  }

  .imgChamp {
    width: ${props => {
      let border = '42px';
      if (props.isme) border = '36px';
      return border;
    }};
    aspect-ratio: 1;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 230px;
`;

const RuneAndPerkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;


const SpellWrapper = styled.div`
  margin-left: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  .imgSpell {
    width: 16px;
    height: 16px;
    border-radius: 3px;
  }
`;

const RunWrapper = styled.div`
  margin-left: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &:hover {
    cursor: pointer;
  }

  .imgSpell {
    width: 16px;
    height: 16px;
    border-radius: 3px;
  }
`;


const PerkWrapper = styled.div`;
  border-radius: 50%;

  .imgRune {
    width: 16px;
    height: 16px;
  }
`;


const SummonerInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 7px;
`;
const NameText = styled.span`
  width: 22ch;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 12px;

  padding-bottom: 2px;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const TagText = styled.span`
  margin-left: 5px;
  font-size: 12px;
  color: ${props => props.theme.color.font_7F_89};
`;


const ImageWrapper = styled.div`
  position: relative;
`;


const BadgeWrapper = styled.div`
  display: flex;
  margin-right: 3px;
`;

const LinkText = styled.span`
  font-weight: ${props => props.isme ? "bold" : 'normal'};

  height: ${props => {
    let height = 'auto';
    if (props.hide) height = '8px';
    return height;
  }};
`;

const TierAndInfluWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 6px;

  .influ-name {
    font-size: 12px;
    color: ${props => props.theme.color.font_7F_EA};
  }

`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;


const SummarySummonerInfo = ({

                                 oldName,
                                 tag,
                                 division,
                                 smite,
                                 win,
                                 summonerName,
                                 level,
                                 champId,
                                 spellFId,
                                 spellDId,
                                 perk,
                                 perkSubStyle,
                                 tier,
                                 isme,
                                 region,
                                 data,
                                 puuId,
                                 hide,
                                 disableBar,
                                 disablePerk,
                             }) => {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {streamerProTag} = useStreamerTag({
        puu_id: data.puu_id,
        platform: region,
    });

    const OpenModal = useCallback(() => {
        if (data) {
            dispatch(openModal(true, data))
        }
    }, []);


    const getName = useMemo(() => {
        if (summonerName?.length > 0) {
            if (hide) {
                return new Array(summonerName.length).fill('').map(() => "*");
            } else {
                return summonerName
            }
        }

        if (oldName.length > 0) {
            if (hide) {
                return new Array(oldName.length).fill('').map(() => "*");
            } else {
                return oldName
            }
        }

        return "";
    }, [hide, summonerName, oldName])


    return (<ContentWrapper>
        {!disableBar &&
            <WinLoseBar win={win}>
                <WinLoseText>{win ? t('gameAnalytics.win') : t('gameAnalytics.lose')}</WinLoseText>
            </WinLoseBar>}
        <ChampionImageWrapper isme={isme}>
            <ImageWrapper>
                <ChampionImageWithLane champion_id={champId} level={level}/>
            </ImageWrapper>
            {!disablePerk &&
                <RuneAndPerkWrapper>
                    <SpellWrapper>
                        <ImageSpell spellId={spellFId} smite={smite}/>
                        <ImageSpell spellId={spellDId} smite={smite}/>
                    </SpellWrapper>
                    {perk &&
                        <RunWrapper onClick={OpenModal}>
                            <PerkWrapper>
                                <ImageRune runeId={perk}/>
                            </PerkWrapper>
                            <PerkWrapper>
                                <ImageRune runeId={perkSubStyle}/>
                            </PerkWrapper>
                        </RunWrapper>}
                </RuneAndPerkWrapper>}
            <SummonerInfoWrapper>
                <NameWrapper>
                    <BadgeWrapper>
                        <TierTag
                            fontSize={'11px'}
                            padding={'4px 4px'}
                            tier={tier}
                            division={division}
                        />
                    </BadgeWrapper>
                    <NameText onClick={() => onStorePuuId(tag ? `${getName}-${tag}` : getName, region, puuId)}><LinkText
                        isme={isme ? true : false} hide={hide}>{getName}{(!hide && tag) &&
                        <TagText>#{tag}</TagText>}</LinkText></NameText>
                </NameWrapper>
                {hide ? null : streamerProTag?.status ?
                    <TierAndInfluWrapper>
                        <InfluInfo
                            smallIcon={true}
                            influData={{...streamerProTag,team: streamerProTag?.pro_team_al}}
                        />
                    </TierAndInfluWrapper> : null
                }
            </SummonerInfoWrapper>
        </ChampionImageWrapper>
    </ContentWrapper>);
};

export default SummarySummonerInfo;

import React, {useMemo} from 'react';
import styled from "styled-components";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";


const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 10px;
`;

const GamesText = styled.span`
  color: ${props => props.theme.color.font_49};
  opacity: 0.8;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 85.714% */
`;

const KDAWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  min-height: 16px;
  margin-bottom: 8px;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const WinLoseSpan = styled.span`
  font-size: 11px;
  line-height: 12px;
  color: ${props => props.theme.color.font_7F_A7};
`;
const OverviewGames = ({games,lose,wins}) => {
    const {t} = useTranslation();
    const currentLang = useSelector((state) => state.translation.lang);
    const getGameText = useMemo(() => {
        if (currentLang === 'KOR') {
            return "게임"
        }
        if (games === 1) {
            return "Game"
        } else {
            return "Games"
        }

    }, [currentLang]);
    return (
        <Wrapper>
            <KDAWrapper>
                <GamesText>{Number(games)} {getGameText}</GamesText>
            </KDAWrapper>
            <ContentWrapper>
                <WinLoseSpan>{wins}{t('gameAnalytics.win')} {lose}{t('gameAnalytics.lose')}</WinLoseSpan>
            </ContentWrapper>
        </Wrapper>
    );
};

export default OverviewGames;

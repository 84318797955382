import React from 'react';
import styled from "styled-components";
import RankingHeaderItem from "./RankingHeaderItem";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import ChartRanking from "../../../../charts/ChartRanking";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
`;

const Splitter = styled.div`
  width: 1px;
  height: 18px;
  background-color: ${props => props.theme.color.border_A5_60};
  opacity: 0.3;
  margin: 0 12px;
`;

const RankingChartBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 15px 30px;
  border: 1px solid ${props => props.theme.color.default_bright_border_color};
  background-color: ${props => props.theme.color.summoner.tab.background_color};
  border-radius: 6px;
  height: 204px;
  margin-left: 8px;
`;
const Title = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme.color.default_gray_font_color};
  margin-bottom: 14px;
`;
const RankingHeaderLP = ({challenger, grandMaster, rankingRegion, graphX}) => {
    const {t} = useTranslation();
    const tier = useSelector((state) => state.imageStore.aiData);

    if (!tier?.tier_boundary_solo) return null;
    console.log(graphX)
    console.log(challenger.graph)
    return (
        <Wrapper>
            <RankingHeaderItem
                title={t('ranking.challenger')}
                img={'/images/Emblem_CHALLENGER.png'}
                lp={challenger.lp}
                diff={challenger.lp - challenger.yesterday}
                ratio={challenger.ratio}
                summonerCount={challenger.cnt}
            />
            <RankingHeaderItem
                title={t('ranking.grandMaster')}
                img={'/images/Emblem_GRANDMASTER.png'}
                lp={grandMaster.lp}
                diff={grandMaster.lp - grandMaster.yesterday}
                ratio={grandMaster.ratio}
                summonerCount={grandMaster.cnt}
            />
            <RankingChartBox>
                <Title>
                    {t('ranking.cutOffGraph')}
                </Title>
                {graphX?.length > 1 &&
                    <ChartRanking width={500} height={150} grandMasterSeries={grandMaster.graph}
                                  challengerSeries={challenger.graph} dateList={graphX}/>}
            </RankingChartBox>
        </Wrapper>
    );
};

export default RankingHeaderLP;

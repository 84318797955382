import React, {useCallback, useEffect,} from 'react';
import styled from "styled-components";
import useInput from "../../../../../common/hooks/useInput";
import ScoreWrapper from "../../../DetailTab/GameAnalytics/BestContent/BestSection/ScoreWrapper";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 10px;
`;


const scoreInit = {
    count: 0,
    value: 0,
}
const initObj = {
    win_ai_score: {
        ...scoreInit
    },
    lose_ai_score: {
        ...scoreInit
    },
    total_ai_score: {
        ...scoreInit
    }
};


const TOTAL_AI_SCORE = 'total_ai_score'

const AiScoreBox = ({positionArray}) => {

    const [aiScores, onChangeAiScores] = useInput({...initObj});


    const setValue = useCallback(() => {
        return positionArray.reduce((sum, cur) => {
            console.log(cur.queue_id)
            if (cur.queue_id === 3000 || cur.queue_id === 1810 || cur.queue_id === 1820 || cur.queue_id === 1830 || cur.queue_id === 1840) {
                return sum;
            }
            sum.total_ai_score = {
                count: sum.total_ai_score.count + 1,
                value: sum.total_ai_score.value + cur.ai_score,
            }
            return sum;
        }, {...initObj})
    }, [positionArray]);


    const renderValue = useCallback((objString) => {
        if (aiScores[objString].count > 0) {
            return (aiScores[objString].value / aiScores[objString].count).toFixed(0)
        } else {
            return 0;
        }
    }, [aiScores]);
    //
    // const renderCount = useCallback((objString) => {
    //     if (aiScores[objString].count > 0) {
    //         return aiScores[objString].count;
    //     } else {
    //         return 0;
    //     }
    // }, [aiScores])


    useEffect(() => {
        if ((Array.isArray(positionArray) && positionArray.length > 0)) {
            onChangeAiScores(setValue());
        } else {
            onChangeAiScores({...initObj});
        }
    }, [positionArray]);


    console.log("????",renderValue(TOTAL_AI_SCORE))

    return (
        <Wrapper>
            <ScoreWrapper score={renderValue(TOTAL_AI_SCORE)} testid={TOTAL_AI_SCORE} fontSize={19}
                          paddingHorizontal={6}
                          paddingVertical={4}
                          noneClickable={true}/>
        </Wrapper>
    );
};

export default AiScoreBox;

import React, {useMemo} from 'react';
import {ColumnBox} from "../../ui/styled";
import SummonerMasteryItem from "./SummonerMasteryItem";
import styled from "styled-components";
import useInput from "../../../common/hooks/useInput";
import {ImageItem} from "../../common";
import {useTranslation} from "react-i18next";

const HeaderBox = styled.div`
  color: ${props => props.theme.color.font_74_EA};
  font-weight: 500;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 11px;
  background-color: ${props => {
    let color = props.theme.color.default_input_background;
    if (props.selected) color = props.theme.color.background_E8E5FE
    return color;
  }};
  cursor: pointer;
  ${props => {
    if (props.selected) {
      return `
  outline: 1px solid ${props.theme.color.border_6A};
  outline-offset: -1px;
          `
    }
  }}
`;
const Wrapper = styled(ColumnBox)`
  border: 1px solid ${props => props.theme.color.default_bright_border_color};

  .masteryItem {
    border: 0;
  }

  .imgItem {
    margin-right: 0;
    border-radius: 5px;
  }
`;

const HeaderBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.color.default_bright_border_color};

  ${HeaderBox}:not(:last-of-type) {
    border-right: 1px solid ${props => props.theme.color.default_bright_border_color};
  }
`;

const ArrowBox = styled.div`
  margin: 0 10px;
`;
const PaddingBox = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  height: 36px;
`;
const YellowWrapper = styled.div`
  border: 2px solid #FCB000;
  border-radius: 5px;
  overflow: hidden;

  .imgItem {
    border-radius: 0;
  }
`
const SummonerMasteryCoreTable = ({list}) => {
    const {t} = useTranslation();
    const [selected, onChangeSelected] = useInput(0);

    const currentList = useMemo(() => {
        try {
            return list[selected];
        } catch (e) {
            return [];
        }
    }, [list, selected])


    const highestDPM = useMemo(() => {
        try {
            const dpmList = currentList.map((data) => data.dpm);
            const dpm = Number(Math.max(...dpmList));
            return dpm
        } catch (e) {
            return 0;
        }
    }, [currentList]);

    const renderItem = (data) => {

        return data?.items?.map((item, index) => {
            return (
                <PaddingBox key={index}>
                    <ImageItem itemId={item} width={32} height={32}/>
                    {data?.items?.length !== index + 1 &&
                        <ArrowBox>
                            <img src={'/images/icon_collapse_arrow.svg'} width={12} height={12}/>
                        </ArrowBox>}
                </PaddingBox>);
        });
    }

    return (
        <Wrapper>
            <HeaderBoxWrapper>
                {Array.from({length: list.length}).map((_, index) => {
                    return (
                        <HeaderBox selected={selected === index} key={index}
                                   onClick={() => onChangeSelected(index)}>{index + 2}{t('summoner.mastery.core')}</HeaderBox>
                    )
                })}
            </HeaderBoxWrapper>
            <SummonerMasteryItem title={t('summoner.mastery.itemBuild')} list={currentList} minHeight={'285px'}
                                 highestDPM={highestDPM} hasDpm={true}>
                {(data) => renderItem(data)}

            </SummonerMasteryItem>
        </Wrapper>
    );
};

export default SummonerMasteryCoreTable;
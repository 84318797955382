import styled, {css} from 'styled-components';

export const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  padding: 0 25px;
  background: transparent linear-gradient(270deg, rgba(44, 47, 67, 0.9) 0%, rgba(84, 106, 128, 0.9) 100%);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4);

  .h__menu {
    display: flex;
    align-items: center;
    flex: 1 1 100%;

    .logo {
      display: flex;
      margin-top: 5px;

      h1 {
        overflow: hidden;

        a {
          //display: block;
          //width: 125px;
          //height: 30px;
          //background: url('/images/deeplol_logo.svg') no-repeat center;
          //text-indent: -9999px;
          //color: transparent;
        }
      }

      .beta {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 16px;
        background: transparent linear-gradient(90deg, #2e79bb 0%, #561dab 100%);
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
        border-radius: 12px;
        font-size: 11px;
        font-weight: 400;
        color: ${props => props.theme.color.default_input_background};
      }
    }

    .hide {
      text-indent: -9999px;
      opacity: 0;
      color: transparent;
    }

    nav {
      display: flex;
      align-items: center;
      margin-left: 20px;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 60px;
        padding: 0 10px;
        font-size: 14px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.6);
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);

        &:hover {
          color: ${props => props.theme.color.default_input_background};
          background: transparent linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(96, 96, 96, 0.3) 100%);
        }

        &.active {
          color: #5df9ff;
        }
      }
    }
  }

  .h__util {
    display: flex;
    align-items: center;
    flex: 1 1 100%;
    justify-content: flex-end;

    .lang {
      position: relative;

      button {
        display: flex;
        align-items: center;
        height: 18px;
        font-size: 12px;
        color: #dfdfdf;

        &::before {
          content: '';
          display: inline-block;
          width: 17px;
          height: 17px;
          margin-top: 3px;
          margin-right: 9px;
          background: url('/images/ico_lang_w.svg') no-repeat center;
          background-size: cover;
        }

        &::after {
          content: '';
          overflow: hidden;
          display: inline-block;
          border-color: #dfdfdf transparent transparent transparent;
          border-style: solid;
          border-width: 4px;
          width: 0;
          height: 0;
          transition: all 0.3s linear;
          margin-top: 3px;
          margin-left: 9px;
        }
      }

      ul {
        position: absolute;
        z-index: 1;
        overflow-x: hidden;
        overflow-y: auto;
        width: 100%;
        max-width: 536px;
        max-height: 140px;
        margin-top: 10px;
        padding: 10px 0;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
        border: 1px solid ${props => props.theme.color.default_gray_font_color};
      }

      li {
        display: flex;
        align-items: center;
        height: 30px;
        padding: 0 10px;
        font-size: 14px;
        font-weight: 400;
        color: #ababab;
        cursor: pointer;

        &:hover,
        &.selected {
          color: #3362e5;
          background: #dbe8ff;
        }
      }
    }
  }

  .h__contactus {
    position: fixed;
    bottom: 47px;
    left: 47px;
    z-index: 50;

    img {
      cursor: pointer;
    }

    .close {
      position: absolute;
      top: 20px;
      right: 15px;
      width: 30px;
    }
  }

  .temp {
    position: absolute;
    top: 100%;
    left: 50%;
    margin-top: 55px;
    margin-left: -350px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 730px;
    min-height: 45px;
    background: #ffe1e1;
    box-shadow: 0px 2px 4px #00000029;
    border: 1px solid #df5555;
    border-radius: 22px;

    p {
      font-size: 14px;
      color: #de3737;
    }

    button {
      position: absolute;
      top: 50%;
      right: 20px;
      margin-top: -8px;
      width: 16px;
      height: 16px;
      background: url('/images/icon_input_del.svg') no-repeat center;
    }
  }
`;

export const FooterContainer = styled.footer`
  //position: fixed;
  //bottom: 50px;
  //left: 50%;
  //transform: translateX(-50%);
  margin-bottom: 4px;
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  .f__copy {
    display: flex;
    align-items: center;

    dl {
      max-width: 620px;
      margin-left: 35px;

      dt {
        font-size: 10px;
        font-weight: 700;
        color: ${props => props.theme.color.footer.copy_font_color};
      }

      dd {
        font-size: 10px;
        font-weight: 400;
        color: ${props => props.theme.color.footer.body_font_color};
        margin: 3px 0;
      }
    }
  }

  .f__menu {
    display: flex;
    align-items: center;

    nav {
      display: flex;
      align-items: center;

    }

    .toggle {
      position: relative;
      width: 127px;
      border-bottom: 1px solid ${props => props.theme.color.footer.content_font_color};

      &__btn {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 28px;
        padding: 0 8px;
        font-size: 12px;
        font-weight: 400;
        color: ${props => props.theme.color.footer.content_font_color};
        cursor: pointer;

        &::after {
          content: '';
          overflow: hidden;
          display: inline-block;
          border-color: ${props => props.theme.color.footer.content_font_color} transparent transparent transparent;
          border-style: solid;
          border-width: 4px;
          width: 0;
          height: 0;
          transition: all 0.3s linear;
          margin-top: 3px;
          margin-left: 9px;
        }
      }

      &__list {
        position: absolute;
        bottom: 100%;
        left: 0;
        overflow-x: hidden;
        overflow-y: auto;
        width: 100%;
        max-width: 536px;
        max-height: 140px;
        margin-bottom: 10px;
        padding: 10px 0;
        background: ${props => props.theme.color.default_input_background};
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
        border: 1px solid ${props => props.theme.color.default_gray_font_color};

        &::-webkit-scrollbar {
          display: none;
        }
      }

      &__list-item {
        display: flex;
        align-items: center;
        height: 30px;
        padding: 0 10px;
        font-size: 14px;
        font-weight: 400;
        color: #ababab;
        cursor: pointer;

        &:hover,
        &.selected {
          color: #3362e5;
          background: #dbe8ff;
        }
      }
    }
  }
`;

export const SearchContainer = styled.div`
  //position: absolute;
  //top: 50%;
  //left: 50%;
  //transform: translate(-50%, -50%);
  display: flex;
  flex: 1 1 100%;
  justify-content: center;
  align-content: center;
  height: 36px;

  .toggle {
    position: relative;
    width: 100%;
    max-width: 72px;
    border-bottom: 1px solid ${props => props.theme.color.default_input_background};

    &__btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 0 8px;
      font-size: 20px;
      font-weight: 700;
      color: #dfdfdf;
      cursor: pointer;

      &::after {
        content: '';
        overflow: hidden;
        display: inline-block;
        border-color: #dfdfdf transparent transparent transparent;
        border-style: solid;
        border-width: 4px;
        width: 0;
        height: 0;
        transition: all 0.3s linear;
        margin-top: 3px;
        margin-left: 9px;
      }
    }

    &__list {
      position: absolute;
      z-index: 1;
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;
      max-width: 536px;
      max-height: 140px;
      margin-top: 10px;
      padding: 10px 0;
      background: ${props => props.theme.color.default_input_background};
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
      border: 1px solid ${props => props.theme.color.default_gray_font_color};
    }

    &__list-item {
      display: flex;
      align-items: center;
      height: 30px;
      padding: 0 10px;
      font-size: 16px;
      font-weight: 400;
      color: #ababab;
      cursor: pointer;

      &:hover,
      &.selected {
        color: #3362e5;
        background: #dbe8ff;
      }
    }
  }

  .search {
    position: relative;
    width: 100%;
    margin-left: 10px;
    border-bottom: 1px solid ${props => props.theme.color.default_input_background};

    &__input {
      width: 100%;
      height: 100%;
      padding: 0 50px 0 10px;
      white-space: normal;
      background: none;
      border: 0;
      font-size: 18px;
      font-weight: 400;
      color: ${props => props.theme.color.default_input_background};

      &::placeholder {
        color: #dfdfdf;
        font-weight: 100;
      }
    }

    &__btn {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      width: 28px;
      height: 28px;
      background: url('/images/ico_search_b.svg') no-repeat center;
      font-size: 0;
      text-indent: -9999px;
    }

    &__autocomplete {
      position: absolute;
      z-index: 99;
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;
      max-width: 536px;
      max-height: 140px;
      margin-top: 10px;
      padding: 10px 0;
      background: ${props => props.theme.color.default_input_background};
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
      border: 1px solid ${props => props.theme.color.default_gray_font_color};

      &::-webkit-scrollbar {
        display: none;
      }

      &-item {
        display: flex;
        align-items: center;
        height: 30px;
        padding: 0 10px;
        font-size: 16px;
        font-weight: 400;
        color: #ababab;
        cursor: pointer;

        &:hover,
        &.selected {
          color: #3362e5;
          background: #dbe8ff;
        }
      }
    }
  }

  ${(props) =>
          props.home &&
          css`
            position: relative;
            top: 0;
            left: 0;
            transform: none;

            .toggle {
              border-bottom-color: ${props => props.theme.color.default_font_color};

              &__btn {
                color: ${props => props.theme.color.default_font_color};

                &::after {
                  border-color: ${props => props.theme.color.default_gray_font_color} transparent transparent transparent;
                  border-style: solid;
                  border-width: 4px;
                }
              }
            }

            .search {
              width: 538px;
              border-bottom: 1px solid ${props => props.theme.color.default_gray_font_color};

              &__input {
                color: ${props => props.theme.color.default_font_color};

                &::placeholder {
                  color: rgba(49, 49, 49, 0.5);
                }
              }

              &__btn {
                background: url('/images/ico_search_w.svg') no-repeat center;
              }
            }
          `}
`;

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;

  &.openModal {
    .dimm,
    .modal {
      animation: modal-show 0.5s;
    }
  }

  .dimm {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(2px);
    z-index: 10;
  }

  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    border: 1px solid ${props => props.theme.color.border_5D};
    background-color: ${props => props.theme.color.background_F4};
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.3);
    z-index: 20;

    &.builds {
      width: 720px;
      padding: 14px;

      h4 {
        font-size: 16px;
        font-weight: 700;
      }

      .build {
        display: flex;
        margin-top: 10px;
        padding: 9px;
        background-color: ${props => props.theme.color.background_fff_1A};
        border: ${props => {
          let border = '1px solid #cad7e2'
          if (props.theme.color.theme === 'dark') border = 0;
          return border;
        }};

        &.items {
          align-items: center;
          flex-wrap: wrap;
          margin-bottom: 14px;
          padding: 10px 0;

          .items_per_min {
            flex: 0 1 auto;
            position: relative;
            padding: 10px 15px;

            &::after {
              content: '';
              position: absolute;
              top: 16px;
              left: 0;
              display: block;
              width: 100%;
              height: 16px;
              background: ${props => props.theme.color.background_CA};
            }

            &:last-of-type {
              .items::after {
                display: none;
              }
            }

            .items {
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              z-index: 10;

              &::after {
                content: '';
                position: absolute;
                top: 11px;
                right: -20px;
                width: 9px;
                height: 8px;
                background: url('/images/icon_arrow_up_w.svg') no-repeat center;
                background-size: cover;
              }

              > span {
                position: relative;
                display: block;

                &.ITEM_SOLD {
                  &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 100%;
                    height: 100%;
                    background: rgba(255, 255, 255, 0.3) url('/images/icon_s_close.svg') no-repeat center;
                  }
                }
              }
            }

            .times {
              display: block;
              margin-top: 5px;
              font-size: 10px;
              font-weight: 500;
              text-align: center;
            }
          }
        }

        &.skills {
          align-items: center;
          margin-bottom: 14px;
          padding: 10px 0;

          .skills_m_seq {
            position: relative;
            display: flex;
            width: 180px;
            margin: 0 30px;

            &::after {
              content: '';
              position: absolute;
              top: 11px;
              left: 0;
              display: block;
              width: 100%;
              height: 16px;
              background: ${props => props.theme.color.background_CA};
            }

            .skills {
              position: relative;
              z-index: 10;

              &::after {
                content: '';
                position: absolute;
                top: 15px;
                right: -24px;
                width: 9px;
                height: 8px;
                background: url('/images/icon_arrow_up_w.svg') no-repeat center;
                background-size: cover;
              }

              &:last-of-type::after {
                display: none;
              }
            }

            .skills + .skills {
              margin-left: 36px;
            }

            .texts {
              display: block;
              margin-top: 2px;
              font-size: 16px;
              font-weight: 700;
              text-align: center;
            }
          }

          .skills_seq {
            display: flex;

            .qwer {
              display: flex;
              flex-direction: column;

              span {
                background-color: ${props => props.theme.color.background_68};

                & + span {
                  margin-top: 1px;
                }

                &.plus {
                  font-size: 18px;
                  font-weight: 400;
                }
              }
            }

            .skills {
              display: flex;

              & + .skills {
                margin-top: 1px;
              }

              span {
                margin-left: 1px;
              }

              &.q {
                .selected {
                  background: transparent linear-gradient(180deg, #f26884 0%, #ac3e79 100%);
                }
              }

              &.w {
                .selected {
                  background: transparent linear-gradient(180deg, #6a70cc 0%, #8630a4 100%);
                }
              }

              &.e {
                .selected {
                  background: transparent linear-gradient(180deg, #5eb0db 0%, #415ca4 100%);
                }
              }

              &.r {
                .selected {
                  background: transparent linear-gradient(180deg, #f8cb38 0%, #b95c2c 100%);
                }
              }

              &.plus {
                .selected {
                  background: transparent linear-gradient(180deg, #56c58e 0%, #376a74 100%);
                }
              }
            }

            span {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              width: 22px;
              height: 24px;
              text-align: center;
              background-color: ${props => props.theme.color.background_01};
              font-size: 11px;
              font-weight: 500;
              color: ${props => props.theme.color.default_gray_font_color_white};
            }
          }
        }

        &.runes {
          justify-content: center;

          > div {
            width: 224px;
            height: 340px;
            margin: 5px 8px;
            padding: 7px;
            background-color: ${props => props.theme.color.default_input_background};
            border: ${props => {
              let border = '1px solid #cad7e2'
              if (props.theme.color.theme === 'dark') border = 0;
              return border;
            }};
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            text-align: center;
          }

          .runes_title {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 12px 0 24px;

            span {
              display: inline-block;
              margin-left: 15px;
              font-size: 12px;
            }
          }

          .runes {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-around;
            border-bottom: ${props => {
              let border = '1px solid #cad7e2'
              if (props.theme.color.theme === 'dark') border = '1px solid #A7A7AF';
              return border;
            }};

            &::before {
              content: '';
              position: absolute;
              bottom: -4px;
              left: 0;
              display: inline-block;
              width: 7px;
              height: 7px;
              border-radius: 50%;
              background-color: ${props => props.theme.color.background_CA_AF};
            }

            &::after {
              content: '';
              position: absolute;
              bottom: -4px;
              right: 0;
              display: inline-block;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background-color: ${props => props.theme.color.background_CA_AF};
            }

            &:first-of-type {
              &::before,
              &::after {
                display: none;
              }
            }

            &:last-of-type {
              border-bottom: 0;

              &::before,
              &::after {
                display: none;
              }
            }

            span {
              opacity: 0.3;
              mix-blend-mode: luminosity;

              &:not(.selected) {
                .tooltip {
                  display: none;
                }
              }

              &.selected {
                opacity: 1;
                mix-blend-mode: normal;
              }
            }
          }

          .runes_pri_seq {
            .runes {
              padding: 15px 0;

              &:first-of-type {
                padding: 12px 8px 8px;
                background-color: ${props => props.theme.color.background_DBD};
                border-radius: 5px;
                border-bottom: 0;

                .imgRune {
                  width: 42px;
                  border-radius: 50%;
                  background: rgba(0, 0, 0, 0.9);
                  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
                }
              }
            }
          }

          .runes_sec_seq {
            .runes {
              padding: 9px 0;

              &:first-of-type {
                display: none;
              }
            }

            .stats {
              .runes {
                padding: 8px 0;

                &:first-of-type {
                  display: flex;

                  &::before,
                  &::after {
                    display: inline-block;
                  }
                }

                img {
                  width: 20px;
                  border-radius: 50%;
                  background: rgba(0, 0, 0, 0.9);
                  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
                }
              }
            }
          }
        }

        .imgItem {
          width: 28px;
          border-radius: 5px;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
        }

        .imgSkill {
          width: 36px;
        }

        .imgRune {
          width: 32px;
        }
      }
    }

    &.aiscores {
      width: 810px;
      padding: 0;

      .title {
        display: flex;
        align-items: center;
        padding: 20px 40px;
        background-color: ${props => props.theme.color.default_input_background};
        border-bottom: 1px solid ${props => props.theme.color.border_5D};

        h4 {
          font-size: 20px;
          font-weight: 700;
        }

        p {
          position: relative;
          margin-left: 14px;
          padding-left: 11px;
          font-size: 12px;
          line-height: 1.4;

          &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            display: block;
            width: 1px;
            height: 25px;
            background-color: ${props => props.theme.color.default_font_color};
          }
        }
      }

      .aiscore {
        display: flex;
        padding: 15px 20px;
        background-color: ${props => props.theme.color.background_fff_1A1};
      }

      .box {
        position: relative;
        flex: 1;
        max-width: 360px;
        margin: 0 auto;
        background-color: ${props => props.theme.color.background_FB};
        border: 1px solid ${props => props.theme.color.champion.border};

        &.lane {
          margin-right: 45px;

          &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: -36px;
            transform: translateY(-50%);
            display: block;
            width: 26px;
            height: 24px;
            background: url('/images/icon_arrow_up_w.svg') no-repeat center;
            background-size: cover;
          }
        }

        h5 {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 34px;
          font-size: 14px;
          font-weight: 500;

          span {
            display: inline-block;
            margin-left: 2px;
            font-size: 12px;
            font-weight: 400;
            color: ${props => props.theme.color.font_rgba49};
          }
        }

        .ai_info {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 80px;
          border-top: 1px solid ${props => props.theme.color.champion.border};
          border-bottom: 1px solid ${props => props.theme.color.champion.border};

          &.win {
            background-color: ${props => props.theme.color.background_CD};
          }

          &.lose {
            background-color: ${props => props.theme.color.background_EBC};
          }

          .col01 {
            text-align: center;

            strong {
              font-size: 30px;
              font-weight: 700;
            }

            p {
              margin: 5px 0;
              font-size: 12px;
            }

            .tier_badge {
              width: 24px;
              font-size: 11px;
              font-weight: 400;
              height: 14px;
              border-radius: 5px;
            }
          }

          .col02 {
            margin-left: 15px;
            max-width: 90px;

            dl {
              display: flex;
              align-items: center;
              justify-content: center;

              & + p {
                width: 100%;
                margin-top: 4px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                word-wrap: break-word;
                line-height: 16px;
                font-size: 12px;

                &.away {
                  color: ${props => props.theme.color.font_C2};
                }

                &.my {
                  font-weight: 700;
                }
              }
            }

            dt {
              position: relative;
              margin: 0 1px;

              &.away {
                &::after {
                  content: '';
                  position: absolute;
                  top: 0;
                  left: 0;
                  display: block;
                  width: 100%;
                  height: 100%;
                  background: rgba(0, 0, 0, 0.4) url(/images/icon_exit_cross.svg) no-repeat center center;
                  z-index: 5;
                }
              }

              &.my {
                &::before {
                  content: '';
                  position: absolute;
                  top: -3px;
                  left: -3px;
                  display: block;
                  width: 100%;
                  height: 100%;
                  border: 3px solid #ffdd00;
                  z-index: 5;
                }
              }

              span {
                position: absolute;
                bottom: 0;
                right: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 15px;
                height: 15px;
                background-color: rgba(0, 0, 0, 0.7);
                border: 1px solid rgba(255, 236, 236, 0.7);
                font-size: 10px;
                font-weight: 400;
                color: ${props => props.theme.color.default_gray_font_color_white};
              }
            }

            dd {
              width: 20px;
              margin: 0 1px;

              div + div {
                margin-top: 2px;

                .imgRune {
                  padding: 2px;
                }
              }
            }
          }

          .col03 {
            margin-left: 15px;
            text-align: center;

            dt {
              display: flex;
              align-items: center;

              .kda_color {
                font-weight: 700;
              }

              p {
                margin-left: 6px;
                font-size: 11px;
                color: ${props => props.theme.color.font_rgba49};

                span {
                  font-size: 12px;
                  color: ${props => props.theme.color.default_gray_font_color};
                }
              }
            }

            dd {
              margin-top: 6px;
              font-size: 11px;
              color: ${props => props.theme.color.font_rgba49};

              span {
                font-size: 12px;
                color: ${props => props.theme.color.default_gray_font_color};
              }
            }
          }

          .col04 {
            margin-left: 10px;
            text-align: center;

            p {
              font-size: 11px;
              color: ${props => props.theme.color.font_rgba49};

              span {
                font-size: 12px;
                color: ${props => props.theme.color.default_gray_font_color};
              }
            }

            .vision {
              margin-top: 6px;
              display: flex;
              align-items: center;

              span {
                display: flex;
                align-items: center;
                font-size: 12px;
                color: ${props => props.theme.color.default_gray_font_color};
              }

              span + span {
                margin-left: 4px;
              }

              img {
                margin-right: 2px;
              }
            }
          }
        }

        .ai_detail {
          .row {
            display: flex;
            align-items: center;

            & > div {
              position: relative;
              text-align: center;
            }

            &_col01 {
              width: 90px;
            }

            &_col02 {
              width: 42px;
            }

            &_col03 {
              width: 173px;
            }

            &_col04 {
              width: 55px;
            }
          }

          .t_head {
            text-align: center;

            .row {
              height: 27px;
              border-bottom: 1px solid ${props => props.theme.color.champion.border};
              font-size: 12px;
              font-weight: 700;

              & > div {
                &::after {
                  content: '';
                  position: absolute;
                  top: 50%;
                  left: 0;
                  display: block;
                  width: 1px;
                  height: 8px;
                  margin-top: -4px;
                  background-color: ${props => props.theme.color.border_A5};
                }

                &:first-of-type {
                  &::after {
                    display: none;
                  }
                }
              }
            }
          }

          .t_body {
            padding: 4px 0;

            .row {
              padding: 2px 0;
              font-size: 13px;
              font-weight: 500;

              &.positive {
                color: #259a7e;
              }

              &.negative {
                color: ${props => props.theme.color.font_C2};
              }

              &.on {
                opacity: 1;
              }

              &.off {
                opacity: 0.5;
              }

              & > div {
                display: flex;
                align-items: center;
                height: 30px;
                padding: 0 10px;
              }

              &_col01 {
                justify-content: flex-start;
              }

              &_col02 {
                justify-content: center;
              }

              &_col03 {
                justify-content: flex-start;
              }

              &_col04 {
                justify-content: flex-end;
                padding-right: 15px !important;
              }
            }

            .target {
              display: flex;
              align-items: center;

              .item {
                position: relative;
                width: 30px;

                span {
                  position: absolute;
                  bottom: 0;
                  right: 0;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 12px;
                  height: 12px;
                  background-color: rgba(0, 0, 0, 0.7);
                  border: 1px solid rgba(255, 236, 236, 0.7);
                  font-size: 10px;
                  font-weight: 300;
                  color: ${props => props.theme.color.default_gray_font_color_white};
                }
              }

              .item + .item {
                margin-inline: 2px;
              }

              .imgChamp {
                width: 36px;
                height: 36px;
              }

              .imgDiv {
                border-radius: 6px;
              }

              .imgIcons {
                width: 30px;
                height: 30px;
              }

              .execution {
                background-color: #35394e;
                border: 1px solid #000;
              }
            }
          }

          .t_foot {
            padding: 4px 0;
            border-top: 1px solid ${props => props.theme.color.border_A5};
            border-bottom: 1px solid ${props => props.theme.color.border_A5};

            dl {
              height: 20px;
              padding: 2px 0;
              display: flex;
              align-items: center;
              justify-content: flex-end;
            }

            dt {
              font-size: 12px;
            }

            dd {
              width: 66px;
              padding: 0 15px 0 10px;
              text-align: right;
              font-size: 14px;
              font-weight: 500;

              &.positive {
                color: #259a7e;
              }

              &.negative {
                color: ${props => props.theme.color.font_C2};
              }
            }
          }

          .t_result {
            background-color: ${props => props.theme.color.background_DF};

            dl {
              height: 35px;
              display: flex;
              align-items: center;
              justify-content: flex-end;
            }

            dt {
              font-size: 14px;
              font-weight: 700;
            }

            dd {
              width: 66px;
              padding: 0 12px 0 10px;
              text-align: right;
              font-size: 24px;
              font-weight: 700;

              &.positive {
                color: #259a7e;
              }

              &.negative {
                color: ${props => props.theme.color.font_C2};
              }
            }
          }

          hr {
            width: 100%;
            height: 1px;
            margin: 4px 0;
            background: ${props => props.theme.color.border_A5};
            border: 0;
          }
        }
      }

      .imgChamp {
        width: 48px;

        &.mini {
          border: 2px solid #000;
        }
      }

      .imgSpell {
        width: 20px;
        border-radius: 3px;
      }

      .imgRune {
        width: 20px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.8);
      }

      .away {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        height: 300px;

        p {
          font-size: 18px;
          font-weight: 500;
        }
      }
    }
  }

  @keyframes modal-show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const StorageContainer = styled.div`
  height: 100%;

  ul {
    background-color: ${props => props.theme.color.default_input_background};
    display: flex;
    flex-direction: column;
    padding-top: 12px;
    padding-bottom: 6px;

    li {
      display: flex;
      align-items: center;
      margin: 2px 6px;
      padding: 6px 6px 6px 9px;
      background-color: ${props => props.theme.color.default_input_background};
      border-radius: 6px;

      &:hover {
        background-color: rgba(80, 108, 184, 0.1);
      }
    }

    a {
      color: ${props => props.theme.color.default_gray_font_color};
      display: block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-wrap: break-word;
      font-size: 14px;
      line-height: 1.4;
    }
  }

  .no_list {
    text-align: center;
    line-height: 1.4;
  }

  .txt {
    display: flex;
    align-items: center;
    flex: 1 1;
    overflow: hidden;
    height: 100%;
    margin: 0 12px;
  }

  .remove {
    min-width: 18px;
    margin-left: 6px;
    aspect-ratio: 1;
    font-size: 0;
    text-indent: -9999px;
    background: url('/images/icon_input_del.svg') no-repeat center;
  }

  .bookmark {
    min-width: 18px;
    height: 20px;
    font-size: 0;
    text-indent: -9999px;

    &.on {
      background: url('/images/ico_star_on.svg') no-repeat center;
    }

    &.off {
      background: url('/images/ico_star_off.svg') no-repeat center;
    }
  }
`;

export const NotFoundContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: transparent radial-gradient(closest-side at 50% 50%, #ffffff 0%, #ffffff 51%, #e8eaee 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1;

  h1 {
    font-size: 240px;
    font-weight: 500;
    color: rgba(33, 17, 117, 0.2);
    background: url('/images/space_man.svg') no-repeat center bottom 15px;
  }

  p {
    margin-top: 40px;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;

    &:last-of-type {
      margin-top: 20px;
      font-weight: 500;
    }
  }
`;

const forLoop = (num, time, inout) => {
    let styles = '';

    for (let i = 1; i <= num; i += 1) {
        styles += `
      .arrow.${inout}-${i} {
        animation-delay: calc(-${time} / ${num} * ${i}s);
      }
    `;
    }

    return css`
      ${styles}
    `;
};

export const LoadingContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: inherit;

  .dank-ass-loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -28px;

    .loader-row {
      display: flex;
    }
  }

  .arrow {
    width: 0;
    height: 0;
    margin: 0 calc(-${(props) => props.size} / 2);
    border-left: ${(props) => props.size} solid transparent;
    border-right: ${(props) => props.size} solid transparent;
    border-bottom: calc(${(props) => props.size} * 1.8) solid ${(props) => props.color};
    animation: blink ${(props) => props.time}s infinite;
      /* filter: drop-shadow(0 0 calc(${(props) => props.size} * 1.5) ${(props) => props.color}); */

    &.down {
      transform: rotate(180deg);
    }
  }

  ${forLoop(18, 1, 'outer')};

  ${forLoop(6, 1, 'inner')};

  @keyframes blink {
    0% {
      opacity: 0.1;
    }
    30% {
      opacity: 1;
    }
    100% {
      opacity: 0.1;
    }
  }
`;

export const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
  width: fit-content;
  height: fit-content;

  &:hover > .tooltip,
  &:active > .tooltip {
    display: block;
  }

  .tooltip {
    display: none;
    position: absolute;
    bottom: 100%;
    left: 50%;
    z-index: 9999;
    min-width: 90px;
    margin-bottom: 8px;
    padding: 8px 10px;
    transform: translateX(-50%);
    animation: tooltip-show 0.5s;
    font-size: 11px;
    color: ${props => props.theme.color.default_input_background};
    line-height: 1.4;
    background: rgba(10, 20, 30, 0.98);
    border-radius: 3px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);

    h5 {
      font-size: 12px;
      font-weight: 500;
      color: #17c791;
      text-align: center;
    }

    p {
      width: 200px;
      margin-top: 5px;
      text-align: left;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: -4px;
      left: 50%;
      display: block;
      width: 6px;
      height: 4px;
      margin-left: -3px;
      background: url('/images/ico_dropbox_arrow_down_w.svg') no-repeat center;
    }
  }

  @keyframes tooltip-show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const NoticeContainer = styled.div`
  position: ${(props) => (props.fixed ? 'absolute' : 'relative')};
  top: ${(props) => (props.fixed ? '48px' : '0px')};
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 42px;
  background: rgba(0, 183, 170, 0.95);

  .link {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

import React, {useCallback, useLayoutEffect, useMemo} from 'react';
import styled, {useTheme} from "styled-components";
import ChampionsWhiteWrapper from "../ChampionsWhiteWrapper";
import ChampionsRankLaneSelect from "./ChampionsRankLaneSelect";
import {useDispatch, useSelector} from "react-redux";
import ChampionsRankingTable from "./ChampionsRankingTable";
import {useTranslation} from "react-i18next";
import ChampionsHeader from "../ChampionsHeader";
import ChampionFilter from "../ChampionFilter";
import {PREV_RANKING_LANE} from "../../../../store/reducer/champions";
import {useHistory, useLocation} from "react-router-dom";
import useInput from "../../../../common/hooks/useInput";
import useQuery from "../../../../common/hooks/useQuery";
import useScrollPagination from "../../../../common/hooks/useScrollPagination";
import {getReplaceTier} from "../../../../function";


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  margin-left: 8px;
`;

const LaneWrapper = styled.div`
  margin-inline: 10px;
`;


const ChampionsRank = () => {
    const {t} = useTranslation();
    const championRanks = useSelector((state) => state?.champions?.ranks);
    const rankingLane = useSelector((state) => state?.champions?.prevRankingLane);
    const dispatch = useDispatch();
    const history = useHistory();
    useLocation();
    const query = useQuery();
    const versions = useSelector((state) => state.champions.championVersions);
    const [currentVersion, onChangeCurrentVersion] = useInput("");
    const [currentTier, onChangeCurrentTier] = useInput("");
    const [pageValue, initPage] = useScrollPagination();
    const theme = useTheme();


    useLayoutEffect(() => {
        if (versions) {
            onChangeCurrentVersion(encodeURIComponent(query.get('version')) !== 'null' && encodeURIComponent(query.get('version')) ? encodeURIComponent(query.get('version')) : versions[0]);
            onChangeCurrentTier(encodeURIComponent(query.get('tier')) !== 'null' && encodeURIComponent(query.get('tier')) ? decodeURIComponent(query.get('tier')).trim() + "+" : "Emerald+");
        }
    }, [versions, query.get("tier"), query.get("version")]);

    const getSelectedRank = useMemo(() => {
        try {
            return championRanks[rankingLane.toLowerCase()]
        } catch (e) {
            return [];
        }
    }, [rankingLane, championRanks])

    const onClickLane = useCallback((index, lane) => {
        dispatch({
            type: PREV_RANKING_LANE,
            data: lane,
        })
        initPage(1);
    }, []);

    const onSelectVersion = useCallback((version) => {
        let replaceTier = getReplaceTier(version,currentTier)
        history.replace(`/champions?version=${version}&tier=${replaceTier}`);
        onChangeCurrentVersion(version);

    }, [currentTier]);

    const onSelectTier = useCallback((tier) => {
        history.replace(`/champions?version=${currentVersion}&tier=${tier}`);
        onChangeCurrentTier(tier)
    }, [currentVersion]);


    return (
        <Wrapper>
            <ChampionsHeader
                title={t('championAnalyze.championRanking')}
            >
                <ChampionFilter
                    borderColor={theme.color.default_border_color}
                    currentVersion={currentVersion}
                    selectVersion={onSelectVersion}
                    currentTier={currentTier}
                    selectTier={onSelectTier}
                />
            </ChampionsHeader>
            <ChampionsWhiteWrapper>
                <LaneWrapper>
                    <ChampionsRankLaneSelect
                        lane={rankingLane}
                        onClick={onClickLane}
                    />
                </LaneWrapper>
                <ChampionsRankingTable
                    pageValue={pageValue}
                    lane={rankingLane}
                    ranks={getSelectedRank}
                />
            </ChampionsWhiteWrapper>
        </Wrapper>
    );
};

export default ChampionsRank;

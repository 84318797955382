import styled from "styled-components";
import regionList from "../../../store/static_json/regionList";
import React from "react";

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  background-color: ${props => props.theme.color.background_fff_1A};
  border-radius: 4px;
  width: 80px;

  select {
    color: ${props => props.theme.color.default_gray_font_color};
    border: 1px solid ${props => props.theme.color.default_bright_border_color};
    border-radius: 4px;
    padding: 0 8px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 80px;
    height: 28px;
    background: ${props => `url("${props.theme.icon.path}/icon_select_arrow.svg")`} no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
  }

  option {
    background: ${props => props.theme.color.background_fff_1A};
    color: ${props => props.theme.color.default_gray_font_color};
  }
`;

const SummonerSearchTableSelect = ({onClickRegion, rankingRegion}) => {
    return (
        <SelectWrapper>
            <select onChange={onClickRegion}>
                {regionList.map((data) => {
                    if (!data.disabled) {
                        return <option value={data.title}
                                       selected={rankingRegion.serverName === data.serverName}
                                       key={data.title}>{data.title}</option>
                    } else {
                        return null;
                    }

                })}
            </select>
        </SelectWrapper>
    )
}

export default SummonerSearchTableSelect;
import React from 'react';
import RenderChart from "./MatchList/RenderChart";
import {FlexBox} from "../ui/styled";
import ItemWithMythic from "./ItemWIthMythic";
import {isAram, isSwarm} from "../../function";

const MatchRenderChart = ({myMatch, match, onClickGraph, aiSorted, onClickToBuild}) => {
        const queueId = match.match_basic_dict.queue_id;


        if (isSwarm(queueId)) {
            return <RenderChart myMatch={myMatch} match={match}
                                queue={match.match_basic_dict.queue_id}
                                onClickGraph={onClickGraph} aiSorted={aiSorted}/>
        }


        if (isAram(queueId)) {
            return (
                <RenderChart myMatch={myMatch} match={match}
                             queue={match.match_basic_dict.queue_id}
                             onClickGraph={onClickGraph} aiSorted={aiSorted}/>
            )
        }

        return (
            <FlexBox onClick={onClickToBuild}>
                <ItemWithMythic item={myMatch.final_item_dict} width={20}
                                array={[myMatch.final_item_dict.item_0, myMatch.final_item_dict.item_1, myMatch.final_item_dict.item_2, myMatch.final_item_dict.item_3, myMatch.final_item_dict.item_4, myMatch.final_item_dict.item_5,]}
                                lens={myMatch.final_item_dict.item_6}/>
            </FlexBox>
        );
    }
;

export default MatchRenderChart;
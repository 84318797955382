import React, {useMemo} from 'react';
import ChampLane from "../../../summoner/Champ/ChampLane";
import {FlexBox} from "../../../ui/styled";
import styled from "styled-components";
import {hundredCheck} from "../../../../function";
import {useTranslation} from "react-i18next";


const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid ${props => props.theme.color.default_bright_border_color};
  border-radius: 4px;
  padding: 0 8px;
  background-color: ${props => props.theme.color.background_fff_1A};

  & + & {
    margin-left: 5px;
  }

  .laneTag {
    min-width: auto;
    padding: 3px 4px;
    margin: 2px 2px;
  }

  .laneTag:nth-child(2) {
    margin-left: 8px;
  }
`;


const BuildLaneFilter = ({selectedLane, pickList, setSelectedLane}) => {
    const {t} = useTranslation()
    const filterLane = useMemo(() => {
        try {
            return pickList.filter((data) => ['top', 'middle', 'bot', 'jungle', 'supporter'].includes(data.lane.toLowerCase()))
        } catch (e) {
            return [];
        }
    }, [pickList]);

    const aram = useMemo(() => {
        try {
            return pickList.filter((data) => data.lane.toLowerCase() === 'aram')
        } catch (e) {
            return [];
        }
    }, [pickList]);
    return (
        <FlexBox>

            {filterLane.length > 0 && <Wrapper>
                <ChampLane
                    selectedLane={selectedLane}
                    onChangePosition={(lane) => {
                        setSelectedLane(lane);
                    }}
                    customList={filterLane.map((data) => data.lane)}
                    mapValue={(lane) => {
                        const findLane = filterLane.find((data) => data.lane === lane);
                        return hundredCheck(findLane.pick_rate) || 0;
                    }}
                />

            </Wrapper>}
            <Wrapper>
                <ChampLane
                    noPercent={true}
                    selectedLane={selectedLane}
                    onChangePosition={(lane) => {
                        setSelectedLane(lane);
                    }}
                    customList={aram.map((data) => data.lane)}
                    mapValue={(data) => {
                        if (data === 'Aram') {
                            return t('tabs.aram')
                        } else {
                            return "U.R.F"
                        }

                    }}
                />
            </Wrapper>
        </FlexBox>
    );
};

export default BuildLaneFilter;
import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-block: 22px;
`;

const EmptyImage = styled.img`
`;

const EmptyText = styled.span`
  margin-top: 22px;
  font-size: 14px;
  color: ${props => props.theme.color.font_3C};
`;

const ChampionsEmpty = () => {
    const {t} = useTranslation();
    return (
        <Wrapper>
            <EmptyImage
                src={'/images/ico_multi_empty01.svg'}
                width={88}
                height={90}
            />
            <EmptyText>{t('championAnalyze.searchEmpty')}</EmptyText>
        </Wrapper>
    );
};

export default ChampionsEmpty;
import React from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${props => props.theme.color.default_input_background};
  width: 100%;
`;

const TableWrapper = styled.table`
  width: 100%;

  tr:hover {
    ${props => {
      if (props.hover) {
        return `
            
    outline: 1px solid ${props => props.theme.color.border_9B};
    outline-offset: -1px;
    background-color: ${props => props.theme.color.background_F0};
    box-shadow: 0px 2px 3px #00000029;
    z-index: 10;
            
            `
      }
    }}
  }
`;

const TableTr = styled.tr`
  border-bottom: 1px solid ${props => props.theme.color.default_bright_border_color};
  background-color: ${props => props.theme.color.background_F5};
  position: relative;
`;

const TableTD = styled.th`
  font-size: 12px;
  font-weight: 500;
  color: ${props => props.theme.color.default_gray_font_color};
  padding-left: 12px;
  text-align: ${props => {
    let align = 'left';
    if (props.align) align = props.align;
    return align;
  }};
`;

const CenterTD = styled.th`
  padding-block: 6px;
  font-size: 12px;
  opacity: 0.6;
  text-align: center;
`;


const WhiteTBody = styled.tbody`
  background-color: ${props => props.theme.color.default_input_background};

  tr {
    td:first-child {
      padding-left: 12px;
    }
  }
`;
const SummonerMasteryTable = ({
                                  title,
                                  list = [],
                                  headers,
                                  children,
                                  hover,
                                  selected = () => {
                                  },
                                  onClick = () => {},
                              }) => {

    return (<Wrapper className={'skillTable'}>
        <TableWrapper hover={hover}>
            <colgroup>
                <col />
                {headers.map((data) => <col key={data.title} width={data.col}/>)}
            </colgroup>
            <thead>
            <TableTr>
                {children && <TableTD>{title}</TableTD>}
                {headers.map((data) => {
                    return <CenterTD key={data.title}>{data.title}</CenterTD>
                })}
            </TableTr>
            </thead>
            <WhiteTBody>
                {(!Array.isArray(list) || list.length === 0) ? null : list.map((data, index) => {
                    return (
                        <tr key={index + title} className={selected(data, index) ? 'selected' : ""}
                            onClick={() => onClick(data, index)}>
                            {children(data, index)}
                        </tr>)
                })}
            </WhiteTBody>
        </TableWrapper>
    </Wrapper>);
};

export default SummonerMasteryTable;

import React, {useCallback} from 'react';
import styled from "styled-components";
import {AI_SCORE_BAD, AI_SCORE_GOOD, AI_SCORE_PERFECT, AI_SCORE_VERY_BAD} from "../../../../../../../styled/scoreColor";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ScoreTag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${props => props.paddingVertical}px ${props => props.paddingHorizontal}px;
  border-radius: 3px;
  background-color: ${props => {
    let color = AI_SCORE_VERY_BAD;
    if (!props.staticColor) {
      if (props.score >= 75) {
        color = AI_SCORE_PERFECT
      } else if (props.score >= 50) {
        color = AI_SCORE_GOOD;
      } else if (props.score >= 25) {
        color = AI_SCORE_BAD;
      }
    } else {
      color = props => props.staticColor;
    }
    return color;
  }};
`;

const ScoreText = styled.span`
  font-size: ${props => props.fontSize}px;
  text-align: center;
  min-width: 2ch;
  font-weight: 400;
  color: white;
`;

const ExtraScoreTag = styled.div`
  margin-left: 6px;
  display: flex;
  background-color: #EEF0F5;
  border-radius: 3px;
  padding: 2px 4px;
`;

const ExtraScoreSpan = styled.span`
  font-size: 10px;
  margin-left: 2px;
`;

const ArrowCss = styled.div`
  width: 0;
  height: 0;
  border-top: transparent 3px solid;
  border-left: transparent 3px solid;
  border-right: transparent 3px solid;
  border-bottom: 4px solid #E37E74;
`;

const ScoreWrapper = ({
                          score = 85,
                          extra = false,
                          paddingHorizontal = 12,
                          fontSize = 20,
                          extraValue,
                          staticColor,
                          onClick,
                          data,
                          paddingVertical = 4,
                          noneClickable = false,
                          testid,
                          hoverScale,
                      }) => {
    const onClickModal = useCallback(() => {
        if (onClick && data) {
            onClick(data);
        }
    }, [])


    return (
        <>
            <Wrapper>
                <ScoreTag paddingHorizontal={paddingHorizontal} paddingVertical={paddingVertical} score={score}
                          hoverScale={hoverScale}
                          staticColor={staticColor} onClick={noneClickable ? () => {
                } : onClickModal} noneClickable={noneClickable}>
                    <ScoreText fontSize={fontSize}
                               data-testid={testid}>{typeof score === 'number' ? score < -1 ? 0 : Math.round(Number(score)).toFixed(0)
                        :
                        score
                    }</ScoreText>
                </ScoreTag>
                {extra && <ExtraScoreTag>
                    <ArrowCss/>
                    <ExtraScoreSpan>{Math.trunc(Number(extraValue))}</ExtraScoreSpan>
                </ExtraScoreTag>}
            </Wrapper>
        </>
    );
};

export default React.memo(ScoreWrapper);

import React from 'react';
import styled from "styled-components";
import AramBalanceTableContentMemo from "../../../BalanceAramContent/ContentMemo";

const Wrapper = styled.tr`
  overflow: hidden;
  border-bottom: 1px solid ${props => props.theme.color.champion.border};
  td:not(:last-of-type) {
    cursor: pointer;
  }

`;

const Content = styled.td`
  vertical-align: middle;
`;


const TempWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;


const AramBalanceTableContent = ({
                                     version,
                                     champion_id,
                                     index,
                                     atkSpeedScale,
                                     correction,
                                     damageDealt,
                                     damageTaken,
                                     energyReg,
                                     haste,
                                     healing,
                                     pickRate,
                                     shieldMod,
                                     winRate,
                                     tenacity,
                                     maxDamageDealt,
                                     maxDamageTaken,
                                 }) => {

    return (
        <Wrapper>
            <Content>
                <TempWrapper>
                    {index + 1}
                </TempWrapper>
            </Content>
            <AramBalanceTableContentMemo
                maxDamageDealt={maxDamageDealt}
                maxDamageTaken={maxDamageTaken}
                champion_id={champion_id}
                version={version}
                energyReg={energyReg}
                winRate={winRate}
                status={correction}
                atkSpeedScale={atkSpeedScale}
                haste={haste}
                healing={healing}
                damageDealt={damageDealt}
                pickRate={pickRate}
                shieldMod={shieldMod}
                damageTaken={damageTaken}
                tenacity={tenacity}
            />
        </Wrapper>
    );
};


export default React.memo(AramBalanceTableContent);

import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import CenterContentBox from "./ContentWrapper";
import useDispatchChampRank from "../../common/hooks/useDispatchChampions";
import {GET_CHAMPIONS_RANK_REQUEST} from "../../store/reducer/champions";
import ChampionsSearch from "./ChampionsContent/ChampionsSearch";
import ChampionsRank from "./ChampionsContent/ChampionsRank";
import {useSelector} from "react-redux";
import NotFound from "../NotFound";
import {Header, Notice} from "../common";
import UseSetCanonical from "../../common/hooks/useSetCanonical";
import useTitleAndDescription from "../../common/hooks/useTitleAndDescription";
import useQuery from "../../common/hooks/useQuery";


const Wrapper = styled.div`
`;

const Champions = () => {
    UseSetCanonical();
    const valid = useDispatchChampRank(GET_CHAMPIONS_RANK_REQUEST);
    const {t} = useTranslation();
    const currentLang = useSelector((state) => state.translation.lang)
    const query = useQuery();
    const versions = useSelector((state) => state.champions.championVersions);

    const version = query.get("version")
    useTitleAndDescription({
        title: t('header.titleChampion'),
        description:t('header.titleChampionDescription',{
            version:version ? version : versions[0],
        })
    })



    if (!valid) {
        return (
            <NotFound/>
        )
    }

    return (
        <Wrapper>
            <Header/>
            <Notice fixed={false}/>
            <CenterContentBox>
                <ChampionsSearch key={currentLang + "championsSearch"}/>
                <ChampionsRank/>
            </CenterContentBox>
            {/*Rich*/}
        </Wrapper>
    );
};


export default Champions;

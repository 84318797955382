import React from 'react';
import styled from "styled-components";
import {ColumnBox} from "../../../components/ui/styled";
import {Footer, Header, Notice} from "../../../components/common";

const Wrapper = styled.div`
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 1253px;
  padding: 16px;
  background-color: ${props => props.theme.color.default_input_background};
  border-radius: 6px;
  z-index: 1;
  margin-top: 10vh;
`;

const Title = styled.span`
  font-size: 32px;
  font-weight: 700;
`;
const Info = styled.span`
  margin-left: 22px;
  white-space: pre-wrap;
  line-height: 45px;
  font-size: 16px;
  a {
    text-decoration: underline;
    color: #0000EE;
  }
`;

const RedSpan = styled.span`
  color: red;
  cursor: pointer;
`;
const BlackBar = styled.div`
  width: 100%;
  height: 3px;
  background-color: black;
  margin-top: 19px;
  margin-bottom: 11px;
`;
const Privacy = () => {
    const onClickRed = () => {
        try {
            window.ramp.showCmpModal();
        } catch (e) {
            console.log(e)
            console.log("showCmpModal click error");
        }
    }
    return (
        <Wrapper>
            <Header/>
            <Notice fixed={false}/>
            <ColumnBox alignItems={'center'}>
                <Content>
                    <Title>DeepLoL Privacy Policy</Title>
                    <BlackBar/>
                    <Info>
                        <p>
                            All or partial advertising on this Website or App is managed by Playwire LLC. If Playwire
                            publisher advertising services are used,{'\n'}
                            Playwire LLC may collect and use certain aggregated and anonymized data for advertising
                            purposes. To learn more about the types of{'\n'}
                            data collected, how data is used and your choices as a user,{'\n'}
                            please visit <a
                            href="https://www.playwire.com/privacypolicy">https://www.playwire.com/privacy-policy</a>.{'\n'}
                        </p>
                        <p><strong>For EU Users only:</strong> If you are located in countries that are part of the
                            European Economic Area, in the United Kingdom or Switzerland, and publisher{'\n'}
                            advertising services are being provided by Playwire LLC, you were presented with messaging
                            from our Consent Management Platform (CMP) around{'\n'}
                            your privacy choices as a user in regards to digital advertising, applicable vendors,
                            cookie usage and more. If you’d like to revisit the choices you have{'\n'}
                            made previously on this Website or App, please <RedSpan onClick={onClickRed}>click
                                here</RedSpan>.
                        </p>
                    </Info>
                    <BlackBar/>
                </Content>
            </ColumnBox>
            <Footer/>
        </Wrapper>
    );
};

export default Privacy;
import React, {useMemo} from 'react';
import styled from "styled-components";
import {useLocation} from "react-router-dom";
import useQuery from "../../../../../common/hooks/useQuery";

const FlexOne = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-weight: ${props => {
    let weight = '400';
    if (props.selected) weight = 'bold';
    return weight;
  }};
  background-color: ${props => {
    let color = props.theme.color.background_E1;
    if (props.selected) color = props.theme.color.background_fff_35
    return color;
  }};
  color: ${props => {
    let color = props.theme.color.font_rgba49;
    if (props.selected) color = props.theme.color.default_input_font_color;
    return color;
  }};
  font-size: 12px;
  cursor: pointer;

  &:not(:last-of-type) {
    border-right: 1px solid ${props => props.theme.color.default_bright_border_color};
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 8px 10px;
  border: 1px solid ${props => props.theme.color.default_bright_border_color};
  border-radius: 3px;
  overflow: hidden;
  height: 36px;
`;
const MasteryRegion = ({regionList, onClick}) => {
    useLocation();
    const query = useQuery();
    const currentRegion = query.get("region");
    const selectedRegion = useMemo(() => {
        try {
            if (!currentRegion) {
                return "All"
            }
            const validateLocation = regionList.find((data) => data.toLowerCase() === currentRegion.toLowerCase());
            if (!validateLocation) {
                return "All"
            }
            return validateLocation;
        } catch (e) {
            return 'All'
        }
    }, [currentRegion]);


    return (
        <Wrapper>
            {regionList.map((data) => {
                return (
                    <FlexOne key={data} selected={selectedRegion === data} onClick={() => onClick(data.toLowerCase())}>
                        {data}
                    </FlexOne>
                )
            })}
        </Wrapper>
    );
};

export default MasteryRegion;
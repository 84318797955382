import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {ColumnBox, FlexBox} from "../../../ui/styled";
import {hundredCheck, threedigits} from "../../../../function";
import useRuneHook from "../../../../common/hooks/useRuneHook";
import {ChampionRune} from "../../../common/ImageSrc";

const Table = styled.table`
  .imgRune {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: #313131;
  }
`
const HeaderTr = styled.tr`
  border-bottom: 1px solid ${props => props.theme.color.default_bright_border_color};
  background-color: ${props => props.theme.color.background_F5};
  position: relative;
`;

const TableTD = styled.td`
  font-size: 12px;
  font-weight: 500;
  color: ${props => props.theme.color.default_gray_font_color};
  padding-left: 12px;
`;

const CenterTD = styled.td`
  padding-block: 6px;
  font-size: 12px;
  opacity: 0.6;
  text-align: center;
`;

const MainRuneText = styled.span`
  text-align: center;
  width: 134px;
`;

const SubRuneText = styled.span`
  margin-left: 5px;
  width: 64px;
  text-align: center;
`;
const WhiteTBody = styled.tbody`
  background-color: ${props => props.theme.color.default_input_background};

  tr:first-child {
    padding-top: 12px;
  }

  tr {
    td:first-child {
      padding-left: 12px;
    }
  }
`;
const ContentTD = styled.td`
  vertical-align: middle;
  padding: 5px;
`;

const getRuneBorderColor = (runeId) => {
    switch (Number(runeId)) {
        case 8000:
            return '#FFCC54'
        case 8100:
            return '#E85C5C';
        case 8200:
            return '#8C85EB'
        case 8300:
            return '#6CBABC'
        case 8400:
            return "#6DD174"
    }
}
const RuneWrapper = styled.div`
  padding: 1px;
  margin-right: 4px;
  border: ${props => {
    let border = 0;
    if (props.border) border = `3px solid ${getRuneBorderColor(props.borderColor)}`;
    return border
  }};
  border-radius: 50%;
`;
const Bar = styled.div`
  height: 17px;
  border-right: 1px solid #7F7F7F;
  margin-right: 5px;
  margin-left: 1px;
`;

const ValueText = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: ${props => {
    let color = props.theme.color.font_E3;
    if (props.value > 50) {
      color = props.theme.color.font_61;
    }
    return color;
  }};
`;

const PurpleText = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: ${props => props.theme.color.font_58};
`;

const CenterDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const PercentText = styled.span`
  font-size: 10px;
  color: ${props => props.theme.color.default_gray_font_color};
  opacity: 0.6;
  margin-left: 2px;
`;

const ExtraText = styled.span`
  width: 100%;
  margin-top: 5px;
  font-size: 10px;
  color: #AAAAAA;
`;
const BuildRuneList = ({list}) => {
    const {findCategoryRune} = useRuneHook();
    const {t} = useTranslation();
    return (
        <Table>
            <colgroup>
                <col/>
                <col width={'55px'}/>
                <col width={'100px'}/>
            </colgroup>
            <HeaderTr>
                <TableTD>
                    <FlexBox>
                        <MainRuneText>{t('championDetailBuild.mainRune')}</MainRuneText>
                        <SubRuneText>{t('championDetailBuild.subRune')}</SubRuneText>
                    </FlexBox>
                </TableTD>
                <CenterTD>{t('championDetailBuild.pickRate')}</CenterTD>
                <CenterTD>{t('summoner.mastery.winRate')}</CenterTD>
            </HeaderTr>
            <WhiteTBody>
                {(!Array.isArray(list) || list.length === 0) ? null : list.map((data, index) => {
                    const winValue = (data.win_rate * 100)?.toFixed(1);
                    return (
                        <tr key={index}>
                            <ContentTD>
                                <FlexBox margin={index === 0 ? '4px 0 0 0' : 0}>
                                    <FlexBox width={'auto'}>
                                        {data.rune_main.map((mainRune, index) => {
                                            if (index === 0) return null;
                                            return (<RuneWrapper border={index === 1} borderColor={data.rune_main[0]}
                                                                 key={index + 'mainRune'}>
                                                <ChampionRune runeId={mainRune}/>
                                            </RuneWrapper>)
                                        })}
                                    </FlexBox>
                                    <Bar/>
                                    <FlexBox width={'auto'}>
                                        {data.rune_sub.map((mainRune, index) => {
                                            const isCategory = findCategoryRune(mainRune);
                                            if (isCategory) return null;
                                            return (<RuneWrapper key={index + 'mainRune'}>
                                                <ChampionRune runeId={mainRune}/>
                                            </RuneWrapper>)
                                        })}
                                    </FlexBox>
                                </FlexBox>
                            </ContentTD>
                            <ContentTD>
                                <CenterDiv>
                                    <ColumnBox alignItems={'center'}>
                                        <FlexBox>
                                            <PurpleText>{hundredCheck((data.pick_rate * 100)?.toFixed(1))}</PurpleText>
                                            <PercentText>
                                                %
                                            </PercentText>
                                        </FlexBox>
                                        <FlexBox>
                                            <ExtraText>{threedigits(data.games)}</ExtraText>
                                        </FlexBox>
                                    </ColumnBox>
                                </CenterDiv>
                            </ContentTD>
                            <ContentTD>
                                <CenterDiv>
                                    <ValueText value={winValue}>{hundredCheck(winValue)}</ValueText>
                                    <PercentText>
                                        %
                                    </PercentText>

                                </CenterDiv>
                            </ContentTD>
                        </tr>
                    )
                })}
            </WhiteTBody>
        </Table>
    );
};

export default BuildRuneList;
import React from 'react';
import styled from "styled-components";
import FilterArrow from "../../../common/FilterArrow";

const ArrowTitleWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
`;

const ChampArrow = ({title, arrow = false, extra,filterSelected}) => {

    return (
        <>
            <ArrowTitleWrapper>
                <span className="name">{title}
                    {extra &&
                        <i>
                            ({extra})
                        </i>
                    }
                </span>
                <FilterArrow arrow={arrow} selected={filterSelected}/>
            </ArrowTitleWrapper>
        </>
    );
};

export default ChampArrow;

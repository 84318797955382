import React, {useEffect, useMemo} from 'react';
import styled from "styled-components";
import ChampionHistoryContent from "./HistoryContent";
import {useDispatch, useSelector} from "react-redux";
import {GET_CHAMPIONS_PATCH_HISTORY_REQUEST} from "../../store/reducer/champions";
import {Loading} from "../common";
import InternalServerError from "../common/InternalError";
import {sortChampData} from "../../function";
import useChampionFunc from "../../common/hooks/useChampionFunc";
import UseSetCanonical from "../../common/hooks/useSetCanonical";
import useTitleAndDescription from "../../common/hooks/useTitleAndDescription";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 80vh;
`;

const ChampionsHistory = ({champion,skillList}) => {
    UseSetCanonical();
    const {t} = useTranslation()
    const dispatch = useDispatch();
    const {
        patchHistory,
        getChampionsPatchHistoryLoading,
        getChampionsPatchHistoryError,
    } = useSelector((state) => state.champions);
    const currentLang = useSelector((state) => state.translation.lang);
    const {getUrlNameById} = useChampionFunc();

    useEffect(() => {
        if (!getChampionsPatchHistoryLoading) {
            dispatch({
                type: GET_CHAMPIONS_PATCH_HISTORY_REQUEST,
                data: {
                    champion_id: champion.champion_id,
                    currentLang: currentLang,
                    championName : getUrlNameById(champion.champion_id)
                }
            })
        }
    }, [currentLang]);


    const mainPatchList = useMemo(() => {
        if (patchHistory.sr_patch_history) {
            if (patchHistory?.sr_patch_history?.champion_patch_history_list) {
                const result = [...patchHistory?.sr_patch_history?.champion_patch_history_list].reverse();
                const aramList = [...patchHistory?.aram_patch_history?.champion_patch_history_list].reverse();
                const filteredAramList = aramList.filter((data) => !result.find((item) => item.version === data.version));
                const addParam = filteredAramList.map((data) => {
                    return {...data, type: 'ARAM'}
                });
                const soredArray = sortChampData([...result, ...addParam], [{sortType: false, value: 'reg_date'}])
                return soredArray;
            }

        } else {
            return [];
        }
    }, [patchHistory]);

    const aramPatchList = useMemo(() => {
        if (patchHistory.aram_patch_history) {
            const result = patchHistory?.aram_patch_history?.champion_patch_history_list;
            return result;
        } else {
            return [];
        }
    }, [patchHistory])


    useTitleAndDescription({
      title: t('header.titleChampionHistory',{
          champion: champion?.champion_name,
      }),
        description: t('header.titleChampionHistoryDescription',{
            champion: champion?.champion_name,
            version: mainPatchList?.[0]?.version
        })
    });

    if (getChampionsPatchHistoryLoading) return <Loading/>
    if (getChampionsPatchHistoryError) {
        return (
            <InternalServerError/>
        )
    }
    return (
        <Wrapper>
            {mainPatchList.map((data) => {
                const aram = aramPatchList.find((item) => item.version === data.version && data.type !== 'ARAM');
                return (
                    <ChampionHistoryContent
                        skillList={skillList}
                        key={data.version}
                        champion={champion}
                        patchHistory={data}
                        aram={aram}
                    />
                )
            })}
        </Wrapper>
    );
};

export default ChampionsHistory;

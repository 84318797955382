import React from 'react';
import {useSelector} from "react-redux";

const RenderByCountry = ({playwire, notPlaywire}) => {
    const country = useSelector((state) => state?.imageStore.country);
    if (country === 'KR') {
        return notPlaywire
    } else {
        return playwire
    }

};

export default RenderByCountry;
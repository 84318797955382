import React, {useCallback} from 'react';
import {useSelector} from "react-redux";

const UseRuneHook = () => {
    const runes = useSelector((state) => state.imageStore.runes);

    const findCategoryRune = useCallback((findValue) => {
        return runes.find((data) => data.id === findValue);
    }, []);

    const findChildRune = useCallback((categoryRune = [], findValue) => {
        return categoryRune.find((data) => {
            if (data.id === findValue) {
                return true;
            } else if (Array.isArray(data.runes)) {
                return findCategoryRune(data.runes, findValue);
            }
        });
    }, [findCategoryRune]);


    const findParentRune = useCallback((categoryRune = [], findValue) => {
        try {
            if (typeof findValue === 'number') {
                return categoryRune.find((data) => {
                    if (data.id === findValue) {
                        return true;
                    } else {
                        return findChildRune(data.slots, findValue);
                    }
                });
            }
            return null;
        } catch (e) {
            return [];
        }

    }, [findChildRune]);

    return {
        findChildRune,
        findCategoryRune,
        findParentRune,
    }
};

export default UseRuneHook;
import React from 'react';
import styled from "styled-components";
import HistoryContentDayInfo from "./HistoryContentDayInfo";
import HistoryContentPatchInfo from "./HistoryContentPatchInfo";

const Wrapper = styled.div`
  margin: 6px 0;
  display: flex;
  flex-direction: row;
  border: 1px solid ${props => props.theme.color.champion.border};
  background: ${props => props.theme.color.default_background_color};
  padding: 12px 14px;
`;


const ChampionHistoryContent = ({patchHistory,champion,aram,skillList}) => {


    return (
        <Wrapper>
            <HistoryContentDayInfo
                correction={patchHistory.correction}
                version={patchHistory.version}
                date={patchHistory.reg_date}
                hotfix={patchHistory?.hot_fix}
            />
            <HistoryContentPatchInfo
                skillList={skillList}
                type={patchHistory.type}
                aram={aram?.skill_history_list}
                version={patchHistory.version}
                champion={champion}
                list={patchHistory.skill_history_list}
            />
        </Wrapper>
    );
};

export default ChampionHistoryContent;

import React from 'react';
import styled from "styled-components";
import SimpleTooltip from "../../../common/SimpleTooltip";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0 8px;
`;

const HeaderSpan = styled.span`
  text-align: center;
  font-size: 12px;
  margin-inline: 2px;
  color: ${props => props.theme.color.font_58};
`;

const ChildContent = styled.div`
  display: flex;
  align-items: ${props => {
      let items = 'flex-start';
      if(props.alignItems) items = props.alignItems;
      return items;
  }};
  justify-content: center;
  height: 100%;
`;

const HeaderDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const OverviewHeader = (props) => {
    const {title, explain,explainCondition,alignItems} = props;

    return (
        <Wrapper>
            <HeaderDiv>
                <HeaderSpan>
                    {title}
                </HeaderSpan>
                {explainCondition && explain &&
                        <SimpleTooltip
                            tooltipText={explain}
                            imgSrc={"/images/ico_warning.svg"}
                        />}
            </HeaderDiv>
            <ChildContent alignItems={alignItems}>
                {props.children}
            </ChildContent>
        </Wrapper>
    );
};

export default OverviewHeader;

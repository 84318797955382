import styled from "styled-components";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {FlexBox} from "../../ui/styled";
import DailyFilterButtonList from "../content/DailyFilterButtonList";
import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import regionList from "../../../store/static_json/regionList";

const Wrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${props => props.theme.color.border_C31};
  background-color: ${props => props.theme.color.default_input_background};
  padding: 10px;
  border-radius: 6px;
  row-gap: 10px;
`;

const FilterName = styled.span`
  font-size: 12px;
  color: ${props => props.theme.color.font_7F};
  min-width: 88px;
`;


const DailyAramFilter = () => {
    const {t} = useTranslation();
    const history = useHistory();
    const {platform, queue} = useParams();
    const {search} = useLocation();


    const date = useMemo(() => {
        const queryString = new URLSearchParams(search);
        if (queryString.get('date') === 'yesterday') {
            return 'yesterday'
        }
        return 'today'
    }, [search]);

    const translateQueue = (value) => {
        if (value === 'Ranked Solo') {
            return 'rank'
        }
        return value.toLowerCase()
    }
    const translateQueueValue = (value) => {
        if (value === 'rank') {
            return 'Ranked Solo'
        }
        return value.toLowerCase()
    }

    const onClickQueue = (value) => {
        if (typeof value === 'string') {
            const translateValue = translateQueue(value);
            if (translateValue === 'aram') {
                history.push(`/daily/ai/${platform}/aram${search}`)
            } else {
                history.push(`/daily/ai/${platform}/${translateValue}/all${search}`)
            }
        }
    }
    const onClickPlatform = (value) => {
        if (typeof value === 'string') {
            history.push(`/daily/ai/${value.toLowerCase()}/aram${search}`)
        }
    }
    const onClickDate = (value) => {
        if (typeof value === 'string') {
            history.push(`/daily/ai/${platform}/aram?date=${value}`)
        }
    }


    const regionMap = useMemo(() => {
        const filterList = regionList.filter((data) => data.daily);
        return filterList.map((data) => {
            return {
                title: data.title,
                value: data.title
            }
        })
    }, []);

    return (
        <Wrapper>
            <FlexBox>
                <FilterName>{t('daily.date')}</FilterName>
                <DailyFilterButtonList
                    minWidth={100}
                    selected={date}
                    list={[
                        {title: t('daily.today'), value: 'today'},
                        {title: t('daily.yesterday'), value: 'yesterday'},

                    ]}
                    onClick={onClickDate}
                />
            </FlexBox>
            <FlexBox>
                <FilterName>{t('proStreamer.region')}</FilterName>
                <DailyFilterButtonList
                    minWidth={70}
                    selected={platform}
                    list={regionMap}
                    onClick={onClickPlatform}
                />
            </FlexBox>
            <FlexBox>
                <FilterName>{t('daily.queue')}</FilterName>
                <DailyFilterButtonList
                    minWidth={100}
                    selected={queue}
                    list={[{title: t('tabs.rankedSolo'), value: 'rank'}, {
                        title: t('summoner.aram'),
                        value: "aram"
                    }]}
                    onClick={onClickQueue}
                />
            </FlexBox>
        </Wrapper>
    );
}
export default DailyAramFilter
import React from 'react';
import styled from "styled-components";
import {ColumnBox, FlexBox} from "../../../ui/styled";
import {tierText} from "../../../../function";
import {useTranslation} from "react-i18next";
import SummonerMatchesProvider from "../index";
import {useSelector} from "react-redux";
import SummonerMatchesProgress from "../SummonerMatchesProgress";

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid ${props => props.theme.color.default_border_color};
  background-color: ${props => props.theme.color.summoner.tab.background_color};
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;

  ${props => {
    if (props.unranked) return `
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
      `;
  }}
`;


const Wrapper = styled.div`

  width: 304px;
`;

const RankTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
`;

const LPText = styled.span`
  font-size: 12px;
  color: ${props => props.theme.color.font_65};;
  font-weight: 400;
`;

const WinLoseText = styled.span`
  font-size: 12px;
  font-style: normal;
  color: ${props => props.theme.color.font_65};;
  font-weight: 400;
  line-height: 12px; /* 92.308% */
`;

const RankNumberText = styled.span`
  font-size: 14px;
  line-height: 12px;
  margin-left: 27px;
  color: ${props => props.theme.color.font_58};
`;


const RankNumberTextOpacity = styled.span`
  font-size: 14px;
  line-height: 12px;
`;

const TierTitleText = styled.span`
  font-size: 13px;
  color: ${props => props.theme.color.default_gray_font_color};
`;

const ContentWrapper = styled(ColumnBox)`
  background-color: ${props => props.theme.color.default_input_background};
  border-left: 1px solid ${props => props.theme.color.default_border_color};
  border-right: 1px solid ${props => props.theme.color.default_border_color};
  border-bottom: 1px solid ${props => props.theme.color.default_border_color};
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
`;

const ImgBox = styled(FlexBox)`
  cursor: pointer;
`;

const UnrankText = styled.span`
  font-size: 14px;
  color: ${props => props.theme.color.font_7F};
`;
const SummonerMatchesRank = ({tier, division, rank, title, topPercent, lp, chartKey, win, lose, tierCut}) => {
    const {t} = useTranslation();
    const summoner = useSelector((state) => state.summoner);
    const currentTierCut = summoner.tierCut

    if (tier.toUpperCase() === 'UNRANKED' || !tier) {
        return (
            <Wrapper>
                <TitleWrapper unranked={true}>
                    <SummonerMatchesRankTitle title={title} rank={rank} topPercent={topPercent}/>
                    <UnrankText>Unranked</UnrankText>
                </TitleWrapper>
            </Wrapper>
        )
    }

    return (
        <Wrapper>
            <TitleWrapper>
                <SummonerMatchesRankTitle title={title} rank={rank} topPercent={topPercent}/>
            </TitleWrapper>
            <ContentWrapper padding={'0 14px'} alignItems={'center'} height={'100%'} width={'100%'}>
                <FlexBox width={'100%'} margin={'4px 0'}>
                    <SummonerMatchesEmblem tier={tier}/>
                    <ColumnBox justifyContent={'center'} width={'100%'} margin={'0 0 0 8px'}>
                        <FlexBox justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
                            <RankTitle>{tierText(tier.toUpperCase(), division)}</RankTitle>
                            {tier &&
                                <WinLoseText>{win}{t('summoner.contractionWin')} {lose}{t('summoner.contractionLose')}</WinLoseText>}
                        </FlexBox>
                        {tier && <FlexBox justifyContent={'space-between'} width={'100%'} alignItems={'center'}
                                          margin={'12px 0 0 0'}>
                            {lp > 0 ? <LPText>{lp} LP</LPText> : <LPText>0 LP</LPText>}
                            {tier &&
                                <WinLoseText>{t('summoner.winrate')} {((win / (win + lose)) * 100).toFixed(0)}%</WinLoseText>}
                        </FlexBox>}
                    </ColumnBox>
                </FlexBox>
                {tierCut && <SummonerMatchesProgress tier={tier} division={division} currentLp={lp} tierCut={currentTierCut}/>}
                {(tier && chartKey) && <SummonerMatchesProvider.LPChart chartKey={chartKey}/>}
            </ContentWrapper>
        </Wrapper>
    );
};


const SummonerMatchesRankTitle = ({title, rank, topPercent}) => {
    const {t} = useTranslation();
    return (

        <FlexBox>
            <TierTitleText>{title}</TierTitleText>
            {rank > 0 &&
                <RankNumberText>{rank.toLocaleString('ko-KR')}<RankNumberTextOpacity>{t("summoner.graph_grade", {
                    count: rank,
                    ordinal: true
                })} ({topPercent}%)</RankNumberTextOpacity></RankNumberText>
            }
        </FlexBox>
    )
}


const SummonerMatchesEmblem = ({tier}) => {
    if (!tier) {
        return <img src={`/images/Emblem_UNRANKED.png`} className="imgEmblem" alt={'UNRANKED'} width={'62px'}
                    height={'62px'}/>
    }
    return (
        <img src={`/images/Emblem_${tier.toUpperCase()}.png`} className="imgEmblem" alt={tier} width={62} height={62}/>
    )
}

export default SummonerMatchesRank

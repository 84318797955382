import React, {useCallback} from 'react';
import styled, {useTheme} from "styled-components";
import {useSelector} from "react-redux";
import {ColumnBox, FlexBox} from "../../ui/styled";
import {ImageItem, ImageSpell} from "../../common";
import CombinedRuneImages from "../../common/CombinedRuneImages";
import {sortChampData} from "../../../function";
import {useTranslation} from "react-i18next";
import ChampionImageWithLane from "../../common/ChampionImageWithLane";


const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 24px 12px;
  border-right: 1px solid rgba(187, 207, 233, 0.3);
  width: 240px;

  .imgDiv {
    border-radius: 50%;
  }

  .imgChamp {
    width: 52px;
    height: 52px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-width: ${props => props.width}px;
  border-right: 1px solid rgba(187, 207, 233, 0.3);
  padding: 12px 10px 0 10px;

  .imgSpell {
    width: 28px;
    border-radius: 5px;
  }

  .imgItem {
    width: 28px;
    border-radius: 5px;
  }
`;


const Text = styled.span`
  font-size: ${props => {
    let size = '12';
    if (props.fontSize) size = props.fontSize;
    return size;
  }}px;
  color: ${props => {
    let color =props.theme.color.default_gray_font_color;
    if (props.color === 'purple') {
      color = '#585858';
    } else if (props.color) {
      color = props.color;
    }
    return color;
  }};
  font-weight: ${props => {
    let weight = '400';
    if (props.fontWeight) weight = props.fontWeight;
    return weight;
  }};
  opacity: ${props => {
    let opacity = '1';
    if (props.opacity) opacity = props.opacity;
    return opacity
  }};
  margin: ${props => props.margin || 0};
`;

const ImageBox = styled.div`
  margin-right: 4px;
`;

const ExtraWrapper = styled.div`
  height: 22px;
  margin-bottom: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: ${props => props.justifyContent || 'flex-start'};
`;

const Wrapper = styled.div`
  width: 100%;
  border: 1px solid ${props => props.theme.color.default_bright_border_color};
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  background-color: ${props => props.theme.color.background_fff_1A};

  ${ContentWrapper}:last-of-type {
    border: 0;
  }
`;

function BasicContent({title, children, row, width}) {
    const theme = useTheme();
    return <ContentWrapper width={width}>
        <Text color={theme.color.font_67} fontSize={12}>{title}</Text>
        {row ? <FlexBox height={'auto'}> {children} </FlexBox> :
            <ColumnBox height={"auto"}>
                {children}
            </ColumnBox>}
    </ContentWrapper>;
}


const SummonerText = styled(Text)`
  width: 17ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 3px;
  padding-bottom: 2px;
`;

const SummonerMasteryOverview = ({lane, patchId, overview}) => {
    const {t} = useTranslation();
    const summoner = useSelector((state) => state.summoner.summoner);
    const theme = useTheme();


    const pickRateSort = useCallback((list, sliceCount) => {
        return sortChampData(list, [{value: 'pick_rate', sortType: false}]).slice(0, sliceCount)
    }, []);

    const champion_id = overview.champion_id;
    const riot_id_name = summoner?.summoner_basic_info_dict?.riot_id_name;


    if (overview.win_rate === 'NaN') return null;
    return (
        <Wrapper>
            <ProfileWrapper>
                <ChampionImageWithLane champion_id={champion_id}/>
                <ColumnBox justifyContent={'space-between'} margin={'0 0 0 15px'}>
                    <SummonerText fontSize={16} fontWeight={500}>{riot_id_name}</SummonerText>
                    <FlexBox height={'auto'}>
                        <ImageBox>
                            <img
                                alt={""}
                                width={16} height={16}
                                src={`${theme.icon.path}/icon_s_position_${lane.toLowerCase()}.svg`}/>
                        </ImageBox>
                        <Text fontSize={12}
                              opacity={'0.6'}>{lane} / {patchId === 'All' ? t('summoner.mastery.allPatches') : `Patch ${patchId}`}</Text>
                    </FlexBox>
                </ColumnBox>
            </ProfileWrapper>
            <BasicContent title={t('summoner.mastery.games')} width={86}>
                <Text fontSize={18} color={theme.color.font_58}>{overview?.games}<Text color={theme.color.default_gray_font_color} opacity={'0.6'}
                                                                             fontSize={12}> {t('summoner.mastery.game')}</Text></Text>
                <ExtraWrapper/>
            </BasicContent>
            <BasicContent title={t('summoner.mastery.winRate')} width={86}>
                <Text fontSize={18} color={theme.color.font_58}>{overview?.win_rate}<Text color={theme.color.default_gray_font_color} opacity={'0.6'}
                                                                                fontSize={12}>%</Text></Text>
                <ExtraWrapper>
                    <Text fontSize={11}>{overview?.wins} <Text fontSize={11}
                                                               opacity={'0.6'}>{t('summoner.mastery.win')}</Text> {overview?.lose}
                        <Text fontSize={11}
                              opacity={'0.6'}>{t('summoner.mastery.lose')}</Text></Text>
                </ExtraWrapper>
            </BasicContent>
            <BasicContent title={t('summoner.mastery.rune')} row={true} width={91}>
                {pickRateSort(overview.rune, 2).map((data, index) => {
                    const margin = index === 1 ? 0 : '0 12px 0 0'
                    return (
                        <ColumnBox height={'auto'} margin={margin} key={index}>
                            <CombinedRuneImages
                                primary={data.rune_main}
                                sub={data.rune_sub}
                                primaryAttr={{width: 32, height: 32}}
                                subAttr={{width: 14, height: 14}}
                            />
                            <ExtraWrapper justifyContent={'center'}>
                                <Text fontSize={11}>{(data.pick_rate * 100).toFixed(0)}<Text fontSize={11}
                                                                                             margin={'0 0 0 2px'}
                                                                                             opacity={'0.6'}>%</Text></Text>
                            </ExtraWrapper>
                        </ColumnBox>
                    )
                })}
            </BasicContent>
            <BasicContent title={t('summoner.mastery.spell')} row={true} width={110}>
                {pickRateSort(overview.spell, 3).map((data, index) => {
                    const margin = index === 2 ? '0' : '0 5px 0 0';
                    return (<ColumnBox height={'auto'} margin={margin} key={index}>
                        <ImageSpell
                            smite={true}
                            spellId={data.spell}
                        />
                        <ExtraWrapper justifyContent={'center'}>
                            <Text fontSize={11}>{(data.pick_rate * 100).toFixed(0)}<Text fontSize={11}
                                                                                         margin={'0 0 0 2px'}
                                                                                         opacity={'0.6'}>%</Text></Text>
                        </ExtraWrapper>
                    </ColumnBox>)
                })}
            </BasicContent>
            <BasicContent title={t('summoner.mastery.firstCore')} row={true} width={82}>
                {pickRateSort(overview.mythic, 2).map((data, index) => {
                    const margin = index === 1 ? 0 : '0 5px 0 0';
                    return (
                        <ColumnBox height={'auto'} margin={margin} key={index}>
                            <ImageItem
                                itemId={data.item}
                                smite={true}
                            />
                            <ExtraWrapper justifyContent={'center'}>
                                <Text fontSize={11}>{(data.pick_rate * 100).toFixed(0)}<Text fontSize={11}
                                                                                             margin={'0 0 0 2px'}
                                                                                             opacity={'0.6'}>%</Text></Text>
                            </ExtraWrapper>
                        </ColumnBox>
                    )
                })}
            </BasicContent>
            <BasicContent title={t('summoner.mastery.hot')} row={true} width={209}>
                {pickRateSort(overview.core, 6).map((data, index) => {
                    const margin = index === 5 ? '0' : '0 1px 0 0';
                    return (
                        <ColumnBox height={'auto'} margin={margin} key={index}>
                            <ImageItem
                                itemId={data.item}
                            />
                            <ExtraWrapper justifyContent={'center'}>
                                <Text fontSize={11}>{(data.pick_rate * 100).toFixed(0)}<Text fontSize={11}
                                                                                             margin={'0 0 0 2px'}
                                                                                             opacity={'0.6'}>%</Text></Text>
                            </ExtraWrapper>
                        </ColumnBox>
                    )
                })}
            </BasicContent>
            <BasicContent title={t('summoner.mastery.popularBoots')} row={true} width={108}>
                {pickRateSort(overview.shoes, 3).map((data, index) => {
                    const margin = index === 2 ? '0' : '0 5px 0 0';
                    return (
                        <ColumnBox height={'auto'} margin={margin} key={index}>
                            <ImageItem
                                itemId={data.boots}
                            />
                            <ExtraWrapper justifyContent={'center'}>
                                <Text fontSize={11}>{(data.pick_rate * 100).toFixed(0)}<Text fontSize={11}
                                                                                             margin={'0 0 0 2px'}
                                                                                             opacity={'0.6'}>%</Text></Text>
                            </ExtraWrapper>
                        </ColumnBox>
                    )
                })}

            </BasicContent>
        </Wrapper>
    );
};

export default SummonerMasteryOverview;
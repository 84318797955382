import React, {useCallback, useMemo} from 'react';
import styled, {useTheme} from "styled-components";
import ChampionFilter from "../../../../Champions/ChampionsContent/ChampionFilter";
import {useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {getReplaceTier} from "../../../../../function";
import BuildLaneFilter from "../../../../compound/ChampionsDetailBuildProvider/BuildLaneFilter";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
`;

const LaneAndCategory = styled.div`
`;

const PatchHistoryWrapper = styled.div`
`;

const BuildCategory = ({
                           championName,
                           selectedLane,
                           lanePick,
                           champion_id,
                           filterCurrentVersion,
                           changeVersion,
                           currentTier,
                           onChangeTier
                       }) => {
    const history = useHistory();
    const location = useLocation();
    const {path} = useRouteMatch();
    const theme = useTheme();

    const onSelectVersion = useCallback((version) => {
        if (version) {
            let replaceTier = getReplaceTier(version, currentTier)
            history.replace(`${location.pathname}?tier=${replaceTier}&version=${version}`);
            changeVersion(version);
            onChangeTier(replaceTier)
        }
    }, [champion_id, currentTier, location.pathname]);

    const onSelectTier = useCallback((tier) => {
        if (tier) {
            history.replace(`${location.pathname}?tier=${tier}&version=${filterCurrentVersion}`);
            onChangeTier(tier);
        }
    }, [champion_id, filterCurrentVersion, location.pathname]);


    const onClickLane = useCallback((value) => {
        if (value !== undefined) {
            history.replace(`/champions/${championName}/build/${value.toLowerCase()}`);
        }
    }, [championName, location.pathname]);

    const disableTier = useMemo(() => {
       if(selectedLane === 'aram') {
           return true
       }
       if(selectedLane === 'urf') {
           return true
       }
       return false;
    },[selectedLane]);

    return (
        <Wrapper>
            <LaneAndCategory>
                {lanePick &&
                    <BuildLaneFilter
                        selectedLane={selectedLane}
                        setSelectedLane={onClickLane}
                        pickList={lanePick}/>}
                {/*<BuildCategoryList*/}
                {/*    build={buildInfo?.build || []}*/}
                {/*    selected={selected}*/}
                {/*    urls={urls}*/}
                {/*/>*/}
            </LaneAndCategory>
            <PatchHistoryWrapper>
                <ChampionFilter
                    disableTier={disableTier}
                    currentTier={currentTier}
                    selectTier={onSelectTier}
                    currentVersion={filterCurrentVersion}
                    selectVersion={onSelectVersion}
                    borderColor={theme.color.default_border_color}
                />
            </PatchHistoryWrapper>
        </Wrapper>
    );
};

export default BuildCategory;

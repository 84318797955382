import React, {useCallback} from 'react';
import styled, {useTheme} from "styled-components";
import KDAText from "../../../../common/KDAText";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 10px;
`;

const KDAWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 8px;
`;


const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ContentSpan = styled.span`
  font-size: 12px;
  margin-inline: 3px;
  color: ${props => {
    let color = props => props.theme.color.default_gray_font_color;
    if (props.color) color = props.color;
    return color;
  }};
`;

const SplashSpan = styled.span`
  font-size: 12px;
  transform: scale(.751);
  color: ${props => props.theme.color.font_31_A7};
`;


const OverviewKDA = ({data}) => {
    const theme = useTheme()
    const calValue = useCallback((value, deaths) => {
        return (value / deaths).toFixed(2);
    }, [])

    const calKDA = useCallback(() => {
        if (data.kills === 0 && data.assists === 0) {
            return "0.00";
        } else if (data.deaths === 0) {
            return calValue(data.kills + data.assists, 1);
        } else {
            return calValue(data.kills + data.assists, data.deaths);
        }
    }, [data]);

    if (typeof data !== 'object') return null;

    return (
        <Wrapper>
            <KDAWrapper>
                <KDAText fontSize={'18px'} fontWeight={'500'} lineHeight={'16px'}>{calKDA()}</KDAText>
            </KDAWrapper>
            <ContentWrapper>
                <ContentSpan color={theme.color.font_7F}>{data.kills.toFixed(1)}</ContentSpan>
                <SplashSpan>/</SplashSpan>
                <ContentSpan color={theme.color.font_DE}>{data.deaths.toFixed(1)}</ContentSpan>
                <SplashSpan>/</SplashSpan>
                <ContentSpan color={theme.color.font_7F}>{data.assists.toFixed(1)}</ContentSpan>
            </ContentWrapper>
        </Wrapper>
    );
};

export default OverviewKDA;

import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import i18n from 'i18next';

import {translation} from '../../store/modules/translation';
import {_HEADER_IMAGE, _NOTICE} from "../../store/modules/notice";
import styled, {useTheme} from "styled-components";
import {Link, NavLink, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import HeaderSearch from "../Header/HeaderSearch";
import {getRegion} from "../../function";
import {PREV_RANKING_LANE} from "../../store/reducer/champions";
import {getOriginalNames} from "../../i18n/languages";
import {LANGUAGE_FOR_OS} from "../../i18n/types";
import {FlexBox} from "../ui/styled";
import ThemeSwitch from "./ThemeSwitch";
import regionList from "../../store/static_json/regionList";


const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 8px;
  height: 32px;
`;
const LogoImg = styled.img`
  margin-top: 6px;
  width: 125px;
`;

const SearchWrapper = styled.div`
  flex: 1;
`;

const ContentWrapper = styled.div`
  .h__contactus {
    position: fixed;
    bottom: 47px;
    left: 47px;
    z-index: 2147483647;

    img {
      cursor: pointer;
    }

    .close {
      position: absolute;
      top: 20px;
      right: 15px;
      width: 30px;
    }
  }

`;


const LangWrapper = styled.div`
  width: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
  height: 32px;

  .h__util {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;

    .lang {
      position: relative;

      button {
        display: flex;
        align-items: center;
        height: 18px;
        font-size: 12px;
        color: #dfdfdf;

        &::before {
          content: '';
          display: inline-block;
          width: 16px;
          height: 16px;
          margin-right: 9px;
          background: url('/images/icons8_globe.svg') no-repeat center;
          background-size: cover;
        }

        &::after {
          content: '';
          overflow: hidden;
          display: inline-block;
          border-color: #dfdfdf transparent transparent transparent;
          border-style: solid;
          border-width: 4px;
          width: 0;
          height: 0;
          transition: all 0.3s linear;
          margin-top: 3px;
          margin-left: 9px;
        }
      }

      ul {
        position: absolute;
        z-index: 10000;
        top: 145%;
        right: 0;
        overflow-x: hidden;
        overflow-y: auto;
        width: 140px;
        max-width: 536px;
        margin-top: 10px;
        padding: 10px 0;
        background: ${props => props.theme.color.default_input_background};
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
        border: 1px solid ${props => props.theme.color.border_6B};
        border-radius: 6px;
      }

      li {
        display: flex;
        align-items: center;
        height: 30px;
        padding: 0 10px;
        font-size: 14px;
        cursor: pointer;

        &:hover,
        &.selected {
          background: ${props => props.theme.color.background_DB};
        }
      }
    }
  }
`;

const Wrapper = styled.div`
  padding: 0 26px;
  z-index: 9999999;
  top: 0;
  display: flex;
  flex-direction: row;
  flex: 1;
  background: #3C466B;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.6);
`;

const FlexWrapper = styled.div`
  flex: 1;
`;

const CenterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;
  z-index: 20000;
`;

const NavWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
`;

const NavText = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  padding: 0 12px;
  margin-right: 22px;
  color: ${props => props.theme.color.header.font_color};
  font-size: 14px;
  text-align: center;

  &:hover {
    color: #fff;
    opacity: 1;
    border-radius: 7px;
    background-color: rgba(255, 255, 255, 0.15);
  }

  &.active {
    color: #03FFD4;
    border-radius: 7px;
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 1;
  }`;


const NavLinkWrapper = styled(NavText)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const NewTag = styled.span`
  position: absolute;
  top: -7px;
  right: -10px;
  background: transparent linear-gradient(112deg, #27E2C5 0%, #4B7CCE 100%) 0 0 no-repeat padding-box;
  padding: 1px 4px;
  border-radius: 3px;
  font-size: 9px;
  color: #fff;
  text-align: center;
  font-weight: bold;
`;


const MaxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 1130px;
`;

const BetaText = styled.span`
  text-align: center;
  margin-top: 6px;
  width: 40px;
  padding-bottom: 1px;
  background: transparent linear-gradient(90deg, #2e79bb 0%, #561dab 100%);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
  border-radius: 12px;
  font-size: 11px;
  font-weight: 400;
  color: #fff;
`;

const IframeWrapper = styled.div`

`;
const langArray = ['ENG', 'KOR', 'ja', 'es', 'pt', 'fr', 'de', 'pl', 'nl', 'it', 'fi', 'norway', 'tr', 'hu', 'ro', 'sr', 'ru', 'th', LANGUAGE_FOR_OS.VI, 'zh-CN', 'zh-TW'];
const Header = ({initLoadingNotFound, openView, setOpenView, isHome}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const [view, setView] = useState(false);
    const region = useSelector((state) => state.region.result);
    const [openLang, setOpenLang] = useState(false);
    const currentLang = useSelector((state) => state.translation.lang);
    const [isTop, setIsTop] = useState(true);
    const router = useRouteMatch();
    const {pathname} = useLocation();
    useHistory();
    const langRef = useRef(null);
    const theme = useTheme();


    useEffect(() => {
        if (pathname.indexOf('/champions') === -1) {
            dispatch({
                type: PREV_RANKING_LANE,
                data: 'top',
            })
        }
    }, [pathname]);


    useEffect(() => {
        if (openView === true) {
            setView(openView);
            setOpenView(false);
        }
    }, [openView])


    const disaptchNotice = useCallback((data) => {
        dispatch({
            type: _NOTICE,
            data: data,
        })
    }, [])


    useEffect(() => {
        const fetchData = () => {
            axios.get(`${process.env.REACT_APP_CACHE_API}common/riot-status?platform_id=${getRegion(region)}`).then((res) => {
                if (!res.data.status) {
                    disaptchNotice('alertError');
                }
            }).catch((e) => {
                const statusCode = e?.response?.status;
                console.log(e);
                if (statusCode === 404) {

                } else if (statusCode === 500) {

                }
            });
        };
        fetchData();
    }, [region]);

    const onChangeLang = (lang) => {
        if (lang === 'KOR') {
            i18n.changeLanguage(LANGUAGE_FOR_OS.ko);
            localStorage.setItem(process.env.REACT_APP_LANG_COOKIE, LANGUAGE_FOR_OS.ko);
        } else if (lang === 'ENG') {
            i18n.changeLanguage(LANGUAGE_FOR_OS.EN);
            localStorage.setItem(process.env.REACT_APP_LANG_COOKIE, LANGUAGE_FOR_OS.EN);
        } else if (lang === 'vi') {
            i18n.changeLanguage(LANGUAGE_FOR_OS.VI);
            localStorage.setItem(process.env.REACT_APP_LANG_COOKIE, LANGUAGE_FOR_OS.VI);
        } else {
            i18n.changeLanguage(lang);
            localStorage.setItem(process.env.REACT_APP_LANG_COOKIE, lang);
        }
        dispatch(translation(lang));
        setOpenLang(false);
    };

    // 언어설정 토글
    const toggleLang = () => {
        setOpenLang((prev) => !prev);
    };

    // 문의하기 열기
    const handleContactOpen = () => {
        setView(true);
    };
    // 문의하기 닫기
    const handleContactClose = () => {
        setView(false);
    };

    // 언어설정 닫기
    const handleClickOutside = useCallback(
        (e) => {
            if (openLang && (!langRef.current || !langRef?.current?.contains(e.target))) setOpenLang(false);
        },
        [openLang]
    );

    const scrollEvent = useCallback(() => {
        if (document.scrollingElement.scrollTop === 0) {
            setIsTop(true);
        } else {
            if (isTop) {
                setIsTop(false);
            }
        }
    }, [isTop])

    useEffect(() => {
        window.addEventListener('scroll', scrollEvent)
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
            window.removeEventListener('scroll', scrollEvent)
        };
    }, [handleClickOutside]);


    const championAramCommonActive = useCallback((pathname) => {
        return pathname.indexOf('summoner') === -1
    }, []);

    const isActiveChampions = useCallback((match, location) => {
        const common = championAramCommonActive(location.pathname);
        return common && location.pathname.indexOf('/champions') > -1 && location.pathname.indexOf('/aram') === -1 && location.pathname.indexOf('/mastery') === -1 && location.pathname.indexOf('/urf') === -1;
    }, []);

    const isActiveAram = useCallback((match, location) => {
        const common = championAramCommonActive(location.pathname);
        return common && location.pathname.indexOf('/aram') > -1 && pathname.indexOf('daily') === -1;
    }, []);
    const isActiveURF = useCallback((match, location) => {
        const common = championAramCommonActive(location.pathname);
        return common && location.pathname.indexOf('/urf') > -1
    }, []);
    const isActiveMastery = useCallback((match, location) => {
        const common = championAramCommonActive(location.pathname);
        return common && location.pathname.indexOf('/mastery') > -1
    }, []);
    const isActiveRanking = useCallback((match, location) => {
        const common = championAramCommonActive(location.pathname);
        return common && location.pathname.indexOf('/ranking') > -1
    }, []);

    const isActiveDailyRanking = useCallback((match, location) => {
        const common = championAramCommonActive(location.pathname);
        return common && location.pathname.indexOf('/daily/ai') > -1
    }, []);

    const filteredRankRegion = useMemo(() => {
        const current = regionList.find((data) => data.title === region);
        if (current?.rank) {
            return current.title.toLowerCase()
        } else {
            return regionList[0].title.toLowerCase();
        }
    },[region]);    return (
        <>
            <Wrapper>
                <LogoWrapper>
                    <Link to="/">
                        <LogoImg src={`/images/deeplol_logo.svg`} width={125} height={30}/>
                    </Link>
                    <BetaText>Beta</BetaText>
                </LogoWrapper>

                <CenterWrapper>
                    <MaxWrapper>
                        <SearchWrapper>
                            {!isHome && <HeaderSearch initLoadingNotFound={initLoadingNotFound} key={router.url}/>}
                        </SearchWrapper>
                        <FlexWrapper>
                            <NavWrapper>
                                <NavText exact to="/">{t('header.navHome')}</NavText>
                                {/*<NavText to="/summoner">{t('header.navSummoner')}</NavText>*/}
                                <NavText to="/champions"
                                         isActive={isActiveChampions}>{t('header.navChampions')}</NavText>
                                <NavText to="/aram" isActive={isActiveAram}>
                                    {t('header.navAram')}
                                </NavText>
                                <NavText to="/mastery" isActive={isActiveMastery}>{t('header.navMastery')}</NavText>
                                {/*<MaintainTag><ScaleFont>점검중</ScaleFont></MaintainTag>*/}
                                <NavText to={`/ranking/${filteredRankRegion}/all`}
                                         isActive={isActiveRanking}>{t('header.navRanking')}</NavText>
                                <DailyAINavigation region={region} isActiveDailyRanking={isActiveDailyRanking}/>

                                {/*<NavLinkWrapper to="/spectate/live/pro">*/}
                                {/*    <span>{t('header.navLive')}</span>*/}
                                {/*    <NewTag>NEW</NewTag>*/}
                                {/*</NavLinkWrapper>*/}
                                <NavText to="/multi">{t('header.navMulti')}</NavText>
                                <FlexBox height={'48px'} margin={'0 0 0 22px'}>
                                    <a href={'https://trackdiff.com/'} target={'_blank'}
                                    >
                                        <img src={'images/logo_td.svg'}
                                             width={89}
                                             height={18}
                                             alt={""}
                                             className={'add_to_TrackDiff'}
                                        />
                                    </a>
                                </FlexBox>
                            </NavWrapper>
                        </FlexWrapper>

                    </MaxWrapper>
                </CenterWrapper>
                <LangWrapper>
                    <div className="h__util">
                        <ThemeSwitch/>
                        <div className="lang" ref={langRef}>
                            <button onClick={toggleLang}>{getOriginalNames(currentLang)}</button>
                            {openLang && (
                                <ul>
                                    {langArray.map((data) => {
                                        return (
                                            <li
                                                key={data}
                                                className={`${currentLang === data && 'selected'}`}
                                                onClick={() => onChangeLang(data)}
                                            >
                                                {getOriginalNames(data)}
                                            </li>
                                        )

                                    })}
                                    {/*<li*/}
                                    {/*    className={`${currentLang === 'KOR' && 'selected'}`}*/}
                                    {/*    onClick={() => onChangeLang('KOR')}*/}
                                    {/*>*/}
                                    {/*    한국어*/}
                                    {/*</li>*/}
                                    {/*<li*/}
                                    {/*    className={`${currentLang === 'ENG' && 'selected'}`}*/}
                                    {/*    onClick={() => onChangeLang('ENG')}*/}
                                    {/*>*/}
                                    {/*    English*/}
                                    {/*</li>*/}
                                    {/*<li*/}
                                    {/*    className={`${currentLang === 'vi' && 'selected'}`}*/}
                                    {/*    onClick={() => onChangeLang('vi')}*/}
                                    {/*>*/}
                                    {/*    Tiếng Việt*/}
                                    {/*</li>*/}
                                </ul>
                            )}
                        </div>
                    </div>
                </LangWrapper>


            </Wrapper>
            {/*<ContentWrapper>*/}
            {/*    <div className="h__contactus">*/}
            {/*        {!view && (*/}
            {/*            <img*/}
            {/*                src="/images/ico_write_qa_w.svg"*/}
            {/*                alt="contact us open"*/}
            {/*                className="open"*/}
            {/*                onClick={handleContactOpen}*/}
            {/*            />*/}
            {/*        )}*/}
            {/*        {view && (*/}
            {/*            <img*/}
            {/*                src="/images/ico_search_tag_del.svg"*/}
            {/*                alt="contact us close"*/}
            {/*                className="close"*/}
            {/*                onClick={handleContactClose}*/}
            {/*            />*/}
            {/*        )}*/}
            {/*        {view && (*/}
            {/*            <IframeWrapper>*/}

            {/*                <iframe*/}
            {/*                    title="contact-us"*/}
            {/*                    src="https://2021.deeplol.gg/test__2.html"*/}
            {/*                    frameBorder="0"*/}
            {/*                    width={350}*/}
            {/*                    height={510}*/}
            {/*                />*/}
            {/*            </IframeWrapper>*/}
            {/*        )}*/}
            {/*    </div>*/}
            {/*</ContentWrapper>*/}
            {/*</HeaderContainer>*/}
        </>
    );
};

const AINavigationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
`;
const VisibleWrapper = styled.div`
  opacity: ${props => props.opacity};
  transition: opacity 0.3s ease-out;
  display: flex;
  flex-direction: row;
  justify-content: center;

  img {
    margin-right: 3px;
  }
`;

const TrophyVisibleWrapper = styled(VisibleWrapper)`
  position: absolute;
  z-index: ${props => {
    let zIndex = '-1';
    if (props.opacity === '1') zIndex = '1'
    return zIndex
  }};
`;
const TrophyImage = styled.div`
  text-indent: -9999px;
  background: url('/images/icon_trophy_home.png') no-repeat center;
  width: 34px;
  height: 34px;
  margin-right: 22px;
`;
const DailyAINavigation = ({region, isActiveDailyRanking}) => {
    const {t} = useTranslation();
    const noticeNumber = useSelector((state) => state.notice.headerImage);
    const dispatch = useDispatch();
    const setNoticeNumber = (value) => {
        dispatch({
            type: _HEADER_IMAGE,
            data: value
        })
    }
    useEffect(() => {
        let trophyImageTimeout;
        let textTimeout;
        if (noticeNumber === 0) {
            trophyImageTimeout = setTimeout(() => {
                setNoticeNumber(1)
            }, 8000);
            clearTimeout(textTimeout);
        } else if (noticeNumber === 1) {
            textTimeout = setTimeout(() => {
                setNoticeNumber(0)
            }, 2000);
            clearTimeout(trophyImageTimeout)
        }

        return () => {
            clearTimeout(trophyImageTimeout)
            clearTimeout(textTimeout)
        }
    }, [noticeNumber]);


    const filteredRegion = useMemo(() => {
        const current = regionList.find((data) => data.title === region);
        if (current?.daily) {
            return current.title.toLowerCase()
        } else {
            return regionList[0].title.toLowerCase();
        }
    },[region]);


    return (
        <AINavigationWrapper>
            <VisibleWrapper width={t('header.navDailyAi').length}
                            opacity={noticeNumber === 0 ? '1' : '0'}>
                <NavText to={`/daily/ai/${filteredRegion}/rank/all`}
                         isActive={isActiveDailyRanking}>{t('header.navDailyAi')}</NavText>
            </VisibleWrapper>
            <TrophyVisibleWrapper width={t('header.navDailyAi').length}
                                  opacity={noticeNumber === 1 ? '1' : '0'}>
                <NavText to={`/daily/ai/${region?.toLowerCase()}/rank/all`} isActive={isActiveDailyRanking}>
                    <img src={'/images/icon_trophy_home.png'} width={34} height={34} alt={""}/>
                </NavText>
            </TrophyVisibleWrapper>
        </AINavigationWrapper>
    )
}

export default Header;

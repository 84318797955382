import React, {useCallback, useEffect, useState} from 'react';
import {
    GET_SUMMONER_REAL_TIME_REQUEST,
    GET_UPDATE_MATCHES_REQUEST,
    UPDATE_TIME_BY_DELAY
} from "../../../store/reducer/summoner";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import useTimeHook from "../../../common/hooks/useTimeHook";
import {_fetch_check_update} from "../../../common/api";

const TextWrapper = styled.div`
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-block: 2px;
  margin-left: 4px;
`;

const RedText = styled.p`
  margin-left: 6px !important;
  color: #FF5151;
  font-size: 11px/17px;
`;

const UpdateText = styled.p`
  margin-left: 6px !important;
  color: ${props => props.theme.color.font_7F};;
  font-weight: 300;
  font-size: 11px;

  span {
    color: ${props => props.theme.color.font_7F};
  }
`;

const UpdateButton = styled.button`
  border: 1px solid ${props => {
    let color = props.theme.color.summoner.profile.update.border_color;
    if (props.delay) color = props.theme.color.summoner.profile.update.border_error;
    if (props.error) color = props.theme.color.summoner.profile.update.border_error;
    return color;
  }};
  background-color: ${props => {
    let color = '#696FEA';
    if (props.delay) color = props.theme.color.background_CF;
    if (props.error) color = props.theme.color.background_CF;
    return color;
  }};

`;

const DELAY_TIME = 30;
const SummonerUpdateButton = ({region, userName, selectedSeason, queueNum}) => {
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const [testValue, setTextValue] = useState(100);
    const [updateError, setUpdateError] = useState(0);
    const maxvalueSeason = useSelector((state) => state.imageStore.currentSeason);
    const oldSeason = useSelector((state) => state.imageStore.oldSeason);
    const {
        summoner,
        updateStatus,
        getUpdateMatchesDone,
        getUpdateMatchesError,
        getUpdateChampionDone,
        getUpdateChampionError,
        getSummonerRealTimeError,
        getSummonerRealTimeDone
    } = useSelector((state) => state?.summoner);
    const currentLang = useSelector((state) => state?.translation?.lang);
    const [delay, setDelay] = useState(0);
    const {lessMinWithIntl} = useTimeHook()


    useEffect(() => {
        const updateTime = updateStatus.updated_timestamp * 1000;
        const currentTime = Date.now();

        const passedDate = Math.trunc((currentTime - updateTime) / 1000 / 86400);
       if(passedDate > 0)   {
           handleUpdate();
       }
    },[updateStatus.updated_timestamp]);

    useEffect(() => {
        if (getUpdateMatchesError || getSummonerRealTimeError) {
            setTextValue(100);
            setUpdateError(1);
        }
    }, [getUpdateMatchesError, getSummonerRealTimeError]);

    useEffect(() => {
        if (getUpdateMatchesDone && getSummonerRealTimeDone) {
            setTextValue(100);
            setIntervalDelay(DELAY_TIME);
        }
    }, [getUpdateChampionDone, getUpdateMatchesDone, getSummonerRealTimeDone])

    useEffect(() => {
        if (updateStatus?.remain_second > 0) {
            setIntervalDelay(updateStatus.remain_second);
        }
    }, [typeof updateStatus?.remain_second])
    const alertText = (value) => {
        return `${t('notice.updateFinished', {count: DELAY_TIME - value})}${t('notice.updatelater', {
            count: value
        })}`
    }

    const setIntervalDelay = (delayTime) => {
        let time = delayTime;
        setDelay(delayTime);
        if (delay > 0) {
            return;
        }
        const interval = setInterval(() => {
            if (delayTime < 0) {
                clearInterval(interval)
                return;
            }
            time--;
            if (time > -1) {
                setDelay(time);
            }
        }, 1000);
        dispatch({
            type: UPDATE_TIME_BY_DELAY,
            data: Math.ceil(Date.now() / 1000) - (DELAY_TIME - delayTime)
        })
    }


    const validateSeason = useCallback(() => {
        if (oldSeason?.includes(Number(selectedSeason))) {
            return Number(maxvalueSeason);
        } else {
            return Number(selectedSeason);
        }
    }, [selectedSeason, maxvalueSeason, oldSeason]);

    const dispatchMatches = (data) => {
        dispatch({
            type: GET_UPDATE_MATCHES_REQUEST,
            data
        })
    }


    const dispatchTier = (data) => {
        dispatch({
            type: GET_SUMMONER_REAL_TIME_REQUEST,
            data: {...data, region: data.platform_id},
        })
    }


    const handleUpdate = async () => {
        // const currentSocket = useSocket.connectTo();
        setTextValue(0);
        setUpdateError(0);


        const updateObj = {
            "platform_id": region,
            "puu_id": summoner?.summoner_basic_info_dict?.puu_id,
            summoner_id: summoner?.summoner_basic_info_dict?.summoner_id,
            "season": validateSeason(),
            "queue_type": queueNum,
            "start_idx": 0,
            "count": 20,
            noLoading: true,
        }

        try {
            const updateCheck = await _fetch_check_update(updateObj);

            if (updateCheck === 'available') {
                dispatchTier(updateObj)
                dispatchMatches(updateObj)
            }


            if (updateCheck?.includes("update")) {
                const delayTime = updateCheck.split("|")?.[1];
                setUpdateError(2);
                setTextValue(100);
                setIntervalDelay(delayTime);
                setTimeout(() => {
                    alert(alertText(delayTime))
                }, 100);
            }
        } catch (e) {
            console.log(e);
            setTextValue(100);
            setUpdateError(1);
        }
    };


    const DispatchToUpdate = useCallback(() => {
        // dispatch({
        //     type: UPDATE_SUMMONER_INFO_REQUEST,
        //     data: {
        //         summonerName: userName,
        //         region: region,
        //         queue_id: queueNum,
        //         currentSeason: selectedSeason,
        //     }
        // });
        // update(100);
        setUpdateError(0);
    }, [userName, region, queueNum, selectedSeason]);

    return (
        <div className="update">
            <UpdateButton delay={delay > 0} error={updateError === 1}
                          disabled={testValue !== 100 || delay > 0 || updateError === 1}
                          onClick={handleUpdate}>
                {testValue === 100 ?
                    <>
                        <img src={'/images/ico_refresh.svg'} width={14} height={14}
                             style={{marginRight: 4}} alt={''}/>
                        <span>{t('buttons.updated')}</span>
                    </>
                    :
                    <>
                        <img src={'/images/ico_updating.svg'} width={14} height={14} style={{marginRight: 4}} alt={''}/>
                        <span>{t('buttons.updated')}</span>
                    </>
                }
                {/*{updateError && <span key={"errorSpan"}>{t('buttons.updated')}</span>}*/}
            </UpdateButton>
            <TextWrapper>
                <RedText>
                    {updateError === 1 ? t('notice.updateError') : " "}
                </RedText>
                {delay ?
                    <UpdateText>
                        {t('notice.updatelater', {
                            count: delay,
                        })}

                    </UpdateText> :
                    <UpdateText>
                        {t('summoner.lastUpdate')}:{' '}
                        <span>{updateStatus?.updated_timestamp > 0 ? lessMinWithIntl(Number(updateStatus.updated_timestamp) * 1000) : '-'}</span>
                    </UpdateText>
                }
            </TextWrapper>
        </div>
    );
};

export default SummonerUpdateButton;
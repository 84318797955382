import React, {useCallback} from 'react';
import styled from "styled-components";

const Wrapper = styled.ul`
  cursor: pointer;
  position: relative;
  display: ${props => {
    let display = 'flex';
    if (!props.visible) display = 'none';
    return display;
  }};
  flex-direction: column;
  align-items: center;
  margin: 5px 9px 5px 0;
  &:hover {
    scale: 1.2;
    transition: scale .2s ease-in-out;
  }
`;

const ChampionImage = styled.img`
  margin: -3px;
`;

const ImageText = styled.span`
  margin-top: 3px;
  font-size: 12px;
  text-align: center;
  height: 14px;
  width: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 4px;
`;

const FreeAbsolute = styled.div`
  position: absolute;
  left: 0;
  z-index: 9;
`;

const FreeImage = styled.img`

`;

const ChampionsChampionImage = ({
                                    koChampName,
                                    enChampName,
                                    imageName,
                                    version,
                                    lang,
                                    visible = true,
                                    isFree,
                                    lane,
                                    onClickChamp
                                }) => {

    const onClick = useCallback(() => {
        if (typeof enChampName === 'string' && typeof lane === 'string') {
            const champName = enChampName.replace(/\s|&|willump|!|/gi, "").toLowerCase();
            onClickChamp(champName);
        }
    }, [lane, onClickChamp]);

    return (
        <Wrapper visible={visible} onClick={onClick}>
            <ImageWrapper>
                {isFree &&
                    <FreeAbsolute>
                        <FreeImage
                            width={36}
                            height={36}
                            src="/images/ico_tag_free.svg"/>
                    </FreeAbsolute>
                }
                <ChampionImage
                    width={48}
                    height={48}
                    src={`${process.env.REACT_APP_IMG}${version}/img/champion/${imageName}?w=96&h=96&f=webp`}
                    onError={(e) => e.target.src = "/images/icon_non_item.svg"}
                />
            </ImageWrapper>
            <ImageText>{lang === 'KOR' ? koChampName : enChampName}</ImageText>
        </Wrapper>
    );
};

export default React.memo(ChampionsChampionImage);

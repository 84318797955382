import React, {useCallback, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {FooterContainer} from '../../styled/CommonStyle';
import styled, {useTheme} from "styled-components";


const FeedbackA = styled.a`
  color: ${props => props.theme.color.footer.content_font_color};
  display: inline-block;
  font-size: 14px;
  font-family: Noto Sans;
`;
const FeedBackLink = styled(Link)`
  color: ${props => props.theme.color.footer.content_font_color};
  display: inline-block;
  font-size: 14px;
  font-family: Noto Sans;
  margin-right: 20px;
`;
const Feedback = styled.button`
  color: ${props => props.theme.color.footer.content_font_color};
  display: inline-block;
  font-size: 14px;
  font-family: Noto Sans;
  margin: 0 20px;
`;


const OwnerText = styled.span`
  font-weight: 400;
  font-size: 10px;
  color:${props => props.theme.color.footer.owner_font_color};
`;


const Footer = ({setOpenFeedback}) => {
    const {t} = useTranslation();
    const [isToggled, setIsToggled] = useState(false);
    const theme = useTheme();
    // 자동완성 닫기
    const handleClickOutside = useCallback(
        (e) => {
            if (isToggled && (!e.current || !e?.current?.contains(e.target))) setIsToggled(false);
        },
        [isToggled]
    );

    useEffect(() => {
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [handleClickOutside]);


    const openFeedback = useCallback(() => {
        setOpenFeedback(true);
    }, []);


    return (
        <FooterContainer>
            <div className="f__copy">
                <h2>
                    <img src={`${theme.icon.path}/icon_gameeye_logo_w.svg`} alt="GAME EYE" width={115} height={33}/>
                </h2>
                <dl>
                    <dt>Copyright © GameEye Corp. All rights reserved.</dt>
                    <dd>
                        deeplol.gg isn’t endorsed by Riot Games and doesn’t reflect the views or opinions of
                        Riot Games or anyone officially involved in producing or managing League of Legends.
                        League of Legends and Riot Games are trademarks or registered trademarks of Riot Games,
                        Inc. League of Legends © Riot Games, Inc.
                        <br/>
                    </dd>
                    <OwnerText>(주)게임아이 대표: 윤덕진 사업자등록번호: 715-81-01319</OwnerText>
                </dl>
            </div>
            <div className="f__menu">
                <nav>
                    <FeedBackLink to={'/policies/privacy'}>
                        Privacy Policy
                    </FeedBackLink>
                    <FeedbackA href="https://blog.deeplol.gg/" target="_blank" rel="noreferrer">
                        ABOUT
                    </FeedbackA>
                    <Feedback onClick={openFeedback}>{t('footer.navInq')}</Feedback>
                </nav>
                <div className="toggle">
                    <span className="toggle__btn" onClick={() => setIsToggled(!isToggled)}>
                        Family Site
                    </span>
                    {isToggled && (
                        <ul className="toggle__list">
                            <li className="toggle__list-item">
                                <a href="https://pro.deeplol.gg/" target="_blank" rel="noreferrer">
                                    {t('footer.familyPro')}
                                </a>
                            </li>
                        </ul>
                    )}
                </div>
            </div>
        </FooterContainer>
    );
};

export default Footer;

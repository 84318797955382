import React, {useCallback, useMemo, useState} from 'react';
import styled from "styled-components";
import regionList from "../../../store/static_json/regionList";
import SummonerSearchTableBox from "./SummonerSearchTableBox";
import {useHistory, useParams} from "react-router-dom";
import {changeRegion} from "../../../store/modules/region";
import {useDispatch} from "react-redux";
import useSummonerSearchAPI from "../../../common/hooks/useSummonerSearchAPI";
import {useTranslation} from "react-i18next";
import RankingTableNavigator from "../../Ranking/RankingContent/RankingTable/RankingTableContent/RankingTableNavigator";
import SummonerSearchTableSelect from "../SummonerSearchTableSelect";
import {Loading} from "../../common";
import ProAndStreamerSearchTable from "../ProAndStreamerSearchTable";
import SummonerSearchTitle from "../SummonerSearchTitle";

const ContentWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  border: 1px solid ${props => props.theme.color.champion.border};
  background-color: ${props => props.theme.color.summoner.tab.background_color};
  border-radius: 10px 10px 0 0;
  margin-top: 5px;
  margin-bottom: 91px;
`;
const ProContentWrapper = styled(ContentWrapper)`
  margin-bottom: 10px;
`;

const Wrapper = styled.div`
  width: 100%;
`;

const Title = styled.div`
  font-weight: 500;
  line-height: 37px;
  font-size: 26px;
`;
const SearchedText = styled.div`
  font-size: 14px;
  opacity: 0.6;
  line-height: 20px;
  color: ${props => props.theme.color.default_gray_font_color};
  margin-bottom: 20px;
`;

const LoadingWrapper = styled.div`
  height: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const SummonerSearchTable = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const {platform, summonerName} = useParams();
    const {summonerSearchQuery, proStreamer} = useSummonerSearchAPI();
    const rankingRegion = useMemo(() => regionList.find((data) => data.title.toLowerCase() === platform?.toLowerCase()), [platform]);
    const lastPage = useMemo(() => Math.ceil(summonerSearchQuery.data.length / 100), [summonerSearchQuery]);


    const calcFirstIndex = () => {
        return ((currentPage - 1) * 100);
    }
    const calcLastIndex = () => {
        return (currentPage * 100 - 1)
    }


    const filteredList = useMemo(() => {
        // 1 - 1 * 100  ~ 1 * 100 - 1
        // 2 - 1 * 100  ~ 2 * 100 -1
        // 3 - 1 * 100  ~ 3 * 100 -1

        const filter = summonerSearchQuery.data.filter((_, index) => {
            return calcFirstIndex() <= index && index <= calcLastIndex()
        })
        return filter;

    }, [summonerSearchQuery.data, currentPage]);

    const onClickRegion = useCallback((event) => {
        const region = event.target.value;
        if (typeof region === 'string') {
            if (region !== rankingRegion.title) {
                dispatch(changeRegion(region.toUpperCase()))
                setCurrentPage(1)
                history.push(`/summoner/search/${region}/${summonerName}`);
            }
        }

    }, [rankingRegion]);


    const onClickGoToPage = (value) => {
        if (currentPage !== value) {
            setCurrentPage(value);
            window.scroll(0, 0)
        }
    }

    const onClickToEnd = () => {
        if (currentPage !== lastPage) {
            setCurrentPage(lastPage)
            window.scroll(0, 0);
        }
    }
    const onClickToFist = () => {
        if (currentPage !== 1) {
            setCurrentPage(1)
            window.scroll(0, 0);
        }

    }
    const onClickPrev = () => {
        if (currentPage !== 0) {
            setCurrentPage(prev => prev - 1);
            window.scroll(0, 0);
        }
    }
    const onClickNext = () => {
        setCurrentPage(prev => prev + 1);
        window.scroll(0, 0);
    }


    if (!summonerSearchQuery.isFetched) {
        return (
            <Wrapper>
                <Title>
                    ‘{summonerName}’ {t('summonerSearch.title')}
                </Title>
                <SearchedText>{t('summonerSearch.count', {
                    count: summonerSearchQuery.data.length
                })}</SearchedText>
                <ContentWrapper>
                    <LoadingWrapper>
                        <Loading/>
                    </LoadingWrapper>
                </ContentWrapper>
            </Wrapper>
        )
    }


    return (
        <Wrapper>
            <Title>
                ‘{summonerName}’ {t('summonerSearch.title')}
            </Title>
            <SearchedText>{t('summonerSearch.count', {
                count: summonerSearchQuery.data.length
            })}</SearchedText>
            {proStreamer?.data?.length > 0 &&
                <ProContentWrapper>
                    <SummonerSearchTitle title={t('search.pro')}>
                    </SummonerSearchTitle>
                    <ProAndStreamerSearchTable searchList={proStreamer.data}/>
                </ProContentWrapper>}
            <ContentWrapper>
                <SummonerSearchTitle title={t('search.summoner')}>
                    <SummonerSearchTableSelect rankingRegion={rankingRegion} onClickRegion={onClickRegion}/>
                </SummonerSearchTitle>
                <SummonerSearchTableBox searchList={filteredList}/>
            </ContentWrapper>
            {lastPage > 0 && <RankingTableNavigator
                onClickGoToPage={onClickGoToPage}
                onClickToEnd={onClickToEnd}
                onClickToFist={onClickToFist}
                onClickPrev={onClickPrev}
                onClickNext={onClickNext}
                currentPage={currentPage}
                lastPage={lastPage}
            />}
        </Wrapper>
    );
};


export default SummonerSearchTable;
import React, {useCallback} from 'react';
import styled from "styled-components";
import {useDispatch} from "react-redux";
import ItemWithMythic from "../../../../../../ItemWIthMythic";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;


  &:hover {
    cursor: pointer;
  }

  .imgItem {
    width: 20px;
    height: 20px;
    margin-left: 2px;
    border-radius: 4px;
  }
`;


const SwarmWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;


  &:hover {
    cursor: pointer;
  }

  .imgItem {
    width: 24px;
    height: 24px;
    border-radius: 4px;
  }
  .img_wrapper + .img_wrapper {
    margin-left: 4px;
  }
`;



const ItemInfo = ({item, myMatch, disable, onChangeBuildIndex, isSwarm}) => {
    useDispatch();

    const OpenModal = useCallback(() => {
        if (!disable) {
            onChangeBuildIndex(myMatch)
        }
    }, [onChangeBuildIndex])


    if (isSwarm) {
        return (
            <SwarmWrapper onClick={OpenModal}>
                <ItemWithMythic item={item}
                                array={[item.item_0, item.item_1, item.item_2, item.item_3, item.item_4,]}/>
            </SwarmWrapper>
        )
    }


    return (
        <Wrapper onClick={OpenModal}>
            <ItemWithMythic item={item}
                            array={[item.item_0, item.item_1, item.item_2, item.item_3, item.item_4, item.item_5, item.item_6]}/>
        </Wrapper>
    )
        ;
};

export default ItemInfo;
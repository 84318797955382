import React, {useCallback, useEffect, useLayoutEffect, useMemo, useState} from 'react';
import {ImageItem, ImageRune} from "../../../common";
import ChampionSkillImage from "../../../common/ChampionSkillImage";
import {getMaxCount, scoreColor} from "../../../../function";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import useGetChampionSpells from "../../../../common/hooks/useGetChampionSpells";
import useChampionFunc from "../../../../common/hooks/useChampionFunc";
import styled from "styled-components";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import ChampionImageWithLane from "../../../common/ChampionImageWithLane";
import runeShards from "../../../../store/static_json/runeShards";
import runeShards_en from "../../../../store/static_json/runeShards_en";
import RuneShardLayout from "../../../common/RuneShardLayout";

const TeamBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  position: relative;
  flex: 1;
  height: 55px;

  & + & {
    margin-left: 54px;
    justify-content: flex-start;
  }

  .react-tabs__tab {
    z-index: 9;
    margin: 0 9px;

    &--selected {
      .imgDiv {
        border-color: #F3A634;
      }
    }
  }

  .imgChamp {
    width: 42px;
    height: 42px;
  }

  .imgDiv {
    border: 3px solid transparent;
    border-radius: 5px;
  }

  list-style-type: none;
`;


const Bar = styled.div`
  position: absolute;
  top: 18px;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 18px;
  padding: 0 10px;
  font-size: 12px;
  font-weight: 400;
  color: #f6f6f6;
  background-color: ${(props) => {
    let color = props.theme.color.background_D1;
    if (props.isWin) color = props.theme.color.background_58;
    return color;
  }};
`;

const BarReverse = styled(Bar)`
  justify-content: flex-end;
`;

const CustomTabList = styled(TabList)`
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  margin-bottom: 10px;
`;

const ScoreSpan = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 2px;
  font-weight: 700;
  font-size: 14px;
`;

const CustomTabs = styled(Tabs)`
  background-color: ${props => props.theme.color.default_input_background};

  .react-tabs__tab-panel {
    background-color: ${props => props.theme.color.default_input_background}
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  background-color: ${props => props.theme.color.background_F4};

  .builds {
    width: 720px;
    padding: 14px;

    h4 {
      margin-left: 12px;
      font-size: 16px;
      font-weight: 700;
    }

    .build {
      display: flex;
      margin-top: 10px;
      padding: 9px;
      background-color: ${props => props.theme.color.background_fff_1A};
      border: ${props => {
        let border = '1px solid #cad7e2'
        if (props.theme.color.theme === 'dark') border = 0;
        return border;
      }};

      &.items {
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 14px;
        padding: 10px 0;

        .items_per_min {
          flex: 0 1 auto;
          position: relative;
          padding: 10px 15px;

          &::after {
            content: '';
            position: absolute;
            top: 16px;
            left: 0;
            display: block;
            width: 100%;
            height: 16px;
            background: ${props => props.theme.color.background_CA};
          }

          &:last-of-type {
            .items::after {
              display: none;
            }
          }

          .items {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 10;

            &::after {
              content: '';
              position: absolute;
              top: 11px;
              right: -20px;
              width: 9px;
              height: 8px;
              background: url('/images/icon_arrow_up_w.svg') no-repeat center;
              background-size: cover;
            }

            > span {
              position: relative;
              display: block;

              &.ITEM_SOLD {
                &::after {
                  content: '';
                  position: absolute;
                  top: 0;
                  left: 0;
                  display: block;
                  width: 100%;
                  height: 100%;
                  background: rgba(255, 255, 255, 0.3) url('/images/icon_s_close.svg') no-repeat center;
                }
              }
            }
          }

          .times {
            display: block;
            margin-top: 5px;
            font-size: 10px;
            font-weight: 500;
            text-align: center;
          }
        }
      }

      &.skills {
        align-items: center;
        margin-bottom: 14px;
        padding: 10px 0;

        .skills_m_seq {
          position: relative;
          display: flex;
          width: 180px;
          margin: 0 30px;

          &::after {
            content: '';
            position: absolute;
            top: 11px;
            left: 0;
            display: block;
            width: 100%;
            height: 16px;
            background: ${props => props.theme.color.background_CA};
          }

          .skills {
            position: relative;
            z-index: 10;

            &::after {
              content: '';
              position: absolute;
              top: 15px;
              right: -24px;
              width: 9px;
              height: 8px;
              background: url('/images/icon_arrow_up_w.svg') no-repeat center;
              background-size: cover;
            }

            &:last-of-type::after {
              display: none;
            }
          }

          .skills + .skills {
            margin-left: 36px;
          }

          .texts {
            display: block;
            margin-top: 2px;
            font-size: 16px;
            font-weight: 700;
            text-align: center;
          }
        }

        .skills_seq {
          display: flex;

          .qwer {
            display: flex;
            flex-direction: column;

            span {
              background-color: ${props => props.theme.color.background_68};

              & + span {
                margin-top: 1px;
              }

              &.plus {
                font-size: 18px;
                font-weight: 400;
              }
            }
          }

          .skills {
            display: flex;

            & + .skills {
              margin-top: 1px;
            }

            span {
              margin-left: 1px;
            }

            &.q {
              .selected {
                background: transparent linear-gradient(180deg, #f26884 0%, #ac3e79 100%);
              }
            }

            &.w {
              .selected {
                background: transparent linear-gradient(180deg, #6a70cc 0%, #8630a4 100%);
              }
            }

            &.e {
              .selected {
                background: transparent linear-gradient(180deg, #5eb0db 0%, #415ca4 100%);
              }
            }

            &.r {
              .selected {
                background: transparent linear-gradient(180deg, #f8cb38 0%, #b95c2c 100%);
              }
            }

            &.plus {
              .selected {
                background: transparent linear-gradient(180deg, #56c58e 0%, #376a74 100%);
              }
            }
          }

          span {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 22px;
            height: 24px;
            text-align: center;
            background-color: ${props => props.theme.color.background_01};
            font-size: 11px;
            font-weight: 500;
            color: ${props => props.theme.color.default_gray_font_color_white};
          }
        }
      }

      &.runes {
        justify-content: center;

        > div {
          width: 224px;
          height: 340px;
          margin: 5px 8px;
          padding: 7px;
          background-color: ${props => props.theme.color.default_input_background};
          border: ${props => {
            let border = '1px solid #cad7e2'
            if (props.theme.color.theme === 'dark') border = 0;
            return border;
          }};
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          text-align: center;
        }

        .runes_title {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 12px 0 24px;

          span {
            display: inline-block;
            margin-left: 15px;
            font-size: 12px;
          }
        }

        .runes {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-around;
          border-bottom: ${props => {
            let border = '1px solid #cad7e2'
            if (props.theme.color.theme === 'dark') border = '1px solid #A7A7AF';
            return border;
          }};

          &::before {
            content: '';
            position: absolute;
            bottom: -4px;
            left: 0;
            display: inline-block;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background-color: ${props => props.theme.color.background_CA_AF};
          }

          &::after {
            content: '';
            position: absolute;
            bottom: -4px;
            right: 0;
            display: inline-block;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: ${props => props.theme.color.background_CA_AF};
          }

          &:first-of-type {
            &::before,
            &::after {
              display: none;
            }
          }

          &:last-of-type {
            border-bottom: 0;

            &::before,
            &::after {
              display: none;
            }
          }

          span {
            opacity: 0.3;
            mix-blend-mode: luminosity;

            &:not(.selected) {
              .tooltip {
                display: none;
              }
            }

            &.selected {
              opacity: 1;
              mix-blend-mode: normal;
            }
          }
        }

        .runes_pri_seq {
          .runes {
            padding: 15px 0;

            &:first-of-type {
              padding: 12px 8px 8px;
              background-color: ${props => props.theme.color.background_DBD};
              border-radius: 5px;
              border-bottom: 0;

              .imgRune {
                width: 42px;
                border-radius: 50%;
                background: rgba(0, 0, 0, 0.9);
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
              }
            }
          }
        }

        .runes_sec_seq {
          .runes {
            padding: 9px 0;

            &:first-of-type {
              display: none;
            }
          }

          .stats {
            .runes {
              padding: 8px 0;

              &:first-of-type {
                display: flex;

                &::before,
                &::after {
                  display: inline-block;
                }
              }

              img {
                width: 20px;
                border-radius: 50%;
                background: rgba(0, 0, 0, 0.9);
                box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
              }
            }
          }
        }
      }

      .imgItem {
        width: 28px;
        border-radius: 5px;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
      }

      .imgSkill {
        width: 36px;
      }

      .imgRune {
        width: 32px;
      }
    }
  }`;

const DetailBuild = ({match, customIndex, resetIndex, onChangeBuildIndex}) => {
    const {t} = useTranslation();

    const redTeam = useMemo(() => {
        try {
            return match.filter((data) => data.side === 'RED');
        } catch (e) {
            return [];
        }
    }, [match]);
    const blueTeam = useMemo(() => {
        try {
            return match.filter((data) => data.side === 'BLUE');
        } catch (e) {
            return [];
        }
    }, [match]);

    useEffect(() => {
        return () => {
            resetIndex();
        }
    }, [])

    return (<CustomTabs selectedIndex={customIndex}>
        <CustomTabList>
            <TeamBox>
                <Bar
                    isWin={blueTeam[0]?.is_win}>{blueTeam[0]?.is_win ? t('summoner.side.winTeam') : t('summoner.side.loseTeam')}</Bar>
                {/*<img src={'images/ico_trophy.svg'} width={18} height={19}*/}
                {/*     alt={""}/>*/}
                {blueTeam.map((data, index) => {
                    return (<Tab>
                        <div onClick={() => onChangeBuildIndex(index)}>
                            <ChampionImageWithLane champion_id={data.champion_id} disabled/>
                            <ScoreSpan>{scoreColor(Math.trunc(data?.final_stat_dict?.ai_score))}</ScoreSpan>

                        </div>
                    </Tab>)
                })}

            </TeamBox>


            <TeamBox>
                {/*<img src={'images/ico_trophy.svg'} width={18} height={19}*/}
                {/*     alt={""}/>*/}
                <BarReverse
                    isWin={redTeam[0]?.is_win}>{redTeam[0]?.is_win ? t('summoner.side.winTeam') : t('summoner.side.loseTeam')}</BarReverse>
                {redTeam.map((data, index) => {
                    return (<Tab>
                        <div onClick={() => onChangeBuildIndex(index + 5)}>
                            <ChampionImageWithLane champion_id={data.champion_id} disabled/>
                            <ScoreSpan>{scoreColor(Math.round(data?.final_stat_dict?.ai_score))}</ScoreSpan>
                        </div>
                    </Tab>)
                })}

            </TeamBox>

        </CustomTabList>

        {blueTeam.map((data, index) => {
            return (<TabPanel key={index + 'blueTeam'}>
                <DetailBuildContent modalData={data}/>
            </TabPanel>)
        })}
        {redTeam.map((data, index) => {
            return (<TabPanel key={index + 'redTeam'}>
                <DetailBuildContent modalData={data}/>
            </TabPanel>)
        })}
    </CustomTabs>)
};

const DetailBuildContent = ({modalData}) => {
    const currentLang = useSelector((state) => state.translation.lang);
    const {getChampion, calcUrlNameByName} = useChampionFunc();
    const skillList = useGetChampionSpells(calcUrlNameByName(getChampion(modalData?.champion_id)?.champion_name_en));
    const version = useSelector((state) => state.gameVersion.version);
    const {t} = useTranslation();
    const [minute, setMinute] = useState([]);
    const [skills, setSkills] = useState({});
    const [master, setMaster] = useState([]);
    const [runes, setRunes] = useState({});
    const {i18n} = useTranslation();
    const currentRuneShards = useMemo(() => ['ko', 'ko-KR'].includes(i18n.language) ? runeShards : runeShards_en, [i18n.language]);
    const runeArr = useSelector((state) => state.imageStore.runes);
    const initialFunc = useCallback(() => {
        /*** ITEM ***/
        let minArr = [];
        modalData.item_seq_list.forEach((item) => {
            minArr.push(item.minute);
        });
        setMinute([...new Set(minArr)]); // min(분) 배열 구해서 중복 제거

        /*** SKILL ***/
        let skillQ = [];
        let skillW = [];
        let skillE = [];
        let skillR = [];
        let skillPlus = [];
        let qCount = 0;
        let wCount = 0;
        let eCount = 0;
        let rCount = 0;
        let qAllCount = getMaxCount('Q', modalData.champion_id);
        let wAllCount = getMaxCount('W', modalData.champion_id);
        let eAllCount = getMaxCount('E', modalData.champion_id);
        let rAllCount = getMaxCount('R', modalData.champion_id);
        let successArr = [];

        const getId = (id) => {
            switch (id) {
                case 1 :
                    return "Q";
                case 2 :
                    return "W";
                case 3 :
                    return "E";
                case 4 :
                    return "R";
                default :
                    return "Q";
            }
        }


        modalData.skill_seq_list.forEach((item, index) => {
            let level = index + 1;
            if (item.type === 'EVOLVE') {
                skillPlus.pop();
                skillPlus.push(getId(item.skill_id));
            }
            if (item.type === "SKILL_LEVEL_UP" || item.type === "NORMAL") {
                if (item.skill_id === 1) {
                    skillQ.push(level);
                    skillW.push(0);
                    skillE.push(0);
                    skillR.push(0);
                    skillPlus.push(0);

                    qCount++;
                    if (qCount === qAllCount) {
                        successArr.push({
                            skill: 'Q', icon: 1,
                        });
                    }
                } else if (item.skill_id === 2) {
                    skillQ.push(0);
                    skillW.push(level);
                    skillE.push(0);
                    skillR.push(0);
                    skillPlus.push(0);
                    wCount++;
                    if (wCount === wAllCount) {
                        successArr.push({
                            skill: 'W', icon: 2,
                        });
                    }
                } else if (item.skill_id === 3) {
                    skillQ.push(0);
                    skillW.push(0);
                    skillE.push(level);
                    skillR.push(0);
                    skillPlus.push(0);
                    eCount++;
                    if (eCount === eAllCount) {
                        successArr.push({
                            skill: 'E', icon: 3,
                        });
                    }
                } else if (item.skill_id === 4) {
                    skillQ.push(0);
                    skillW.push(0);
                    skillE.push(0);
                    skillR.push(level);
                    skillPlus.push(0);
                    rCount++;
                    if (rCount === rAllCount) {
                        successArr.push({
                            skill: 'R', icon: 4,
                        });
                    }
                }
            }
        });
        setSkills({skillQ, skillW, skillE, skillR, skillPlus});

        let countArr = [{
            skill: 'Q', count: qCount, icon: 1,
        }, {
            skill: 'W', count: wCount, icon: 2,
        }, {
            skill: 'E', count: eCount, icon: 3,
        },];
        countArr.sort(function (a, b) {
            return b.count - a.count;
        });

        if (successArr.length === 0) {
            // 모든 스킬 마스터 X
            setMaster(countArr);
        } else if (successArr.length === 3) {
            // 모든 스킬 마스터 O
            setMaster(successArr);
        } else {
            // 한개 or 두개 스킬 마스터 O
            let successSkill = '';
            let skillArr = [];
            successArr.forEach((item) => {
                successSkill += item.skill;
            });
            countArr.forEach((item) => {
                if (!successSkill.includes(item.skill)) {
                    skillArr.push(item);
                }
            });
            let newArr = successArr.concat(skillArr);
            setMaster(newArr);
        }

        /*** RUNE ***/
        let perkPrimary = {};
        let perkSub = {};
        runeArr.forEach((item) => {
            if (item.id === modalData.rune_detail_dict.perk_primary_style) {
                perkPrimary = item;
            } else if (item.id === modalData.rune_detail_dict.perk_sub_style) {
                perkSub = item;
            }
        });
        setRunes({perkPrimary, perkSub});
    }, [modalData, runeArr])

    useLayoutEffect(() => {
        initialFunc();
    }, [initialFunc,]);

    return (<Wrapper>
        <div className="builds">
            <h4>{t('modal.build.itemBuild')}</h4>
            <div className="build items">
                {minute.map((m, index) => {
                    return (<div className="items_per_min" key={index}>
                        <div className="items">
                            {modalData.item_seq_list.map((item, index) => m === item.minute && (
                                <span key={index} className={`${item.type}`}>
                            <ImageItem itemId={item.item_id}/>
                          </span>))}
                        </div>
                        <span className="times">{m}{t('summoner.minute')}</span>
                    </div>);
                })}
            </div>
            <h4>{t('modal.build.skillBuild')}</h4>
            <div className="build skills">
                {/* num : Q = 1, W = 2, E = 3, R = 4 */}
                {master.length !== 0 && (<div className="skills_m_seq">
                    {master.slice(0, 3).map((skill, index) => (<div className="skills" key={index}>
                        <ChampionSkillImage
                            reverse={true}
                            margin={'0 5px 0 0'}
                            width={36}
                            height={36}
                            spell={skillList?.spells[skill.icon - 1]}
                            version={version}
                            img={skillList?.spells[skill.icon - 1]?.image?.full}
                        />
                        <span className="texts">{skill.skill}</span>
                    </div>))}
                </div>)}
                {Object.values(skills).length !== 0 && (<div className="skills_seq">
                    <div className="qwer">
                        <span className="q">Q</span>
                        <span className="w">W</span>
                        <span className="e">E</span>
                        <span className="r">R</span>
                        <span className="plus">+</span>
                    </div>
                    <div className="qwer_per_level">
                        <div className="skills q">
                            {skills.skillQ.map((skill, index) => skill !== 0 ? (
                                <span className="selected" key={index}>
                          {skill}
                        </span>) : (<span key={index}></span>))}
                        </div>
                        <div className="skills w">
                            {skills.skillW.map((skill, index) => skill !== 0 ? (
                                <span className="selected" key={index}>
                          {skill}
                        </span>) : (<span key={index}></span>))}
                        </div>
                        <div className="skills e">
                            {skills.skillE.map((skill, index) => skill !== 0 ? (
                                <span className="selected" key={index}>
                          {skill}
                        </span>) : (<span key={index}></span>))}
                        </div>
                        <div className="skills r">
                            {skills.skillR.map((skill, index) => skill !== 0 ? (
                                <span className="selected" key={index}>
                          {skill}
                        </span>) : (<span key={index}></span>))}
                        </div>
                        <div className="skills plus">
                            {skills.skillPlus.map((skill, index) => skill !== 0 ? (
                                <span className="selected" key={index}>
                          {skill}
                        </span>) : (<span key={index}></span>))}
                        </div>
                    </div>
                </div>)}
            </div>
            <h4>{t('modal.build.runeBuild')}</h4>
            <div className="build runes">
                {Object.values(runes).length !== 0 && (<>
                    <div className="runes_pri_seq">
                        <div className="runes_title">
                            <ImageRune runeId={runes.perkPrimary.id}/>
                            <span>
                      {currentLang === 'KOR' ? runes.perkPrimary.name : runes.perkPrimary.key}
                    </span>
                        </div>
                        <div className="runes_row">
                            {runes.perkPrimary.slots.map((slot, index) => (<div className="runes" key={index}>
                                {slot.runes.map((rune) => modalData.rune_detail_dict.perk_0 === rune.id || modalData.rune_detail_dict.perk_1 === rune.id || modalData.rune_detail_dict.perk_2 === rune.id || modalData.rune_detail_dict.perk_3 === rune.id ? (
                                    <span className="selected" key={rune.key}>
                              <ImageRune runeId={rune.id}/>
                            </span>) : (<span key={rune.key}>
                              <ImageRune runeId={rune.id}/>
                            </span>))}
                            </div>))}
                        </div>
                    </div>
                    <div className="runes_sec_seq">
                        <div className="runes_title">
                            <ImageRune runeId={runes.perkSub.id}/>
                            <span>{currentLang === 'KOR' ? runes.perkSub.name : runes.perkSub.key}</span>
                        </div>
                        <div className="runes_row">
                            {runes.perkSub.slots.map((slot, index) => (<div className="runes" key={index}>
                                {slot.runes.map((rune) => modalData.rune_detail_dict.perk_4 === rune.id || modalData.rune_detail_dict.perk_5 === rune.id ? (
                                    <span className="selected" key={rune.key}>
                              <ImageRune runeId={rune.id}/>
                            </span>) : (<span key={rune.key}>
                              <ImageRune runeId={rune.id}/>
                            </span>))}
                            </div>))}
                        </div>
                        <div className="runes_row stats">
                            <div className="runes">
                                {currentRuneShards.slots[0].runes.map((data) => {
                                    return (
                                        <RuneShardLayout data={data} selectedRune={modalData.rune_detail_dict.stat_perk_2} key={data.id}/>
                                    )
                                })}
                            </div>
                            <div className="runes">
                                {currentRuneShards.slots[1].runes.map((data) => {
                                    return (
                                        <RuneShardLayout data={data} selectedRune={modalData.rune_detail_dict.stat_perk_1} key={data.id}/>
                                    )
                                })}
                            </div>
                            <div className="runes">
                                {currentRuneShards.slots[2].runes.map((data) => {
                                    return (
                                        <RuneShardLayout data={data} selectedRune={modalData.rune_detail_dict.stat_perk_0} key={data.id}/>
                                    )
                                })}
                            </div>

                        </div>
                    </div>
                </>)}
            </div>
        </div>
    </Wrapper>)
}


export default DetailBuild;
import {useCallback, useMemo, useState} from "react";
import {uniq} from "lodash";
import {sortChampData} from "../../function";
//TODO 룬코어 리셋

const laneSortValue = (position) => {
    switch (position) {
        case "bot":
            return 4;
        case "top":
            return 1;
        case "jungle":
            return 2;
        case "middle" :
            return 3;
        case "supporter":
            return 5;
        default:
            return 99
    }
}
const useMasteryFunc = (mastery, selectedLane) => {
    const [championIndex, setChampionIndex] = useState(0);
    const [coreSelected, onChangeCoreSelected] = useState(1);

    const onChangeChampion = useCallback((index) => {
        setChampionIndex(index);
        // setSelectedLane('All')
        onChangeCoreSelected(1);
    }, []);

    const onChangeVersion = useCallback((index) => {
        // setSelectedLane('All');
        onChangeCoreSelected(1);
    }, []);

    const onChangeLane = useCallback(() => {
        // setSelectedLane(lane);
        onChangeCoreSelected(1);
    }, []);

    const masteryList = useMemo(() => {
        try {
            if (Array.isArray(mastery) && mastery.length > 0) {
                return mastery;
            }
        } catch (e) {
            return [];
        }
    }, [mastery]);

    const totalGames = useMemo(() => {
        try {
            return masteryList.reduce((sum, cur) => {
                if (['top','middle','bot','jungle','supporter'].includes(cur.lane.toLowerCase())) {
                    sum += Number(cur.games)
                }
                return sum;
            }, 0);
        } catch (e) {
            return 0;
        }
    }, [masteryList]);


    const laneList = useMemo(() => {
        try {
            const list = uniq(masteryList.map((data) => data.lane));
            return ['ALL', ...list]

        } catch (e) {
            return [];
        }
    }, [masteryList, masteryList,]);

    const laneFilter = useMemo(() => {
        try {
            const list = masteryList.filter((data) => data.lane.toLowerCase() === selectedLane.toLowerCase());
            return list;
        } catch (e) {
            console.log(e);
            return [];
        }
    }, [masteryList, selectedLane]);

    const synergyWeakStrongList = useMemo(() => {
        try {
            const list = laneFilter[0];
            const synergyList = list.synergy_champion.map((data) => {
                return {
                    ...data,
                    enemy_champion_id: data.champion_id,
                }
            })
            return {
                easy_champion_list: list.easy_champion,
                synergy_champion_list: synergyList,
                difficult_champion_list: list.difficult_champion,
            }
        } catch (e) {
            return {
                easy_champion_list: [],
                synergy_champion_list: [],
                difficult_champion_list: [],
            };
        }
    }, [laneFilter])


    const coreList = useMemo(() => {
        try {
            const list = laneFilter.reduce((sum, data) => {
                return [...sum, ...data.build_detail];
            }, []);
            return sortChampData(list, [{value: "games", sortType: false}, {
                value: "win_rate",
                sortType: false
            }, {value: "core_item", sortType: true}]);
        } catch (e) {
            return [];
        }
    }, [laneFilter]);

    const combined = useCallback((list, findList, equals) => {
        list.forEach((data) => {
            const findIndex = findList.findIndex((item) => equals(item, data));
            if (findIndex > -1) {
                const item = findList[findIndex];
                findList[findIndex] = {...item, pick_rate: item.pick_rate + data.pick_rate, cnt: item.cnt + 1}
            } else {
                findList.push({...data, cnt: 1});
            }
        });
        return findList.map((boot) => {
            return {
                ...boot,
                pick_rate: boot.pick_rate / boot.cnt
            }
        });
    }, []);

    const combinedItem = useCallback((list, findList, itemName) => {
        list.forEach((data, index) => {
            const findIndex = findList.findIndex((item) => item[itemName] === data[itemName]);
            if (findIndex > -1) {
                const item = findList[findIndex];
                findList[findIndex] = {...item, pick_rate: item.pick_rate + data.pick_rate, cnt: item.cnt + 1}
            } else {
                findList.push({...data, cnt: 1});
            }
        });
        return findList.map((boot) => {
            return {
                ...boot,
                pick_rate: boot.pick_rate / boot.cnt
            }
        });
    }, []);

    const overview = useMemo(() => {
        const obj = {
            champion_id: 0,
            games: 0,
            win_rate: 0,
            wins: 0,
            lose: 0,
            rune: [],
            spell: [],
            mythic: [],
            core: [],
            shoes: [],
        }
        try {
            const reduce = laneFilter.reduce((sum, cur,) => {
                const combinedShoes = combinedItem(cur.boots, sum.shoes, 'boots');
                const combinedCore = combinedItem(cur.core_items, sum.core, 'item');
                const combinedMythic = combinedItem(cur.mythic_items, sum.mythic, 'item');
                const combinedSpell = combinedItem(cur.spells, sum.spell, 'spell')
                const combinedRunes = combined(cur.rune, sum.rune, (a, b) => a.rune_main === b.rune_main && a.rune_sub === b.rune_sub);
                const champion_id = masteryList?.[championIndex]?.champion_id;
                return {
                    ...sum,
                    champion_id: champion_id,
                    boundary: cur.lane_boundary,
                    games: sum.games + cur.games,
                    wins: sum.wins + cur.win_cnt,
                    lose: sum.lose + cur.loss_cnt,
                    rune: [...combinedRunes],
                    spell: [...combinedSpell],
                    mythic: [...combinedMythic],
                    core: [...combinedCore],
                    shoes: [...combinedShoes]
                }
            }, {
                ...obj
            });
            reduce.tier = laneFilter[0].tier
            reduce.rank = laneFilter[0].rank
            reduce.rank_delta = laneFilter[0].rank_delta
            reduce.ban_rate = (laneFilter[0].ban_rate * 100).toFixed(1)
            reduce.win_rate = (laneFilter[0].win_rate * 100).toFixed(1)
            reduce.pick_rate = (laneFilter[0].pick_rate * 100).toFixed(1)
            return reduce
        } catch (e) {
            console.log("error", e);
            return obj;
        }

    }, [laneFilter]);

    const lanePick = useMemo(() => {
        try {
            const pickList = masteryList.reduce((sum, cur) => {
                if (cur.lane !== 'All') {
                    sum[cur.lane] = {

                        lane: cur.lane,
                        games: cur.games + (sum[cur.lane]?.games || 0),
                    };
                }

                return sum;
            }, {});

            const sortedList = Object.values(pickList).sort((a, b) => {
                const aLaneValue = laneSortValue(a.lane.toLowerCase())
                const bLaneValue = laneSortValue(b.lane.toLowerCase())
                if (aLaneValue > bLaneValue) {
                    return 1;
                } else if (aLaneValue === bLaneValue) {
                    return 0;
                } else if (aLaneValue < bLaneValue) {
                    return -1;
                }
            });
            return sortedList.map((data) => {
                return {
                    ...data,
                    pick_rate: (data.games / totalGames * 100).toFixed(0)
                }
            })

        } catch (e) {
            return [];
        }
    }, [overview]);

    return {
        mastery: masteryList,
        laneList,
        onChangeVersion,
        selectedLane,
        coreList,
        overview,
        lanePick,
        championIndex,
        onChangeChampion,
        coreSelected,
        onChangeCoreSelected,
        onChangeLane,
        synergyWeakStrongList,
    };
}


export default useMasteryFunc;
import React from 'react';
import {useTranslation} from "react-i18next";
import styled from "styled-components";


const WinLoseBox = styled.div`
  font-size: 13px;
  color: ${props => {
    let color = props.theme.color.font_fff_f1
    if (props.isWin) color = props.theme.color.font_fff_33
    return color;
  }};
  font-weight: 500;
  padding: 4px 5px;
  background-color: ${props => {
    let color = props.theme.color.background_D5_3f
    if (props.isWin) color = props.theme.color.background_4E_1A
    return color;
  }};
  border-radius: 5px;
`;

const TableWinLose = ({isWin}) => {
    const {t} = useTranslation();
    return (
        <WinLoseBox isWin={isWin}>{isWin ? t('summoner.win') : t('summoner.lose')}</WinLoseBox>
    );
};

export default TableWinLose;
import React, {useMemo} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

import {bookmarkList} from '../../store/modules/bookmarkList';
import {StorageContainer} from '../../styled/CommonStyle';
import styled from "styled-components";
import TierTag from "./TierTag";
import InfluIcon from "../summoner/Profile/InfluInfo/InfluIcon";

const NodataWrapper = styled.div`
  color: ${props => props.theme.color.default_gray_font_color};
  background-color: ${props => props.theme.color.default_input_background};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
`;

const RegionTagWrapper = styled.div`
  .tier_badge {
    display: flex;
    align-content: center;
    justify-content: center;
    width: 24px;
    height: 14px;
    line-height: 15px;
    font-size: 11px;
    border-radius: 5px;

    &.challenger {
      font-size: 11px;
    }

    &.grandmaster {
      width: 24px;
      font-size: 11px;

    }

    &.master {
      font-size: 11px;
    }
  }

  margin-left: 3px;
`;

const TagText = styled.span`
  color: ${props => props.theme.color.font_7F};;
  font-size: 14px;
  margin-left: 5px;
`;

const BookmarkList = ({fullWidth, onSelect}) => {
    const dispatch = useDispatch();
    const bookmarks = useSelector((state) => state.bookmarkList.bookmarks);
    const region = useSelector((state) => state.region.result);
    const viewBookmarks = useMemo(() => [...bookmarks].reverse().slice(0, 10), [bookmarks]);
    const {t} = useTranslation();

    // 즐겨찾기 항목 제거
    const removeBookmark = (item) => {
        dispatch(bookmarkList(item, 'remove'));
    };


    return (
        <StorageContainer fullWidth={fullWidth} className={'searchListContainer'}>
            {bookmarks.length !== 0 ? (
                <ul className="bookmark_list">
                    {viewBookmarks.map((item, index) => {
                        if (item.type === 'influencer') {
                            return (
                                <li key={index}>
                                    <InfluIcon status={item.status}/>
                                    <p className="txt" onClick={onSelect}>
                                        <Link
                                            to={`/${item.url_tag}/${item.url_name}`}
                                            className="common_search">
                                            {item.name}
                                        </Link>
                                    </p>
                                    <button className="remove" onClick={() => removeBookmark(item)}>
                                        {t('buttons.delete')}
                                    </button>
                                </li>
                            )
                        }
                        return (
                            <li key={index}>
                                <RegionTagWrapper>
                                    <TierTag
                                        fontSize={'11px'}
                                        padding={'4px 4px'}
                                        tier={item?.season_tier_info_dict?.ranked_solo_5x5?.tier}
                                        division={item?.season_tier_info_dict?.ranked_solo_5x5?.division}
                                    />
                                </RegionTagWrapper>
                                <p className="txt" onClick={onSelect}>
                                    <Link
                                        to={`/summoner/${item.region ? item.region : region}/${item?.summoner_basic_info_dict?.riot_id_name}-${item?.summoner_basic_info_dict?.riot_id_tag_line}`}
                                        className="common_search">
                                        {decodeURI(item?.summoner_basic_info_dict?.riot_id_name)}<TagText>#{decodeURI(item?.summoner_basic_info_dict?.riot_id_tag_line)}</TagText>
                                    </Link>
                                </p>
                                <button className="remove" onClick={() => removeBookmark(item)}>
                                    {t('buttons.delete')}
                                </button>
                            </li>
                        )
                    })}
                </ul>
            ) : (
                <NodataWrapper>
                    <p className="no_list">{t('persist.noFavorite')}</p>
                </NodataWrapper>
            )}
        </StorageContainer>
    );
};

export default BookmarkList;

import React, {useCallback, useEffect, useState} from 'react';
import styled from "styled-components";
import {getIngameTime} from "../../../../../function";

const TimerBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 6px;
`;

const TimerText = styled.span`
  font-size: 12px;
  font-weight: 500;
`;

const LiveBox = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px 7px;
  background-color: #EA5A5A;
  box-shadow: 0px 1px 3px #00000029;
  border-radius: 5px;
  margin-right: 8px;
`;

const LiveText = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${props => props.theme.color.default_gray_font_color_white};
  letter-spacing: 1px;
`;

const LiveBlock = ({initTimer}) => {

    const [ingameTime,setIngameTime] = useState(Math.trunc((Date.now() / 1000)) - initTimer);

    const getTime = useCallback(() => {
        setIngameTime(prev => prev + 1);
    }, []);

    useEffect(() => {
        let interval = setInterval(() => {
            getTime();
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, []);


    return (
        <TimerBox>
            <LiveBox>
                <LiveText>LIVE</LiveText>
            </LiveBox>
            <TimerText>{getIngameTime(ingameTime)}</TimerText>
        </TimerBox>
    );
};

export default LiveBlock;
import React from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.flexD};
  align-items: ${props => props.alignItems};
  width: 100%;
  border-radius: 3px;
  background-color: ${props => props.theme.color.summoner.tab.background_color};
  border: 1px solid ${props => props.theme.color.default_border_color};
  justify-content: space-between;
  padding: 13px;
  margin-bottom: 9px;
`;

const TitleText = styled.span`
  color: ${props => props.theme.color.default_gray_font_color};
  font-size: 16px;
  font-weight: 500;
`;

const ChampionsHeader = ({title, children,flexD = 'row',alignItems='center'}) => {
    return (
        <Wrapper flexD={flexD} alignItems={alignItems}>
            {title && <TitleText>{title}</TitleText>}
            {children}
        </Wrapper>
    );
};



export default React.memo(ChampionsHeader);

import React from 'react';
import styled from "styled-components";

const Wrapper = styled.span`
  color: ${props => {
    let color = props.theme.color.font_69;
    if (props.value >= 75) {
      color = props.theme.color.font_F19;
    } else if (props.value >= 50) {
      color = '#259A7E';
    } else if (props.value >= 25) {
      color = '#DE5353';
    }
    return color;
  }}
`;
const DifferentScoreColor = ({value}) => {
    return (
        <Wrapper value={value}>
            {value}
        </Wrapper>
    );
};

export default DifferentScoreColor;
import React from 'react';
import styled from "styled-components";
import RankingTableContent from "./RankingTableContent";
import {useHistory} from "react-router-dom";
import {Loading} from "../../../common";
import InternalServerError from "../../../common/InternalError";

const Wrapper = styled.div`
  border-left: 1px solid ${props => props.theme.color.champion.border};
  border-right: 1px solid ${props => props.theme.color.champion.border};
  border-bottom: 1px solid ${props => props.theme.color.champion.border};
  margin-top: 5px;
`;

const ErrorBox = styled.div`
  border: 1px solid ${props => props.theme.color.champion.border};
  margin-top: 5px;
`;

const LoadingWrapper = styled.div`
  width: 100%;
`;


const RankingTable = ({rankingRegion, currentLane, ranking, getRankingDone, version, getRankingError}) => {
    const history = useHistory();


    if (getRankingError) {
        return (
            <ErrorBox>
                <InternalServerError
                    errorText={getRankingError?.response.data?.msg}
                />
            </ErrorBox>
        );
    }
    if (!getRankingDone) {
        return (
            <LoadingWrapper>
                <Loading/>
            </LoadingWrapper>
        )
    }

    return (
        <Wrapper>
            <RankingTableContent
                version={version}
                region={rankingRegion?.title}
                rankingList={ranking?.summoner_rank_list}
            />
        </Wrapper>
    );
};

export default RankingTable;

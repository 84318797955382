import React from 'react';
import styled, {useTheme} from "styled-components";
import {FlexBox} from "../../../ui/styled";
import DamageGraph from "../GameAnalytics/GameSummary/Table/TableContent/DamageGraph";
import ItemInfo from "../GameAnalytics/GameSummary/Table/TableContent/ItemInfo";
import SummarySummonerInfo from "../GameAnalytics/GameSummary/Table/TableContent/SummarySummonerInfo";
import SwarmImage from "../../../common/SwarmImage";
import {threedigits} from "../../../../function";


const Wrapper = styled.tr`
  height: 42px;
  background-color: ${props => {
    let color = 'transparent';
    if (props?.isme) {
      if (props.win) color = props.theme.color.background_B3D;
      if (!props.win) color = props.theme.color.background_EBC;
    } else {
      if (props.win) color = props.theme.color.background_win;
      if (!props.win) color = props.theme.color.background_lose;
    }

    if (props.hide) {
      if (props.win) color = props.theme.color.background_win;
      if (!props.win) color = props.theme.color.background_lose;
    }
    return color;
  }};

  border-top: 1px solid ${props => props.borderColor};
`;


const TableChild = styled.td`
  vertical-align: middle;
`;


const DamageWrapper = styled.div`
  width: 68px;
`;
const DamageBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const KillBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 12px;
  width: 50px;
`;

const KillText = styled.span`
  color: ${props => props.theme.color.font_58_A7};
`;

const SwarmAnalyticsTableContent = ({isme, data, hide, region, allDamage}) => {
    console.log(data)
    const theme = useTheme();
    return (
        <Wrapper isme={isme} win={data.is_win} hide={hide} className={'arena_item'}
                 borderColor={theme.color.champion.border}>
            <TableChild>
                <FlexBox width={'250px'}>
                    <SummarySummonerInfo
                        disablePerk={true}
                        disableBar={true}
                        hide={hide}
                        smite={data.smite}
                        data={data}
                        region={region}
                        isme={hide ? false : isme}
                        tier={data.tier}
                        division={data.division}
                        level={data.final_stat_dict.champion_level}
                        champId={data.champion_id}
                        win={data.is_win}
                        summonerName={data.riot_id_name}
                        tag={data.riot_id_tag_line}
                        puuId={data.puu_id}
                    />
                </FlexBox>
            </TableChild>
            <TableChild>
                <FlexBox justifyContent={'center'} width={'198px'}>
                    <SwarmImage id={data.arena_augments.playerAugment1} width={24} height={24}/>
                    <SwarmImage id={data.arena_augments.playerAugment2} width={24} height={24}/>
                    <SwarmImage id={data.arena_augments.playerAugment3} width={24} height={24}/>
                    <SwarmImage id={data.arena_augments.playerAugment4} width={24} height={24}/>
                    <SwarmImage id={data.arena_augments.playerAugment5} width={24} height={24}/>
                    <SwarmImage id={data.arena_augments.playerAugment6} width={24} height={24}/>
                </FlexBox>
            </TableChild>
            <TableChild>
                <DamageBox>
                    <DamageWrapper>
                        <DamageGraph
                            dmg={data.swarm_total_damage_dealt}
                            total={allDamage?.totaldmg}
                            color={theme.color.font_74_EA}
                            barColor={'#EF4D8A'}
                        />
                    </DamageWrapper>
                </DamageBox>
            </TableChild>
            <TableChild>
                <KillBox>
                    <KillText>{threedigits(data?.final_stat_dict?.cs)}</KillText>
                </KillBox>
            </TableChild>
            <TableChild>
                <KillBox>
                    <KillText>{threedigits(data?.total_gold)}</KillText>
                </KillBox>
            </TableChild>
            <TableChild>
                <FlexBox width={'150px'} justifyContent={'center'}>

                    <ItemInfo
                        isSwarm={true}
                        disable={true}
                        myMatch={data}
                        item={data.final_item_dict}
                    />

                </FlexBox>
            </TableChild>
        </Wrapper>
    );
};

export default SwarmAnalyticsTableContent;
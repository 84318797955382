import React, {useCallback} from 'react';
import styled from "styled-components";
import RankingHeaderLP from "./RankingHeaderLP";
import {useTranslation} from "react-i18next";
import useTimeHook from "../../../../common/hooks/useTimeHook";
import SponsorAD from "../../../../ad/Tapin";
import RankingTableFilter from "../RankingTable/RankingTableFilter";
import {capitalizeLetter} from "../../../../function";
import {useHistory} from "react-router-dom";

const Wrapper = styled.div`
  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;
const Title = styled.span`
  font-size: 26px;
  font-weight: 500;
  line-height: 37px;
`;
const ExtraText = styled.p`
  color: ${props => props.theme.color.default_gray_font_color};
  margin-top: 2px;
  opacity: 0.6;
  line-height: 20px;
  margin-bottom: 17px;
`;


const UpdatedTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
const UpdatedText = styled.span`
  color: ${props => props.theme.color.font_7F_EA};
  font-size: 12px;
  line-height: 17px;
  margin-bottom: 5px;
`;

const RankingHeader = ({rankingRegion, challengerLp, grandMasterLp, time, ranking, currentLane}) => {
    const {t} = useTranslation();
    const {lessMinWithIntl} = useTimeHook()
    const history = useHistory();

    const onClickLane = useCallback((lane) => {
        if (typeof lane === 'string') {
            if (lane !== currentLane) {
                history.push(`/ranking/${rankingRegion.title}/${lane.toLowerCase()}`)
            }
        }

    }, [currentLane, rankingRegion.title]);

    const onClickRegion = useCallback((event) => {
        const region = event.target.value;
        if (typeof region === 'string') {
            if (region !== rankingRegion.title) {
                history.push(`/ranking/${region}/${currentLane.toLowerCase()}`)
            }
        }

    }, [currentLane, rankingRegion]);



    return (
        <Wrapper>
            <TitleWrapper>
                <Title>{t("ranking.soloRank")}</Title>
            </TitleWrapper>
            <ExtraText>{t('ranking.rankingInfo')}</ExtraText>
            <RankingTableFilter
                botRate={ranking?.rate_bot}
                jugRate={ranking?.rate_jug}
                midRate={ranking?.rate_mid}
                supRate={ranking?.rate_sup}
                topRate={ranking?.rate_top}
                currentLane={capitalizeLetter(currentLane)}
                setCurrentLane={onClickLane}
                rankingRegion={rankingRegion}
                onClickRegion={onClickRegion}
            />
            <UpdatedTextWrapper>
                {time &&
                    <UpdatedText>{t("ranking.updateInfo", {time: lessMinWithIntl(Date.parse(time))})}</UpdatedText>}
            </UpdatedTextWrapper>
            {typeof ranking.challenger_cnt !== "undefined" &&
                <RankingHeaderLP
                    challenger={{
                        lp: ranking?.challenger_cut_off,
                        graph: ranking?.challenger_y,
                        ratio: ranking?.challenger_ratio,
                        cnt: ranking?.challenger_cnt,
                        yesterday: ranking?.challenger_y?.at(-1),
                    }}
                    grandMaster={{
                        lp: ranking?.grandmaster_cut_off,
                        graph: ranking?.grandmaster_y,
                        ratio: ranking?.grandmaster_ratio,
                        cnt: ranking?.grandmaster_cnt,
                        yesterday: ranking?.grandmaster_y?.at(-1),
                    }}
                    graphX={[...ranking.graph_x,new Date()]}
                    rankingRegion={rankingRegion}
                />}
            <SponsorAD/>
        </Wrapper>
    );
};

export default RankingHeader;

import React from 'react';
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import styled from "styled-components";


const Wrapper = styled.div`
  height: 360px;
  background-color: ${props => props.theme.color.default_input_background};
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
`;

const CenterBox = styled.div`
    display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const Text = styled.p`
  line-height: 30px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
`;


const IngameNotPlaying = () => {
    const summoner = useSelector((state) => state.summoner.summoner);
    const {t} = useTranslation();

    return (
        <Wrapper>
            <CenterBox>
                <Text>
                    {summoner.riot_id_name} {t('ingame.notPlaying1')} <br/>
                    {t('ingame.notPlaying2')}
                </Text>
            </CenterBox>
        </Wrapper>
    );
};

export default IngameNotPlaying;

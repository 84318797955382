import React, {lazy, Suspense, useCallback, useState} from 'react';
import styled from "styled-components";
import ChampionsHeader from "../ChampionsHeader";
import ChampionsWhiteWrapper from "../ChampionsWhiteWrapper";
import SearchListByLane from "./SearchListByLane";
import ChampionsCname from "./ChampionsCName";
import ChampionsInput from "../ChampionsInput";
import {Loading} from "../../../common";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useHistory, useLocation} from "react-router-dom";

const ChampionsList = lazy(() => import("./ChampionsList"));


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 82%;
`;

const ChampionsSearch = () => {
    const history = useHistory();
    const location = useLocation();
    const {t} = useTranslation();
    const [choVisible, setChoVisible] = useState(false);
    const [indivisualVisible, setindivisualVisible] = useState('');
    const [laneVisible, setLaneVisible] = useState('all');
    const championData = useSelector((state) => state?.imageStore.champions);

    const onClickCname = useCallback((value) => {
        if (value !== undefined && (typeof value === 'string' || typeof value === 'boolean')) {
            setChoVisible(value);
        }
    }, []);

    const onChangeINidivisualVisible = useCallback((value) => {
        setindivisualVisible(value)
    }, []);

    const onClickLane = useCallback((index, value) => {
        if (typeof value === 'string') {
            const result = value.toLowerCase()
            setLaneVisible(result);
        }

    }, []);

    const onClickChamp = useCallback((champName) => {
        if (laneVisible === 'rotation' || laneVisible === 'all') {
            history.push({
                pathname: `/champions/${champName}/build`,
                search: location.search
            });
        } else {

            history.push({
                pathname: `/champions/${champName}/build/${laneVisible}`,
                search: location.search
            });
        }
    }, [location.search, laneVisible]);


    return (
        <Wrapper>
            <ChampionsHeader flexD={'column'} alignItems={'stretch'}>
                <ChampionsInput
                    inputValue={indivisualVisible}
                    setInputValue={onChangeINidivisualVisible}
                />
                <SearchListByLane
                    onClick={onClickLane}
                />
                <ChampionsCname
                    onClick={onClickCname}
                />
            </ChampionsHeader>

            <ChampionsWhiteWrapper>
                <Suspense fallback={<Loading/>}>
                    <ChampionsList
                        laneVisible={laneVisible}
                        championData={championData}
                        inidivisualVisible={indivisualVisible}
                        choVisible={choVisible}
                        onClickChamp={onClickChamp}
                    />
                </Suspense>
            </ChampionsWhiteWrapper>
        </Wrapper>
    );
};

export default ChampionsSearch;

import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import FilterArrow from "../../../../common/FilterArrow";

const TitleUl = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 14px;

  li:last-child {
    margin-right: ${props => {
      let right = 0;
      if (props.length > 10) right = '6px';
      return right;
    }};
  }
`;

const TitleLi = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.color.default_gray_font_color};
  flex: ${props => props.flex || 1};
  text-align: center;
`;

const SynergyLi = styled(TitleLi)`
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const SynergySpan = styled.span`
  opacity: 0.6;
`;





const ChampionTitleLi = styled.li`
  flex: ${props => props.flex || 1};
`;


const AgainstTableHeader = ({
                                currentSort,
                                sort,
                                onClickHeader,
                                CHAMPION_TABLE_FLEX,
                                GAMES_TABLE_FLEX,
                                WINRATE_TABLE_FLEX,
                                SYNERGY_TABLE_FLEX,
                                length
                            }) => {
    const {t} = useTranslation();


    return (
        <TitleUl length={length}>
            <ChampionTitleLi flex={CHAMPION_TABLE_FLEX}>
                <SynergySpan>{t('tabs.champions')}</SynergySpan>
            </ChampionTitleLi>
            <SynergyLi flex={GAMES_TABLE_FLEX} onClick={() => onClickHeader(sort.cnt)}>
                <SynergySpan>{t('mastery.games')}</SynergySpan>
                <FilterArrow
                    selected={currentSort[0].value === sort.cnt.value}
                    arrow={sort.cnt.sortType}
                />
                {/*<ArrowImage*/}
                {/*    width={10}*/}
                {/*    height={11}*/}
                {/*    src={ ? '/images/ico_align_arrow_down.svg' : '/images/ico_align_arrow_down_dim.svg'}/>*/}
            </SynergyLi>
            <SynergyLi flex={WINRATE_TABLE_FLEX} onClick={() => onClickHeader(sort.win_rate)}>
                <SynergySpan>{t('championDetailBuild.wr')}</SynergySpan>
                <FilterArrow
                    selected={currentSort[0].value === sort.win_rate.value}
                    arrow={sort.win_rate.sortType}
                />
                {/*<ArrowImage*/}
                {/*    width={10}*/}
                {/*    height={11}*/}
                {/*    src={arrowStatus(sort.win_rate?.reverse,sort.win_rate.sortType) ? '/images/ico_align_arrow_down.svg' : '/images/ico_align_arrow_down_dim.svg'}/>*/}
            </SynergyLi>
            {typeof SYNERGY_TABLE_FLEX === 'number' &&
                <SynergyLi flex={SYNERGY_TABLE_FLEX} onClick={() => onClickHeader(sort.synergy)}>
                    <SynergySpan>{t('championDetailBuild.synergy')}</SynergySpan>
                    <FilterArrow
                        selected={currentSort[0].value === sort.synergy.value}
                        arrow={sort.synergy.sortType}
                    />
                    {/*<ArrowImage*/}
                    {/*    width={10}*/}
                    {/*    height={11}*/}
                    {/*    src={arrowStatus(sort.synergy.reverse,sort.synergy.sortType) ? '/images/ico_align_arrow_down.svg' : '/images/ico_align_arrow_down_dim.svg'}/>*/}

                </SynergyLi>
            }
        </TitleUl>
    );
};

export default AgainstTableHeader;
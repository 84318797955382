import React from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${props => props.theme.color.default_gray_font_color};
  height: 50px;
  align-items: center;
  padding: 0 15px;
`;
const Title = styled.span`
  font-size: 16px;
  font-weight: 500;
`;
const SummonerSearchTitle = ({children, title}) => {
    return (
        <Wrapper>
            <Title>
                {title}
            </Title>
            <div>
                {children}
            </div>
        </Wrapper>
    );
};

export default SummonerSearchTitle;
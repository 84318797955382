import React from 'react';
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.color.default_input_background};
  width: 100%;
  height: 100%;
`;

const EmptyText=  styled.span`
    font-size: 20px;
  font-weight: bold;
`;

const EmptyWithText = ({text}) => {
    return (
        <Wrapper>
            <EmptyText>
                {text}
            </EmptyText>
        </Wrapper>
    );
};

export default EmptyWithText;
import React from 'react';
import styled from "styled-components";

const ImgBox = styled.button`
  &:hover {
    scale: 1.2;
    transition: scale .2s ease-in-out;
  }
`;
const SnsImage = ({influData, width = 20, height = 20,iconScale}) => {

    const snsSrc = [
        {
            title: 'kick',
            src: '/images/ico_sns_kick.svg',
            url: influData?.kick,
        },
        {
            title: 'chzzk',
            src: '/images/ico_sns_chzzk.svg',
            url: influData?.chzzk,
        },
        {
            title: 'twitter',
            src: '/images/icn_sns_x.svg',
            url: influData?.twitter,
        },
        {
            title: 'youtube',
            src: '/images/ico_sns_yout.svg',
            url: influData?.youtube,
        },
        {
            title: 'africa',
            src: '/images/ico_sns_afri.svg',
            url: influData?.afreecatv,
        },
        {
            title: 'twitch',
            src: '/images/ico_sns_twi.svg',
            url: influData?.twitch,
        },
        {
            title: 'leaguepidia',
            src: '/images/ico_sns_leag.svg',
            url: influData?.leaguepedia,
        }];

    return snsSrc.map((data, index) => {
        if (data.url !== '-' && data.url) {
            const onClickImgBox = (e) => {
                e.stopPropagation();
                window.open(data.url, "_blank",);
            }
            return (
                <ImgBox key={'indlue' + index} onClick={onClickImgBox} className={'imgBox'} iconScale={iconScale}>
                    <img
                        height={height}
                        width={width}
                        src={data.src}
                        alt={data.title}
                    />
                </ImgBox>
            )
        } else {
            return <React.Fragment key={index + 'unknown'}></React.Fragment>
        }
    })
}

export default SnsImage;
import React, {useMemo} from 'react';
import styled from "styled-components";
import SvgArrow from "../../../../../common/SVGArrow";
import RankingTableNavigatorArrow from "./RankingTableNavigatorArrow";
import DownArrow from "../../../../../common/TagWithArrow/DownArrow";

const Wrapper = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;


const ArrowWrapper = styled.div`
  margin-inline: 3px;
`;



const ListItem = styled.button`
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  margin: 0 6px;
  width: 24px;
  aspect-ratio: 1;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  background-color: ${props => {
    let color = props.theme.color.background_164164;
    if (props.selected) color = props.theme.color.background_164_EA;
    return color;
  }};
  color: ${props => {
    let color =  props.theme.color.font_fff_EAop5;
    if (props.selected) color = props => props.theme.color.default_gray_font_color_white;
    return color;
  }};

  &:hover {
    background-color: ${props => {
      let color = props.theme.color.background_164;
      if (props.selected) color = props.theme.color.background_1641;
      return color;
    }};
  }
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 9px;
`;


const RankingTableNavigator = ({
                                   currentPage,
                                   lastPage,
                                   onClickNext,
                                   onClickPrev,
                                   onClickToEnd,
                                   onClickToFist,
                                   onClickGoToPage
                               }) => {


    const visiblePageList = useMemo(() => {
        const BASE_LENGTH = 10;
        const startNumber = Math.floor((currentPage - 1) / 10) * 10;
        let listLength = BASE_LENGTH;
        if (BASE_LENGTH + startNumber > lastPage) {
            listLength =  lastPage - startNumber;
        }
        if(listLength > 0) {
            return Array.from(new Array(listLength), (_, index) => 1 + index + startNumber);
        }
        return [];
    }, [currentPage,lastPage]);


    return (
        <Wrapper>
            <ArrowWrapper>
                <RankingTableNavigatorArrow
                    onClick={onClickToFist}
                    Arrow={DownArrow}
                    matrix={'0, 1, -1, 0, 0, 0'}
                    disabled={currentPage === 1}
                />
            </ArrowWrapper>
            <ArrowWrapper>
                <RankingTableNavigatorArrow
                    onClick={onClickPrev}
                    Arrow={SvgArrow}
                    matrix={'0, 1, -1, 0, 0, 0'}
                    disabled={currentPage === 1}
                />
            </ArrowWrapper>
            <ListWrapper>
                {visiblePageList.map((data) => {
                    return (
                        <ListItem key={"list" + data} selected={data === currentPage}
                                  onClick={() => onClickGoToPage(data)}>
                            {data}
                        </ListItem>
                    )
                })}
            </ListWrapper>
            <ArrowWrapper>
                <RankingTableNavigatorArrow
                    onClick={onClickNext}
                    Arrow={SvgArrow}
                    matrix={'0, -1, 1, 0, 0, 0'}
                    disabled={Number(currentPage) >= Number(lastPage)}
                />
            </ArrowWrapper>
            <ArrowWrapper>
                <RankingTableNavigatorArrow
                    onClick={onClickToEnd}
                    Arrow={({color}) => <DownArrow size={3} color={color}/>}
                    matrix={'0, -1, 1, 0, 0, 0'}
                    disabled={Number(currentPage) >= Number(lastPage)}
                />
            </ArrowWrapper>
        </Wrapper>
    );
};

export default RankingTableNavigator;

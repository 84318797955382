import React from 'react';
import styled from "styled-components";

const Text = styled.span`
  color: ${props => {
    let color = props.theme.color.font_69;
    if (props.children >= 75) color = props.theme.color.font_F19;
    else if (props.children >= 50) color = props.theme.color.font_25;
    else if (props.children >= 25) color = props.theme.color.font_DE;
    if(props.staticColor && typeof props.staticColor === 'string') color = props.staticColor;
    return color;
  }};
  font-weight: ${props => {
    let weight = 'normal';
    if (props.fontWeight) weight = props.fontWeight;
    return weight;
  }};
  font-size: ${props => {
    let size = '14px';
    if (props.fontSize) size = props.fontSize;
    return size;
  }};
`;

const WinRateText = ({children, fontWeight, fontSize,staticColor}) => {
    return (
        <Text fontWeight={fontWeight} fontSize={fontSize} staticColor={staticColor}>{children}</Text>
    );
};

export default WinRateText;
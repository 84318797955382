import React, {useEffect} from 'react';
import {krAdStart, playwire_destory, playwire_start} from "../../ad/bidrich";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";

const UseAdHooks = (playwire, option) => {
    const country = useSelector((state) => state?.imageStore.country);
    const location = useLocation();


    const disablePageBanner = () => {
        if (location.pathname.indexOf('strm/PzZZang') > -1) {
            return false
        }
        return true;
    }

    useEffect(() => {
        if (!option?.disableTop) {
            window.scroll(0, 0);
        }
        if (country === 'KR') {
            if (disablePageBanner()) {
                setTimeout(() => krAdStart(), 100);
            }
        } else {
            playwire_start(playwire);
        }

        return () => {
            if (country !== 'KR') {
                playwire_destory(playwire);
            }
        }
    }, [country]);
};

export default UseAdHooks;
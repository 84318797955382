import React, {useCallback} from 'react';
import styled from "styled-components";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import ChampionsHeader from "../../../Champions/ChampionsContent/ChampionsHeader";
import ChampionsWhiteWrapper from "../../../Champions/ChampionsContent/ChampionsWhiteWrapper";
import MasteryRankTable from "./MasteryRankTable";
import {ErrorBoundary} from "react-error-boundary";
import {useHistory} from "react-router-dom";
import MasteryRegion from "./MasteryRegion";
import {Loading} from "../../../common";
import useQuery from "../../../../common/hooks/useQuery";
import useTitleAndDescription from "../../../../common/hooks/useTitleAndDescription";


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
  flex: 1 1 0;
`;

const LaneWrapper = styled.div`
  margin-inline: 10px;
`;

const LoadingWrapper = styled.div`
  height: 200px;
  width: 100%;
`;


const MasteryRank = () => {
    const {
        getMasteryRankingLoading,

    } = useSelector((state) => state.champions);
    const history = useHistory();
    const {t} = useTranslation();
    const query = useQuery();
    const currentRegion = query.get("region");
    const ranking = useSelector((state) => state.champions.masteryGlobal);
    const regionList = ['All','KR','NA','EUW','BR','VN'];
    const onSelectRegion = useCallback((region) => {
        history.replace(`/mastery?region=${region}`);
    }, []);


    useTitleAndDescription({
        title: t('header.titleOtp',{
            server: currentRegion === 'all' ? '' : currentRegion?.toUpperCase()
        }),
        description: t('header.titleOtpDescription',{
            server: currentRegion === 'all' ? '' : currentRegion?.toUpperCase()
        })
    })


    return (
        <Wrapper>
            <ChampionsHeader
                title={t('mastery.rankingHeader')}
            >
            </ChampionsHeader>
            <ChampionsWhiteWrapper>
                <LaneWrapper>
                    <MasteryRegion regionList={regionList} onClick={onSelectRegion}/>
                </LaneWrapper>
                {getMasteryRankingLoading ? <LoadingWrapper><Loading/></LoadingWrapper> :
                    <ErrorBoundary fallback={<>Something went wrong...</>}>
                        <MasteryRankTable
                            regionList={regionList}
                            pageValue={100}
                            ranks={ranking}
                        />
                    </ErrorBoundary>}
            </ChampionsWhiteWrapper>
        </Wrapper>
    );
};

export default MasteryRank;

import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import styled, {useTheme} from "styled-components";
import {monthsForLocale} from "../../function";
import {useTranslation} from "react-i18next";
import _, {range} from "lodash";

const ChartWrapper = styled.div`
  position: relative;

  .highcharts-tick {
    fill: rgba(255, 168, 80, 1);
    fill-opacity: 1;
    stroke-width: 0;
  }

  .highcharts-tooltip {
    z-index: 999999 !important;
  }
`;

const AbsoluteBar = styled.div`
  position: absolute;
  background-color: #000;
  opacity: 0.5;
  bottom: 23px;
  left: 37px;
  width: ${props => {
    let width = '454px';
    if (props.width) width = props.width + "px";
    return width;
  }};
  height: 1px;
  z-index: 0;
`;

const ChartLPBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 100%;
  font-size: 18px;
`;
const ChartRanking = ({challengerSeries, grandMasterSeries, dateList, width = 265, height = 180}) => {
    const {t} = useTranslation()
    const theme = useTheme();
    const {i18n} = useTranslation();

    const rangeData = range(_.min([...challengerSeries, ...grandMasterSeries]), _.max([...challengerSeries, ...grandMasterSeries]) + 200, 200);
    console.log(challengerSeries)
    const options = {
        // 하이차트 옵션
        chart: {
            marginBottom: 24,
            marginRight: 9,
            type: 'area',
            plotBackgroundColor: theme.color.background_255234,
            backgroundColor: 'none',
            plotBorderWidth: 0,
            spacingLeft: 0,
            spacingRight: 0,
            spacingBottom: 0,
            spacingTop: 5,
            width: width,
            height: height,
        },
        credits: {
            enabled: false,
        },
        title: {
            text: '',
        },
        series: [
            {
                pointStart: new Date(dateList[0]).getTime(),
                pointInterval: 24 * 3600 * 1000,
                type: 'line',
                showInLegend: false,
                name: 'challenger graph',
                data: challengerSeries,
                lineWidth: 2,
                lineColor: '#FD8A12',
                marker: {
                    symbol: 'circle',
                    lineColor: "#fff",
                    lineWidth: 2,
                    enabled: false,
                    fillColor: '#FD8A12',
                    radius: 4,
                },
            },
            {
                pointStart: new Date(dateList[0]).getTime(),
                pointInterval: 24 * 3600 * 1000,
                type: 'line',
                showInLegend: false,
                name: 'grandmaster graph',
                data: grandMasterSeries,
                lineWidth: 2,
                lineColor: '#C23449',
                marker: {
                    symbol: 'circle',
                    lineColor: "#fff",
                    lineWidth: 2,
                    enabled: false,
                    fillColor: '#C23449',
                    radius: 4,
                },
            },
        ],
        xAxis: {
            type: 'datetime',
            tickInterval: 24 * 3600 * 1000 * 30,
            labels: {
                style: {
                    textOverflow: "ellipsis",
                    fontFamily: 'Apple SD Gothic Neo',
                    fontSize: '11px',
                    fontWeight: 400,
                    color: theme.color.default_gray_font_color,
                },
                useHTML: false,
                formatter: function () {
                    const calvalue = monthsForLocale(i18n.language, 'short', this.value);
                    return calvalue;
                },
            },
            tickWidth: 1,
            tickColor: '#FFA850',
            lineWidth: 0,
            // lineColor: 'rgba(0,0,0,0.5)',
            gridLineWidth: 1,
            gridLineColor: theme.color.dash_01,
            gridLineDashStyle: 'ShortDash',
        },
        yAxis: {
            title: {
                enabled: false,
            },
            labels: {
                style: {
                    textOverflow: "ellipsis",
                    fontFamily: 'Apple SD Gothic Neo',
                    fontSize: 12,
                    fontWeight: 500,
                    color: theme.color.default_gray_font_color
                },
            },
            startOnTick: false,
            offset: -8,
            endOnTick: false,
            tickColor: 'transparent',
            minorGridLineWidth: 0,
            minorGridLineColor: 'transparent',
            lineWidth: 0,
            tickPositions: rangeData,
            // lineColor: 'rgba(0,0,0,0.5)',
            gridLineWidth: 1,
            gridLineColor: theme.color.dash_01,
            gridLineDashStyle: 'ShortDash',
            // gridLineColor: 'rgba(0,0,0,0.2)',
            // gridLineDashStyle: 'ShortDash',
        },
        tooltip: {
            backgroundColor: theme.color.background_fff_1A,
            borderColor: theme.color.border_4C_60,
            borderRadius: 4,
            style: {
                zIndex: 999999,
            },
            className: 'tooltip',
            useHTML: true,
            formatter: function () {
                const tier = this.colorIndex === 0 ? "challenger" : "grandmaster"
                // const [time, text] = getDividedGameTime(this.x, currentLang);
                const date = new Date(this.x);
                return `<div style="z-index:99999;display: flex; flex-direction: column; font-family: Noto Sans KR; ">
                            <span class="tier_color ${tier}" style="font-weight: bold; font-size: 11px;">${tier.toUpperCase()}</span>
                            <p><span style="color: ${theme.color.font_7F_89}; font-size: 11px; font-weight: 500;">${t('ranking.cutOff')}</span><span style="color: ${theme.color.default_gray_font_color}; font-size: 11px; font-weight: bold; margin-left: 3px;">${this.y} LP</span></p>
                              <p><span style="font-weight: 500; font-size: 11px; color:${theme.color.default_gray_font_color};">${date.getFullYear()}.${date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)}.${date.getDate() > 9 ? date.getDate() : "0" + (date.getDate())}</span></p>
                        </div>`;
            },
        },
    };


    Highcharts.wrap(Highcharts.Tick.prototype, 'getMarkPath', function (prev, x, y, tickLength, tickWidth) {
        if (tickWidth > 0) {
            const xPath = Math.round(x) - 2.5;
            const elevateValue = 2;
            const yPath = Math.round(y) + 0.4;

            return ["M", xPath, yPath,
                "L",
                xPath + elevateValue, yPath - elevateValue,
                xPath + elevateValue * 2, yPath,
                xPath + elevateValue, yPath + elevateValue,
                xPath, yPath
            ]
        }
    });

    return (
        <ChartWrapper>
            <div>
                <AbsoluteBar/>
                <HighchartsReact highcharts={Highcharts} options={options}/>
            </div>
        </ChartWrapper>
    );
};

export default ChartRanking;

import React, {useCallback} from 'react';
import styled from "styled-components";
import ScoreWrapper from "../../../../BestContent/BestSection/ScoreWrapper";
import {useTranslation} from "react-i18next";
import {isAram} from "../../../../../../../../function";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const RankBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const RankText = styled.span`
  font-size: 12px;
  color: ${props => props.checkAway ? props.theme.color.font_DE : props.theme.color.font_58};
`;
const HeaderImage = styled.img`
  width: 16px;
  height: 16px;
`;
const AiTableContent = ({aiScore, rank, isAce, isMvp, onClick, data, checkAway = true, gameType}) => {
    const {t} = useTranslation();


    const getRank = useCallback((rank) => {
        if (rank) {
            return t('summoner.grade', {count: rank, ordinal: true});
        }
    }, [])

    return (
        <>
            <Wrapper>
                <ScoreWrapper
                    hoverScale={!isAram(gameType)}
                    noneClickable={isAram(gameType)}
                    data={data}
                    onClick={isAram(gameType) ? () => {
                    } : onClick}
                    fontSize={16}
                    score={checkAway ? 0 : aiScore}
                    paddingHorizontal={8}
                />
                <RankBox>
                    {!checkAway && isAce && <HeaderImage src={'images/ico_best_player04.svg'}/>}
                    {!checkAway && isMvp && <HeaderImage src={'images/ico_best_player01.svg'}/>}
                    <RankText checkAway={checkAway}>{checkAway ? t('tags.escape') : rank + getRank(rank)}</RankText>
                </RankBox>
            </Wrapper>
        </>
    );
};

export default React.memo(AiTableContent);

import {all, call, fork, put, takeLatest} from "redux-saga/effects";
import {GET_RANKING_FAILURE, GET_RANKING_REQUEST, GET_RANKING_SUCCESS} from "../reducer/ranking";
import {getRegion} from "../../function";
import axios from "axios";

const getRankingData = (action) => {
    const platform = getRegion(action.platform);
    const lane = action.lane;
    const page = action.page;
    const url = `${process.env.REACT_APP_CACHE_API}summoner/summoner_rank?platform_id=${platform}&lane=${lane}&page=${page}&version=0308`;
    return axios.get(url).then((result) => result.data);
}


function* getRanking(action) {

    try {
        const getData = yield call(getRankingData, action.data);

        yield put({
            type: GET_RANKING_SUCCESS,
            data: {
                ranking : getData
            }
        })
    } catch (e) {

        yield put({
          type: GET_RANKING_FAILURE,
          error: e,
        })
    }
}

function* watchGetRanking() {
    yield takeLatest(GET_RANKING_REQUEST, getRanking)
}


export default function* rankingSaga() {
    yield all([
        fork(watchGetRanking),
    ])
}

import React from 'react';
import styled from "styled-components";
import useChampionFunc from "../../../../common/hooks/useChampionFunc";
import {ColumnBox, FlexBox} from "../../../ui/styled";
import ChampionImageWithLane from "../../../common/ChampionImageWithLane";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: ${props => props.theme.color.default_input_background};
  border: ${props => {
    let border = `1px solid ${props.theme.color.border_106}`;
    if (props.selected) border = `2px solid ${props.theme.color.border_106}`;
    return border;
  }};
  padding: ${props => {
    let padding = '1px';
    if (props.selected) padding = '0';
    return padding;
  }};
  opacity: ${props => {
    let opacity = '0.3';
    if (props.selected) opacity = '1';
    return opacity;
  }};
  margin-right: 10px;
  cursor: pointer;
`;

const ChampionBox = styled(FlexBox)`
  margin: 8px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .imgChamp {
    width: 58px;
  }

  .imgDiv {
    border-radius: 9px;
  }

`;

const ChampText = styled.span`
  font-size: 12px;
  width: 10ch;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  overflow: hidden;
`;

const RankText = styled.span`
  margin-top: 6px;
  font-size: 12px;
  margin-bottom: 10px;
`;


const SummonerMasteryChampion = ({selected, champion_id, onClick, games}) => {
    const {t} = useTranslation();
    const {getChampion} = useChampionFunc()
    const champion = getChampion(champion_id);
    return (
        <Wrapper selected={selected} onClick={onClick}>
            <ChampionBox>
                <ChampionImageWithLane champion_id={champion_id} disabled={true} />
            </ChampionBox>
            <ColumnBox alignItems={'center'}>
                <ChampText>{champion.champion_name}</ChampText>
                <RankText>{games} {t('summoner.mastery.game')}</RankText>
            </ColumnBox>
        </Wrapper>
    );
};

export default SummonerMasteryChampion;
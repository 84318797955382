import React, {useEffect, useMemo} from 'react';
import {MatchListContainer} from "../../../styled/SummonerStyle";
import styled from "styled-components";
import MatchItem from "../MatchItem";
import {getSortedArray, getSortedObject} from "../../../function";
import UseSetCanonical from "../../../common/hooks/useSetCanonical";
import useTitleAndDescription from "../../../common/hooks/useTitleAndDescription";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const ErrorWrapper = styled.div`
  display: flex;
  background-color: ${props => props.theme.color.default_input_background};
  box-shadow: black;
`;

const ErrorBox = styled.div`
  background-color: ${props => props.theme.color.default_input_background};
  margin: 10px;
`;

const SummonerMatches = ({match, region, summoner, currentLang,}) => {
    const {userName} = useParams();
    const {t} = useTranslation()
    UseSetCanonical();
    const currentUserName = useMemo(() => {
        if (userName && summoner) {
            return summoner?.summoner_basic_info_dict?.riot_id_name;
        } else {
            return userName;
        }
    }, [summoner, userName]);

    const currentTagName = useMemo(() => {
        if (userName && summoner) {
            return summoner?.summoner_basic_info_dict?.riot_id_tag_line
        } else {
            return '#';
        }
    }, [summoner, userName])

    const currentProName = useMemo(() => {
        if (userName && summoner) {
            return summoner?.summoner_basic_info_dict?.pro_streamer_info_dict?.name
        } else {
            return '';
        }
    }, [summoner, userName])
    const handleMoreInfo = () => {

    }


    useTitleAndDescription({
        description: t('header.titleSummonerDescription', {
            summoner: decodeURI(currentUserName),
            tag: currentTagName,
            pro: currentProName,
            location: region,
        }),
        title: t('header.titleSummoner', {
            summoner: decodeURI(currentUserName),
            tag: currentTagName,
            pro: currentProName,
        })
    })

    useEffect(() => {
        if(match.match_json) {
            getData([match],summoner);
        }
    },[match])

    const getData = (matchInfo, summoner) => {
        let xAxisArr = [];
        let scoreArr = [];
        let gameArr = [];
        let winloseArr = [];
        let ratingArr = [];

        for (const match of matchInfo) {
            // AI스코어순 rating 추가
            let aiArr = getSortedObject(getSortedArray(match.match_json), 'minmax_z', 'descending'); // AI스코어순 정렬
            let winArr = [];
            let loseArr = [];
            for (let i = 0; i < aiArr.length; i++) {
                aiArr[i].rating = i + 1;
                if (aiArr[i].win) winArr.push(aiArr[i].minmax_z);
                else loseArr.push(aiArr[i].minmax_z);
            }

            // 경기 찾기
            for (const item of match.match_json) {
                for (const key in item) {
                    // mvp, ace, ai_score
                    if (!match.remake) {
                        if (item[key].minmax_z === winArr[0] && item[key].win) item[key].mvp = true;
                        if (item[key].minmax_z === loseArr[0] && !item[key].win) item[key].ace = true;
                        item[key].ai_score = Math.round(item[key].minmax_z * 100);
                    }

                    // 내 경기
                    if (key === summoner.summoner_id) {
                        // winloseArr배열 만들기
                        winloseArr.push(item[key].win);

                        // ratingArr배열 만들기
                        ratingArr.push(item[key].rating);

                        // scoreArr배열 만들기
                        if (item[key].minmax_z === undefined) scoreArr.push(0);
                        else scoreArr.push(item[key].minmax_z * 100);

                        // gameArr배열 만들기
                        if (!match.remake) {
                            gameArr.push(item[key]);
                        }
                    }
                }
            }

            // xAxisArr배열 만들기
            xAxisArr.push(new Date(match.creation_date));
        }
        scoreArr.reverse();
        xAxisArr.reverse();
        gameArr.reverse();
        winloseArr.reverse();
        ratingArr.reverse();

        return {xAxisArr, scoreArr, gameArr, winloseArr, ratingArr};
    };


    return (
        <Wrapper>
            {match.match_basic_dict && summoner ?
                <MatchListContainer>
                    <MatchItem
                        openButton={false}
                        summoner={summoner}
                        currentLang={currentLang}
                        region={region}
                        match={match}
                        openId={match.match_basic_dict.match_id}
                        handleMoreInfo={handleMoreInfo}/>
                </MatchListContainer>
                :
                <ErrorWrapper>
                    <ErrorBox>
                        ERROR
                    </ErrorBox>
                </ErrorWrapper>
            }

        </Wrapper>
    );
};

export default SummonerMatches;
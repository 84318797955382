import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import SummonerMasteryTable from "../../../compound/summonerMasteryProvider/SummonerMasteryTable";
import {ColumnBox, FlexBox} from "../../../ui/styled";
import CombinedRuneImages from "../../../common/CombinedRuneImages";
import {hundredCheck, threedigits} from "../../../../function";
import {BuildImageItem, ChampionRune} from "../../../common/ImageSrc";

const Wrapper = styled.div`
  width: 100%;
  border: 1px solid ${props => props.theme.color.default_bright_border_color};
  height: 997px;
  overflow: auto;
  background-color: ${props => props.theme.color.background_fff_22};

  tbody {
    tr {
      border-bottom: 1px solid ${props => props.theme.color.default_bright_border_color};
      cursor: pointer;
    }

    tr:nth-child(even) {
      background-color: ${props => props.theme.color.background_F9};
    }

    tr:hover {
      outline: 1px solid ${props => props.theme.color.border_9B};
      outline-offset: -1px;
      background-color: ${props => props.theme.color.background_F0};
      box-shadow: 0px 2px 3px #00000029;
      z-index: 10;
    }

    .selected {
      outline: 1px solid ${props => props.theme.color.border_6A};
      outline-offset: -1px;
      background-color: ${props => props.theme.color.background_E8} !important;
    }
  }
`;

const AbsoluteBar = styled.div`
  position: absolute;
  left: 0;
  width: 5px;
  height: 100%;
  background-color: ${props => props.theme.color.border_6A};
`;

const ContentTD = styled.td`
  position: relative;
  vertical-align: middle;

  .imgItem {
  }
`;


const PickRateSpan = styled.span`
  font-size: 12px;
  color: ${props => props.theme.color.font_58};
  font-weight: 500;
`;

const ValueText = styled.span`
  font-size: 12px;
  color: ${props => {
    let color = props.theme.color.font_E3;
    if (props.value > 50) {
      color = props.theme.color.font_61;
    }
    return color;
  }};
  font-weight: 500;
`;
const Percent = styled.span`
  color: ${props => props.theme.color.default_gray_font_color};
  font-size: 10px;
  opacity: 0.6;
  margin-left: 2px;
`;
// const YellowWrapper = styled.div`
//   border: 2px solid ${props => {
//     let color = 'transparent';
//     if (props.isMythic) color = '#FCB000';
//     return color
//   }};
//   border-radius: 6px;
//   margin-left: 8px;
//   overflow: hidden;
// `;

const MarginMythicItem = styled.div`
  margin-left: 8px;

  .imgItem {
    border-radius: 6px;
  }
`;

const ExtraText = styled.span`
  width: 100%;
  margin-top: 5px;
  font-size: 10px;
  color: #AAAAAA;
`;


const AllText = styled.span`
  font-size: 12px;
  font-weight: 500;
  margin-left: 5px;

`;
const BuildRuneCore = ({coreList, coreSelected, onChangeCoreSelected}) => {
    const {t} = useTranslation();
    return (
        <Wrapper>
            <SummonerMasteryTable title={t('summoner.mastery.runeItem')}
                                  headers={[{title: t('championDetailBuild.pickRate'), col: '53px'},
                                      {
                                          title: t('summoner.mastery.coreWinRate'),
                                          col: '50px'
                                      }]} list={coreList} selected={(data, index) => coreSelected === index}
                                  onClick={(_, index) => onChangeCoreSelected(index)}>
                {(data, index) => {
                    const winValue = (data.win_rate * 100).toFixed(1);
                    return (
                        <React.Fragment key={index}>
                            <ContentTD>
                                {coreSelected === index && <AbsoluteBar/>}
                                <FlexBox padding={'8px 0'}>
                                    {index === 0 ?
                                        <FlexBox height={'32px'}>
                                            <img
                                                src={'./images/icon_s_position_all_p.svg'}
                                                width={16}
                                                height={16}
                                                alt={""}
                                            />
                                            <AllText>{t('tabs.summary')}</AllText>
                                        </FlexBox>
                                        :
                                        <>
                                            <CombinedRuneImages
                                                RuneComponent={ChampionRune}
                                                primary={data.rune_main}
                                                sub={data.rune_sub}
                                                primaryAttr={{width: 32, height: 32}}
                                                subAttr={{width: 14, height: 14}}
                                            />
                                            <MarginMythicItem >
                                                <BuildImageItem itemId={data.core_item} width={28} height={28}/>
                                            </MarginMythicItem>
                                        </>
                                    }
                                </FlexBox>
                            </ContentTD>
                            <ContentTD>
                                {index === 0 ?
                                    <FlexBox justifyContent={'center'}>
                                        <PickRateSpan>-</PickRateSpan>
                                    </FlexBox>
                                    :
                                    <ColumnBox alignItems={'center'}>
                                        <FlexBox>
                                            <PickRateSpan>{hundredCheck((data.pick_rate * 100)?.toFixed(1))}<Percent>%</Percent></PickRateSpan>
                                        </FlexBox>
                                        <FlexBox justifyContent={'flex-end'}>
                                            <ExtraText>{threedigits(data.games)}</ExtraText>
                                        </FlexBox>
                                    </ColumnBox>
                                }
                            </ContentTD>
                            <ContentTD>
                                <FlexBox justifyContent={'center'}>
                                    {index === 0 ? <PickRateSpan>-</PickRateSpan> :
                                        <ValueText
                                            value={winValue}>{hundredCheck(winValue)}<Percent>%</Percent></ValueText>}
                                </FlexBox>
                            </ContentTD>
                        </React.Fragment>
                    )
                }}
            </SummonerMasteryTable>
        </Wrapper>
    );
};

export default BuildRuneCore;
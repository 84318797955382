import React, {useMemo} from 'react';
import styled from "styled-components";

import {useTranslation} from "react-i18next";
import TableHeader from "../GameAnalytics/GameSummary/Table/TableHeader";
import ArenaGameAnalyticsTableContent from "./ArenaGameAnalyticsTableContent";
import {FlexBox} from "../../../ui/styled";
import ArenaInfo from "../../ArenaInfo";
import ColorRankBox from "../../ColorRankBox";

const Wrapper = styled.div`
  background-color: ${props => props.theme.color.default_input_background};
`;
const TableWrapper = styled.table`
  position: relative;
  width: 100%;
`;

const TableHeaderWrapper = styled.tr`
  height: 28px;
  border-bottom: 1px solid ${props => props.theme.color.champion.border};
  border-top: 1px solid ${props => props.theme.color.champion.border};
`;

const ArenaHeader = styled.th`
  height: 33px;
  vertical-align: middle;
  background-color: ${props => props.theme.color.background_fff_1A};
`;

const ArenaGameSummary = ({match, myMatch, region, hide}) => {








    const teamList = useMemo(() => match.match_basic_dict.arena_team, [match]);
    const MatchMemo = useMemo(() => match.participants_list, [match]);


    const Alldamage = useMemo(() => MatchMemo.reduce((sum, data, index) => {
        if (sum.totaldmg < data.total_damage_dealt) {
            sum.totaldmg = data.total_damage_dealt;
        }

        if (sum.totalTaken < data.total_damage_taken) {
            sum.totalTaken = data.total_damage_taken;
        }

        return sum;
    }, {totaldmg: 0, totalTaken: 0}), [MatchMemo]);


    return teamList.map((data, index) => {
        return (
            <Table key={index} list={data} hide={hide} region={region} Alldamage={Alldamage} MatchMemo={MatchMemo}
                   myMatch={myMatch}/>
        )
    })

};


const Table = ({MatchMemo, list, hide, Alldamage, region, myMatch}) => {
    const {t} = useTranslation();
    return (
        <Wrapper>
            <TableWrapper>
                <colgroup>
                    <col width={'290px'}/>
                    <col width={'120px'}/>
                    <col />
                    <col />
                    <col width={'168px'}/>
                </colgroup>
                <thead>
                <TableHeaderWrapper>
                    <ArenaHeader>
                        <FlexBox height={'100%'} margin={'0 0 0 7px'}>
                            <ColorRankBox rank={list.placement} width={'34px'} height={'21px'} borderRadius={'5px'} fontSize={'13px'}/>
                            <ArenaInfo team={list}  small={true}/>
                        </FlexBox>
                    </ArenaHeader>
                    {/*<WinTableHeader*/}
                    {/*    filterSelected={sort[0].value === sortData.sideIndex.value}*/}
                    {/*    leftTitle={t('gameAnalytics.winLose')}*/}
                    {/*    onClickSort={onClickSort}*/}
                    {/*    sort={sortData.sideIndex}*/}
                    {/*    percent={25} title={t('gameAnalytics.summoner')}*/}
                    <TableHeader
                        percent={8}
                        title={t('summoner.arena.argument')}/>
                    <TableHeader
                        percent={8}
                        title={"KDA"}
                    />
                    <TableHeader
                        percent={16}
                        title={t('gameAnalytics.damage')}/>

                    <TableHeader
                        percent={27} title={t('summoner.arena.itemNone')}/>
                </TableHeaderWrapper>
                </thead>

                {/*<ArenaGameAnalyticsTeam team={data}/>*/}
                {list.participant_puu_ids.map((puu_id) => {
                    const player = MatchMemo.find((user) => user?.puu_id === puu_id);
                    const isme = player?.riot_id_name === myMatch.riot_id_name;
                    if (!player) return null;
                    return (
                        <ArenaGameAnalyticsTableContent
                            hide={hide}
                            region={region}
                            isme={isme}
                            key={player.riot_id_name}
                            allDamage={Alldamage}
                            data={player}
                        />
                    )
                })}
            </TableWrapper>
        </Wrapper>
    )
}

export default React.memo(ArenaGameSummary);

import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled, {useTheme} from "styled-components";
import useProAndStreamerApi from "../../common/hooks/useProAndStreamerAPI";
import {ColumnBox, FlexBox} from "../ui/styled";
import InfluIcon from "../summoner/Profile/InfluInfo/InfluIcon";
import {
    changePositionNameToImgPositionName,
    countryName,
    getDivisionValue,
    getRegionColor,
    getRegionServer,
    getTierValue,
    onStorePuuId,
    romanNumberConverter,
    sortChampData
} from "../../function";
import {useTranslation} from "react-i18next";
import SnsImage from "../common/SnsImage";
import EmblemImage from "../EmblemImage";
import useTimeHook from "../../common/hooks/useTimeHook";
import SummonerMatchesProgress from "../compound/SummonerMatches/SummonerMatchesProgress";
import useTierCutAPI from "../../common/hooks/useTierCutAPI";
import {addSearchListProStreamerList} from "../../store/modules/searchList";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import Favorite from "../common/Favorite";
import UseSetCanonical from "../../common/hooks/useSetCanonical";
import useTitleAndDescription from "../../common/hooks/useTitleAndDescription";

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  border-radius: 6px;
`;
// ${process.env.REACT_APP_ROOT_IMG}streamer/${data.player_name}.png
const ProfileWrapper = styled.div`
  height: 220px;
  position: relative;
  overflow: hidden;
`;
const BannerImageBox = styled.div`
  width: 100%;
  height: 220px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: absolute;
`;
const ProfileBannerImage = styled.img`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  height: 220px;
  object-fit: cover;
  filter: blur(30px);
`;
const BlackFilter = styled.div`
  position: absolute;
  width: 100%;
  height: 220px;
  background-color: #000;
  opacity: 0.6;
`;
const ProfileContent = styled.div`
  position: absolute;
  padding: 35px 60px;
`;
const ProfileImage = styled.img`
  border-radius: 50%;
  background-color: ${props => props.theme.color.background_fff_000};
`;
const ProfileInfo = styled.div`
  margin-left: 30px;
  display: flex;
  flex-direction: column;
`;

const ProfileNameBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 15px;
  align-items: flex-end;
  padding-bottom: 4px;
`;
const ProfileName = styled.div`
  color: ${props => props.theme.color.font_fff_EA};
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 32px;
  height: 46px;
  font-weight: bold;
`;

const RealName = styled.span`
  font-size: 14px;
  color: ${props => props.theme.color.font_fff_EA};
  opacity: 0.5;
  margin-left: 8px;
  line-height: 20px;
  margin-bottom: 5px;
`;
const ProfileInfoTitle = styled.span`
  width: 64px;
  color: ${props => props.theme.color.font_fff_EA};
  opacity: 0.5;
  font-size: 14px;
`;
const ProfileInfoText = styled.span`
  color: ${props => props.theme.color.font_fff_EA};
  font-size: 14px;
  line-height: 20px;
`;
const TeamImage = styled.img`
  margin-right: 10px;
`;
const PositionText = styled(ProfileInfoText)`
  margin-left: 0;
`;
const StreamingBox = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;

  .imgBox + .imgBox {
    margin-left: 10px;
  }
`;

const AccountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 40px 60px;
  justify-content: space-between;
  background-color: ${props => props.theme.color.summoner.tab.background_color};
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  margin-right: 10px;
  border-radius: 50%;
  background-color: ${props => {
    let color = props.theme.color.font_7F_A7;
    if (props.selected) color = props.theme.color.default_gray_font_color;
    return color;
  }};
  opacity: ${props => {
    let opacity = '0';
    if (props.selected) opacity = '1';
    return opacity;
  }};
`;

const AccountFilterText = styled.span`
  font-size: 14px;
  color: ${props => {
    let color = props.theme.color.font_7F_A7;
    if (props.selected) color = props.theme.color.default_gray_font_color;
    return color;
  }};
  line-height: 20px;
`;

const FilterBox = styled(FlexBox)`
  font-weight: 500;
  cursor: pointer;

  &:hover {
    ${Dot} {
      background-color: ${props => {
        let color = props.theme.color.font_7F_A7;
        if (props.selected) color = props.theme.color.default_gray_font_color;
        return color;
      }};
      opacity: ${props => {
        let opacity = '0.5';
        if (props.selected) opacity = '1';
        return opacity;
      }};
    }

    ${AccountFilterText} {
      color: ${props => {
        let color = props.theme.color.font_7F_A7;
        if (props.selected) color = props.theme.color.default_gray_font_color;
        return color;
      }};
    }
  }
`;

const AccountInfoBox = styled.div`
  height: 80px;
  display: flex;
  flex-direction: row;
  background-color: ${props => props.theme.color.background_fff_22};
  cursor: pointer;
  border-radius: 4px;
  overflow: hidden;

  &:hover {
    background-color: ${props => props.theme.color.background_E0};
  }

  & + & {
    margin-top: 10px;
  }
`;
const AccountInfoContentBox = styled.div`
  width: 772px;
  height: 100%;
  border-right: 1px solid ${props => props.theme.color.border_C31_22};
  border-top: 1px solid ${props => props.theme.color.border_C31_22};
  border-bottom: 1px solid ${props => props.theme.color.border_C31_22};
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 7px;

  .imgEmblem {
    width: 48px;
    height: 48px;
  }
`;

const Bar = styled.div`
  width: 8px;
  height: 100%;
  background-color: ${props => getRegionColor(props.region)};
`;

const RegionText = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: ${props => getRegionColor(props.region)};
  line-height: 20px;
  width: 60px;
  text-align: center;
`;

const LongBar = styled.div`
  width: 1px;
  height: 40px;
  opacity: 0.3;
  background-color: ${props => getRegionColor(props.region)};
`;
const SummonerName = styled.span`
  font-size: 18px;
  line-height: 26px;
  font-weight: bold;
  color: ${props => props.theme.color.default_gray_font_color};
`;

const TagText = styled.span`
  font-size: 18px;
  font-weight: 500;
  color: ${props => props.theme.color.font_7F_89};
  margin-left: 5px;
`;

const LiveBox = styled.div`
  position: absolute;
  left: -2px;
`;

const TimeWrapper = styled(FlexBox)`
  position: relative;
`;

const FlexOne = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
const TimeText = styled.span`
  font-weight: 500;
  font-size: 12px;
  color: ${props => props.theme.color.font_7F_89};
`;

const ProgressWrapper = styled.div`
  width: 251px;
`;

const PositionImg = styled.img`
  margin-right: 12px;
`;


const ProContent = ({status}) => {
    UseSetCanonical();
    const dispatch = useDispatch();
    const theme = useTheme();
    const {i18n, t} = useTranslation();
    const {proAndStreamerQuery} = useProAndStreamerApi(status);
    const [filterIndex, setFilterIndex] = useState(0);
    const data = proAndStreamerQuery.data;
    const {proName} = useParams();
    const bookmarks = useSelector((state) => {
        let arr = state?.bookmarkList.bookmarks.filter((item) => item?.url_name === proName);
        return arr.length > 0
    });

    useEffect(() => {
        dispatch(addSearchListProStreamerList({...data}));
    }, [proName]);

    const sortedList = useMemo(() => {
        try {
            const list = data.account_list;
            const sortByRegion = sortChampData(list, [{sortType: true, value: 'platform_id'}]);
            if (filterIndex === 0) {
                const live = sortByRegion.filter((data) => data.live !== 0);
                const notLive = sortByRegion.filter((data) => data.live === 0);
                const zeroList = notLive.filter((data) => data.last_game_date === 0);
                const timedList = notLive.filter((data) => data.last_game_date !== 0);
                const sortedTimedList = sortChampData(timedList, [{sortType: false, value: 'last_game_date'}]);
                return [...live, ...sortedTimedList, ...zeroList]
            } else if (filterIndex === 1) {
                const setTierMap = sortByRegion.map((data) => {
                    const splitTier = data.tier.split(" ");
                    const tier = splitTier[0];
                    const division = romanNumberConverter(splitTier?.[1]);
                    return {
                        ...data,
                        tierScore: getTierValue(tier) + getDivisionValue(division)
                    }
                })
                return sortChampData(setTierMap, [{sortType: false, value: 'tierScore'}, {
                    sortType: false,
                    value: 'lp'
                }]);
            }
            return data.account_list;
        } catch (e) {
            console.log(e)
            return [];
        }
    }, [data.account_list, filterIndex]);

    const getLaneName = useCallback((lane) => {
        switch (lane) {
            case 'Top':
                return t('gameAnalytics.top')
            case 'Jungle':
                return t('gameAnalytics.jungle')
            case 'Mid':
                return t('gameAnalytics.mid')
            case 'Bot':
                return t('championAnalyze.bot')
            case 'Support':
                return t('gameAnalytics.support')
            case 'Coach':
                return t('proStreamer.coach')
            default:
                break;
        }
    }, []);


    useTitleAndDescription({
        title: t('header.titlePro',{
            pro: proName
        }),
        description: t('header.titleProDescription',{
            pro:proName
        })
    })

    return (
        <Wrapper>
            <ProfileWrapper backgroundImage={data?.name}>
                <BannerImageBox>
                    <ProfileBannerImage
                        src={`${process.env.REACT_APP_ROOT_IMG}${status}/${status === 'pro' ? data.championship_name : data.name}.png`}
                        onError={(e) => e.target.src = '/images/img_unknown.svg'}/>
                    <BlackFilter/>
                </BannerImageBox>
                <StreamingBox>
                    <SnsImage
                        influData={{
                            chzzk: data.chzzk,
                            afreecatv: data.afreecatv,
                            youtube: data.youtube,
                            twitch: data.twitch,
                            twitter: data.twitter,
                            kick:data?.kick,
                            leaguepedia: data.leaguepedia
                        }}
                        height={30}
                        width={30}
                    />
                </StreamingBox>
                <ProfileContent>
                    <FlexBox alignItems={'flex-start'}>
                        <ProfileImage src={`${process.env.REACT_APP_ROOT_IMG}${status}/${status === 'pro' ? data.championship_name : data.name}.png`} width={150}
                                      height={150} onError={(e) => e.target.src = '/images/img_unknown.svg'}/>
                        <ProfileInfo>
                            <FlexBox margin={'0 0 16px 0'}>
                                <InfluIcon status={status} width={54} height={26} icon={true}/>
                                <ProfileNameBox>
                                    <ProfileName>{data?.name}</ProfileName>
                                    {data?.name_en !== '-' && <RealName>{data?.name_en}</RealName>}
                                </ProfileNameBox>
                                <FlexBox margin={'0 0 0 12px'}>
                                    <Favorite isSelected={bookmarks} item={data}/>
                                </FlexBox>
                            </FlexBox>
                            <ColumnBox>
                                {(data?.nation !== '-' && data.nation !== '') && <FlexBox margin={'0 0 8px 0'}>
                                    <ProfileInfoTitle>{t('proStreamer.region')}</ProfileInfoTitle>
                                    <FlexBox>
                                        <TeamImage
                                            key={data?.nation + "nation"}
                                            src={`${process.env.REACT_APP_ROOT_IMG}nation/${data?.nation?.toLowerCase()}.png`}
                                            width={18} height={12} alt={''}
                                            onError={(e) => e.target.style.display = 'none'}
                                        />
                                        <ProfileInfoText>{countryName(i18n.language, data?.nation)}</ProfileInfoText>
                                    </FlexBox>
                                </FlexBox>}
                                {status === 'pro' &&
                                    <FlexBox margin={'0 0 8px 0'}>
                                        <ProfileInfoTitle>{t('proStreamer.team')}</ProfileInfoTitle>
                                        <FlexBox>
                                            <TeamImage
                                                key={data?.team_al + "teamAl"}
                                                src={`${process.env.REACT_APP_ROOT_IMG}assets/team_logos/${data?.team}.png`}
                                                width={18} alt={''}
                                                onError={(e) => e.target.style.display = 'none'}/>
                                            <ProfileInfoText>{data?.team_al}</ProfileInfoText>
                                        </FlexBox>
                                    </FlexBox>}

                                {status === 'pro' &&
                                    <FlexBox>
                                        <ProfileInfoTitle>{t('mastery.position')}</ProfileInfoTitle>
                                        <FlexBox>
                                            <PositionImg
                                                key={data?.position + "position"}
                                                src={`${theme.icon.path}/icon_s_position_${changePositionNameToImgPositionName(data?.position)}_w.svg`}
                                                alt={""} width={16} height={16}
                                                onError={(e) => e.target.style.display = 'none'}
                                            />
                                            <PositionText>{getLaneName(data?.position)}</PositionText>
                                        </FlexBox>
                                    </FlexBox>}
                            </ColumnBox>
                        </ProfileInfo>
                    </FlexBox>
                </ProfileContent>
            </ProfileWrapper>
            <AccountWrapper>
                <ColumnBox>
                    <FilterBox margin={'0 0 15px 0'} selected={filterIndex === 0} onClick={() => setFilterIndex(0)}>
                        <Dot selected={filterIndex === 0}/>
                        <AccountFilterText selected={filterIndex === 0}>{t("proStreamer.byRecent")}</AccountFilterText>
                    </FilterBox>
                    <FilterBox selected={filterIndex === 1} onClick={() => setFilterIndex(1)}>
                        <Dot selected={filterIndex === 1}/>
                        <AccountFilterText selected={filterIndex === 1}>{t('proStreamer.byTier')}</AccountFilterText>
                    </FilterBox>
                </ColumnBox>
                <ColumnBox>
                    {sortedList.map((item, index) => {
                        return (
                            <AccountInfoCompo item={item} key={index}/>
                        )
                    })}
                </ColumnBox>
            </AccountWrapper>
        </Wrapper>
    );
};


const AccountInfoCompo = ({item}) => {
    const {i18n} = useTranslation();
    const {lessMinWithIntl} = useTimeHook()
    const tierSplit = item.tier.split(" ");
    const serverRegion = getRegionServer(item.platform_id);
    const {tierCut} = useTierCutAPI(serverRegion);
    if (!tierCut.data) return null
    return (
        <AccountInfoBox onClick={() => onStorePuuId(`${item.riot_id}-${item.riot_tag}`, serverRegion)}>
            <Bar region={serverRegion}/>
            <AccountInfoContentBox>
                <RegionText region={serverRegion}>
                    {serverRegion}
                </RegionText>
                <LongBar region={serverRegion}/>
                <FlexBox margin={'0 20px 0'}>
                    <EmblemImage tier={item.tier} unrankVisible={true}/>
                </FlexBox>
                <ColumnBox>
                    <SummonerName>{item.riot_id}<TagText>#{item.riot_tag}</TagText></SummonerName>
                    <TimeWrapper height={'17px'}>
                        {item.live !== 0 ?
                            <LiveBox>
                                <img src="/images/ico_live.svg"
                                     alt="LIVE"/>
                            </LiveBox> :
                            <TimeText>{item.last_game_date === 0 ? ['ko', 'ko-KR'].includes(i18n.language) ? '오래 전' : 'long time ago' : lessMinWithIntl(item.last_game_date * 1000)}</TimeText>
                        }
                    </TimeWrapper>
                </ColumnBox>
                <FlexOne>
                    <ProgressWrapper>
                        <SummonerMatchesProgress tier={tierSplit[0]} currentLp={item.lp}
                                                 division={romanNumberConverter(tierSplit[1])}
                                                 tierCut={tierCut.data}/>
                    </ProgressWrapper>
                </FlexOne>
            </AccountInfoContentBox>
        </AccountInfoBox>
    )
}

export default ProContent;
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import _ from 'lodash';
import {useTranslation} from 'react-i18next';

import MultiItem from './MultiItem';
import {MultiListContainer} from '../../styled/MultiStyle';
import MultiListType from "./MultiList/MultiListType";
import {getLPScore} from "../../function";
import styled from "styled-components";
import ChartMulti from "../charts/ChartMulti";
import {useSelector} from "react-redux";
import {Loading} from "../common";
import useTierCutAPI from "../../common/hooks/useTierCutAPI";


const ChartWrapper = styled.div`
  margin-top: 20px;
  border: 1px solid ${props => props.theme.color.champion.border};
  min-height: 120px;
  background-color: ${props => props.theme.color.default_input_background};
`;
const ChartHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.color.champion.border};
  padding-left: 40px;
  padding-block: 8px;
`;
const ChartHeaderText = styled.span`
  font-weight: bold;
  font-size: 14px;
`;
const RecentDaysText = styled.span`
  font-size: 12px;
  opacity: 0.6;
  font-weight: normal;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const MultiList = ({multiList, multiLPChart, isLoading}) => {
    const {t} = useTranslation();
    // const [newItem, setNewItem] = useState({});
    // const [list, setList] = useState([]);
    // const [duoList, setDuoList] = useState([]);
    const [openId, setOpenId] = useState(0);
    const [renderType, setRenderType] = useState(0);
    const [typeButtonPressed, onChangeTypeButtonPressed] = useState(false);
    // const [multiList, setmultiList] = useState(_.uniqBy(multiList, (e) => e.summoner_info.summoner_id));
    const [sortedList, setSortedList] = useState([]);
    const DUO_START = 1;
    const DUO_LIMIT = 3;
    const {getMultiLPChartDone, getMultiListDone} = useSelector((state) => state.summoner);
    const region = useSelector((state) => state.region.result);
    const {tierCut}=useTierCutAPI(region)


    // const initialSort = [
    //     {
    //         value: 'ai_score',
    //         sortType: false,
    //     },
    // ]


    useEffect(() => {
        if (Array.isArray(multiList)) {
            const array = _.uniqBy(multiList, (e) => e.summoner.summoner_basic_info_dict.summoner_id);
            // setmultiList(array);
            const sortedMultilist = array.sort((a, b) => Number(b.summoner_info_dict.ai_score_avg) - Number(a.summoner_info_dict.ai_score_avg));
            setSortedList(sortedMultilist);
        }
    }, [multiList])

    const handleLayer = (id) => {
        const currentId = openId;
        if (currentId === id) {
            setOpenId(0);
        } else {
            setOpenId(id);
        }
    };

    //?????? 왜 있어야됨?
    // const getProcessData = useCallback(
    //     (arr) => {
    //         let newArr = [];
    //         arr.forEach((item, index) => {
    //             if (index !== 0) {
    //                 newArr.push(item);
    //             }
    //         });
    //
    //         let total = 0;
    //         let tmp = [];
    //         newArr.forEach((item, index) => {
    //             total += item.ai_score_total;
    //             let aa = 0;
    //             let bb = '';
    //             item?.summoner_match_list?.forEach((itm, idx) => {
    //                 aa = itm.matchId;
    //                 bb = itm.side;
    //                 newArr?.forEach((ii, xx) => {
    //                     if (index !== xx) {
    //                         ii?.summoner_match_list.forEach((i, x) => {
    //                             if (aa === i.matchId && bb === i.side) {
    //                                 tmp.push(ii.summoner_info.riot_id_name);
    //                             }
    //                         });
    //                     }
    //                 });
    //             });
    //         });
    //
    //         let set = new Set(tmp);
    //         setDuoList([...set]);
    //
    //         // getAvg((total / newArr.length).toFixed(1));
    //     },
    //     []
    // );

    // useEffect(() => {
    //     setList((list) => {
    //         return [...list, newItem];
    //     });
    // }, [newItem]);


    // //?????????????????
    // useEffect(() => {
    //     getProcessData(list);
    // }, [getProcessData, list]);


    const onClickTypeButton = (index) => {
        setRenderType(index);
        onChangeTypeButtonPressed(true);
    }


    const getDuoList = useCallback((multiList) => {
        return multiList.reduce((sum, cur) => {
            const curRank = cur.summoner.season_tier_info_dict.ranked_solo_5x5.tier;
            if (curRank !== 'CHALLENGER' && curRank !== 'GRANDMASTER' && curRank !== 'MASTER') {
                const currentId = cur.summoner?.summoner_basic_info_dict.summoner_id;
                const currentMatchList = cur?.summoner_info_dict?.last_match_list;
                const filterNotMy = multiList.filter((multiItem) => currentId !== multiItem.summoner.summoner_basic_info_dict.summoner_id);
                const calcu = filterNotMy.map((notMe) => {
                    const test = notMe.summoner_info_dict.last_match_list.filter((list) => currentMatchList.some((some) => some.is_win === list.is_win && some.match_id === list.match_id));
                    return {
                        is_duo: test.length > 0,
                        match_ids: test.map((data) => data.match_id),
                    };
                });


                sum.push({
                    summoner_id: currentId,
                    match_ids: _.uniq(calcu.reduce((sum, data) => [...sum, ...data.match_ids], [])),
                });

            }
            return sum;
        }, []);
    }, []);

    const getCombined = (arr, num) => {
        const results = [];
        if (num === 1) return arr.map(v => [v]);
        arr.forEach((fixed, index, origin) => {
            const rest = origin.slice(index + 1);
            const combinations = getCombined(rest, num - 1);
            const attached = combinations.map(v => [fixed, ...v]);
            results.push(...attached);
        });

        return results;
    }


    const getCombinations = useCallback((list) => {
        if (Array.isArray(list) && list.length > 0) {
            if (list.length === 1) return [];
            const result = [];
            new Array(list.length).fill("").forEach((data, index) => {
                if (index === 0) {
                    result.push(list);
                } else {
                    const answer = getCombined(list, list.length - index);
                    const filteredAnswer = answer.filter((data) => data.length > DUO_START);

                    result.push(...filteredAnswer);
                }
            })

            return result;
        }
        return [];
    }, [])


    const reduceMap = useMemo(() => {

        //경우의 수가 너무많이 존재함
        //많은 경우의 수를 전부다 코딩으로 작성하는게 불필요할정도로 길어서
        //전체 경우의수를 구한다음 거기에 대입하는 방식으로 되어있음. 5명이면 60000만개 정도의 경우의 수가 나옴.

        const colorPallet = ['#B1FFC8', '#C2EEFF', '#DED2FE', '#FFF3C2'];
        const textColorPallet = ['#1E714A', '#3D6384', '#61528A', '#775648'];
        const borderPallet = ['#7FD5AE', '#91BFE6', '#A895DE', '#E3A489'];
        let colorPattern = 0;
        if (Array.isArray(multiList) && multiList.length > 1) {
            const list = getDuoList(multiList);
            const matches = _.uniq(list.reduce((sum, cur) => [...sum, ...cur.match_ids], []));
            const combinationList = getCombinations(matches, 1);
            const result = list.reduce((sum, cur) => {
                const sortedList = cur.match_ids.sort();
                if (sortedList.length > 0) {
                    new Array(sortedList.length - 1).fill("").forEach((data, index) => {
                        const combined = getCombined(sortedList, sortedList.length - index);
                        combined.map((combinedData) => {
                            const answer = combinationList.find((data) =>
                                data.length === combinedData.length &&
                                combinedData.every((every, index) => {
                                    return data.includes(every);
                                }));
                            if (answer) {
                                sum[answer.toString()] = {
                                    matches: answer,
                                    count: sum[answer.toString()]?.count ? sum[answer.toString()]?.count + 1 : 1,
                                    summoner_id: sum[answer.toString()]?.summoner_id ? [...sum[answer.toString()]?.summoner_id, cur.summoner_id] : [cur.summoner_id],
                                }
                            }
                        })
                    })
                }

                return sum;
            }, []);

            const deleteSame = Object.values(result).reduce((sum, cur) => {
                const sortSummonerId = cur.summoner_id.sort();
                const filtered = Object.values(sum).filter((data) => data.summoner_id.length === cur.summoner_id.length && data.summoner_id.sort().every((data, index) => data === sortSummonerId[index]));
                if (filtered.length > 0) {
                    return sum;
                } else {
                    sum[cur.matches.toString()] = ({...cur});
                }
                return sum;
            }, {});

            let lastObj = {};
            for (const obj in deleteSame) {
                if (deleteSame[obj]?.count > DUO_START) {
                    if (deleteSame[obj]?.count > DUO_LIMIT) {
                        lastObj[obj] = {
                            ...deleteSame[obj],
                            summoner_id: deleteSame[obj].slice(0, DUO_LIMIT),
                        }
                    } else {
                        lastObj[obj] = {
                            ...deleteSame[obj],
                        }
                    }
                    lastObj[obj] = {
                        ...lastObj[obj],
                        background: colorPallet[colorPattern],
                        textColor: textColorPallet[colorPattern],
                        borderColor: borderPallet[colorPattern],
                    }
                    if (colorPattern < colorPallet.length - 1) {
                        colorPattern++
                    }

                }
            }
            return Object.values(lastObj);

        }
        return [];
    }, [multiList])


    const renderMap = useCallback(() => {
        return multiList.length > 0 && multiList.map((multiItem, index) => {
            const ranks = sortedList.findIndex((data) => data.summoner.summoner_basic_info_dict.summoner_id === multiItem.summoner.summoner_basic_info_dict.summoner_id) + 1;
            const isDuo = reduceMap.filter((reduce) => reduce.summoner_id.some((some) => some === multiItem.summoner.summoner_basic_info_dict.summoner_id));
            const duo = isDuo.slice(0, 2)
            return (
                <MultiItem
                    isEven={(index + 1) % 2 === 0}
                    isLoading={isLoading}
                    setTypeButton={onChangeTypeButtonPressed}
                    typeButton={typeButtonPressed}
                    renderType={renderType}
                    ranks={ranks}
                    key={index + "multiItem"}
                    multiItem={multiItem}
                    isDuo={duo}
                    openId={openId}
                    handleLayer={handleLayer}
                />
            )
        })
    }, [multiList, typeButtonPressed, renderType,  openId, isLoading]);


    const getArrChartOptions = (arr, lpArr) => {
        if (Array.isArray(lpArr) && Array.isArray(arr) && arr.length > 0) {
            const result = arr.map((data, index) => {
                if (lpArr[index]?.ranked_solo_5x5) {
                    return {
                        ...getChartOptions(lpArr[index].ranked_solo_5x5),
                        summoner: data.summoner,
                    };
                } else {
                    return {
                        calculatedArr: [],
                        originalArr: [],
                        dateArr: [],
                    }
                }

            });

            const longestLength = Math.max.apply(null, result.map((data) => data && data.calculatedArr.length));


            const answer = result.map((data) => {
                return {
                    ...data,
                    multiCalArr: data.calculatedArr.length === longestLength ? data.calculatedArr :
                        new Array(longestLength).fill('').map((item, index) => {
                            if (longestLength - index <= data.calculatedArr.length) {
                                const result = data.calculatedArr[(data.calculatedArr.length) - (longestLength - index)];
                                return result ? result : "";
                            } else {
                                return "";
                            }
                        }),
                    multiOriginalArr: data.originalArr.length === longestLength ? data.originalArr :
                        new Array(longestLength).fill('').map((item, index) => {

                            if (longestLength - index <= data.originalArr.length) {

                                const result = data.originalArr[(data.originalArr.length) - (longestLength - index)];
                                return result ? result : 0;
                            } else {
                                return 0;
                            }
                        }),
                }
            });
            return answer;
        }

    }


    const getChartOptions = (data) => {
        let originalArr = []; // 원래 data값을 배열로
        let calculatedArr = []; // 계산된 lp 값
        let dateArr = []; // xAxis categories 값
        let tierArr = []; // tier, order 배열
        let grandMasterValue = 2800 + tierCut?.data?.grandmaster_cut_off;
        let challengerValue = grandMasterValue + tierCut?.data?.challenger_cut_off;




        // for (const obj in data) {
        //
        //     if (data[obj].tier === "GRANDMASTER") {
        //         grandMasterValue = Math.min(grandMasterValue, data[obj].league_points + 2800);
        //         grandMaxValue = Math.max(grandMaxValue, data[obj].league_points + 2800);
        //     }
        //     if (data[obj].tier === "CHALLENGER") {
        //         challengerValue = Math.min(challengerValue, data[obj].league_points + 3100);
        //         challengerMaxvalue = Math.max(challengerMaxvalue, data[obj].league_points + 2800);
        //     }
        // }


        for (const i in data) {
            originalArr.push(data[i]);
            // 티어별 order값
            let tierOrder = 0;
            if (data[i].tier === 'IRON') tierOrder = 1;
            else if (data[i].tier === 'BRONZE') tierOrder = 2;
            else if (data[i].tier === 'SILVER') tierOrder = 3;
            else if (data[i].tier === 'GOLD') tierOrder = 4;
            else if (data[i].tier === 'PLATINUM') tierOrder = 5;
            else if (data[i].tier === 'DIAMOND') tierOrder = 6;
            else if (data[i].tier === 'MASTER') tierOrder = 7;
            else if (data[i].tier === 'GRANDMASTER') tierOrder = 8;
            else if (data[i].tier === 'CHALLENGER') tierOrder = 9;


            tierArr.push({
                tier: data[i].tier,
                order: tierOrder,
            });

            // 계산된 lp로 입력
            calculatedArr.push(getLPScore(data[i].tier, data[i].division, data[i].league_points, grandMasterValue, challengerValue));

            // xAxis categories (날짜데이터)
            // let idxDate = new Date(i * 1000);
            // let idxDate = getGameTime(i, currentLang);
            // let idxDate = getGameTime(i, currentLang);
            dateArr.push(data[i].timestamp);
        }

        // calculatedArr로 티어별 최소,최대값 구하기
        // let yTickPositions = [minVal, maxVal]; // yAxis tickPositions 값

        // 객체배열 중복제거
        tierArr = _.uniqBy(tierArr, 'tier');
        tierArr.sort(function (a, b) {
            // order 순으로 정렬
            return a.order - b.order;
        });


        let yTickPositions = {
            GRANDMASTER: grandMasterValue,
            CHALLENGER: challengerValue,
        }

        // 티어별 yAxis tickPositions 값 넣기


        // yTickPositions 앞뒤로 400 추가 (왜 하는거지?)
        // let minTP = yTickPositions[0];
        // let maxTP = yTickPositions[yTickPositions.length - 1];
        // if (minTP !== 0) yTickPositions.unshift(minTP - 400);
        // if (maxTP < 2400) yTickPositions.push(maxTP + 400);
        // else yTickPositions.push(maxTP + 1000);

        // 내림차순 정렬


        return {originalArr, calculatedArr, dateArr, yTickPositions};
    };

    // if(multiList.length === 0) return   <SummonerNotFound userName={'???'}/>;

    return (
        <Wrapper>
            <MultiListContainer>
                <div className="t_head">
                    <div className="row">
                        <div className="col flex_column1">{t('multi.head1')}</div>
                        <div className="col flex_column2">{t('multi.head2')}</div>
                        <div className="col flex_column3">{t('multi.head3')}
                            <span>{t('multi.head3Extra')}</span>
                            <MultiListType
                                renderType={renderType}
                                onClickButton={onClickTypeButton}
                            />
                        </div>
                        <div className="col flex_column4">{t('multi.head4')}</div>
                    </div>
                </div>
                <div className="t_body">
                    {renderMap()}
                </div>
            </MultiListContainer>

            <ChartWrapper>
                <ChartHeaderWrapper>
                    <ChartHeaderText>LP {t('summoner.graph')}
                        <RecentDaysText>({t('summoner.recent')} 60{t('summoner.day')})</RecentDaysText></ChartHeaderText>
                </ChartHeaderWrapper>
                {(getMultiListDone && getMultiLPChartDone) ?
                    <ChartMulti
                        data={getArrChartOptions(multiList, multiLPChart)}
                    /> :
                    <Loading/>
                }
            </ChartWrapper>
        </Wrapper>
    );
};

export default MultiList;

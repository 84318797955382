import React, {useCallback, useMemo, useState} from 'react';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import {useTranslation} from 'react-i18next';
import DetailShareTag from "./MatchDetail/DetailShareTag";
import styled, {useTheme} from "styled-components";
import DetailHideSummonerName from "./MatchDetail/DetailHideSummonerName";
import {isArena, isSwarm} from "../../function";
import DetailBuild from "./DetailTab/DetailBuild";
import RenderAnalytics from "./DetailTab/RenderAnalytics";


const TabHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .react-tabs {
    &__tab-list {
      display: flex;
      align-items: center;
      margin: 6px 8px;
    }

    &__tab {
      cursor: pointer;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      min-width: 115px;
      height: 28px;
      padding: 0 8px;
      border-radius: 5px;
      border: 1px solid ${props => props.theme.color.default_bright_border_color};
      color: ${props => props.theme.color.default_gray_font_color};
      background-color: ${props => props.theme.color.default_input_background};
      opacity: 0.5;

      &:hover {
        opacity: 1;
        background-color: ${props => props.theme.color.background_4A};
        border: 1px solid ${props => props.theme.color.border_5D};
        color: ${props => props.theme.color.font_fff_A7};
      }

      &--selected {
        opacity: 1;
        background-color: ${props => props.theme.color.background_4A};
        color: ${props => props.theme.color.font_fff_EA};
        border-color: transparent;

        &:hover {
          border-color: transparent;
          color: #fff;
        }
      }

      & + .react-tabs__tab {
        margin-left: 5px;
      }
    }

  }
`;

const HeaderWrapper = styled.div`
  display: ${props => props.visible};
  background-color: ${props => props.theme.color.background_E4};
  border-top: 1px solid ${props => props.theme.color.border_C1};
`;


const LoadingWrapper = styled.div`
  height: 528px;
`;

const ButtonWrapper = styled.div`
  padding: 7px;
  display: flex;
  flex-direction: row;
  border-top: 1px solid ${props => props.theme.color.champion.border};
`;


const MatchDetail = ({
                         summoner,
                         match,
                         myMatch,
                         openIndex,
                         region,
                         isUrf,
                         hide,
                         onChangeHide,
                         queueId,
                         setOpenIndex
                     }) => {
    const theme = useTheme();
    const defaultTabIndex = useMemo(() => {
        try {
            if (match) {
                const team = match.participants_list.filter((data) => data.side === myMatch.side);
                const findIndex = team.findIndex((data) => data.puu_id === myMatch.puu_id);
                return myMatch.side === 'BLUE' ? findIndex : findIndex + 5;
            }
            return 0;
        } catch (e) {
            return 0;
        }
    }, [match, myMatch]);
    const [buildIndex, setBuildIndex] = useState(defaultTabIndex);

    const resetIndex = useCallback(() => {
        setBuildIndex(defaultTabIndex);
    }, [defaultTabIndex]);


    const onChangeBuildIndex = useCallback((summoner) => {
        const team = match.participants_list.filter((data) => data.side === summoner.side);
        const findIndex = team.findIndex((data) => data.puu_id === summoner.puu_id);
        const result = summoner.side === 'BLUE' ? findIndex : findIndex + 5;
        setBuildIndex(result);
        setOpenIndex(1)
    }, [match]);

    const onChangeBuildIndexPara = useCallback((value) => {
        setBuildIndex(value);
    }, []);

    const {t} = useTranslation();

    const renderBuild = useMemo(() => {
        return (queueId === 900 || isArena(queueId) || isSwarm(queueId))
    }, [queueId]);


    return (
        <HeaderWrapper
            borderColor={myMatch.is_win ? theme.color.font_5D : theme.color.font_E3}>
            <Tabs
                selectedIndex={openIndex}
            >
                <TabHeaderWrapper>
                    <TabList>
                        <Tab onClick={() => setOpenIndex(0)}>{t('tabs.aiAnalysis')}</Tab>
                        {!renderBuild && <Tab onClick={() => {
                            setOpenIndex(1)
                        }}>{t('tabs.build')}
                        </Tab>}
                    </TabList>
                </TabHeaderWrapper>
                <TabPanel>
                    <RenderAnalytics queueId={queueId} myMatch={myMatch} match={match}
                                     onChangeBuildIndex={onChangeBuildIndex} isUrf={isUrf} region={region} hide={hide}/>

                    <ButtonWrapper>
                        <DetailShareTag
                            region={region}
                            summoner={summoner}
                            match={match}
                        />
                        <DetailHideSummonerName
                            hide={hide}
                            onChangeHide={onChangeHide}
                        />
                    </ButtonWrapper>
                </TabPanel>
                {!renderBuild && <TabPanel>
                    <DetailBuild match={match.participants_list} myMatch={myMatch} resetIndex={resetIndex}
                                 customIndex={buildIndex} onChangeBuildIndex={onChangeBuildIndexPara}/>
                </TabPanel>}
            </Tabs>
        </HeaderWrapper>
    );
};

export default React.memo(MatchDetail);

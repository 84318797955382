import React, {useCallback} from 'react';
import styled, {useTheme} from "styled-components";
import {useTranslation} from "react-i18next";
import {useHistory, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import TierTag from "../../../../common/TierTag";
import {ColumnBox, FlexBox} from "../../../../ui/styled";
import ChampionImageWithLane from "../../../../common/ChampionImageWithLane";
import InfluInfo from "../../../../summoner/Profile/InfluInfo";
import useChampionFunc from "../../../../../common/hooks/useChampionFunc";
import {getRegionServer, multiplyNumber, scoreColor, threedigits} from "../../../../../function";
import {ImageItem, ImageRune} from "../../../../common";

const Wrapper = styled.tr`
  position: relative;
  border-bottom: 1px solid ${props => props.theme.color.champion.border};
  border-left: 1px solid ${props => props.theme.color.champion.border};
  border-right: 1px solid ${props => props.theme.color.champion.border};
  height: 52px;

  td:not(:last-of-type) {
    cursor: pointer;
  }

`;

const Content = styled.td`
  vertical-align: middle;
  background-color: ${props => props.test && "red"};

  .imgChamp {
    width: 36px;
    height: 36px;
    margin: 0;
  }

  .imgDiv {
    border-radius: 6px;
  }

`;

const TempWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const IndexText = styled.div`
  color: ${props => props.theme.color.background_5C6};
`


const RegionText = styled.span`
  width: 6ch;
  font-size: 14px;
  text-align: center;
  color: ${props => props.theme.color.default_gray_font_color};
  margin: ${props => props.margin || 0};
`;

const LPValueText = styled(RegionText)`
  width: 8ch;
`;


const SummonerText = styled(RegionText)`
  text-align: left;
  width: 15ch;
  height: 17px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
`;

const LPText = styled(RegionText)`
  opacity: 0.6;
`;

const RoundImage = styled.div`
  img {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background-color: black;
    overflow: hidden;
  }
`;

const BorderRadiusImage = styled.div`
  background-color: black;
  overflow: hidden;
  border-radius: 4px;
  margin-left: 9px;

  img {
    height: 26px;
  }
`;


const LaneBox = styled.div`
  margin: 0 5px;
`;


const BuildButton = styled.div`
  cursor: pointer;
  border: 1px solid ${props => props.theme.color.font_5F};
  border-radius: 6px;
  padding: 5px 14px;
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.color.font_5F};
`;

const BuildImgDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
`;
const InfluWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const ChampionDetailMasteryTableContent = ({
                                               tag,
                                               champion_id,
                                               tier,
                                               division,
                                               lp,
                                               riot_id_name,
                                               play_rate,
                                               games,
                                               win_rate,
                                               rank,
                                               region,
                                               influData,
                                               lane,
                                               kda, ds, gd15,
                                               item, rune
                                           }) => {
    const {t} = useTranslation();
    useSelector((state) => state?.gameVersion.version);
    const {getChampion, getUrlNameByChampion} = useChampionFunc();
    const champion = getChampion(champion_id)
    const history = useHistory();
    const location = useLocation();
    const theme = useTheme();

    const navigateToBuild = useCallback(() => {
        const champName = getUrlNameByChampion(champion);
        history.push(`/champions/${champName}/build/aram`)
    }, [champion, location.search]);

    const navigateToSummoner = useCallback((e) => {
        e.stopPropagation();
        const serverRegion = getRegionServer(region).toLowerCase();
        window.open(`/summoner/${serverRegion}/${riot_id_name}-${tag}`)
    }, [location.search, riot_id_name, tag, region]);


    const navigateToMastery = useCallback((e) => {
        e.stopPropagation();
        const serverRegion = getRegionServer(region).toLowerCase();
        window.open(`/summoner/${serverRegion}/${riot_id_name}-${tag}/mastery?champion=${champion.champion_id}`)
    }, [location.search, riot_id_name, tag, region]);
    return (
        <Wrapper>
            <Content onClick={navigateToBuild}>
                <TempWrapper>
                    <IndexText>{rank}</IndexText>
                </TempWrapper>
            </Content>
            <Content>
                <TempWrapper>
                    <RegionText>{getRegionServer(region)}</RegionText>
                </TempWrapper>
            </Content>
            <Content>
                <FlexBox>
                    <ChampionImageWithLane
                        champion_id={champion_id}
                        borderRadius={'50%'}
                    />
                    <ColumnBox margin={'0 0 0 8px'} height={'36px'}
                               justifyContent={influData?.status ? 'space-between' : 'center'}>
                        <SummonerText onClick={navigateToSummoner}>{riot_id_name}</SummonerText>
                        {influData?.status ? <InfluWrapper>
                            <InfluInfo
                                textColor={theme.color.background_49}
                                influData={influData}
                            />
                        </InfluWrapper> : null}
                    </ColumnBox>
                </FlexBox>
            </Content>
            <Content>
                <TempWrapper>
                    <TierTag
                        fontSize={'11px'}
                        padding={'4px 4px'}
                        tier={tier}
                        division={division}
                    />
                    <LPValueText margin={'0 0 0 10px'}>{threedigits(lp)}<LPText> LP</LPText></LPValueText>
                </TempWrapper>
            </Content>
            <Content>
                <TempWrapper>
                    <LaneBox>
                        <img src={`${theme.icon.path}/icon_s_position_${lane.toLowerCase()}.svg`}
                             alt={""}/>
                    </LaneBox>
                </TempWrapper>
            </Content>
            <Content>
                <TempWrapper>
                    <RoundImage>
                        <ImageRune runeId={rune}/>
                    </RoundImage>
                    <BorderRadiusImage>
                        <ImageItem itemId={item}/>
                    </BorderRadiusImage>
                </TempWrapper>
            </Content>
            <Content>
                <TempWrapper>
                    <RegionText>{multiplyNumber(play_rate, 100)}<LPText> %</LPText></RegionText>
                </TempWrapper>
            </Content>
            <Content>
                <TempWrapper>
                    <RegionText>{threedigits(games)}</RegionText>
                </TempWrapper>
            </Content>
            <Content>
                <TempWrapper>
                    <RegionText>{scoreColor(multiplyNumber(win_rate, 100,))}<LPText> %</LPText></RegionText>
                </TempWrapper>
            </Content>
            <Content>
                <TempWrapper>
                    <RegionText>{kda?.toFixed(2)}</RegionText>
                </TempWrapper>
            </Content>
            <Content>
                <TempWrapper>
                    <RegionText margin={'0 8px 0 0'}>{gd15 > 0 ? '+' : ""}{gd15}</RegionText>
                </TempWrapper>
            </Content>
            <Content>
                <TempWrapper>
                    <RegionText>{multiplyNumber(ds, 100,)}<LPText> %</LPText></RegionText>
                </TempWrapper>
            </Content>
            <Content>
                <TempWrapper>

                    <BuildButton onClick={navigateToMastery}>
                        <FlexBox>
                            <BuildImgDiv>
                                <img src={'/images/icon_build_more.svg'} width={16} height={16} alt={""}/>
                            </BuildImgDiv>
                            {t('mastery.buildMore')}
                        </FlexBox>
                    </BuildButton>

                </TempWrapper>
            </Content>
        </Wrapper>
    );
};


export default ChampionDetailMasteryTableContent;

import React from 'react';
import TierTag from "../../../../common/TierTag";
import ScoreWrapper from "../../../../summoner/DetailTab/GameAnalytics/BestContent/BestSection/ScoreWrapper";
import ChampionImageWithLane from "../../../../common/ChampionImageWithLane";
import styled from "styled-components";

const BodyTd = styled.td`
  vertical-align: middle;
`;
const CenterBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const BanWrapper = styled(CenterBox)`
  .imgDiv {
    border-radius: 0;
    margin-inline: 3px;
  }

  .imgChamp {
    width: 24px;
    height: 24px;
    margin: 0;
    border: 2px solid ${props => props.borderColor};
  }
  .imgDiv {
    border-radius: 3px;
  }


  .non_image {
    width: 24px;
    height: 24px;
  }
`;

const TeamText = styled.span`
  color: ${props => props.color};
  font-weight: bold;
  font-size: 14px;
  width: 9ch;
  margin-right: 18px;
`;

const BodyTr = styled.tr`
  height: 41px;
  border-bottom: ${props => {
    let bottom = 0;
    if (props.borderBottom) bottom = `1px solid ${props.theme.color.border_A5}`;
    return bottom;
  }};
`;

const Graph = styled.div`
  display: flex;
  justify-content: ${props => {
    let items = 'flex-end';
    if (props.reverse) items = 'flex-start';
    return items;
  }};
  flex: 1;
  height: 8px;
  background-color: ${props => {
    let bgColor = '#ffffff';
    if (props.bgColor) bgColor = props.bgColor;
    return bgColor;
  }};
  border-radius: 6px;
`;


const Filled = styled.div`
  height: 8px;
  border-radius: 6px;
  width: ${props => props.percent}%;
  background-color: ${props => props.color};
  position: relative;
`;

const GraphWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 15px;
`;

const WinRateText = styled.span`
  color: ${props => props.color};
  font-size: 20px;
  font-weight: bold;
  margin-left: 28px;
`;
const PercentText = styled.span`
  color: ${props => props.theme.color.default_font_color};
  font-size: 12px;
  opacity: 0.6;
  margin-left: 2px;
`;


const OverviewTableTr = ({borderBottom, teamColor, teamName,winRate,aiScore,banList,tier}) => {

    return (
        <BodyTr borderBottom={borderBottom}>
            <BodyTd>
                <GraphWrapper>
                    <TeamText color={teamColor}>{teamName}</TeamText>
                    <Graph bgColor={'#DCE1E5'} reverse={true}>
                        <Filled percent={winRate} color={teamColor}/>
                    </Graph>
                    <WinRateText color={teamColor}>{winRate?.toFixed(0)}<PercentText>%</PercentText></WinRateText>
                </GraphWrapper>
            </BodyTd>
            <BodyTd>
                <CenterBox>
                    <TierTag
                        fontSize={'11px'}
                        padding={'4px 4px'}
                        tier={tier.tier}
                        division={tier.division}
                    />
                </CenterBox>
            </BodyTd>
            <BodyTd>
                <CenterBox>
                    <ScoreWrapper
                        noneClickable={true}
                        score={aiScore}
                        fontSize={18}
                        paddingHorizontal={8}
                    />
                </CenterBox>
            </BodyTd>
            <BodyTd>
                <BanWrapper borderColor={teamColor}>
                    {banList.map((data) => {
                        return (
                            <ChampionImageWithLane
                                key={data}
                                borderRadius={0}
                                champion_id={data}
                            />
                        )
                    })}
                </BanWrapper>
            </BodyTd>
        </BodyTr>
    );
};

export default OverviewTableTr;

import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import {useSelector} from "react-redux";
import ChampionsChampionImage from "./ChampionsImage";
import {cho_convertor, sortChampData} from "../../../../../function";
import {Loading} from "../../../../common";
import ChampionsListHeader from "./ChampionsListHeader";
import ChampionsEmpty from "../../../ChampionsEmpty";

const SectionWrapper = styled.div`
  display: ${props => {
    let display = 'flex';
    if (props?.visible) display = 'none';
    return display;
  }};
`;
const Wrapper = styled.div`
  min-height: 20vh;
`;

const ImageWrapper = styled.ul`
  flex: 1;
  border-bottom-width: 1px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-style: solid;
  border-color:${props => props.theme.color.border_E7};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ChampionsList = ({choVisible = false, inidivisualVisible, laneVisible, onClickChamp}) => {

    const version = useSelector((state) => state?.gameVersion.version);
    const championData = useSelector((state) => state?.imageStore.champions);
    const korName = useMemo(() => ["ㄱ", "ㄴ", "ㄷ", "ㄹ", "ㅁ", "ㅂ", "ㅅ", "ㅇ", "ㅈ", "ㅊ", "ㅋ", "ㅌ", "ㅍ", "ㅎ",], []);
    const endName = useMemo(() => ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'], []);
    const currentLang = useSelector((state) => state.translation.lang);
    const isKor = useMemo(() => currentLang === 'KOR', [currentLang]);
    const renderData = useMemo(() => isKor ? korName : endName, [isKor, korName, endName]);
    const championlanes = useSelector((state) => state?.champions?.ranks);
    const rotation = useSelector((state) => state?.champions?.rotations);
    const [visibleCounter, setVisibleCounter] = useState(999);


    const nameSortValue = useMemo(() => [{
        value: isKor ? 'champion_name_kr' : 'champion_name_en',
        sortType: true,
    }], [isKor]);


    const setMapData = useCallback((value) => {
        if (value !== undefined) {
            const regexp = new RegExp(`^${value}+`);
            const doubleExp = new RegExp(`^([ㄱㄷㅅㅂㅈ])+`);
            if (isKor) {

                if (doubleExp.test(value)) {
                    let replacedValue = value.replaceAll('ㄱ', "ㄲ");
                    replacedValue = replacedValue.replaceAll("ㄷ", "ㄸ");
                    replacedValue = replacedValue.replaceAll("ㅅ", "ㅆ");
                    replacedValue = replacedValue.replaceAll("ㅂ", "ㅃ");
                    replacedValue = replacedValue.replaceAll("ㅈ", "ㅉ");

                    const replacedRegexp = new RegExp(`^${replacedValue}+`)
                    const data = championData.filter((filterData) => {
                        return regexp.test(filterData.cho_name) || replacedRegexp.test(filterData.cho_name);
                    });
                    return sortChampData(data, nameSortValue);
                } else {
                    const data = championData.filter((filterData) => {
                        return regexp.test(filterData.cho_name);
                    });
                    return sortChampData(data, nameSortValue);
                }
            } else {
                const data = championData.filter((filterData) => {
                    return regexp.test(filterData.champion_name_en);
                });
                return sortChampData(data, nameSortValue);
            }

        } else {
            return [];
        }
    }, [championData, nameSortValue, isKor]);

    const shouldHide = useCallback((value, contain, shoulVisible) => {
        if (shoulVisible) {
            if (inidivisualVisible.length > 0) {
                return false;
            }


            if (value !== false && value !== undefined) {
                return value !== contain;
            } else {
                return false;
            }
        } else {
            return true;
        }

    }, [inidivisualVisible]);

    const isVisible = useCallback((data) => {
        try {
            if (typeof inidivisualVisible === 'string' && inidivisualVisible !== '') {
                const regexp = new RegExp(`^${cho_convertor(inidivisualVisible.toLowerCase())}+`);
                if (regexp.test(data.champion_name_en.toLowerCase())) {
                    return true;
                } else if (regexp.test(data.champion_name_kr)) {
                    return true;
                } else if (regexp.test(data.cho_name.replace(" ", ""))) {
                    return true;
                } else if (regexp.test(data.cho_name)) {
                    return true;
                } else {
                    let replacedValue = inidivisualVisible.replace('ㄱ', "ㄲ");
                    replacedValue = replacedValue.replace("ㄷ", "ㄸ");
                    replacedValue = replacedValue.replace("ㅅ", "ㅆ");
                    replacedValue = replacedValue.replace("ㅂ", "ㅃ");
                    replacedValue = replacedValue.replace("ㅈ", "ㅉ");
                    const replacedRegexp = new RegExp(`^${replacedValue}+`)
                    return replacedRegexp.test(data.cho_name);
                }
            } else if (typeof laneVisible === 'string' && laneVisible !== 'all' && laneVisible !== 'rotation') {
                const visible = championlanes && championlanes[laneVisible].find((map) => Number(data.champion_id) === Number(map.champion_id));
                return visible ? true : false;
            } else if (laneVisible === 'rotation') {
                return rotation?.includes(Number(data.champion_id))
            } else {
                return true;
            }
        } catch (e) {
            return false;
        }


    }, [inidivisualVisible, laneVisible, championlanes]);

    useEffect(() => {
        if (championData.length > 0) {
            const counter = Array.from(document.querySelectorAll('.visible'));
            setVisibleCounter(counter?.length);
        }

    }, [inidivisualVisible, choVisible, laneVisible, championData])


    return (
        <Wrapper>
            {visibleCounter === 0 && <ChampionsEmpty/>}
            {championData.length === 0 ? <Loading/> :
                Array.isArray(renderData) && (
                    renderData.map((render) => {
                        const mapData = setMapData(render);

                        const visibleMap = mapData.map((data) => {
                            return isVisible(data)
                        });
                        const shouldVisible = visibleMap.find((data) => data === true);
                        const visible = shouldHide(choVisible, render, shouldVisible, mapData);
                        return (
                            <SectionWrapper
                                className={visible ? "none" : "visible"}
                                visible={visible}
                                key={render + "alphabet"}>
                                <ChampionsListHeader
                                    data={render}
                                />
                                <ImageWrapper>
                                    {mapData.map((data, index) => {
                                        return (
                                            <ChampionsChampionImage
                                                lane={inidivisualVisible.length > 0 ? "all" : laneVisible}
                                                isFree={rotation?.includes(Number(data?.champion_id))}
                                                visible={visibleMap[index]}
                                                lang={currentLang}
                                                key={data.champion_id}
                                                version={version}
                                                imageName={data.image_name}
                                                koChampName={data.champion_name_kr}
                                                enChampName={data.champion_name_en}
                                                onClickChamp={onClickChamp}
                                            />
                                        )
                                    })}
                                </ImageWrapper>
                            </SectionWrapper>
                        )
                    })
                )}
        </Wrapper>
    );
};

export default ChampionsList;

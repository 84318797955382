import styled from 'styled-components';

export const HomeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  padding: 0 0 3rem 0;
  .home {
    width: 1162px;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5rem 0 0;
    .react-tabs {
      position: relative;
      background-color: ${props => props.theme.color.default_background_color};
      box-shadow: 0px 1px 6px #00000029;
      border: 1px solid ${props => props.theme.color.autoComplete.content_border_color};
      border-radius: 6px;
      overflow: hidden;
      &__tab-list {
        display: flex;
        border-radius: 6px;
      }
      &__tab {
        position: relative;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 37px;
        font-size: 14px;
        font-weight: 500;
        color: ${props => props.theme.color.searchList.tab_font_color};
        background-color: ${props => props.theme.color.searchList.tab_idle_bg};
        cursor: pointer;
        border-bottom: 1px solid ${props => props.theme.color.searchList.tab_border_color};


        &:first-of-type {
          border-right: 1px solid ${props => props.theme.color.searchList.tab_border_color};
        }
        
        &:last-of-type {
          border-left: 1px solid ${props => props.theme.color.searchList.tab_border_color};
        }
        
        
        
        &--selected {
          background-color: ${props => props.theme.color.default_input_background};
          color: ${props => props.theme.color.searchList.tab_selected_font_color};
          border-bottom: 0;
          
          &:first-of-type {
            border-top-left-radius: 6px;
            border-left: 0;
            border-right: 0;
          }
          &:last-of-type {
            border-top-right-radius: 6px;
            border-right: 0;
            border-left: 0;
          }
        }
      }
      &__tab-panel {
        display: none;
        border-top: 0;
        ::-webkit-scrollbar {
          width: 5px;
        }
        ::-webkit-scrollbar-thumb {
          background-color: #b8cddc;
        }
        ::-webkit-scrollbar-track {
          background-color: #dbe8ff;
        }

        &--selected {
          display: block;
        }
      }
    }
  }
`;

import React, {useMemo} from 'react';
import styled from "styled-components";
import {SummonerMatchBox} from "../../../ui/Box";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {useSelector} from "react-redux";
import {FlexBox} from "../../../ui/styled";
import SmallInfluInfo from "../../../common/SmallInfluInfo";
import {sortChampData} from "../../../../function";
import {useTranslation} from "react-i18next";
import {Loading} from "../../../common";
import {useParams} from "react-router-dom";
import ProfileIcon from "../../../common/ProfileIcon";

const Wrapper = styled(SummonerMatchBox)`
  background-color: ${props => props.theme.color.default_input_background};
  width: 304px;
  padding-top: 8px;
  border-radius: 6px;
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 300px;
`;

const CustomTabList = styled(TabList)`
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  padding: 8px;
`;

const CustomTab = styled(Tab)`
  cursor: pointer;
  font-size: 12px;
  flex: 1;
  padding: 6px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: 1px solid ${props => props.theme.color.default_border_color};
  border-radius: 5px;

  &:hover {
    background-color: ${props => props.theme.color.background_4A};
    color: ${props => props.theme.color.font_fff_A7};
    border: 1px solid ${props => props.theme.color.border_5D};
  }

  & + & {
    margin-left: 16px;
  }

  &.react-tabs__tab--selected {
    background-color: ${props => props.theme.color.background_4A};
    color: #fff;
    border-color: transparent;
  }
`;
const Table = styled.table`
  width: 100%;

  .imgIcon {
    border-radius: 6px;
  }
`;
const HeaderTR = styled.tr`
  height: 41px;
  border-top: 1px solid ${props => props.theme.color.default_border_color};
  border-bottom: 1px solid ${props => props.theme.color.default_border_color};
`;

const HeaderTh = styled.th`
  font-size: 12px;
  position: relative;
  vertical-align: middle;
  color: ${props => props.theme.color.font_58};
`;

const BodyTR = styled.tr`
  height: 40px;
`;


const BodyTd = styled.td`
  vertical-align: middle;
  height: 41px;

  .profile_icon {
    border-radius: 50%;
  }
`;
const SummonerName = styled.span`
  color: ${props => props.theme.color.font_7F};;
  font-size: 12px;
  font-weight: 400;
  margin-left: 3px;
  line-height: 12px;
`;
const Title = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 16.8px;
  color: ${props => props.theme.color.font_000};;
`;

const MoreButton = styled.button`
  opacity: 0.7;
  font-size: 12px;
  font-weight: 400;
  padding: 10px;
  text-align: center;
  width: 100%;
  background-color: ${props => props.theme.color.background_EA};;
  border-top: 1px solid ${props => props.theme.color.default_bright_border_color};
`;

const UnderLineFlexBox = styled(FlexBox)`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;
const AbsoluteWrapper = styled.div`
  position: absolute;
  right: -7px;
  bottom: 0;
`;


const SummonerMatchesRecentPlayWith = ({matchList}) => {
    const summoner = useSelector((state) => state.summoner.summoner);
    const {t} = useTranslation();
    const setTeamObj = (team, item, findSummoner) => {
        return {
            games: team[item.puu_id]?.games ? team[item.puu_id].games + 1 : 1,
            win: findSummoner.is_win ? team[item.puu_id]?.win ? team[item.puu_id]?.win + 1 : 1 : team[item.puu_id]?.win || 0,
            lose: !findSummoner.is_win ? team[item.puu_id]?.lose ? team[item.puu_id]?.lose + 1 : 1 : team[item.puu_id]?.lose || 0,
            pro_streamer_status: item.pro_streamer_status,
            riot_id_name: item.riot_id_name,
            riot_id_tag_line: item.riot_id_tag_line,
            profile_icon: item.profile_icon || 0,
            summoner_name: item.summoner_name,
        }
    }
    const listObj = useMemo(() => {
        try {
            const summoner_puu_id = summoner.summoner_basic_info_dict.puu_id;
            const sliceList = matchList;
            const withTeam = {};
            const enemyTeam = {};
            sliceList.forEach((data) => {
                const findSummoner = data.participants_list.find((item) => item.puu_id === summoner_puu_id);
                data.participants_list.forEach((item) => {
                    if (item.side === findSummoner.side && findSummoner.puu_id !== item.puu_id) {
                        withTeam[item.puu_id] = setTeamObj(withTeam, item, findSummoner)
                    }
                    if (item.side !== findSummoner.side) {
                        enemyTeam[item.puu_id] = setTeamObj(enemyTeam, item, findSummoner);
                    }
                })
            });
            return {
                withTeam: sortChampData(Object.values(withTeam), [{value: "games", sortType: false}, {
                    value: 'win',
                    sortType: false
                }]).slice(0, 7),
                enemyTeam: sortChampData(Object.values(enemyTeam), [{value: "games", sortType: false}, {
                    value: 'win',
                    sortType: false
                }]).slice(0, 7),
            }

        } catch (e) {
            return {
                withTeam: [],
                enemyTeam: [],
            }
        }
    }, [matchList]);

    const {
        getMatchByQueueDone,
    } = useSelector((state) => state.summoner);

    if (!getMatchByQueueDone) {
        return (
            <Wrapper>
                <EmptyWrapper>
                    <Loading/>
                </EmptyWrapper>
            </Wrapper>
        )
    }
    if (matchList.length < 1) {
        return null;
    }
    return (
        <Wrapper>
            <FlexBox width={'100%'} justifyContent={'center'}>
                <Title>{t('summoner.recentPlayed', {count: matchList.length})}</Title>
            </FlexBox>
            <Tabs>
                <CustomTabList>
                    <RecentCustomTab>{t('summoner.ourTeam')}</RecentCustomTab>
                    <RecentCustomTab>{t('summoner.enemyTeam')}</RecentCustomTab>
                </CustomTabList>
                <TabPanel>
                    <RecentCustomPanel list={listObj.withTeam}/>
                </TabPanel>
                <TabPanel>
                    <RecentCustomPanel list={listObj.enemyTeam}/>
                </TabPanel>
            </Tabs>
        </Wrapper>
    );
};


const RecentCustomPanel = ({list}) => {
    const {t} = useTranslation();
    const version = useSelector((state) => state.gameVersion.version);
    const summonerRegion = useParams()?.region?.toLowerCase() || 'kr';
    return (
        <Table>
            <colgroup>
                <col width={'50%'}/>
                <col width={'15%'}/>
                <col width={'20%'}/>
                <col width={'15%'}/>
            </colgroup>
            <thead>
            <HeaderTR>
                <HeaderTh>{t('gameAnalytics.summoner')}</HeaderTh>
                <HeaderTh>{t('tabs.games')}</HeaderTh>
                <HeaderTh>{t('summoner.enemyTeam_winLose')}</HeaderTh>
                <HeaderTh>{t('tabs.winrate')}</HeaderTh>
            </HeaderTR>
            </thead>
            <tbody>
            {list.map((data, index) => {
                return (
                    <BodyTR key={index}>
                        <BodyTd>
                            <FlexBox margin={'0 0 0 14px'}>
                                <IconWrapper>
                                    <ProfileIcon width={28} height={28} iconId={data.profile_icon}/>
                                    <AbsoluteWrapper>
                                        <SmallInfluInfo status={data.pro_streamer_status}/>
                                    </AbsoluteWrapper>
                                </IconWrapper>
                                <UnderLineFlexBox alignItems={'center'} margin={'0 0 0 11px'}
                                                  onClick={() => window.open(`/summoner/${summonerRegion}/${data.riot_id_name}-${data.riot_id_tag_line}`)}
                                                  width={'auto'}>
                                    <SummonerName>{data.riot_id_name ? decodeURI(data.riot_id_name) : data.summoner_name}</SummonerName>
                                </UnderLineFlexBox>
                            </FlexBox>
                        </BodyTd>
                        <BodyTd>
                            <FlexBox justifyContent={'center'}>
                                <SummonerName>{data.games}</SummonerName>
                            </FlexBox>
                        </BodyTd>
                        <BodyTd>
                            <FlexBox justifyContent={'center'}>
                                <SummonerName>{data.win} - {data.lose}</SummonerName>
                            </FlexBox>
                        </BodyTd>
                        <BodyTd>
                            <FlexBox justifyContent={'center'}>
                                <SummonerName>{((data.win / (data.win + data.lose)) * 100).toFixed(0)}%</SummonerName>
                            </FlexBox>
                        </BodyTd>
                    </BodyTR>
                )
            })}
            </tbody>
        </Table>

    )
}

const RecentCustomTab = ({children, ...others}) => {
    return (
        <CustomTab {...others}>
            <span>{children}</span>
        </CustomTab>
    )
}

RecentCustomTab.tabsRole = 'Tab'
export default SummonerMatchesRecentPlayWith;
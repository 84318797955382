import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import React, {useCallback, useMemo} from "react";
import runeShards from "../../../../store/static_json/runeShards";
import runeShards_en from "../../../../store/static_json/runeShards_en";
import JustRuneImage from "../ChampionsDetailRunes/JustRuneImage";
import DetailMapRune from "../ChampionsDetailRunes/DetailPrimaryRunes";


const HeaderBox = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${props => props.theme.color.default_gray_font_color};
  padding-left: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-bottom: 1px solid ${props => props.theme.color.default_bright_border_color};
  background-color: ${props => props.theme.color.background_F5};
  position: relative;
`;


const RuneContent = styled.div`
  width: 100%;
  height: 100%;
  padding: 37px 20px;
  background-color: ${props => props.theme.color.default_input_background};
`;
const RuneBox = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid ${props => props.theme.color.default_bright_border_color};
  height: 100%;
  width: 100%;
  border-radius: 6px;
  padding: 22px;
`;
const Flex = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const SubRune = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${props => props.marginTop || 16}px;
`;


const MainRuneInfoBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: ${props => props.marginBottom || 0}px;
`;

const MainRuneText = styled.span`
  font-size: 14px;
  font-weight: 500;
  margin-left: 9px;
`;

const MainRuneInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 24px;
`;
const DiamondWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  margin-top: 14px;
`;

const DiamondBar = styled.div`
  width: 95%;
  height: 1px;
  background-color: #CAD7E2;
`;
const AbsoluteDiamon = styled.div`
  position: absolute;
  left: ${props => props.left}px;
  right: ${props => props.right}px;
`;

const Diamond = styled.div`
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-bottom-color: #CAD7E2;
  position: relative;
  top: -4px;

  &:after {
    content: '';
    position: absolute;
    left: -4px;
    top: 4px;
    width: 0;
    height: 0;
    border: 4px solid transparent;
    border-top-color: #CAD7E2;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${props => props.theme.color.default_bright_border_color};
  height: 479px;
  background-color: ${props => props.theme.color.default_input_background};

  ${Flex}:first-child {
    margin-right: 45px;
  }
`;

const ChampionsDetailBuildRuneBuild = ({runeList,customRunes}) => {
    const {i18n,t} = useTranslation();
    const runes = useSelector((state) => state.champions.buildRune);
    const currentRuneShards = useMemo(() => ['ko', 'ko-KR'].includes(i18n.language) ? runeShards : runeShards_en, [i18n.language]);
    const findCategoryRune = useCallback((runeData, findValue) => {
        return runeData.find((data) => data.id === findValue);
    }, []);

    const findChildRune = useCallback((categoryRune = [], findValue) => {
        return categoryRune.find((data) => {
            if (data.id === findValue) {
                return true;
            } else if (Array.isArray(data.runes)) {
                return findCategoryRune(data.runes, findValue);
            }
        });
    }, [findCategoryRune]);


    const findParentRune = useCallback((categoryRune = [], findValue) => {
        try {
            if (typeof findValue === 'number') {
                return categoryRune.find((data) => {
                    if (data.id === findValue) {
                        return true;
                    } else {
                        return findChildRune(data.slots, findValue);
                    }
                });
            }
            return null;
        } catch (e) {
            return [];
        }

    }, [findChildRune]);
    if (runeList?.rune_main?.length < 1) return (
        <Wrapper>
            <HeaderBox>{t('summoner.mastery.rune')}</HeaderBox>
        </Wrapper>);

    return (
        <Wrapper className={'summoner_mastery_wrapper'}>
            <HeaderBox>{t('summoner.mastery.rune')}</HeaderBox>
            <RuneContent>
                <RuneBox>
                    <Flex>
                        <MainRuneInfo>
                            <MainRuneInfoBox marginBottom={8}>
                                <JustRuneImage
                                    width={24}
                                    height={24}
                                    image={findParentRune(runes, runeList?.rune_main?.[0])?.icon}
                                />
                                <MainRuneText>{findParentRune(runes, runeList?.rune_main?.[0])?.name}</MainRuneText>
                            </MainRuneInfoBox>
                        </MainRuneInfo>
                        {findParentRune(runes, runeList?.rune_main?.[0])?.slots?.map((data, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <SubRune marginTop={index > 1 ? 32 : 16}>
                                        <DetailMapRune
                                            width={32}
                                            height={32}
                                            selectedRune={[...runeList.rune_main]}
                                            imageBorder={index === 0 ? 0 : 2}
                                            borderColor={"#46B9AA"}
                                            runes={data.runes}
                                        />
                                    </SubRune>
                                    {index === 0 &&
                                        <DiamondWrapper>
                                            <AbsoluteDiamon left={0}>
                                                <Diamond/>
                                            </AbsoluteDiamon>
                                            <DiamondBar/>
                                            <AbsoluteDiamon right={0}>
                                                <Diamond/>
                                            </AbsoluteDiamon>
                                        </DiamondWrapper>}
                                </React.Fragment>
                            )
                        })}
                    </Flex>
                    <Flex>
                        <MainRuneInfo>
                            <MainRuneInfoBox marginBottom={8}>
                                <JustRuneImage
                                    width={24}
                                    height={24}
                                    image={findParentRune(runes, runeList?.rune_sub?.[0])?.icon}
                                />
                                <MainRuneText>{findParentRune(runes, runeList?.rune_sub?.[0])?.name}</MainRuneText>
                            </MainRuneInfoBox>
                        </MainRuneInfo>
                        {findParentRune(runes, runeList?.rune_sub?.[0])?.slots?.map((data, index) => {
                            if (index === 0) return null
                            return (
                                <SubRune key={index}>
                                    <DetailMapRune
                                        width={32}
                                        height={32}
                                        selectedRune={[...runeList.rune_sub]}
                                        imageBorder={2}
                                        borderColor={"#46B9AA"}
                                        runes={data.runes}
                                    />
                                </SubRune>
                            )
                        })}
                        <DiamondWrapper>
                            <AbsoluteDiamon left={0}>
                                <Diamond/>
                            </AbsoluteDiamon>
                            <DiamondBar/>
                            <AbsoluteDiamon right={0}>
                                <Diamond/>
                            </AbsoluteDiamon>
                        </DiamondWrapper>
                        {currentRuneShards?.slots?.map((data, index) => {
                            const selectedRuneData = () => {
                                if (index === 0) {
                                    return [runeList.rune_stat?.[2]];
                                } else if (index === 1) {
                                    return [runeList.rune_stat?.[1]]
                                } else if (index === 2) {
                                    return [runeList.rune_stat?.[0]]
                                }
                            }
                            return (
                                <SubRune key={index}>
                                    <DetailMapRune
                                        width={20}
                                        height={20}
                                        selectedRune={selectedRuneData()}
                                        imageBorder={2}
                                        borderColor={"#46B9AA"}
                                        runes={data.runes}
                                    />
                                </SubRune>
                            )
                        })}
                    </Flex>
                </RuneBox>
            </RuneContent>
        </Wrapper>
    );
};

export default ChampionsDetailBuildRuneBuild;
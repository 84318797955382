/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useMemo} from 'react';
import styled from "styled-components";
import BuildCategory from "./BuildCategory";
import {Redirect, Route, Switch, useLocation, useRouteMatch} from "react-router-dom";
import NotFound from "../../../NotFound";
import {sortNestedObject, urlChecker} from "../../../../function";
import {useDispatch, useSelector} from "react-redux";
import {GET_CHAMPIONS_BUILD_REQUEST} from "../../../../store/reducer/champions";
import InternalServerError from "../../../common/InternalError";
import {useTranslation} from "react-i18next";
import useInput from "../../../../common/hooks/useInput";
import useQuery from "../../../../common/hooks/useQuery";
import {Loading} from "../../../common";
import ChampionsDetailMasteryLayout from "../ChampionsDetailMasteryLayout";
import {getDataDragonRegion} from "../../../../i18n/languages";
import useTitleAndDescription from "../../../../common/hooks/useTitleAndDescription";
import useLaneTrans from "../../../../common/hooks/useLaneTrans";

const Wrapper = styled.div`
  min-height: 80vh;
`;


const ChampionsDetailBuild = ({champion, championName, skillList}) => {
    const dispatch = useDispatch();
    const {path, url} = useRouteMatch();
    const location = useLocation();
    const {t, i18n} = useTranslation();
    const query = useQuery();
    const buildVersion = useSelector((state) => state.champions.championVersions);
    const [currentVersion, onChangeCurrentVersion] = useInput(query.get("version") !== 'null' && query.get("version") ? encodeURIComponent(query.get("version")) : buildVersion[0]);
    const [currentTier, onChangeTier] = useInput(query.get("tier") !== 'null' && query.get("tier") ? decodeURIComponent(query.get("tier")).trim() + "+" : "Emerald+");
    const buildInfo = useSelector((state) => state?.champions?.build);
    const trans = useLaneTrans();


    const {
        getChampionsBuildLoading,
        getChampionsBuildError,
        getChampionsBuildDone,
        getChampionsBuildVersionLoading,
        getChampionsBuildVersionError,
    } = useSelector((state) => state?.champions);
    const urls = useMemo(() => Array.isArray(buildInfo?.build) ? buildInfo.build.map((data) => data.lane.toLowerCase()) : [], [buildInfo]);



    const currentLane = useMemo(() => {
        if (Array.isArray(urls)) {
            if (location.pathname.split("/")[4]) {
                const index = urls.findIndex((data) => data === location.pathname.split("/")[4]);
                if (index < 0) {
                    return -1;
                } else {
                    return index;
                }
            }
        }
        return 0;
    }, [location, urls]);


    const combinedText = useMemo(() => Array.isArray(urls) ? urls.reduce((sum, prev, index) => {
        if (index + 1 === urls.length) {
            sum += prev
        } else {
            sum += prev + "|"
        }
        return sum;
    }, "") : '', [urls]);
    const currentBuild = useMemo(() => Array.isArray(buildInfo?.build) && buildInfo?.build[currentLane], [buildInfo?.build, currentLane]);


    useEffect(() => {
        if (!getChampionsBuildLoading) {
            dispatch({
                type: GET_CHAMPIONS_BUILD_REQUEST,
                data: {
                    platform_id: 'KR',
                    currentLang: getDataDragonRegion(i18n.language),
                    champion_id: champion.champion_id,
                    tier: currentTier,
                    version: currentVersion,
                }
            })
        }
    }, [champion, currentTier, currentVersion,i18n.language]);

    // console.log("info",buildInfo);

    const initialUrl = useCallback(() => {
        if (Number(buildInfo.champion_id) === Number(champion.champion_id)) {
            const finalUrl = urlChecker(url);
            const filterAramList = buildInfo.build.filter((data) => ['top', 'middle', 'bot', 'jungle', 'supporter'].includes(data.lane.toLowerCase()));
            const hasAram = buildInfo.build.find((data) => data.lane.toLowerCase() === 'aram');
            const soredBuil = sortNestedObject(filterAramList, [{
                value: 'games',
                sortType: false
            }]);
            if (filterAramList.length > 0) {
                return `${finalUrl}/${soredBuil[0]?.lane?.toLowerCase()}${location.search}`;
            } else if (hasAram) {
                return `${finalUrl}/aram${location.search}`;
            } else {
                return `${finalUrl}/${soredBuil[0]?.lane?.toLowerCase()}${location.search}`;
            }
        }
    }, [url, buildInfo, champion, location.search])


    const redirectToFirst = useCallback(() => {
        if (buildInfo.champion_id === champion.champion_id && urls.length > 0) {
            const finalUrl = initialUrl();
            if (finalUrl) {
                return <Redirect to={`${finalUrl}`}/>
            }
        }
    }, [initialUrl, urls]);


    const renderEmptyOr404 = useCallback(() => {
        if (urls.length === 0) {
            if (urls.length === 0 && !getChampionsBuildLoading) {
                return (
                    <Wrapper>
                        <InternalServerError
                            errorText={"There are no Data"}
                        />
                    </Wrapper>)
            } else {
                return <NotFound/>
            }
        }
        return <BuildCategory
            championName={championName}
            selectedLane={urls[currentLane]}
            currentTier={currentTier}
            onChangeTier={onChangeTier}
            changeVersion={onChangeCurrentVersion}
            filterCurrentVersion={currentVersion}
            champion_id={Number(champion.champion_id)}
            buildInfo={currentBuild}
            urls={urls ? urls : []}
        />;
    }, [urls, getChampionsBuildLoading])



    useTitleAndDescription({
        title: t(urls[currentLane] === 'aram' ? 'header.titleAramBuild' : 'header.titleChampionBuild',{
            position: trans(urls[currentLane]),
            champion: champion.champion_name
        }),
        description: t(urls[currentLane] === 'aram' ? 'header.titleAramBuildDescription' : 'header.titleChampionBuildDescription',{
            version:currentVersion,
            position:trans(urls[currentLane]),
            champion: champion.champion_name,
        })
    })


    if (getChampionsBuildError !== null || getChampionsBuildVersionError !== null) {
        if (getChampionsBuildError.response.status) {
            return (
                <InternalServerError errorText={t("championDetail.error500")}/>
            )
        }
        return (
            <InternalServerError/>
        )
    }



    if (!getChampionsBuildDone || getChampionsBuildVersionLoading) return (
        <Wrapper>
            <Loading/>
        </Wrapper>
    );

    if (currentLane === -1) {
        return (
            <InternalServerError errorText={t("championDetail.error500")}/>
        )
    }

    if (urls.length === 0) {
        return <InternalServerError errorText={t("championDetail.error500")}/>
    }

    return (
        <Wrapper>
            <Switch>
                <Route path={`${path}/:lane(${combinedText})`}>
                    <ChampionsDetailMasteryLayout
                        skillList={skillList}
                        championName={championName}
                        currentBuild={buildInfo}
                        championId={champion.champion_id}
                        versionList={buildVersion}
                        currentLane={currentLane}
                        champion={champion}
                        currentTier={currentTier}
                        urls={urls}
                        currentVersion={currentVersion}
                        onChangeCurrentVersion={onChangeCurrentVersion}
                        onChangeTier={onChangeTier}
                    />
                </Route>
                <Route exact path={`${path}/:else`}>
                    {renderEmptyOr404}
                </Route>
                <Route exact path={`${path}`}>
                    {redirectToFirst}
                </Route>
            </Switch>
            {/*<BuildCategory*/}
            {/*    currentTier={currentTier}*/}
            {/*    onChangeTier={onChangeTier}*/}
            {/*    changeVersion={onChangeCurrentVersion}*/}
            {/*    filterCurrentVersion={currentVersion}*/}
            {/*    champion_id={Number(champion.champion_id)}*/}
            {/*    buildInfo={buildInfo}*/}
            {/*    urls={urls ? urls : []}*/}
            {/*    selected={currentLane}*/}
            {/*/>*/}
            {/*<ChampionsDetailSummary*/}
            {/*    balance={currentBuild?.balance}*/}
            {/*    banList={currentBuild?.ban_recommendation_list}*/}
            {/*    summary={currentBuild}*/}
            {/*/>*/}

        </Wrapper>
    );
};

export default ChampionsDetailBuild;

import React, {useMemo} from 'react';
import styled from "styled-components";
import ChampionsRankingTableContent from "./ChampionsRankingTableContent";
import {useSelector} from "react-redux";
import ChampionRankingTableHeader from "./ChampionRankingTableHeader";
import {capitalizeLetter, sortChampData} from "../../../../../function";
import {Loading} from "../../../../common";
import InternalServerError from "../../../../common/InternalError";
import useSortArray from "../../../../../common/hooks/useSortArray";


const Wrapper = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TBody = styled.tbody`

  tr:nth-child(even) {
    background-color: ${props => props.theme.color.background_F2F4};
  }
`;

const initSort = [{
    value: 'rank',
    sortType: false,
    reverseSort: true,
}];

const initSortList = {
    rank: {
        value: 'rank',
        sortType: false,
        reverseSort: true,
    },
    tier: {
        value: 'tier',
        sortType: true,
        reverseSort: true,
    },
    ai_score: {
        value: 'ai_score',
        sortType: true,
    },
    win_rate: {
        value: 'win_rate',
        sortType: true,
    },
    pick_rate: {
        value: 'pick_rate',
        sortType: true,
    },
    ban_rate: {
        value: 'ban_rate',
        sortType: true,
    },
}

const LoadingWrapper = styled.div`
  width: 100%;
  height: 30vh;
`;


const ChampionsRankingTable = ({ranks = [], lane, pageValue = 99}) => {
    const version = useSelector((state) => state.gameVersion.version);
    const championData = useSelector((state) => state?.imageStore.champions);
    const {
        getChampionsRankLoading,
        getChampionsRankError
    } = useSelector((state) => state.champions);
    const {sort, sortList, onClickSort} = useSortArray({
        initSort: initSort,
        initSortList: initSortList,
    })



    const rankMemo = useMemo(() => {
        const currentData = ranks.map((mapData) => ({
            ...mapData?.performance_dict[capitalizeLetter(lane)],
            champion_id: mapData.champion_id,
        }));
        return sortChampData(currentData, sort);
    }, [sort, ranks, lane]);


    if (getChampionsRankError) return (
        <InternalServerError/>
    );
    if (championData.length === 0 || getChampionsRankLoading) return (
        <LoadingWrapper>
            <Loading/>
        </LoadingWrapper>
    );

    if (ranks.length === 0) return (
        <InternalServerError errorText={"There are no data"}/>
    );

    return (
        <Wrapper>
            <colgroup>
                <col width={'12%'}/>
                <col/>
                <col width={'15%'}/>
                <col width={'15%'}/>
                <col width={'15%'}/>
                <col width={'15%'}/>
                <col width={'15%'}/>
            </colgroup>
            <thead>
            <ChampionRankingTableHeader
                currentSort={sort}
                onClickSort={onClickSort}
                sortData={sortList}
            />
            </thead>
            <TBody>
                {Array.isArray(ranks) && rankMemo.slice(0, pageValue).map((data, index) => {
                    return (
                        <ChampionsRankingTableContent
                            lane={lane}
                            tier={data.tier}
                            rankDelta={data.rank_delta}
                            champion_id={data.champion_id}
                            pickRate={data?.pick_rate}
                            winRate={data?.win_rate}
                            banRate={data?.ban_rate}
                            aiScore={data?.ai_score}
                            key={data.rank + lane + data.champion_id}
                            counter={data?.counter || []}
                            rank={data.rank}
                            version={version}
                        />
                    )
                })}
            </TBody>
        </Wrapper>
    );
};


export default ChampionsRankingTable;

import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";

const SummonerAdBanner = ({status}) => {
    const currentLang = useSelector((state) => state.translation.lang);
    const country = useSelector((state) => state?.imageStore.country);
    const [imgError, setImgError] = useState(false);


    useEffect(() => {
        setImgError(false)
    }, [currentLang])

    if (imgError) {
        return (
            <div>
                {country === 'KR' ?
                    <iframe
                        src="https://ads-partners.coupang.com/widgets.html?id=798384&template=carousel&trackingCode=AF1158950&subId=&width=1080&height=50&tsource="
                        width="1080" height="50" frameBorder="0" scrolling="no" referrerPolicy="unsafe-url"
                        browsingtopics></iframe>
                    :
                    <a href={'https://forms.gle/NYMLvh77mSW6eBscA'} target={'_blank'}>
                        <img src={'./images/ad_geng_en.png'} width={'100%'} height={50}
                             className={'add_to_Gen_Global'} alt={''}/>
                    </a>
                }
            </div>

        )
    }

    if (!status?.title_url || status?.title_url === '-') {
        return (
            <div>
                {country === 'KR' ?
                    <iframe
                        src="https://ads-partners.coupang.com/widgets.html?id=798384&template=carousel&trackingCode=AF1158950&subId=&width=1080&height=50&tsource="
                        width="1080" height="50" frameBorder="0" scrolling="no" referrerPolicy="unsafe-url"
                        browsingtopics></iframe>
                    :
                    <a href={'https://forms.gle/NYMLvh77mSW6eBscA'} target={'_blank'}>
                        <img src={'./images/ad_geng_en.png'} width={'100%'} height={50}
                             className={'add_to_Gen_Global'} alt={''}/>
                    </a>
                }
            </div>

        )
    }

    return (
        <div>
            <a href={status.title_url} target={'_blank'} rel={'noreferrer'}>
                <img
                    src={`${process.env.REACT_APP_ROOT_IMG}sub_title/${status?.name}_${currentLang === 'KOR' ? 'kr' : 'en'}_title_1.png`}
                    width={'100%'}
                    height={50} alt={''}
                    className={'sub_title_banner'}
                    onError={() => {
                        setImgError(true);
                    }}
                />
            </a>
        </div>
    );
};

export default SummonerAdBanner;
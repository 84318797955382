import React from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {FlexBox} from "../../ui/styled";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  font-size: 26px;
  line-height: 38px;
  color: ${props => props.theme.color.default_gray_font_color};
  font-weight: 500;
`;
const Extra = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.color.font_7F};
  margin-top: 5px;
`;

const DailyHeader = () => {
    const {t} = useTranslation();
    return (
        <Wrapper>
            <Title>{t('daily.title')}</Title>
            <FlexBox justifyContent={'space-between'}>
                <Extra>{t('daily.titleSub')}</Extra>
                <Extra>{t('daily.5min')}</Extra>
            </FlexBox>
        </Wrapper>
    );
};

export default DailyHeader;
import React, {useCallback, useMemo} from 'react';
import styled from "styled-components";
import ChampionPassiveImage from "../../../common/ChampionPassiveImage";
import ChampionSkillImage from "../../../common/ChampionSkillImage";
import {useTranslation} from "react-i18next";
import useQuery from "../../../../common/hooks/useQuery";
import {useHistory} from "react-router-dom";
import {ErrorBoundary} from "react-error-boundary";
import useChampionFunc from "../../../../common/hooks/useChampionFunc";
import {useSelector} from "react-redux";

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  background-color: ${props => props.theme.color.summoner.tab.background_color};
  border: 1px solid ${props => props.theme.color.default_border_color};
  border-radius: 6px;
`;


const ChampionImage = styled.img`
  margin: -3px;
`;

const ChampionImageWrapper = styled.div`
  border-radius: 4px;
  overflow: hidden;
  margin-right: 12px;
  height: fit-content;
  z-index: 99;
`;


const ChampionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 20px 0 0 20px;
`;

const ChampionSpellWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const SpellAndNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 91px;

`;
const ChampionNameText = styled.span`
  font-size: 22px;
  font-weight: 500;
  color: ${props => props.theme.color.default_gray_font_color};
`;

const BuildText = styled.span`
  font-size: 12px;
  opacity: 0.8;
  margin-left: 12px;
`;

const ChampionNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PatchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const BuildInfoText = styled.span`
  margin-top: 13.5px;
  font-size: 12px;
  opacity: 0.8;
  white-space: pre-wrap;
  line-height: 15px;
  max-width: 470px;
`;

const VideoAd = styled.div`
  width: 410px;
  min-height: 210px;
`;


const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;


const ChampionsDetailHeader = ({champion, version, patchHistory, skillList}) => {
    const {t, i18n} = useTranslation();
    const isKorean = useMemo(() => ["ko", "ko-KR"].includes(i18n.language), [i18n.language])
    const buildVersion = useSelector((state) => state.champions.championVersions);
    const query = useQuery();
    const history = useHistory();
    const {getChampionName} = useChampionFunc()


    const laneTrans = useCallback((data) => {
        switch (data) {
            case "top": {
                return t('championAnalyze.top')
            }
            case "jungle" : {
                return t('championAnalyze.jungle')
            }
            case "middle" : {
                return t('championAnalyze.mid')
            }
            case "bot" : {
                return t('championAnalyze.bot')
            }
            case "supporter": {
                return t('championAnalyze.support')
            }
            case "aram": {
                return t("championsHistory.title.aram")
            }
            case "singular": {
                return "단일 챔피언"
            }
            default:
                return ""
        }
    }, []);

    const tierTrans = useCallback((data) => {
        switch (data.trim()) {
            case "Emerald": {
                return t('gameAnalytics.emerald')
            }
            case "Platinum": {
                return t('gameAnalytics.platinum')
            }
            case "Diamond" : {
                return t('gameAnalytics.diamond')
            }
            case "Master" : {
                return t('gameAnalytics.master')
            }
            case "Grandmi18naster" : {
                return t('gameAnalytics.grandmaster')
            }
            case "Challenger": {
                return t('gameAnalytics.challenger')
            }
            default:
                return ""
        }
    }, []);

    const tier = useMemo(() => {
        try {
            if (history.location.pathname.split('/')[4] !== 'aram') {
                const tier = query.get('tier');
                return tier ? `${tierTrans(tier)}` : `${tierTrans("Emerald")}`;
            }
            return "";
        } catch (e) {
            return "";
        }
    }, [window.location.href, i18n.language]);

    const currentBuild = useMemo(() => {
        try {
            const lane = history.location.pathname.split('/')[4];
            if (lane) {
                return laneTrans(lane);
            } else {
                return "";
            }
        } catch (e) {
            return "";
        }
    }, [window.location.href, i18n.language]);

    const currentVersion = useMemo(() => {
        try {
            const queryVersion = query.get('version');
            return queryVersion ? queryVersion : buildVersion[0];
        } catch (e) {
            return "";
        }

    }, [window.location.href, i18n.language, buildVersion])


    return (
        <Wrapper>
            <ChampionWrapper>
                <ChampionImageWrapper>
                    <ChampionImage
                        width={97}
                        height={97}
                        src={`${process.env.REACT_APP_IMG}${version}/img/champion/${champion?.image_name}?f=webp`}
                        onError={(e) => e.target.src = "/images/icon_non_item.svg"}
                    />
                </ChampionImageWrapper>
                <InfoWrapper>
                    <SpellAndNameWrapper>
                        <ChampionNameWrapper>
                            <ChampionNameText>{champion?.champion_name}</ChampionNameText>
                            <ErrorBoundary fallback={null}>
                                {history.location.pathname.split('/')[3] === 'build' &&
                                    <BuildText>{currentBuild} {t("championDetail.build")}, {tier ? tier + "," : ""} {t('gameAnalytics.patch')} {currentVersion}</BuildText>}
                            </ErrorBoundary>
                        </ChampionNameWrapper>
                        <ChampionSpellWrapper>
                            <ChampionPassiveImage
                                reverse={true}
                                margin={'0 5px 0 0'}
                                version={version}
                                width={36}
                                height={36}
                                img={skillList?.passive?.image?.full}
                                tooltip={`<span style="font-weight: 500; color: #46B9AA;">${skillList?.passive?.name}</span>\n${skillList?.passive?.description}`}
                            />
                            <ChampionSkillImage
                                reverse={true}
                                margin={'0 5px 0 0'}
                                width={36}
                                height={36}
                                spell={skillList?.spells[0]}
                                version={version}
                                absoluteText={'Q'}
                                img={skillList?.spells[0]?.image?.full}

                            />
                            <ChampionSkillImage
                                reverse={true}
                                margin={'0 5px 0 0'}
                                width={36}
                                height={36}
                                spell={skillList?.spells?.[1]}
                                version={version}
                                absoluteText={'W'}
                                img={skillList?.spells[1]?.image?.full}
                            />
                            <ChampionSkillImage
                                reverse={true}
                                margin={'0 5px 0 0'}
                                width={36}
                                height={36}
                                spell={skillList?.spells?.[2]}
                                tooltip={`${champion.spells?.[2]?.spell_name_kr}\n${champion.spells?.[2]?.tooltip}`}
                                version={version}
                                absoluteText={'E'}
                                img={skillList?.spells[2]?.image?.full}
                            />
                            <ChampionSkillImage
                                reverse={true}
                                margin={'0 0 0 0'}
                                width={36}
                                height={36}
                                spell={skillList?.spells?.[3]}
                                tooltip={`${champion.spells?.[3]?.spell_name_kr}\n${champion.spells?.[3]?.tooltip}`}
                                version={version}
                                absoluteText={'R'}
                                img={skillList?.spells[3]?.image?.full}
                            />
                        </ChampionSpellWrapper>
                    </SpellAndNameWrapper>
                    {history.location.pathname.split('/')[3] === 'build' && <BuildInfoText>
                        {t('championDetailBuild.headerInfo', {
                            currentBuild,
                            champion: getChampionName(champion),
                            currentVersion,
                            tier,
                        })}
                    </BuildInfoText>}
                </InfoWrapper>
            </ChampionWrapper>
            <div id="trendi-player-container">
                <VideoAd className={'ad-video-box'} id="trendi-player-placeholder">

                </VideoAd>
            </div>
        </Wrapper>
    );
};


export default React.memo(ChampionsDetailHeader);

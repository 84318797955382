import React, {useCallback} from 'react';
import styled from "styled-components";

const ListLI = styled.li`
  display: flex;
  justify-content: center;
  flex: 1;
  align-items: center;
  cursor: pointer;
  position: relative;
  background-color: ${props => {
    let color = props.theme.color.background_E1;
    if (props.selected) color = props.theme.color.background_fff_35;
    return color;
  }};

  border: 1px solid ${props => {
    let color = 'transparent';
    if(props.selected) color =props.theme.color.border_BB_60;
    return color;
  }};

`;

const ListText = styled.span`
  flex: 1;
  margin-left: 4px;
  font-size: 0.85rem;
  color: ${props => {
    let color = '#828282';
    if (props.selected) color = props => props.theme.color.default_gray_font_color;
    return color;
  }};
  font-weight: ${props => {
    let color = '';
    if (props.selected) color = 'bold';
    return color;
  }};;
  line-height: 22px;
`;

const LaneImage = styled.img`
  opacity: ${props => {
    let opacity = '0.5';
    if (props.selected) opacity = '1';
    return opacity
  }};
`;

const DataWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const SearchListLI = ({data, index, selected, onClick, lane,src,visibleText}) => {
    const onClickList = useCallback(() => {
        if (onClick !== undefined && index!== undefined) {
            onClick(index,lane);
        }
    }, []);

    return (
        <ListLI
            onClick={onClickList}
            selected={selected}
        >
            <DataWrapper>
                {src && <LaneImage
                    selected={selected}
                    width={16} height={16} src={src}/>}
                {visibleText && <ListText selected={selected}>{data}</ListText>}
            </DataWrapper>
        </ListLI>
    );
};


export default React.memo(SearchListLI);

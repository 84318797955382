import React, {useMemo} from 'react';
import styled from "styled-components";
import BalanceText from "../../../../../common/BalanceText";

const Wrapper = styled.div`

  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Stick = styled.div`

  display: flex;
  flex-direction: row;
  align-items: center;
  border-right: 1px solid ${props => props.theme.color.border_A5_EA};
  height: 20px;
  transform: rotate(${props => {
    let rotate = 180;
    if (props.isPositive) rotate = 0;
    return rotate
  }}deg);
`;

const Absolute = styled.div`
  margin: 0 ${props => {
    let margin = '0';
    if (props.theme.color.theme === 'dark') {
      margin = '1px';
    }
    return margin;
  }};
  width: 82px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
`;

const Bar = styled.div`
  height: 8px;
  width: ${props => Math.abs(props.width)}%;
  background-color: ${props => {
    if (!props.colorReverse) {
      let color = '#E96767';
      if (props.isPositive > 0) color = '#1CA484';
      return color;
    } else {
      let color = '#1CA484';
      if (props.isPositive > 0) color = '#E96767';
      return color;
    }
  }};
`;

const DegBox = styled.div`
  margin-inline: 2px;
  transform: rotate(${props => {
    let rotate = 180;
    if (props.isPositive) rotate = 0;
    return rotate
  }}deg);
`;


const AramBalanceTablePivot = ({value, colorReverse, max}) => {

    const calValue = useMemo(() => {
        return Number(value) > 0
    }, [value]);


    return (
        <Wrapper>
            <Stick isPositive={calValue}>
                <Absolute>
                    <Bar width={value / max * 65} isPositive={calValue} colorReverse={colorReverse}/>
                    <DegBox isPositive={calValue}>
                        <BalanceText colorReverse={colorReverse}>{value}</BalanceText>
                    </DegBox>
                </Absolute>
            </Stick>
        </Wrapper>
    );
};

export default AramBalanceTablePivot;

import React, {useCallback, useMemo} from 'react';
import styled from "styled-components";
import ScoreWrapper from "../../DetailTab/GameAnalytics/BestContent/BestSection/ScoreWrapper";
import {getTierScore, intlMinuetsTimeFormatter} from "../../../../function";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
  margin-top: 16px;
  padding-left: 12px;
  margin-bottom: 5.5px;
`;

const DateSpan = styled.span`
  font-size: 12px;
  color: ${props => props.theme.color.font_58};
  font-weight: 500;
  margin-right: 5px;
`;


const SummaryWrapper = styled.div`
  margin-left: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const StaticText = styled.span`
  text-transform: capitalize;
  font-size: 12px;
  color: ${props => props.theme.color.font_58};
`;

const SlashText = styled(StaticText)`
  transform: scale(0.775);
  margin-inline: 6px;
`;

const InfoText = styled.span`
  font-size: 12px;
  font-weight: 400;
  margin: 2px;
  color: ${props => props.theme.color.font_58};
`;

const RestDayInfo = ({myMatch, timestamp, duration, currentLang, tierArray}) => {
    const {t} = useTranslation();
    const initObj = {
        games: 0,
        wins: 0,
        lose: 0,
        ai_score: 0,
    }

    const summaryMatchMemo = useMemo(() => {
        if (Array.isArray(myMatch) && myMatch.length > 0) {
            return myMatch.reduce((sum, cur) => {
                sum = {
                    games: sum.games + 1,
                    wins: cur.is_win ? sum.wins + 1 : sum.wins,
                    lose: cur.is_win ? sum.lose : sum.lose + 1,
                    ai_score: sum.ai_score + cur.ai_score,
                }
                return sum;
            }, {...initObj})
        }
        return {...initObj}
    }, [myMatch]);

    const winRateCalc = useCallback((games, win) => {
        if (games === 0) {
            return 0;
        }
        if (win === 0) {
            return 0;
        }
        return Math.trunc(win / games * 100)

    }, []);

    const avgTier = useMemo(() => {
        try {
            const filterZero = tierArray.filter((data) => data !== 0);
            const sum = filterZero.reduce((sum, cur) => {
                return sum + cur;
            }, 0);

            const length = filterZero.length === 0 ? 1 : filterZero.length;
            return getTierScore(Math.trunc(sum / length)).obj
        } catch (e) {
            return {tier: "", division: ""}
        }
    }, [tierArray]);

    const korHour = '시간';
    const engHour = 'h';

    const korMin = '분';
    const engMin = 'm';

    const getLangHour = () => {
        return currentLang === 'KOR' ? korHour : engHour;
    }

    const getLangMin = () => {
        return currentLang === 'KOR' ? korMin : engMin;
    }

    const durationHour = useMemo(() => {
        try {
            const hour = Math.floor(duration / 3600);
            if(hour > 0) {
                return `${hour}${getLangHour(currentLang)}`
            } else {
                return "";
            }
        } catch (e) {
            return "";
        }
    }, [duration,currentLang]);

    const durationMin = useMemo(() => {
        try {
            const min = Math.floor(duration / 60 % 60);
            if(min > 0) {
                return `${min}${getLangMin(currentLang)}`
            } else {
                return `0${getLangMin(currentLang)}`;
            }
        } catch (e) {
            return "";
        }
    }, [duration,currentLang]);

    const durationVisible = useMemo(() => {
        if(durationHour) {
            return `${durationHour} ${durationMin}`;
        } else {
            return durationMin
        }
    },[durationHour,durationMin]);

    return (
        <Wrapper>
            <DateSpan>{intlMinuetsTimeFormatter(timestamp * 1000, currentLang)}</DateSpan>
            <SummaryWrapper>
                <InfoText>{summaryMatchMemo.games}</InfoText>
                <StaticText>{t('summoner.games')}</StaticText>
                <SlashText>/</SlashText>
                <InfoText>{summaryMatchMemo.wins}</InfoText>
                <StaticText>{t('gameAnalytics.win')}</StaticText>
                <InfoText>{summaryMatchMemo.lose}</InfoText>
                <StaticText>{t('gameAnalytics.lose')}</StaticText>
                <SlashText>/</SlashText>
                <InfoText>{winRateCalc(summaryMatchMemo.games, summaryMatchMemo.wins)}</InfoText>
                <StaticText>%</StaticText>
                <SlashText>/</SlashText>
                <ScoreWrapper
                    noneClickable={true}
                    score={summaryMatchMemo.ai_score / summaryMatchMemo.games}
                    fontSize={12}
                    paddingHorizontal={6}
                    paddingVertical={2}
                />
                <SlashText>/</SlashText>
                <StaticText>{`${avgTier.tier} ${avgTier.division}`}</StaticText>
                <SlashText>/</SlashText>
                <StaticText>{durationVisible}</StaticText>
            </SummaryWrapper>
        </Wrapper>
    );
};

export default RestDayInfo;

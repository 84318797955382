import React, {useCallback} from 'react';
import Dropdown from "../../compound/Dropdown";
import RegionTag from "../../common/NewSearch/RegionTag";
import regionList from "../../../store/static_json/regionList";
import {changeRegion} from "../../../store/modules/region";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import DownArrow from "../../common/TagWithArrow/DownArrow";
import {dark, light} from "../../../common/theme";

const RowAbosulute = styled.div`
  padding: 7px;
  z-index: 1;
  position: absolute;
  top: ${props => {
    let top = '130%';
    if (props.top) top = props.top;
    return top;
  }};
  width: 202px;
  left: 0;
  box-shadow: 0px 1px 6px #00000029;
  border: 1px solid ${props => props.theme.color.border_6B_60};
  background-color: ${props =>  props.theme.color.default_input_background};
  border-radius: 6px;

  .dropdown-item {
    margin: 6px 7px;
  }
`;


const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const ListTitle = styled.div`
  color: ${props => props.theme.color.default_gray_font_color};
  font-size: 11px;
  margin-left: 7px;
  margin-bottom: 13px;
  margin-top: 7px;
`;

const ListContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;


const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const ArrowWrapper = styled.div`
  margin-left: 12px;
`;

const SearchRegion = ({
                          region,
                          buttonWidth = "48px",
                          buttonHeight = "28px",
                          buttonFontSize,
                          buttonRadius,
                          top = '130%'
                      }) => {
    const dispatch = useDispatch();
    const themeColor = useSelector((state) => state.theme.color);
    const theme = themeColor === 'light' ? light : dark;

    const onClickRegionClick = useCallback((title) => {
        dispatch(changeRegion(title));
    }, []);


    return (
        <Dropdown>
            <Dropdown.Button>
                <ButtonWrapper>
                    <RegionTag
                        customWeight={'500'}
                        borderRadius={buttonRadius}
                        fontSize={buttonFontSize}
                        height={buttonHeight}
                        width={buttonWidth}
                        selected={region}
                        isDisabled={false}
                        title={region}
                    />
                    <ArrowWrapper>
                        <DownArrow color={theme.search.searchArrow_bg} size={4}/>
                    </ArrowWrapper>
                </ButtonWrapper>
            </Dropdown.Button>
            <Dropdown.List>
                <RowAbosulute top={top}>
                    <ListWrapper>
                        <ListTitle>
                            Region
                        </ListTitle>
                        <ListContent>
                            {regionList.map((data) => {
                                return (
                                    <Dropdown.Item key={data.title}>
                                        <RegionTag
                                            customWeight={'500'}
                                            height={'28px'}
                                            width={'48px'}
                                            onClick={onClickRegionClick}
                                            selected={region === data.title}
                                            isDisabled={data.disabled}
                                            title={data.title}
                                        /></Dropdown.Item>)
                            })}
                        </ListContent>
                    </ListWrapper>
                </RowAbosulute>
            </Dropdown.List>
        </Dropdown>
    );
};

export default SearchRegion;

import React, {useCallback, useMemo} from 'react';
import styled, {useTheme} from "styled-components";
import TierTag from "../../../../common/TierTag";
import {useSelector} from "react-redux";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 17px;
`;

const TagWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-inline: 5px;
`;


const ArrowBox = styled.div`
  height: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;


const baseTier = {
    IRON4: {
        value: 47,
        ranks: 'I4',
        tier: "iron",
        division: 4,
    },
    IRON3: {
        value: 47,
        ranks: 'I3',
        tier: "iron",
        division: 3
    },
    IRON2: {
        value: 47,
        ranks: 'I2',
        tier: "iron",
        division: 2
    },
    IRON1: {
        value: 47,
        ranks: 'I1',
        tier: "iron",
        division: 1
    },
    BRONZE4: {
        value: 47,
        ranks: 'B4',
        tier: "bronze",
        division: 4
    },
    BRONZE3: {
        value: 47,
        ranks: 'B3',
        tier: "bronze",
        division: 3
    },
    BRONZE2: {
        value: 47,
        ranks: 'B2',
        tier: "bronze",
        division: 2
    },
    BRONZE1: {
        value: 47,
        ranks: 'B1',
        tier: "bronze",
        division: 1
    },
    SILVER4: {
        value: 48,
        ranks: 'S4',
        tier: "silver",
        division: 4
    },
    SILVER3: {
        value: 48,
        ranks: 'S3',
        tier: "silver",
        division: 3
    },
    SILVER2: {
        value: 48,
        ranks: 'S2',
        tier: "silver",
        division: 2
    },
    SILVER1: {
        value: 48,
        ranks: 'S1',
        tier: "silver",
        division: 1
    },
    GOLD4: {
        value: 48,
        ranks: 'G4',
        tier: "gold",
        division: 4
    },
    GOLD3: {
        value: 48,
        ranks: 'G3',
        tier: "gold",
        division: 3
    },
    GOLD2: {
        value: 48,
        ranks: 'G2',
        tier: "gold",
        division: 2
    },
    GOLD1: {
        value: 48,
        ranks: 'G1',
        tier: "gold",
        division: 1
    },
    PLATINUM4: {
        value: 48,
        ranks: 'P4',
        tier: "platinum",
        division: 4
    },
    PLATINUM3: {
        value: 48,
        ranks: 'P3',
        tier: "platinum",
        division: 3
    },
    PLATINUM2: {
        value: 48,
        ranks: 'P2',
        tier: "platinum",
        division: 2
    },
    PLATINUM1: {
        value: 48,
        ranks: 'P1',
        tier: "platinum",
        division: 1
    },
    EMERALD4: {
        value: 49,
        ranks: 'E4',
        tier: "emerald",
        division: 4
    },
    EMERALD3: {
        value: 49,
        ranks: 'E3',
        tier: "emerald",
        division: 3
    },
    EMERALD2: {
        value: 49,
        ranks: 'E2',
        tier: "emerald",
        division: 2
    },
    EMERALD1: {
        value: 49,
        ranks: 'E1',
        tier: "emerald",
        division: 1
    },
    DIAMOND4: {
        value: 50,
        ranks: 'D4',
        tier: "diamond",
        division: 4
    },
    DIAMOND3: {
        value: 50,
        ranks: 'D3',
        tier: "diamond",
        division: 3
    },
    DIAMOND2: {
        value: 51,
        ranks: 'D2',
        tier: "diamond",
        division: 2
    },
    DIAMOND1: {
        value: 52,
        overValue: 0,
        ranks: 'D1',
        tier: "diamond",
        division: 1
    },
    MASTER1: {
        value: 53,
        overValue: 100,
        ranks: 'M',
        tier: "master",
        division: 1
    },
    GRANDMASTER1: {
        value: 53,
        overValue: 1000,
        ranks: 'GM',
        tier: "grandmaster",
        division: 1
    },
    CHALLENGER1: {
        value: 53,
        overValue: 10000,
        ranks: 'C',
        tier: "challenger",
        division: 1
    }
}
const ranks = ['I4', 'I3', 'I2', 'I1', 'B4', 'B3', 'B2', 'B1', 'S4', 'S3', 'S2', 'S1', 'G4', 'G3', 'G2', 'G1', 'P4', 'P3', 'P2', 'P1', 'E4', 'E3', 'E2', 'E1', 'D4', 'D3', 'D2', 'D1', 'M', 'M', 'M', 'GM', 'GM', 'GM', 'C'];
const aiArray = [-3, -2, -2, -2, -2, -1, -1, -1, -1, 0, 0, 0, 0, 0, 1, 1, 1, 1, 2, 2, 2, 2, 3];

const TierPrediction = ({tier, division, ai_score, leaguePoints}) => {
    const tierCut = useSelector((state) => state.summoner.tierCut);
    const theme = useTheme();


    const predictionCalculate = useCallback((aiScore) => {
        if (tier && division) {
            let userTier = tier.toUpperCase() + division;
            if (tier.toUpperCase() === 'CHALLENGER') {
                userTier = 'CHALLENGER1'
            } else if (tier.toUpperCase() === 'GRANDMASTER') {
                userTier = 'GRANDMASTER1'
            } else if (tier.toUpperCase() === 'MASTER') {
                userTier = 'MASTER1';
            }

            const tiers = {
                ...baseTier,
                MASTER1: {
                    ...baseTier.MASTER1,
                    overValue: tierCut?.grandmaster_cut_off,

                },
                GRANDMASTER1: {
                    ...baseTier.GRANDMASTER1,
                    overValue: tierCut?.challenger_cut_off,
                },
            }


            if (!tiers[userTier]) {
                userTier = 'BRONZE1';
            }

            const calculatedValue = Math.trunc(aiScore - tiers[userTier].value);


            let caledA4alue;

            const userTierValue = ranks.findIndex((data) => data === tiers[userTier].ranks);
            if (calculatedValue < -9) {
                caledA4alue = aiArray[0];
            } else if (calculatedValue > 12) {
                caledA4alue = aiArray[aiArray.length - 1];
            } else {
                caledA4alue = aiArray[(calculatedValue + 9)]
            }


            if (userTier === 'MASTER1' || userTier === 'GRANDMASTER1' || userTier === "CHALLENGER1") {

                const lpcal = leaguePoints + (caledA4alue * 100);

                const currentTier = tiers[userTier];
                const prevTier = Object.entries(tiers).find((data) => data[1].ranks === ranks[userTierValue - 1])[1];

                if (lpcal > currentTier.overValue) {
                    caledA4alue = 3;
                } else if (prevTier.overValue > lpcal) {
                    caledA4alue = -1;
                } else {
                    caledA4alue = 0;
                }
            }

            // let resultNum = 0;
            let resultTxt;
            const predictionValue = userTierValue + caledA4alue;


            if (predictionValue > ranks.length - 1) {
                resultTxt = ranks[ranks.length - 1];
            } else if (predictionValue < 0) {
                resultTxt = ranks[0];
            } else {
                resultTxt = ranks[predictionValue];
            }

            const result_tier = Object.values(tiers).find((data) => data.ranks === ranks[(!predictionValue && predictionValue < 0) ? 0 : predictionValue]);

            if (result_tier) {

                return {tier: result_tier.tier, division: result_tier.division};
            }
            return {tier: 'BRONZE', division: 1,}
        }
        return {tier: 'UNKNOWN', division: "",}
    }, [tier, division, tierCut, leaguePoints]);

    const predictMemo = useMemo(() => {
        if (Array.isArray(ai_score) && ai_score.length > 0) {
            return predictionCalculate(ai_score.reduce((a, b) => a + b, 0) / ai_score.length)
        } else {
            return {
                tier: '',
                division: '',
            }
        }

    }, [ai_score]);


    if (typeof tier !== 'string' || typeof division !== "number" || typeof tierCut !== 'object' || !Array.isArray(ai_score) || ai_score.length < 8 || !tier) return null;

    return (
        <Wrapper>
            <TagWrapper>
                <TierTag
                    fontSize={'11px'}
                    padding={'4px 4px'}
                    tier={tier}
                    division={division}
                />
            </TagWrapper>

            <ArrowBox>
                <img src={`${theme.icon.path}/ico_cell_right_arrow_w.svg`} width={10} height={8} alt={""}/>
            </ArrowBox>
            <TagWrapper>
                <TierTag
                    fontSize={'11px'}
                    padding={'4px 4px'}
                    testId={"ai_score_tag"}
                    tier={predictMemo?.tier}
                    division={predictMemo?.division}
                />
            </TagWrapper>
        </Wrapper>
    );
};

export default TierPrediction;

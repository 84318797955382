import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {changeRegion} from "../../../store/modules/region";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import SearchList from "../../common/SearchList";
import BookmarkList from "../../common/BookmarkList";
import SearchRegion from "../../Search/SearchRegion";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import useChampionFunc from "../../../common/hooks/useChampionFunc";
import PlaceHolderTag from "../../common/PlaceHolderTag";
import {light} from "../../../common/theme";
import useSummonerSearch from "../../../common/hooks/useSummonerSearch";
import _ from "lodash";
import AutoComplete from "../../common/NewSearch/AutoComplete";
import {replaceBlockUnicode} from "../../../function";


const SearchAndIconWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 6px 0;
`;
const Wrapper = styled.div`
  position: relative;
  padding: 8px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  flex: 1;
`;


const SearchInput = styled.input`
  height: 100%;
  font-size: 14px;
  width: 100%;
  border: 0;
  padding: 0 6px;

  &:focus::-webkit-input-placeholder {
    color: transparent;
  }

  &:focus:-moz-placeholder {
    color: transparent;
  }

  &:focus::-moz-placeholder {
    color: transparent;
  }

  &:focus:-ms-input-placeholder {
    color: transparent;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #fff;
  height: 100%;
  border-radius: 3px;
  width: 100%;
  z-index: 99;
`;

const AlignBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline: 10px;
  cursor: pointer;
`;

const LangBox = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 3px 0 0 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;


const SearchImage = styled.img`
`;

const BackDrop = styled.div`
  display: ${props => {
    let display = 'none';
    if (props.backdrop) display = 'flex';
    return display;
  }};
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  opacity: 0.4;
  width: 100%;
  height: 100%;
  z-index: 80;
`;

const AbsoluteBox = styled.div`
  z-index: 99999;
  width: 100%;
  display: ${props => {
    let display = 'none';
    if (props.backdrop) display = 'flex';
    return display;
  }};
  top: 50px;
  left: 0;
  flex-direction: column;
  position: absolute;
  border-radius: 3px;
  ${props => {
    if (props.backdrop) {
      return "animation: absolutefade-in 250ms;"
    } else {
      return "animation: absolutefade-out 250ms;"
    }
  }};
  @keyframes absolutefade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes absolutefade-out {
    from {
      opacity: 1;

    }
    to {
      opacity: 0;
    }
  }
`;


const AutoCompleteWrapper = styled.div`
  background-color: transparent;
`;

const RecentWrapper = styled.div`
  box-shadow: 0 1px 6px #00000029;
  border-radius: 6px;

  .react-tabs {
    position: relative;
    background-color: ${props => props.theme.color.default_input_background};
    box-shadow: 0px 1px 6px #00000029;
    border: 1px solid ${props => props.theme.color.border_6B};
    border-radius: 6px;
    overflow: hidden;

    &__tab-list {
      display: flex;
      border-radius: 6px;
    }

    &__tab {
      position: relative;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 37px;
      font-size: 14px;
      font-weight: 500;
      color: ${props => props.theme.color.searchList.tab_font_color};
      background-color: ${props => props.theme.color.searchList.tab_idle_bg};
      cursor: pointer;
      border-bottom: 1px solid ${props => props.theme.color.searchList.tab_border_color};


      &:first-of-type {
        border-right: 1px solid ${props => props.theme.color.searchList.tab_border_color};
      }

      &:last-of-type {
        border-left: 1px solid ${props => props.theme.color.searchList.tab_border_color};
      }


      &--selected {
        background-color: ${props => props.theme.color.default_input_background};
        color: ${props => props.theme.color.searchList.tab_selected_font_color};
        border-bottom: 0;

        &:first-of-type {
          border-top-left-radius: 6px;
          border-left: 0;
          border-right: 0;
        }

        &:last-of-type {
          border-top-right-radius: 6px;
          border-right: 0;
          border-left: 0;
        }
      }
    }

`;


const ServerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 63px;
  padding: 0;
`;


const InputBox = styled.div`
  width: 100%;
  position: relative;
`;

const InputPlaceHolderLabel = styled.label`
  display: flex;
  flex-direction: row;
  position: absolute;
  line-height: 20px;
  cursor: inherit;
  color: #AAAAAA;

  .tag_span {
    font: normal normal normal 14px/20px Noto Sans KR;
  }
`;

const HeaderSearch = ({initLoadingNotFound}) => {
    const {t} = useTranslation();
    const inputRef = useRef(null);
    const history = useHistory();
    const params = useParams();
    const backdropRef = useRef(null);
    const dispatch = useDispatch();
    const [inputValue, setInputValue] = useState('');
    const [summonerName, setSummonerName] = useState('');
    const [, setIsToggled] = useState(false);
    const [backdrop, setBackdrop] = useState(false);
    const [openInputContent, setOpenInputContent] = useState(false);
    const region = useSelector((state) => state.region.result);
    const {getChampionByName, navigateToBuild} = useChampionFunc();
    const [focusInput, setFocusInput] = useState(false);
    const {navigateToSummonerSearch} = useSummonerSearch();

    useLayoutEffect(() => {
        if (params.region && params.region !== region) {
            dispatch(changeRegion(params.region.toUpperCase()))
        }

        if (params.userName === summonerName) {
            setSummonerName("");
        }

    }, [params.region]);


    // 자동완성 debounce
    const autoCompleteDelay = _.debounce((value) => {
        setSummonerName(value);
    }, 1)
    // search input에 값 입력시
    const handleSummonerName = (e) => {

        const {value} = e.target;
        const removeBlock = replaceBlockUnicode(value);
        setInputValue(removeBlock);
        autoCompleteDelay(removeBlock);
    };


    // search button 클릭시
    const handleSummonerSearch = () => {
        if(summonerName[0] === '/') {
            return  history.push({
                pathname: summonerName,
            });
        }
        setBackdrop(false);
        setOpenInputContent(false);
        inputRef?.current?.blur();
        if (initLoadingNotFound) {
            initLoadingNotFound();
        }

        const champion = getChampionByName(summonerName);

        if (champion) {
            return navigateToBuild(champion);
        }


        // eslint-disable-next-line no-useless-escape
        const regExp = /(joined the room.)|(joined the lobby.)|로비에|,|(,\s),/gi;
        if (regExp.test(summonerName) === false) {
            // 전적검색으로
            if (summonerName !== "") {
                if (summonerName.indexOf("#") === -1) {
                    return navigateToSummonerSearch(summonerName)
                }
                history.push({
                    pathname: `/summoner/${region}/${summonerName.replace('#', '-')}`,
                    state: {name: summonerName},
                });
            }
            // history.go(0);
        } else {
            // 멀티서치로
            let arr_str = summonerName.replace(/(joined the room.)|(joined the lobby.)|(님이 로비에 참가했습니다.)|(님이 로비에 참가하셨습니다.)|(がロビーに参加しました)|(,\s)|,|\n|\r/gi, '/');
            arr_str = arr_str.replace(/(\s\/)+|(\s\/)/gi, '/');
            arr_str = arr_str.replace(/(\/\s)|(\/)+/gi, '/');
            arr_str = arr_str.replace(/\/$/gi, '');
            arr_str = arr_str.split('/');

            // 중복, 빈값 제거
            const set = new Set(arr_str);
            const uniqueArr = [...set];
            const modyArr = uniqueArr.filter((item) => item !== '').slice(0, 5);

            history.replace({
                pathname: `/multi/${region}/${modyArr}`,
                state: {name: modyArr},
            });

        }
    };

    // enter 입력시
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') handleSummonerSearch();
    };

    const setBackdropOn = useCallback(() => {
        setBackdrop(true);
    }, []);

    const setBackDropClose = useCallback(() => {
        setIsToggled(false);
        setOpenInputContent(false);
        setBackdrop(false);
    }, [])


    const ServerSelectorChange = useCallback(() => {
        setIsToggled(prev => !prev)
        setOpenInputContent(false);
        setBackdrop(false);
    }, []);

    const onOpenInput = useCallback(() => {
        setOpenInputContent(true);
        setBackdropOn();
    }, []);

    const onFocusInput = () => {
        setFocusInput(true);
    }
    const onFocusOutInput = () => {
        if (summonerName.length === 0) {
            setFocusInput(false);
        }
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (inputRef?.current?.contains(event.target)) {
                setBackdropOn();
            }
            if (!backdropRef?.current?.contains(event.target)) {
                setBackDropClose();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    return (
        <Wrapper>
            <ContentWrapper ref={backdropRef}>
                <LangBox onClick={ServerSelectorChange}>
                    <ServerWrapper>
                        <SearchRegion
                            buttonRadius={'3px'}
                            buttonFontSize={'10px'}
                            buttonWidth={"34px"}
                            buttonHeight={"20px"}
                            region={region.toUpperCase()}
                        />
                    </ServerWrapper>

                </LangBox>
                <SearchAndIconWrapper onClick={onOpenInput}>
                    <InputBox>
                        {!focusInput && <InputPlaceHolderLabel
                            htmlFor={'search-summoner-name'}>{t('search.placeholder')} +<PlaceHolderTag
                            backgroundColor={light.tag.background_color}
                            fontColor={light.font_AC}/></InputPlaceHolderLabel>}
                        <SearchInput
                            autoComplete={"off"}
                            onBlur={onFocusOutInput}
                            onFocus={onFocusInput}
                            id={'search-summoner-name'}
                            type="text"
                            onChange={handleSummonerName}
                            onKeyPress={handleKeyPress}
                            value={inputValue}
                            ref={inputRef}
                        />
                    </InputBox>
                    <AlignBox onClick={handleSummonerSearch}>
                        <SearchImage
                            src={'/images/ico_search_w.svg'}
                            width={18}
                            height={18}
                            alt={'?'}
                        />
                    </AlignBox>
                </SearchAndIconWrapper>

                <AbsoluteBox backdrop={openInputContent}>
                    {summonerName.length > 0 ?
                        <AutoCompleteWrapper>
                            <AutoComplete
                                focus={true}
                                region={region}
                                summonerName={summonerName}
                            />
                        </AutoCompleteWrapper>
                        :
                        <RecentWrapper>
                            <Tabs>
                                <TabList>
                                    <Tab>{t('buttons.recentSearch')}</Tab>
                                    <Tab>{t('buttons.favorite')}</Tab>
                                </TabList>
                                <TabPanel>
                                    <SearchList/>
                                </TabPanel>
                                <TabPanel>
                                    <BookmarkList/>
                                </TabPanel>
                            </Tabs>
                        </RecentWrapper>
                    }
                </AbsoluteBox>
            </ContentWrapper>
            {backdrop && <BackDrop backdrop={backdrop}/>}
        </Wrapper>

    );
};

export default HeaderSearch;

import React, {useCallback} from 'react';
import styled from "styled-components";

const NameLI = styled.li`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 30.2px;
  height: 40px;
  background-color: ${props => {
    let color = props.theme.color.background_E1;
    if (props.selected) color = props.theme.color.background_fff_35;
    return color;
  }};
  color: ${props => {
    let color = '#828282';
    if (props.selected) color = props.theme.color.default_gray_font_color;
    return color;
  }};
  border: 1px solid ${props => {
    let color = 'transparent';
    if(props.selected) color =props.theme.color.border_BB_60;
    return color;
  }};
`;

const NameText = styled.span`
  width: auto;
  font-size: 12px;
  border-radius: 4px;
`;

const CNameLI = ({data, index, onClick, selected}) => {
    const onClickLi = useCallback(() => {
        if (index !== undefined && onClick !== undefined) {
            onClick(index, data);
        }
    }, [])

    return (
        <NameLI selected={selected} onClick={onClickLi}>
            <NameText selected={selected}>{data}</NameText>
        </NameLI>
    );
};

export default React.memo(CNameLI);